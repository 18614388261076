import gql from 'graphql-tag';

export const editorRecipientInputQuery = gql`
  query editorRecipientInput($documentId: String!) {
    editorRecipientInput(documentId: $documentId) {
      fieldId
      value
    }
  }
`;

export const viewerRecipientInputQuery = gql`
  query editorRecipientInput {
    editorRecipientInput {
      fieldId
      value
    }
  }
`;
