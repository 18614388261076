export enum DocumentFilter {
  Active = 'active',
  Draft = 'draft',
  Sent = 'sent',
  Reviewed = 'reviewed',
  Signed = 'signed',
  Expired = 'expired',
  Rejected = 'rejected',
  Recalled = 'recalled',
  Deleted = 'deleted',
}
