import type { Video } from '../../../types/Video';
import { VideoSource } from '../../types/enums/video-source';
import { vimeoPatterns, youtubePatterns } from '../constants/video-link.constants';

export class VideoLinkHelper {
  static getVideoSource(videoUrl): VideoSource {
    if (this.getYoutubeURLValidation(videoUrl).valid) {
      return VideoSource.Youtube;
    }
    if (this.getVimeoURLValidation(videoUrl).valid) {
      return VideoSource.Vimeo;
    }
    return VideoSource.Unknown;
  }

  static getYoutubeURLValidation(url: string): { id: string; valid: boolean } {
    const matchingPattern: number = this.getYoutubeMatchingPattern(url);
    return {
      valid: matchingPattern > -1,
      id: (matchingPattern > -1 && this.getYoutubeId(url, matchingPattern)) || null,
    };
  }

  static getYoutubeMatchingPattern(url: string): number {
    if (!/youtu\.?be/.test(url)) {
      return -1;
    }
    for (let i = 0; i < youtubePatterns.length; i++) {
      if (youtubePatterns[i].test(url)) {
        return i;
      }
    }
    return -1;
  }

  static getYoutubeId(url: string, matchingPattern: number) {
    return youtubePatterns[matchingPattern].exec(url)[1];
  }

  static getVimeoURLValidation(url: string): { valid: boolean; id: string } {
    const matchingPattern: number = this.getVimeoURLMatchingPattern(url);
    return {
      valid: matchingPattern > -1,
      id: (matchingPattern > -1 && this.getVimeoId(url, matchingPattern)) || null,
    };
  }

  static getVimeoURLMatchingPattern(url: string): number {
    if (!/vimeo/.test(url)) {
      return -1;
    }
    for (let i = 0; i < vimeoPatterns.length; i++) {
      if (vimeoPatterns[i].test(url)) {
        return i;
      }
    }
    return -1;
  }

  static getVimeoId(url: string, matchingPattern: number) {
    return vimeoPatterns[matchingPattern].exec(url)[2];
  }

  static checkIfVideoExists(videos: Video[], url: string): boolean {
    if (videos.length === 0) {
      return false;
    }
    return !!videos.find((video: Video) => video.videoUrl === url);
  }
}
