import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import { MergeKey, FieldType, MergeCategory, EditorHelper } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { timezones } from '@getaccept/lib-shared-new/src/constants/time-zone.constants';
import type { Entity } from '@getaccept/lib-shared-new/src/types/entity';
import type { MergeTagsSettings } from '../types/models';
import type { PartiallySignedEvent } from '../types/signed-field';
import type { SignatureInputSettings } from '../types/field';

export class SignatureHelper {
  static getVisibleFields = (fields: Field[]) =>
    fields?.filter(field => (field.inputSettings as SignatureInputSettings).hide === false);

  static senderSignatureField = (fields: Field[], senderId: string) =>
    fields.find(field => field.userId === senderId);

  static recipientSignatures = (fields: Field[]) =>
    fields.filter(field => field.recipientId !== null);

  static getRecipient = (recipients: Recipient[], recipientId: string) =>
    recipients.find(recipient => recipient.id === recipientId);

  static getRecipientSigners = (recipients: Recipient[]) =>
    recipients.filter(recipient => recipient.role === 'signer');

  static getFieldForRecipient = (fields: Field[], recipientId: string) =>
    fields.find(field => field.recipientId === recipientId);

  static removeRecipientField = (fields: Field[], recipients: Recipient[]) =>
    fields.filter(
      field => SignatureHelper.getRecipient(recipients, field.recipientId) || field?.userId !== null
    );

  static formatSignDate = (
    format: string,
    timestamp: string,
    mergeValues: Partial<MergeValues>
  ) => {
    const date = new Date(Number(timestamp) * 1000);
    const entityTimeZone = timezones.find(
      value => value.key === mergeValues?.entity.timezone
    )?.zone;
    const locale = `${(mergeValues.entity as Entity)?.language}-${mergeValues.entity?.countryCode}`;
    switch (format) {
      case 'ISO':
        return new Intl.DateTimeFormat(locale, {
          timeZone: entityTimeZone,
        }).format(date);
      case 'Short':
        return new Intl.DateTimeFormat(locale, {
          month: 'long',
          year: 'numeric',
          day: 'numeric',
          timeZone: entityTimeZone,
        }).format(date);
      case 'Long':
        return new Intl.DateTimeFormat(locale, {
          dateStyle: 'long',
          timeStyle: 'long',
          timeZone: entityTimeZone,
        }).format(date);
      default:
        return t('sign_date').toString();
    }
  };

  static hasPartiallySignedField = (
    signedFields: PartiallySignedEvent[],
    recipientId: string,
    userId: string,
    nodeId: string
  ) => {
    if (userId) {
      return signedFields.find(
        signedField => signedField.eventMetaData === nodeId && signedField.userId === userId
      );
    }
    return signedFields.find(
      signedField => signedField.eventMetaData === nodeId && signedField.recipientId === recipientId
    );
  };

  static findRecipientsWithoutAssignedField = (fields: Field[], recipients: Partial<Recipient[]>) =>
    recipients.filter(
      recipient =>
        fields.filter(field => field.recipientId === recipient.id && field?.userId === null)
          .length === 0
    );

  static sortFields = (fields: Field[], recipients: Recipient[]) =>
    recipients.map(recipient => SignatureHelper.getFieldForRecipient(fields, recipient.id));

  static getMergeTagsForRecipient = (mergeKeys: string[], recipientId: string) =>
    mergeKeys.map(mergeKey => ({
      type: FieldType.Merge,
      category: MergeCategory.Recipient,
      mergeKey,
      userId: null,
      recipientId: recipientId,
    }));

  static getMergeTagsForSender = (mergeKeys: string[], userId: string) =>
    mergeKeys.map(mergeKey => {
      if (mergeKey === MergeKey.companyName || mergeKey === MergeKey.companyNumber) {
        return {
          type: FieldType.Merge,
          category: MergeCategory.Entity,
          mergeKey:
            mergeKey === MergeKey.companyName ? MergeKey.companyName : MergeKey.registeredNumber,
          userId,
          recipientId: null,
        };
      }
      return {
        type: FieldType.Merge,
        category: MergeCategory.Sender,
        mergeKey,
        userId: userId,
        recipientId: null,
      };
    });

  static getMergeValueForSender = (mergeValues: MergeValues, mergeKey: string) => {
    if (mergeKey === MergeKey.companyName) {
      return mergeValues.entity[mergeKey];
    }
    if (mergeKey === MergeKey.companyNumber) {
      return mergeValues.entity.registeredNumber;
    }
    return mergeValues.sender[mergeKey];
  };

  static getMergeValueForRecipient = (
    mergeValues: MergeValues,
    mergeKey: string,
    recipientId: string
  ) => {
    const signee = mergeValues.recipients.findIndex(recipient => recipient.id === recipientId);
    return mergeValues.recipients[signee]?.[mergeKey];
  };

  static isMergeTagEnabled = (mergeTagsSettings: MergeTagsSettings, mergeKey: string) =>
    mergeKey === MergeKey.signDate
      ? mergeTagsSettings[mergeKey].show
      : !!mergeTagsSettings[mergeKey];

  static getSanitizedMergeTagsSettings = (mergeTagsSettings: MergeTagsSettings) => {
    if (!mergeTagsSettings) {
      return EditorHelper.getDefaultMergeTagsSettings();
    }

    const mergeTagsSettingsCopy = { ...mergeTagsSettings };
    delete (mergeTagsSettingsCopy as any).__typename;
    return mergeTagsSettingsCopy;
  };
}
