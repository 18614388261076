import type { PlaceholderOptions } from '@tiptap/extension-placeholder';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import { Plugin } from '@tiptap/pm/state';

interface TextPlaceHolderOptions extends PlaceholderOptions {
  placeholder: string;
  secondPlaceholder: string;
}

export const TextPlaceholder = Placeholder.extend<TextPlaceHolderOptions>({
  addOptions() {
    return {
      ...this.parent?.(),
      secondPlaceholder: '',
      showOnlyCurrent: false,
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, selection }): DecorationSet | undefined => {
            const active = this.editor.isEditable || !this.options.showOnlyWhenEditable;
            const { anchor } = selection;
            const decorations: Decoration[] = [];

            if (!active) {
              return;
            }

            const isEditorEmpty = !doc.textContent.length && doc.childCount <= 2;

            doc.descendants((node, pos) => {
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize;
              const isEmpty = !node.isLeaf && !node.childCount;

              if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                const classes = [this.options.emptyNodeClass];

                if (isEditorEmpty) {
                  classes.push(this.options.emptyEditorClass);
                }

                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  class: classes.join(' '),
                  'data-placeholder':
                    pos === 0 ? this.options.placeholder : this.options.secondPlaceholder,
                });

                decorations.push(decoration);
              }

              return this.options.includeChildren;
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});
