import HardBreak from '@tiptap/extension-hard-break';

export const EnterBreakNode = HardBreak.extend({
  name: 'hard_break',
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.setHardBreak(),
    };
  },
});
