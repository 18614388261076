<template>
  <div class="input-editor-container">
    <EditorContent :editor="viewer" spellcheck="false" />
    <FriedSpinner v-if="isUpdating" class="updating-spinner" :size="20" />
  </div>
</template>
<script lang="ts">
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Text from '@tiptap/extension-text';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import {
  EnterBreakNode,
  InputDocNode,
  MergeValueNode,
  ParagraphNode,
} from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { ref, onBeforeUnmount, onMounted, watch, computed, defineComponent } from 'vue';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { updateMergeValues, setContent } from './helpers/prosemirror-viewer.helper';

export default defineComponent({
  name: 'ProsemirrorViewerFormOption',
  components: {
    EditorContent,
  },
  props: {
    content: {
      type: [Object, String] as PropType<TextContentDoc | string>,
      default: '',
    },
    isUpdating: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  setup(props) {
    const viewer: Ref<TipTapEditor | null> = ref(null);

    const contentPayload = computed(() => ({
      viewer: viewer.value,
      content: props.content,
      fields: props.fields,
      mergeValues: props.mergeValues,
    }));

    const inputViewer = computed(() => ({
      content: props.content,
      editable: false,
      enableInputRules: false,
      enablePasteRules: false,
      extensions: [InputDocNode, ParagraphNode, Text, MergeValueNode, EnterBreakNode],
    }));

    onMounted(() => {
      viewer.value = new TipTapEditor(inputViewer.value);
      updateMergeValues(contentPayload.value);
    });

    onBeforeUnmount(() => {
      viewer.value.destroy();
    });

    watch(
      () => [props.fields, props.mergeValues],
      () => {
        updateMergeValues(contentPayload.value);
      }
    );

    watch(
      () => props.content,
      () => {
        setContent(contentPayload.value);
      }
    );

    return {
      viewer,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/form-input-editor';

.updating-spinner {
  position: absolute;
  right: 2.5rem;
  top: calc(#{var(--spacing-50)} + 1px);
  width: 20px;
}
</style>
