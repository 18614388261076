import type { Ref } from 'vue';
import { computed } from 'vue';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { DocumentHelper } from '@getaccept/lib-shared-new/src/helpers/document.helper';
import type { ContractLink } from '@getaccept/editor-lib-new/src/types/models';
import type { ContractListItem } from '@getaccept/editor-lib-new';
import { fallbackParagraphContent } from '@getaccept/editor-lib-new';
import { RecipientsHelper } from '@getaccept/lib-shared-new/src/recipients/helpers/recipients.helper';
import type { RecipientRole } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-role';

export function useContractLink(contract: Ref<ContractListItem>, node: Ref<ContractLink>) {
  const contractStatus = computed(() => contract.value?.status);
  const showContractDetails = computed(() => contractStatus.value !== DocumentStatus.Recalled);

  const contractExpired = computed(
    () =>
      contractStatus.value === DocumentStatus.Expired ||
      DocumentHelper.isExpired(String(contract.value?.expirationDate))
  );

  const contractDescription = computed(() => {
    const contentToParse = node.value.content || fallbackParagraphContent;
    return JSON.parse(contentToParse);
  });

  const filteredRecipients = computed(() => {
    if (!contract.value || !contract.value?.recipients) {
      return [];
    }
    return contract.value.recipients
      .filter(recipient => !RecipientsHelper.isApprover(recipient.role))
      .sort((a, b) => a.orderNum - b.orderNum);
  });

  const translatedRole = (role: RecipientRole) => RecipientsHelper.getTranslatedRole(role);

  const updateDocumentStatus = ({ status, documentId }: { status: string; documentId: string }) => {
    if (documentId !== node.value.contractId) {
      return;
    }
    contract.value = {
      ...contract.value,
      status: status as DocumentStatus,
    };
  };

  const updateRecipientRole = async ({
    role,
    recipientId,
  }: {
    role: RecipientRole;
    recipientId: string;
  }) => {
    contract.value = {
      ...contract.value,
      recipients: contract.value.recipients.map(recipient =>
        recipient.id === recipientId ? { ...recipient, role } : recipient
      ),
    };
  };

  return {
    updateDocumentStatus,
    filteredRecipients,
    translatedRole,
    updateRecipientRole,
    contractDescription,
    contractStatus,
    showContractDetails,
    contractExpired,
  };
}
