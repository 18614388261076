import { GraphQLFetchPolicy } from '@getaccept/lib-shared-new/src/graphql/graphql-fetch-policy';
import { AuthQuery } from '@getaccept/lib-shared-new/src/session/signing-site/session.queries';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';
import type { Token } from '@getaccept/lib-shared-new/src/session/type/token';
import {
  ApolloClient as ApolloDocumentClient,
  createHttpLink,
  ApolloLink,
} from '@apollo/client/core';
import { InMemoryCache } from '@apollo/client/cache';
import { breadcrumbLink } from '@getaccept/lib-shared-new/src/bugsnag';
import type { ContractListItem } from '@getaccept/editor-lib-new';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { getPrefixedPublicApiBaseUrl } from '@getaccept/lib-shared-new/src/helpers/get-prefixed-api-base-url';
import {
  updateEditorInputFieldMutation,
  updateDealroomEditorInputField,
  partiallySignDocumentMutation,
} from '../graphql/editor-view.mutations';
import { getDocumentQuery, getSigningPageUrlQuery } from '../graphql/editor-view.queries';
import { useApolloStore } from '../../store/apollo.store';
import type { PartiallySignRequest, UpdateInputFieldRequest } from './types/requests';

export class EditorViewService {
  static getApolloClient() {
    const { apolloClient } = useApolloStore();
    return apolloClient;
  }

  static async updateEditorInputField(payload: UpdateInputFieldRequest): Promise<boolean> {
    const result = await EditorViewService.getApolloClient().mutate({
      mutation: updateEditorInputFieldMutation,
      variables: payload,
    });
    return !!result;
  }

  static async updateDealroomEditorInputField(payload: UpdateInputFieldRequest): Promise<boolean> {
    const result = await EditorViewService.getApolloClient().mutate({
      mutation: updateDealroomEditorInputField,
      variables: payload,
    });
    return !!result;
  }

  static async partiallySignDocument(payload: PartiallySignRequest): Promise<boolean> {
    const result = await EditorViewService.getApolloClient().mutate({
      mutation: partiallySignDocumentMutation,
      variables: payload,
    });
    return !!result;
  }

  static async getToken(
    documentId: string,
    authId: string,
    recipientId: string,
    authStepsInput?: AuthenticationStepInput[]
  ): Promise<Token> {
    const {
      data: {
        auth: { jwt, exp, authSteps, status, metaData },
      },
    } = await EditorViewService.getApolloClient().query({
      query: AuthQuery,
      variables: {
        documentId,
        recipientId,
        authId,
        authSteps: authStepsInput,
        setRecipientViewedStatus: false,
      },
    });
    return { jwt, exp, authSteps, status, metaData };
  }

  static async getDocument(jwt: string): Promise<ContractListItem> {
    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: { authorization: `Bearer ${jwt}` },
      });
      return forward(operation);
    });
    const apolloDocumentClient = new ApolloDocumentClient({
      link: ApolloLink.from([authLink, breadcrumbLink, httpLink]),
      cache: new InMemoryCache(),
    });

    const {
      data: { document },
    } = await apolloDocumentClient.query({
      query: getDocumentQuery,
      fetchPolicy: GraphQLFetchPolicy.NoCache,
    });
    return document;
  }

  static async getSigningPageUrl(documentId: string): Promise<string> {
    const {
      data: { signingPageUrl },
    } = await EditorViewService.getApolloClient().query({
      query: getSigningPageUrlQuery,
      variables: { documentId },
      fetchPolicy: GraphQLFetchPolicy.NetworkOnly,
    });

    return signingPageUrl;
  }
}

const pubBaseUrl = process.env.VUE_APP_PUBLIC_API_BASE_URL;
const domain = pubBaseUrl
  ? getPrefixedPublicApiBaseUrl(pubBaseUrl, window.location.hostname)
  : 'localhost:3002';

const httpLink = createHttpLink({
  uri: ({ operationName }) => UrlHelper.getHttpsUrl(`${domain}/graphql?op=${operationName}`),
});
