<template>
  <DropdownItemsWrapper v-if="open" class="units-modifier-container">
    <FriedH4 class="settings-header">{{ $t('choose-amount') }}</FriedH4>
    <div v-if="getMinValue(inputField)" class="unit-range-container">
      <label>{{ $t('min-amount') }}</label>
      {{ getMinValue(inputField) }}
    </div>
    <div v-if="getMaxValue(inputField)" class="unit-range-container">
      <label>{{ $t('max-amount') }}</label>
      {{ getMaxValue(inputField) }}
    </div>
    <FriedNumericalStepper
      :model-value="currentValue"
      class="input"
      data-external="editor-pricing-table-units-modifier-menu-default-value-input"
      pattern="[0-9]*"
      :min="getMinValue(inputField) ? Number(getMinValue(inputField)) : 1"
      :max="getMaxValue(inputField) ? Number(getMaxValue(inputField)) : undefined"
      :disabled="disabled"
      :error="defaultValueError !== ''"
      :error-message="$t(defaultValueError)"
      @keyup.stop="handleKeyPress"
      @blur="handleDefaultValueBlur"
      @keydown.enter="handleEnter"
      @keydown.esc="handleEsc"
      @update:model-value="handleStep"
      @keydown.exact.tab="close"
      @paste.prevent
    />
  </DropdownItemsWrapper>
</template>
<script lang="ts">
import {
  getMinValue,
  getMaxValue,
} from '@getaccept/editor-lib-new/src/helpers/pricing-table/pricing-table-units-modifier-menu.helper';
import { allowedNumericKeys, PricingTableHelper, UnitInputType } from '@getaccept/editor-lib-new';
import type { Field, NumberInputSettings } from '@getaccept/editor-lib-new';
import DropdownItemsWrapper from '@getaccept/editor-lib-new/src/components/DropdownItemsWrapper.vue';
import type { PropType } from 'vue';
import { computed, onBeforeUnmount, onMounted, watch, defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'PricingTableUnitsModifierMenu',
  components: {
    DropdownItemsWrapper,
  },
  props: {
    inputField: {
      type: Object as PropType<Field & { inputSettings: NumberInputSettings }>,
      default: () => ({}),
    },
    open: { type: Boolean },
    disabled: { type: Boolean },
  },
  emits: ['close', 'update-field'],
  setup(props, { emit }) {
    const defaultValueError = ref('');
    const currentValue = ref(null);

    watch(
      () => props.open,
      (open: boolean) => {
        if (!open) {
          return;
        }
        defaultValueError.value = '';
      }
    );

    const close = () => {
      emit('close');
    };

    const handleEsc = () => {
      close();
    };

    const handleEnter = () => {
      handleDefaultValueBlur();
      if (defaultValueError.value === '') {
        close();
      }
    };

    const handleDefaultValueBlur = () => {
      const hasValueChanged = Number(props.inputField.value) !== currentValue.value;
      const isValidValue = hasValueChanged && !!currentValue.value;

      if (!isValidValue || defaultValueError.value) {
        return;
      }

      emit('update-field', { id: props.inputField.id, value: String(currentValue.value) });
    };

    const handleKeyPress = (event: KeyboardEvent) => {
      if (!allowedNumericKeys.includes(event.key)) {
        return;
      }
      const { value } = event.target as HTMLInputElement;
      handleDefaultValueChange(Number(value));
    };

    const minValue = computed(() => {
      const value = getMinValue(props.inputField);
      return Number(value) || null;
    });

    const maxValue = computed(() => {
      const value = getMaxValue(props.inputField);
      return Number(value) || null;
    });

    const isOneStep = (value: number) =>
      value === currentValue.value + 1 || value === currentValue.value - 1;

    const isWithinRange = (value: number) => {
      const isWithinMinRange = minValue.value === null || value >= minValue.value;
      const isWithinMaxRange = maxValue.value === null || value <= maxValue.value;

      return isWithinMinRange && isWithinMaxRange;
    };

    const handleStep = (value: number) => {
      if (!isOneStep(value) || !isWithinRange(value)) {
        return;
      }
      handleDefaultValueChange(value);
    };

    const handleDefaultValueChange = (value: number) => {
      currentValue.value = value;

      if (minValue.value === null || maxValue.value === null) {
        defaultValueError.value = '';
      } else if (minValue.value > 0 && value < minValue.value) {
        defaultValueError.value = 'default-value-error-cant-be-lower-than-min-value';
      } else if (maxValue.value > 0 && value > maxValue.value) {
        defaultValueError.value = 'default-value-error-cant-be-higher-than-max-value';
      } else {
        defaultValueError.value = '';
      }
    };

    onMounted(() => {
      currentValue.value = Number(props.inputField.value);
    });

    onBeforeUnmount(() => {
      handleDefaultValueBlur();
    });

    return {
      currentValue,
      defaultValueError,
      close,
      handleEsc,
      handleEnter,
      handleDefaultValueBlur,
      isOneStep,
      isWithinRange,
      handleDefaultValueChange,
      handleStep,
      handleKeyPress,
      isKeyAllowed: PricingTableHelper.isKeyAllowed,
      UnitInputType,
      getMinValue,
      getMaxValue,
    };
  },
});
</script>
<style lang="scss" scoped>
.units-modifier-container {
  padding: var(--spacing-100);
  text-align: left;

  .settings-header {
    color: var(--text-gray);
    margin-bottom: var(--spacing-50);
  }

  .input:not(:last-child) {
    margin-bottom: var(--spacing-50);
  }

  .error-text {
    color: var(--red-42);
  }

  .unit-range-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--spacing-50);

    label {
      color: var(--text-gray);
    }
  }
}
</style>
