import { NodeType } from '@getaccept/editor-lib-new';
import type { Section, ContractListItem } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import type { Participant } from '@getaccept/dsr-shared-new';
import type { RecipientMap } from '../types/models';

export class ContractHelper {
  static isParticipantMappedToContract = (map: RecipientMap, recipient: Recipient) =>
    map.dsrRecipientId === recipient?.id ||
    map.participantId === recipient?.id ||
    (map.participantId && map.participantId === (recipient as Participant)?.newParticipantId) ||
    (map.dsrRecipientId && map.dsrRecipientId === (recipient as Participant)?.legacyRecipientId);

  static sectionContainsContractLink(section: Section) {
    return section.rows.some(row =>
      row.cells.some(cell => cell.nodes.some(node => node.type === NodeType.ContractLink))
    );
  }

  static updateContractRecipientStatus(
    contract: ContractListItem,
    recipientId: string,
    status: RecipientStatus
  ) {
    return {
      ...contract,
      recipients: contract.recipients.map(recipient =>
        recipient.id === recipientId ? { ...recipient, status } : recipient
      ),
    };
  }
}
