import { findParentNode, mergeAttributes } from '@tiptap/vue-3';
import type { TableOptions } from '@tiptap/extension-table';
import Table, { createColGroup } from '@tiptap/extension-table';
import type { DOMOutputSpec, Node as ProsemirrorNode } from '@tiptap/pm/model';
import { TableMap, CellSelection, columnResizing, tableEditing } from '@tiptap/pm/tables';
import { ProsemirrorType } from '../../types/enums';

interface CustomTableNodeOptions extends TableOptions {
  isPdfViewer: boolean;
}

const cloneTr = tr => Object.assign(Object.create(tr), tr).setTime(Date.now());

const selectTable = tr => {
  const table = findParentNode((node: ProsemirrorNode) => node.type.name === ProsemirrorType.Table)(
    tr.selection
  );

  if (table) {
    const { map } = TableMap.get(table.node);
    if (map && map.length) {
      const head = table.start + map[0];
      const anchor = table.start + map[map.length - 1];
      const $head = tr.doc.resolve(head);
      const $anchor = tr.doc.resolve(anchor);

      return cloneTr(tr.setSelection(new CellSelection($anchor, $head)));
    }
  }
  return tr;
};

export const TableNode = Table.extend<CustomTableNodeOptions>({
  name: 'table',

  content: 'table_row+',

  addOptions() {
    return {
      ...this.parent?.(),
    };
  },

  addKeyboardShortcuts() {
    return {
      Tab: () => this.editor.commands.goToNextCell(),
      'Shift-Tab': () => this.editor.commands.goToPreviousCell(),
      'Mod-a': () => {
        this.editor.view.dispatch(selectTable(this.editor.state.tr));
        return true;
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      ...(this.options.resizable
        ? [
            columnResizing({
              handleWidth: this.options.handleWidth,
              cellMinWidth: this.options.cellMinWidth,
              View: this.options.View,
              lastColumnResizable: this.options.lastColumnResizable,
            } as any),
          ]
        : []),
      tableEditing({
        allowTableNodeSelection: this.options.allowTableNodeSelection,
      }),
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    if (this.options.isPdfViewer) {
      return ['table', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ['tbody', 0]];
    }
    const { colgroup, tableWidth, tableMinWidth } = createColGroup(node, this.options.cellMinWidth);

    const table: DOMOutputSpec = [
      'table',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        style: tableWidth ? `width: ${tableWidth}` : `minWidth: ${tableMinWidth}`,
      }),
      colgroup,
      ['tbody', 0],
    ];

    return table;
  },
});
