export const cyrillic = 'cyrillic';
export const cyrillicExt = 'cyrillicExt';
export const devanagari = 'devanagari';
export const greek = 'greek';
export const greekExt = 'greekExt';
export const hebrew = 'hebrew';
export const latin = 'latin';
export const latinExt = 'latinExt';
export const vietnamese = 'vietnamese';

export type UnicodeRange =
  | typeof cyrillic
  | typeof cyrillicExt
  | typeof devanagari
  | typeof greek
  | typeof greekExt
  | typeof hebrew
  | typeof latin
  | typeof latinExt
  | typeof vietnamese;
