<template>
  <FriedDropdown
    v-model:open="open"
    :class="['input-date', { editable }]"
    :disabled="!editable"
    :close-on-action="false"
    :main-axis-offset="0"
    :cross-axis-offset="4"
  >
    <template #trigger>
      <FriedFieldSet
        :id="inputField.id"
        ref="dateInputBoxRef"
        class="input-editor-container"
        :tabindex="0"
        data-external="date-input-field-input"
        @drop.stop.prevent
      >
        <div ref="editorContainerRef" class="date-input-container">
          <div class="input-left-container">
            <FriedIcon class="icon-left" icon="calendar-edit" :size="IconSize.Small" />
            <EditorContent :editor="editor" spellcheck="false" />
          </div>
          <div :class="['select-chevron', { open }]">
            <FriedIcon icon="chevron-down" />
          </div>
        </div>
      </FriedFieldSet>
    </template>
    <template #content>
      <div class="datepicker-container">
        <FriedDatePicker :model-value="fieldValue" @update:model-value="handleDateSelect" />
        <FriedButton
          data-external="date-input-field-clear-date-button"
          :button-type="ButtonType.Secondary"
          :disabled="!fieldValue"
          class="clear-button"
          @click="handleClear"
        >
          {{ t('date_input_clear_date') }}
        </FriedButton>
      </div>
    </template>
  </FriedDropdown>
</template>
<script lang="ts">
import type { PropType, Ref } from 'vue';
import { watch, computed, defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { IconSize, ButtonType } from '@getaccept/fried-tofu';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import {
  ProsemirrorHelper,
  InputDocNode,
  ParagraphNode,
  MergeValueNode,
  EditorHelper,
} from '@getaccept/editor-lib-new';
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Text from '@tiptap/extension-text';
import Focus from '@tiptap/extension-focus';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import Placeholder from '@tiptap/extension-placeholder';
import { DateHelper } from '@getaccept/lib-shared-new/src/helpers/date.helper';
import type { FriedFieldSet } from '@getaccept/fried-tofu';

export default defineComponent({
  components: {
    EditorContent,
  },
  props: {
    hasUpdateError: { type: Boolean },
    fieldFocused: { type: Boolean },
    inputField: { type: Object as PropType<Field>, required: true },
    recipientInput: { type: Object as PropType<EditorRecipientInput>, default: null },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    editable: { type: Boolean },
    isUpdating: { type: Boolean },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const open = ref(false);
    const dateInputBoxRef = ref<typeof FriedFieldSet>(null);

    const editor: Ref<TipTapEditor> = ref(null);

    const hasCleared: Ref<boolean> = ref(false);

    const fieldValue = computed(() => {
      if (props.recipientInput?.value) {
        return props.recipientInput.value;
      }

      if (props.inputField.value && !hasCleared.value) {
        return props.inputField.value;
      }
      return '';
    });

    const mergeTagValue = computed(() =>
      ProsemirrorHelper.getMergeValueFromMergeTag(
        ProsemirrorHelper.getTagIdFromMergeTag(props.inputField.value),
        props.fields,
        props.mergeValues
      )
    );

    const selectedMediumDate = computed(() =>
      EditorHelper.hasMergeTags(fieldValue.value)
        ? ''
        : +fieldValue.value
          ? DateHelper.fromSecondsToMediumDate(+fieldValue.value)
          : DateHelper.fromISODateToLocaleString(fieldValue.value)
    );

    const initialDate = computed(() =>
      EditorHelper.hasMergeTags(fieldValue.value)
        ? mergeTagValue.value
        : +fieldValue.value
          ? DateHelper.fromSecondsToMediumDate(+fieldValue.value)
          : DateHelper.fromISODateToLocaleString(fieldValue.value)
    );

    const initialFormattedDate = computed(() =>
      ProsemirrorHelper.getInputContent(initialDate.value, props.fields)
    );

    const editorConfig = computed(() => ({
      content: initialFormattedDate.value,
      editable: false,
      extensions: [
        InputDocNode,
        Focus,
        ParagraphNode,
        Text,
        MergeValueNode,
        Placeholder.configure({
          placeholder: t('input_fields_add_date'),
          showOnlyWhenEditable: false,
        }),
      ],
      onBlur: update,
      onUpdate: update,
      enableInputRules: false,
      enablePasteRules: false,
    }));

    const innerTextContent = computed(() => {
      const transaction = editor.value?.state.tr;

      return ProsemirrorHelper.getStringFromTransaction(transaction, editor.value?.state.schema);
    });

    const update = () => {
      if (!props.editable) {
        return;
      }

      const newDate = innerTextContent.value ? innerTextContent.value : '';
      emit('update', newDate);
    };

    const close = () => {
      open.value = false;
    };

    const handleDateSelect = (date: Date) => {
      const isoDate = DateHelper.fromJSDateToISODate(date);
      editor.value.commands.setContent(isoDate);
      update();
      close();
    };

    const handleClear = () => {
      editor.value.commands.setContent('');
      if (!hasCleared.value) {
        hasCleared.value = true;
      }
      update();
    };

    const focus = () => {
      if (dateInputBoxRef.value) {
        (dateInputBoxRef.value.$el as HTMLElement).focus();
      }
    };

    watch(
      () => props.fieldFocused,
      (fieldFocused: boolean) => {
        if (fieldFocused) {
          focus();
        }
      }
    );

    watch(
      () => [props.fields, props.mergeValues, props.recipientInput],
      (newValues, oldValues) => {
        if (editor.value.isDestroyed || JSON.stringify(newValues) === JSON.stringify(oldValues)) {
          return;
        }

        editor.value.commands.setContent(
          ProsemirrorHelper.getInputContent(initialDate.value, props.fields)
        );
      }
    );

    onMounted(() => {
      editor.value = new TipTapEditor(editorConfig.value);
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    return {
      ButtonType,
      IconSize,
      editor,
      selectedMediumDate,
      fieldValue,
      dateInputBoxRef,
      innerTextContent,
      hasCleared,
      handleClear,
      handleDateSelect,
      t,
      update,
      open,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/form-input-editor';
@import '@getaccept/editor-lib-new/src/scss/input-dropdown';

.input-date {
  width: 100%;

  &.editable {
    cursor: pointer;
  }

  .input-editor-container {
    outline: none;

    .date-input-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: calc(#{var(--spacing-75)} - 1px);
      padding-right: var(--spacing-200);
      width: 100%;

      .input-left-container {
        display: flex;
        gap: var(--spacing-50);
      }

      .merge-tag-menu-wrapper {
        position: absolute;
        z-index: 7;
      }

      .icon-left {
        flex-shrink: 0;
      }

      .chevron-icon {
        transition: all 0.2s ease-in-out;
        margin-right: var(--spacing-50);

        &.expanded {
          transform: rotate(180deg);
        }
      }

      &.focus {
        border-color: var(--info-dark);
      }
    }
  }

  .datepicker-container {
    display: flex;
    flex-direction: column;
    padding: var(--spacing-50);
    padding-top: 0;
    gap: var(--spacing-100);
    font-family: var(--default-font-family);
    font-size: var(--paragraph-medium-font-size);

    .datepicker {
      box-shadow: none;
    }

    .clear-button {
      width: 100%;
    }
  }
}
</style>
