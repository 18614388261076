export enum RecipientStatus {
  Waiting = 'waiting',
  Added = 'added',
  Sent = 'sent',
  Signed = 'signed',
  Approved = 'approved',
  Reviewed = 'reviewed',
  Rejected = 'rejected',
  Viewed = 'viewed',
  Hardbounced = 'hardbounced',
}
