import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useApolloStore = defineStore('apollo', () => {
  const apolloClient: Ref<ApolloClient<NormalizedCacheObject>> = ref(null);

  const setApolloClient = (client: ApolloClient<NormalizedCacheObject>) => {
    apolloClient.value = client;
  };

  return {
    apolloClient,
    setApolloClient,
  };
});
