<template>
  <div class="form-input-checkbox-container">
    <FriedCheckbox
      :id="input.id"
      data-external="form-input-checkbox-input-view"
      :model-value="checked"
      :disabled="isDisabled"
      :error="hasUpdateError"
      @update:model-value="$emit('update-checked', $event)"
    />
    <ProsemirrorViewerFormInput
      :class="['checkbox-label', { 'is-required': input.inputSettings.required && editable }]"
      data-external="form-input-checkbox-label-view"
      :content="getSafeText(input.inputSettings.richTextLabel)"
      :fields="fields"
      :label="false"
      :link="false"
      :merge-values="mergeValues"
      :multi-line="false"
      placeholder=""
    />
    <FriedSpinner v-if="isUpdating" class="updating-spinner" :size="20" />
  </div>
</template>

<script lang="ts">
import { ProsemirrorHelper } from '@getaccept/editor-lib-new';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { watch, computed, defineComponent } from 'vue';
import ProsemirrorViewerFormInput from './ProsemirrorViewerFormInput.vue';

export default defineComponent({
  name: 'InputCheckbox',
  components: {
    ProsemirrorViewerFormInput,
  },
  props: {
    checked: { type: Boolean },
    hasUpdateError: { type: Boolean },
    isUpdating: { type: Boolean },
    editable: { type: Boolean },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    fieldFocused: { type: Boolean },
    input: { type: Object as PropType<Field>, default: () => ({}) },
    isPdfViewer: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
  },
  emits: ['update-checked'],
  setup(props) {
    const isDisabled = computed(() => !props.editable && !props.isPdfViewer);

    watch(
      () => props.fieldFocused,
      fieldFocused => {
        if (fieldFocused) {
          const input: HTMLInputElement = document.getElementById(
            props.input.id
          ) as HTMLInputElement;
          input.focus({
            preventScroll: true,
          });
        }
      }
    );

    return {
      isDisabled,
      getSafeText: ProsemirrorHelper.getSafeText,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-checkbox';

.updating-spinner {
  position: absolute;
  right: var(--spacing-100);
  top: calc(#{var(--spacing-50)} + 1px);
  width: 20px;
}
</style>
