import { ProsemirrorType } from '../types/enums/prosemirror-type';

export const fallbackParagraphContent: string = JSON.stringify({
  type: ProsemirrorType.Doc,
  content: [
    {
      type: ProsemirrorType.Paragraph,
    },
  ],
});
