import type { Timezone } from '@getaccept/lib-shared-new/src/types/time-zone';

export const timezones: Timezone[] = [
  { key: '-12:00', label: '(GMT-12:00) International dateline, west', zone: 'Pacific/Nauru' },
  { key: '-11:00', label: '(GMT-11:00) Midway Islands, Samoan Islands', zone: 'Pacific/Niue' },
  { key: '-10:00', label: '(GMT-10:00) Hawaii', zone: 'Pacific/Honolulu' },
  { key: '-09:00', label: '(GMT-09:00) Alaska', zone: 'America/Anchorage' },
  { key: '-08:00', label: '(GMT-08:00) Pacific Time (USA & Canada)', zone: 'America/Los_Angeles' },
  { key: '-07:00', label: '(GMT-07:00) Mountain Time (USA & Canada)', zone: 'America/Phoenix' },
  { key: '-06:00', label: '(GMT-06:00) Central time (USA & Canada)', zone: 'America/Costa_Rica' },
  { key: '-05:00', label: '(GMT-05:00) Eastern time (USA & Canada)', zone: 'Atlantic/Madeira' },
  { key: '-04:00', label: '(GMT-04:00) Atlantic Time (Canada)', zone: 'Atlantic/Bermuda' },
  { key: '-03:30', label: '(GMT-03:30) Newfoundland', zone: 'America/St_Johns' },
  { key: '-03:00', label: '(GMT-03:00) Brasilia', zone: 'America/Sao_Paulo' },
  { key: '-02:00', label: '(GMT-02:00) Mid-Atlantic', zone: 'Atlantic/South_Georgia' },
  { key: '-01:00', label: '(GMT-01:00) Azorerne', zone: 'Atlantic/Azores' },
  { key: '+00:00', label: '(GMT) : Dublin, Edinburgh, Lissabon, London', zone: 'Europe/Lisbon' },
  {
    key: '+01:00',
    label: '(GMT+01:00) Stockholm, Amsterdam, Berlin, Paris',
    zone: 'Europe/Amsterdam',
  },
  { key: '+02:00', label: '(GMT+02:00) Athen, Istanbul, Minsk', zone: 'Europe/Istanbul' },
  { key: '+03:00', label: '(GMT+03:00) Moscow, St. Petersburg, Volgograd', zone: 'Europe/Moscow' },
  { key: '+03:30', label: '(GMT+03:30) Teheran', zone: 'Asia/Tehran' },
  { key: '+04:00', label: '(GMT+04:00) Abu Dhabi, Muscat', zone: 'Asia/Dubai' },
  { key: '+04:30', label: '(GMT+04:30) Kabul', zone: 'Asia/Kabul' },
  { key: '+05:00', label: '(GMT+05:00) Islamabad, Karachi, Tasjkent', zone: 'Asia/Karachi' },
  { key: '+05:30', label: '(GMT+05:30) Kolkata, Chennai, Mumbai, New Delhi', zone: 'Asia/Kolkata' },
  { key: '+05:45', label: '(GMT+05:45) Katmandu', zone: 'Asia/Kathmandu' },
  { key: '+06:00', label: '(GMT+06:00) Astana, Dhaka', zone: 'Asia/Dhaka' },
  { key: '+06:30', label: '(GMT+06:30) Rangoon', zone: 'Asia/Yangon' },
  { key: '+07:00', label: '(GMT+07:00) Bangkok, Hanoi, Djakarta', zone: 'Asia/Bangkok' },
  { key: '+08:00', label: '(GMT+08:00) Beijing, Chongjin, Hong Kong', zone: 'Asia/Shanghai' },
  { key: '+09:00', label: '(GMT+09:00) Osaka, Sapporo, Tokyo', zone: 'Asia/Tokyo' },
  { key: '+09:30', label: '(GMT+09:30) Adelaide', zone: 'Australia/Adelaide' },
  { key: '+10:00', label: '(GMT+10:00) Canberra, Melbourne, Sydney', zone: 'Australia/Melbourne' },
  { key: '+11:00', label: '(GMT+11:00) Magadan, Solomon Islands', zone: 'Asia/Magadan' },
  { key: '+12:00', label: '(GMT+12:00) Fiji, Kamtjatka, Marshall Islands', zone: 'Pacific/Fiji' },
  { key: '+13:00', label: "(GMT+13:00) Nuku'alofa}", zone: 'Pacific/Tongatapu' },
];
