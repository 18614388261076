<template>
  <div :class="['slider-top-bar-container', { 'lightbox-top-bar': isLightboxView }]">
    <div class="view-mode-button-container">
      <FriedIconButton
        :class="['grid-view-button icon-button-dark', { active: isGridView }]"
        data-external="editor-button-slideshow-card-view"
        @click="$emit('toggle-grid-view', !isGridView)"
      >
        <FriedIcon icon="card" />
      </FriedIconButton>
      <router-link v-if="isLightboxView" v-slot="{ navigate }" :to="route" custom>
        <div role="link" @click="navigate">
          <FriedIconButton
            data-external="editor-button-slideshow-lightbox-view"
            @click="$emit('toggle-lightbox')"
          >
            <FriedIcon icon="arrow-collapse" />
          </FriedIconButton>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SlideShowTopBar',
  props: {
    isGridView: { type: Boolean },
    isLightboxView: { type: Boolean },
    route: { type: Object, default: () => ({}) },
  },
  emits: ['toggle-grid-view', 'toggle-lightbox'],
});
</script>
<style lang="scss" scoped>
@import '@getaccept/lib-shared-new/src/assets/scss/icon-button-dark';

.slider-top-bar-container {
  position: absolute;
  z-index: 2;
  right: var(--spacing-100);
  top: var(--spacing-50);

  @media screen and (max-width: $sm) {
    &.lightbox-top-bar {
      margin-top: var(--spacing-100);

      button {
        color: var(--white);
        background-color: var(--gray-60);
      }
    }
  }

  &.small {
    right: var(--spacing-150);
  }

  .view-mode-button-container {
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing-50);
    align-items: center;
  }
}
</style>
