import type { Node as ProsemirrorNode, Schema } from '@tiptap/pm/model';
import { DOMSerializer } from '@tiptap/pm/model';
import type { Transaction, EditorState } from '@tiptap/pm/state';
import { NodeSelection, TextSelection } from '@tiptap/pm/state';
import type { NodeWithPos } from '@tiptap/vue-3';
import { findChildren } from '@tiptap/vue-3';
import type {
  Text,
  MergeTagNode,
  TextContentDoc,
} from '@getaccept/lib-shared-new/src/types/text-content';
import { DateHelper } from '@getaccept/lib-shared-new/src/helpers/date.helper';
import { DateTime } from 'luxon';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { Field, MergeValues } from '../types';
import { MergeCategory, MergeKey, FieldType } from '../types';
import { FieldInputType, NodeAlign, ProsemirrorType } from '../types/enums';
import { EditorHelper } from './editor.helper';

export class ProsemirrorHelper {
  static selectNode(transaction: Transaction, position: number): Transaction {
    return transaction.setSelection(NodeSelection.create(transaction.doc, position));
  }

  static selectTextNode(transaction: Transaction, position: number): Transaction {
    return transaction.setSelection(TextSelection.create(transaction.doc, position));
  }

  static replaceBrNodes(fromTransaction: Transaction, schema: Schema): Transaction {
    let transaction = fromTransaction;

    const brNodes: NodeWithPos[] = findChildren(
      transaction.doc,
      (node: ProsemirrorNode) => node.type.name === ProsemirrorType.HardBreak
    ).reverse();

    brNodes.forEach(({ node, pos }: NodeWithPos) => {
      transaction = transaction.replaceWith(
        pos,
        pos + node.nodeSize,
        schema.text('\n', node.marks)
      );
    });

    return transaction;
  }

  static getStringFromTransaction(transaction: Transaction, schema: Schema): string {
    // doc.content.firstChild.content - targets content of the root paragraph element
    const fragment = DOMSerializer.fromSchema(schema).serializeFragment(
      transaction.doc.content.firstChild.content
    );
    const container = document.createElement('div');
    container.appendChild(fragment);
    return container.innerText;
  }

  static getTagIdFromMergeTag(mergeTag: string) {
    return mergeTag.substr(2, EditorHelper.NANO_ID_LENGTH);
  }

  private static makeMergeTagNode(mergeTag: string, fields: Field[]): MergeTagNode {
    const tagId = ProsemirrorHelper.getTagIdFromMergeTag(mergeTag);
    const { category, mergeKey, customName } = fields.find(field => field.id === tagId) || {
      category: null,
      customName: null,
      mergeKey: null,
    };

    return {
      type: ProsemirrorType.MergeTag,
      attrs: {
        category,
        customName,
        mergeKey,
        tagId,
      },
    };
  }

  private static makeTextNode(text: string): Text {
    return {
      type: ProsemirrorType.Text,
      text: text.replace(/(\n)+/g, '<br>'),
    };
  }

  static getInputContent(value: string, fields: Field[]): TextContentDoc | string {
    if (!value) {
      return '';
    }

    const mergeTagRegex = new RegExp(EditorHelper.mergeTagExpression);
    const newline = '\n';
    const tagOrNewlineExpression = new RegExp(`(${mergeTagRegex.source}|${newline})`);

    return {
      type: ProsemirrorType.Doc,
      content: [
        {
          attrs: { align: NodeAlign.Left },
          type: ProsemirrorType.Paragraph,
          content: value
            .split(tagOrNewlineExpression)
            .filter(node => node)
            .map(node => {
              if (mergeTagRegex.test(node)) {
                return ProsemirrorHelper.makeMergeTagNode(node, fields);
              }

              if (node === newline) {
                return { type: ProsemirrorType.HardBreak };
              }

              return ProsemirrorHelper.makeTextNode(node);
            }),
        },
      ],
    };
  }

  static getSafeText(text: null | string): string {
    return text ? JSON.parse(text) : '';
  }

  static replaceMergeNodes(fromTransaction: Transaction, schema: Schema): Transaction {
    let transaction = fromTransaction;

    const nodes: NodeWithPos[] = findChildren(
      transaction.doc,
      (node: ProsemirrorNode) => node.type.name === ProsemirrorType.MergeTag
    ).reverse();

    nodes.forEach(({ node, pos }: NodeWithPos) => {
      const mergeId = node.attrs.tagId;

      if (mergeId) {
        transaction = transaction.replaceWith(
          pos,
          pos + node.nodeSize,
          schema.text(`{{${mergeId}}}`, node.marks)
        );
      }
    });

    return transaction;
  }

  static replaceEmailFormInputNodes(transaction: Transaction, schema: Schema): string {
    const nodes: NodeWithPos[] = findChildren(
      transaction.doc,
      (node: ProsemirrorNode) => node.type.name === ProsemirrorType.Text
    ).reverse();

    nodes.forEach(({ node, pos }: NodeWithPos) => {
      if (node.text) {
        transaction.replaceWith(pos, pos + node.nodeSize, schema.text(node.text.trim()));
      }
    });

    return ProsemirrorHelper.getStringFromTransaction(transaction, schema);
  }

  static getInnerTextContent(state: EditorState): string {
    const { tr, schema } = state;

    let transaction = tr;
    transaction = ProsemirrorHelper.replaceMergeNodes(transaction, schema);
    transaction = ProsemirrorHelper.replaceBrNodes(transaction, schema);

    return ProsemirrorHelper.getStringFromTransaction(transaction, schema);
  }

  static replaceMergeTagsWithText(
    state: EditorState,
    fields: Field[],
    mergeValues: MergeValues
  ): Transaction {
    const nodes: NodeWithPos[] = findChildren(
      state.doc,
      (node: ProsemirrorNode) => node.type.name === ProsemirrorType.MergeTag
    ).reverse();

    let transaction = state.tr;

    nodes.forEach(({ node, pos }: NodeWithPos) => {
      const mergeValue = ProsemirrorHelper.getMergeValueFromMergeTag(
        node.attrs.tagId,
        fields,
        mergeValues
      );

      if (mergeValue) {
        transaction = transaction.replaceWith(
          pos,
          pos + node.nodeSize,
          state.schema.text(mergeValue, node.marks)
        );
      }
    });

    return transaction;
  }

  static getFieldById(fieldId: string, fields: Field[]): Field | undefined {
    return fields.find(({ id }: Field) => id === fieldId);
  }

  static getFieldInputType(fields: Field[]): FieldInputType | undefined {
    return fields.find(({ inputSettings }: Field) => inputSettings !== null)?.inputSettings?.type;
  }

  static getRecipientValue(field: Field, mergeKey: MergeKey, mergeValues: MergeValues): string {
    const recipient = mergeValues?.recipients?.find(
      ({ id }) => field.recipientId === id || field.participantId === id
    );

    if (!recipient || (!recipient.email && !(recipient as Recipient).mobile)) {
      return '';
    }

    if (mergeKey === MergeKey.mobile) {
      return recipient?.[mergeKey] || recipient?.[MergeKey.phone] || '';
    }

    return recipient?.[mergeKey] || '';
  }

  static getEntityValue(mergeKey: MergeKey, mergeValues: MergeValues): string {
    return mergeValues?.entity?.[mergeKey] || '';
  }
  static getSenderValue(mergeKey: MergeKey, mergeValues: MergeValues): string {
    if (mergeKey === MergeKey.mobile) {
      return mergeValues?.sender?.[mergeKey] || mergeValues?.sender?.[MergeKey.phone] || '';
    }

    return mergeValues?.sender?.[mergeKey] || '';
  }

  static getDocumentValue(
    mergeKey: MergeKey,
    mergeValues: MergeValues,
    isDateFieldInputType?: boolean
  ): string {
    const value: string | number = mergeValues?.document?.[mergeKey];

    if (!value) {
      return '';
    }

    if (mergeKey === MergeKey.sendDate || mergeKey === MergeKey.expirationDate) {
      let date = DateTime.fromISO(value as string);
      if (!date.isValid) {
        date = DateTime.fromMillis(+value);
      }
      return isDateFieldInputType
        ? DateHelper.mediumDateLocale(date)
        : DateHelper.mediumDateTime(date);
    }

    if (typeof value === 'number' && mergeKey === MergeKey.value) {
      return String(value.toFixed(0));
    }

    return String(value);
  }

  static getMergeValueFromMergeTag(
    mergeTagId: string,
    fields: Field[],
    mergeValues: MergeValues
  ): string {
    const field = ProsemirrorHelper.getFieldById(mergeTagId, fields);

    const isDateFieldInputType =
      ProsemirrorHelper.getFieldInputType(fields) === FieldInputType.Date;

    if (field?.type === FieldType.Custom) {
      return field.value || '';
    }

    switch (field?.category) {
      case MergeCategory.Recipient:
        return ProsemirrorHelper.getRecipientValue(field, field.mergeKey, mergeValues);

      case MergeCategory.Entity:
        return ProsemirrorHelper.getEntityValue(field.mergeKey, mergeValues);

      case MergeCategory.Sender:
        return ProsemirrorHelper.getSenderValue(field.mergeKey, mergeValues);

      case MergeCategory.Document:
        return ProsemirrorHelper.getDocumentValue(
          field.mergeKey,
          mergeValues,
          isDateFieldInputType
        );

      default:
        return '';
    }
  }
}
