<template>
  <div class="input-field-content">
    <FriedFieldSet
      v-if="input.inputSettings.type === FieldInputType.Text"
      class="text-input-container"
      :error="hasUpdateError(input.id)"
    >
      <ProsemirrorViewerFormInput
        :id="input.id"
        v-tooltip="getTooltip(input)"
        class="input-default-text"
        :field-focused="hasFocus"
        data-external="form-input-text-input-view"
        :content="getInputContent(getTextValue(input, recipientInput), fields)"
        :merge-values="mergeValues"
        :fields="fields"
        :bold="false"
        :italic="false"
        :underline="false"
        :superscript="false"
        :label="false"
        :link="false"
        :editable="editable"
        :is-updating="isUpdatingInputValue(input.id)"
        @update="handleInputUpdate($event, input)"
      />
    </FriedFieldSet>
    <ProsemirrorViewerFormEmailInput
      v-else-if="input.inputSettings.type === FieldInputType.Email"
      :id="input.id"
      v-tooltip="getTooltip(input)"
      data-external="form-input-email-input-view"
      :has-update-error="hasUpdateError(input.id)"
      :field-focused="hasFocus"
      :input-field="input"
      :recipient-input="getRecipientInputField(input.id, recipientInput)"
      :merge-values="mergeValues"
      :fields="fields"
      :editable="editable"
      :is-required="input.inputSettings.required"
      :is-updating="isUpdatingInputValue(input.id)"
      @update="handleInputUpdate($event, input)"
      @validate-form-input="validateFormInputField($event)"
    />
    <InputDate
      v-else-if="input.inputSettings.type === FieldInputType.Date"
      v-tooltip="getTooltip(input)"
      :has-update-error="hasUpdateError(input.id)"
      :field-focused="hasFocus"
      :input-field="input"
      :recipient-input="getRecipientInputField(input.id, recipientInput)"
      :merge-values="mergeValues"
      :fields="fields"
      :editable="editable"
      :is-updating="isUpdatingInputValue(input.id)"
      @update="handleInputUpdate($event, input)"
    />
    <InputCheckbox
      v-else-if="input.inputSettings.type === FieldInputType.Checkbox"
      v-tooltip="getTooltip(input)"
      :editable="editable"
      :fields="fields"
      :field-focused="hasFocus"
      :input="input"
      :is-pdf-viewer="isPdfViewer"
      :checked="getCheckboxValue(input, recipientInput)"
      :merge-values="mergeValues"
      :has-update-error="hasUpdateError(input.id)"
      :is-updating="isUpdatingInputValue(input.id)"
      @update-checked="handleCheckboxUpdate($event, input.id)"
    />
    <InputDropdown
      v-else-if="input.inputSettings.type === FieldInputType.Dropdown"
      :tooltip="getTooltip(input)"
      :editable="editable"
      :fields="fields"
      :field-focused="hasFocus"
      :input="input as any"
      :is-pdf-viewer="isPdfViewer"
      :recipient-input="recipientInput"
      :merge-values="mergeValues"
      :has-update-error="hasUpdateError(input.id)"
      :is-updating="isUpdatingInputValue(input.id)"
      @update-selected="updateValue({ value: $event, inputId: input.id })"
    />
    <ProsemirrorViewerFormInput
      v-if="input.inputSettings.helpText"
      class="input-help-text"
      data-external="form-input-text-help-text-view"
      :content="getSafeText(input.inputSettings.helpText)"
      :merge-values="mergeValues"
      :fields="fields"
      placeholder=""
    />
  </div>
</template>
<script lang="ts">
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import { EditorHelper, FieldInputType, ProsemirrorHelper } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import { useInputFieldView, inputFieldProps } from '../composables/input-field-view.composable';
import { useEditorViewInputStore } from '../store/editor-view-input.store';
import ProsemirrorViewerFormInput from './ProsemirrorViewerFormInput.vue';
import ProsemirrorViewerFormEmailInput from './ProsemirrorViewerFormEmailInput.vue';
import InputDropdown from './InputDropdown.vue';
import InputCheckbox from './InputCheckbox.vue';
import InputDate from './InputDate.vue';

export default defineComponent({
  name: 'InputSetField',
  components: {
    ProsemirrorViewerFormInput,
    ProsemirrorViewerFormEmailInput,
    InputDate,
    InputCheckbox,
    InputDropdown,
  },
  props: {
    ...inputFieldProps,
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    isPdfViewer: { type: Boolean },
    input: { type: Object as PropType<Field>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    editable: { type: Boolean, default: false },
    hasFocus: { type: Boolean, default: false },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    userId: { type: String, default: '' },
  },
  emits: ['update-value'],
  setup(props, { emit }) {
    const { getTooltip } = useInputFieldView(props);

    const editorViewInputStore = useEditorViewInputStore();

    const updateValue = (payload: { value: string; inputId: string }) => {
      emit('update-value', payload);
    };

    const hasUpdateError = (inputId: string) =>
      props.failedInputUpdates.some(input => input.fieldId === inputId);

    const isUpdatingInputValue = (inputId: string) =>
      props.updatingInputs.some(input => input.fieldId === inputId);

    const handleInputUpdate = (value: string, input: Field) => {
      if (EditorHelper.getTextValue(input, props.recipientInput) === value) {
        return;
      }
      updateValue({ value, inputId: input.id });
    };

    const handleCheckboxUpdate = (value: boolean, inputId: string) => {
      updateValue({ value: value ? '1' : '0', inputId });
    };

    const validateFormInputField = (isValid: boolean) => {
      editorViewInputStore.updateFormInputFieldsWithErrors({ fieldId: props.input.id, isValid });
    };

    return {
      hasUpdateError,
      handleInputUpdate,
      handleCheckboxUpdate,
      isUpdatingInputValue,
      getTextValue: EditorHelper.getTextValue,
      getSafeText: ProsemirrorHelper.getSafeText,
      getInputContent: ProsemirrorHelper.getInputContent,
      getCheckboxValue: EditorHelper.getCheckboxValue,
      getRecipientInputField: EditorHelper.getRecipientInputField,
      FieldInputType,
      getTooltip,
      updateValue,
      validateFormInputField,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-set';

.text-input-container {
  width: 100%;

  .input-default-text {
    padding: calc(var(--spacing-75) - 1px);
  }
}
</style>
