<template>
  <div
    ref="editorContainer"
    :class="[
      'table-editor-container',
      {
        'no-border': borderColor === Colors.Transparent,
        'alternate-row-color': alternateRowColor === Colors.Gray,
        'header-row-background-color': headerRowBackgroundColor === Colors.Gray,
        overflow,
      },
    ]"
  >
    <EditorContent :editor="viewer" spellcheck="false" />
  </div>
</template>
<script lang="ts">
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import debounce from 'debounce';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import Text from '@tiptap/extension-text';
import { columnResizingPluginKey } from '@tiptap/pm/tables';
import type { EditorView } from '@tiptap/pm/view';
import type { MergeValues, Field } from '@getaccept/editor-lib-new';
import {
  ProsemirrorType,
  ListItemNode,
  BulletListNode,
  HeadingNode,
  OrderedListNode,
  ParagraphNode,
  TableCellNode,
  TableNode,
  TableHeaderNode,
  TableRowNode,
  TableDocNode,
  MergeValueNode,
  HardBreakNode,
  TextAlign,
  EditorHelper,
} from '@getaccept/editor-lib-new';
import { Colors } from '@getaccept/lib-shared-new/src/types/colors';
import type { PropType, Ref } from 'vue';
import { onBeforeUnmount, onMounted, computed, defineComponent, ref, watch } from 'vue';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { updateMergeValues, setContent, handleInit } from './helpers/prosemirror-viewer.helper';

export default defineComponent({
  name: 'ProsemirrorViewerTable',
  components: {
    EditorContent,
  },
  props: {
    borderColor: { type: String, default: '' },
    alternateRowColor: { type: String, default: '' },
    headerRowBackgroundColor: { type: String, default: '' },
    isPdfViewer: { type: Boolean, default: false },
    content: {
      type: [Object, String] as PropType<TextContentDoc | string>,
      default: '',
    },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  setup(props) {
    const viewer: Ref<TipTapEditor | null> = ref(null);
    const overflow = ref(false);
    const tableWrapper: Ref<HTMLElement> = ref(null);
    const editorContainer: Ref<HTMLElement> = ref(null);

    const setOverflow = () => {
      overflow.value = EditorHelper.hasHiddenContentRight(tableWrapper.value);
    };

    const debounceHandleScroll = debounce(setOverflow, 300);

    const handleMouseDown = (view: EditorView) =>
      columnResizingPluginKey.getState(view.state).activeHandle !== -1;

    const handleLoaded = () => {
      handleInit(viewer.value, props.fields, props.mergeValues);

      tableWrapper.value = editorContainer.value?.querySelector('.tableWrapper');
      if (!tableWrapper.value) {
        return;
      }

      tableWrapper.value?.addEventListener('scroll', debounceHandleScroll);
      setOverflow();
    };

    const contentPayload = computed(() => ({
      viewer: viewer.value,
      content: props.content,
      fields: props.fields,
      mergeValues: props.mergeValues,
    }));

    const tableViewer = computed(() => ({
      content: props.content,
      editable: false,
      enableInputRules: false,
      enablePasteRules: false,
      extensions: [
        TableDocNode,
        ParagraphNode,
        Text,
        Bold,
        Italic,
        Strike,
        Superscript,
        Underline,
        OrderedListNode,
        BulletListNode,
        ListItemNode,
        MergeValueNode,
        Link.configure({
          autolink: false,
          openOnClick: true,
          linkOnPaste: false,
        }),
        TableNode.configure({
          resizable: !props.isPdfViewer,
          isPdfViewer: props.isPdfViewer,
        }),
        TableHeaderNode,
        TableCellNode,
        TableRowNode,
        HeadingNode,
        HardBreakNode,
        TextAlign.configure({
          types: [
            ProsemirrorType.Paragraph,
            ProsemirrorType.Heading,
            ProsemirrorType.BulletList,
            ProsemirrorType.OrderedList,
          ],
        }),
      ],
      onCreate: handleLoaded,
      editorProps: {
        handleDOMEvents: {
          mousedown: handleMouseDown,
        },
      },
    }));

    onMounted(() => {
      viewer.value = new TipTapEditor(tableViewer.value);
    });

    onBeforeUnmount(() => {
      tableWrapper.value?.removeEventListener('scroll', debounceHandleScroll);
      viewer.value.destroy();
    });

    watch(
      () => [props.fields, props.mergeValues],
      () => {
        updateMergeValues(contentPayload.value);
      }
    );

    watch(
      () => props.content,
      () => {
        setContent(contentPayload.value);
      }
    );

    return {
      viewer,
      Colors,
      editorContainer,
      handleMouseDown,
      setOverflow,
      handleLoaded,
      overflow,
      tableWrapper,
    };
  },
});
</script>
<style lang="scss" scoped>
/* stylelint-disable selector-class-pattern */
@import '@getaccept/editor-lib-new/src/scss/prosemirror-editor-table';
@import '@getaccept/editor-lib-new/src/scss/prosemirror-editor-text-styles';

.table-editor-container {
  :deep(.ProseMirror) {
    ::-webkit-scrollbar {
      appearance: none;
      height: 0.4375rem;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 0.25rem;
      background-color: rgb(0 0 0 / 50%);
      box-shadow: 0 0 $editor-border-thin rgb(255 255 255 / 50%);
    }

    table {
      .selectedCell::after {
        display: none;
      }

      .column-resize-handle {
        display: none;
      }
    }
  }
}
</style>
