import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { EditorViewService } from '../api/services/editor-view.service';
import type { PartiallySignRequest, UpdateInputFieldRequest } from '../api/services/types/requests';

export const useEditorViewInputStore = defineStore('editor-view-input', () => {
  const updatingInputs: Ref<EditorRecipientInput[]> = ref([]);
  const failedInputUpdates: Ref<EditorRecipientInput[]> = ref([]);
  const alert: Ref<string> = ref(null);
  const formInputFieldsWithErrors = ref<string[]>([]);

  const updateInputValue = async (payload: UpdateInputFieldRequest, dealroom = false) => {
    updatingInputs.value = [
      ...updatingInputs.value,
      {
        fieldId: payload.fieldId,
        value: payload.value,
      },
    ];

    try {
      dealroom
        ? await EditorViewService.updateDealroomEditorInputField(payload)
        : await EditorViewService.updateEditorInputField(payload);

      updateInputValueSuccess(payload.fieldId);
    } catch (error) {
      updateInputValueFailed({
        fieldId: payload.fieldId,
        value: payload.value,
      });
    }
  };

  const updateInputValueSuccess = (id: string) => {
    clearFailedInputUpdates(id);
    removeUpdatingInput(id);
  };

  const clearFailedInputUpdates = (id: string) => {
    failedInputUpdates.value = failedInputUpdates.value.filter(input => input.fieldId !== id);
  };

  const removeUpdatingInput = (inputId: string) => {
    const indexToRemove = updatingInputs.value.findIndex(input => input.fieldId === inputId);
    updatingInputs.value = updatingInputs.value.filter((_, index) => index != indexToRemove);
  };

  const updateInputValueFailed = (input: EditorRecipientInput) => {
    removeUpdatingInput(input.fieldId);
    if (updatingInputs.value.find(updatingInput => updatingInput.fieldId === input.fieldId)) {
      return;
    }
    addToFailedInputUpdates(input);
    setAlert(t('could-not-save-please-try-again-or-contact-support'));
  };

  const addToFailedInputUpdates = (input: EditorRecipientInput) => {
    failedInputUpdates.value = [...failedInputUpdates.value, input];
  };

  const setAlert = (message: string) => {
    alert.value = message;
  };

  const partiallySignField = async (payload: PartiallySignRequest) => {
    try {
      await EditorViewService.partiallySignDocument(payload);
    } catch (error) {
      setAlert(t('could-not-save-please-try-again-or-contact-support'));
    }
  };

  const hasFormInputFieldsWithErrors = computed(() => formInputFieldsWithErrors.value.length > 0);

  const updateFormInputFieldsWithErrors = ({
    fieldId,
    isValid,
  }: {
    fieldId: string;
    isValid: boolean;
  }) => {
    if (isValid) {
      formInputFieldsWithErrors.value = formInputFieldsWithErrors.value.filter(
        id => id !== fieldId
      );
      return;
    }
    formInputFieldsWithErrors.value = [...formInputFieldsWithErrors.value, fieldId];
  };

  return {
    updatingInputs,
    failedInputUpdates,
    alert,
    hasFormInputFieldsWithErrors,

    updateInputValue,
    partiallySignField,
    setAlert,
    updateFormInputFieldsWithErrors,
  };
});
