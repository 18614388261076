import gql from 'graphql-tag';
import { editorBlockContentFragment } from './editor-block-content.fragment';

export const editorBlockFragment = gql`
  fragment editorBlockFragment on EditorBlock {
    id
    entityId
    userId
    contentId
    contentVersion
    pdfPageCount
    content {
      ...editorBlockContentFragment
    }
  }
  ${editorBlockContentFragment}
`;
