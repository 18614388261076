<template>
  <div class="node-empty-state">
    <div class="node-empty-state-content">
      <div v-if="icon || heading" class="icon-heading">
        <div class="blob">
          <EmptyStateBlob />
          <FriedIcon v-if="icon" :icon="icon" />
        </div>
        <FriedH3 v-if="heading">{{ heading }}</FriedH3>
      </div>
      <FriedParagraph v-if="description">{{ description }}</FriedParagraph>
    </div>
    <div v-if="$slots.action" class="node-empty-state-action">
      <slot name="action" />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import type { IconName } from '@getaccept/fried-tofu';
import EmptyStateBlob from './assets/node-empty-state-blob.svg';

export default defineComponent({
  components: {
    EmptyStateBlob,
  },
  props: {
    icon: { type: String as PropType<IconName>, default: '' },
    heading: { type: String, default: '' },
    description: { type: String, default: '' },
  },
});
</script>

<style lang="scss" scoped>
.node-empty-state {
  padding: var(--spacing-200);
  justify-content: center;
  margin: var(--spacing-100);
  flex-direction: column;
  gap: var(--spacing-100);
  background: $info-light;
  text-align: center;
  border-radius: var(--radius-medium);

  &-content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-50);

    .icon-heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-100);

      .blob {
        position: relative;

        .svg-icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: var(--blue-45);
        }
      }
    }

    .paragraph {
      color: var(--gray-39);
    }
  }

  &-action {
    margin-top: var(--spacing-100);
    display: flex;
    justify-content: center;
  }
}
</style>
