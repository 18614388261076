<template>
  <FriedFieldSet
    :error="hasError"
    :error-message="errorMessage"
    class="input-editor-container"
    @drop.stop.prevent
  >
    <div class="email-input-container">
      <FriedIcon class="icon-left" icon="mail-sign-at" :size="IconSize.Small" />
      <EditorContent class="email-input" :editor="editor" spellcheck="false" />
    </div>
    <FriedSpinner v-if="isUpdating" class="updating-spinner" :size="20" />
  </FriedFieldSet>
</template>
<script lang="ts">
import debounce from 'debounce';
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Focus from '@tiptap/extension-focus';
import Text from '@tiptap/extension-text';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import {
  ProsemirrorHelper,
  InputDocNode,
  MergeValueNode,
  ParagraphNode,
  EditorHelper,
} from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { onBeforeUnmount, onMounted, computed, defineComponent, ref, watch } from 'vue';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import Placeholder from '@tiptap/extension-placeholder';
import { validateEmail } from '@getaccept/lib-shared-new/src/helpers';
import { IconSize } from '@getaccept/fried-tofu';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';

export default defineComponent({
  components: { EditorContent },
  props: {
    inputField: { type: Object as PropType<Field>, required: true },
    recipientInput: { type: Object as PropType<EditorRecipientInput>, default: null },
    fieldFocused: { type: Boolean },
    editable: { type: Boolean },
    isUpdating: { type: Boolean },
    isRequired: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    hasUpdateError: { type: Boolean },
  },
  emits: ['update', 'validate-form-input'],
  setup(props, { emit }) {
    const editor: Ref<TipTapEditor | null> = ref(null);
    const emailErrorMessage = ref('');

    const hasError = computed(() => props.hasUpdateError || !!emailErrorMessage.value);
    const errorMessage = computed(() => emailErrorMessage.value || '');

    const plainContent = computed(() =>
      editor.value ? ProsemirrorHelper.getInnerTextContent(editor.value.state).trim() : ''
    );

    const updateValidationData = () => {
      if (plainContent.value === '') {
        emit('update', plainContent.value);
        emit('validate-form-input', !props.isRequired);
        emailErrorMessage.value = props.isRequired
          ? t('input_fields_error_field_not_correctly_filled_out')
          : '';
        return;
      }
      const isValid = validateEmail(plainContent.value);
      emit('validate-form-input', isValid);
      emailErrorMessage.value = isValid
        ? ''
        : t('input_fields_error_field_not_correctly_filled_out');
      if (isValid) {
        emit('update', plainContent.value);
      }
    };

    const update = () => {
      if (!props.editable) {
        return;
      }

      updateValidationData();
    };

    const debounceUpdate = debounce(update, 300);

    watch(
      () => props.fieldFocused,
      () => {
        if (props.fieldFocused) {
          editor.value.view.focus();
        }
      }
    );

    watch(
      () => props.recipientInput,
      () => {
        if (!props.recipientInput) {
          return;
        }

        const content = ProsemirrorHelper.getInputContent(props.recipientInput.value, props.fields);
        editor.value.commands.setContent(content);
      }
    );

    watch(
      () => props.editable,
      () => {
        editor.value.setEditable(props.editable);
      }
    );

    const initialContent = () => {
      const inputField = EditorHelper.hasMergeTags(props.inputField.value)
        ? ProsemirrorHelper.getMergeValueFromMergeTag(
            ProsemirrorHelper.getTagIdFromMergeTag(props.inputField.value),
            props.fields,
            props.mergeValues
          )
        : props.inputField.value;
      return ProsemirrorHelper.getInputContent(
        props.recipientInput?.value || inputField,
        props.fields
      );
    };

    const editorConfig = computed(() => ({
      content: initialContent(),
      editable: props.editable,
      enableInputRules: false,
      enablePasteRules: false,
      extensions: [
        InputDocNode,
        Focus,
        ParagraphNode,
        Text,
        MergeValueNode,
        Placeholder.configure({
          placeholder: t('input_fields_add_email'),
        }),
      ],
      onBlur: update,
      onUpdate: debounceUpdate,
    }));

    onMounted(() => {
      editor.value = new TipTapEditor(editorConfig.value);
    });

    onBeforeUnmount(() => {
      editor.value.destroy();
    });

    return {
      IconSize,
      editor,
      errorMessage,
      emailErrorMessage,
      hasError,
      update,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/form-input-editor';

.input-editor-container {
  .email-input-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-50);
    width: 100%;
    padding: calc(#{var(--spacing-75)} - 1px);

    .icon-left {
      flex-shrink: 0;
    }

    .email-input {
      width: 100%;
    }
  }

  .updating-spinner {
    position: absolute;
    right: var(--spacing-50);
    top: calc(#{var(--spacing-50)} + 1px);
    width: 20px;
  }
}
</style>
