<template>
  <canvas ref="canvasRef" />
</template>
<script lang="ts">
import type { PropType, Ref } from 'vue';
import { watch, defineComponent, ref, onMounted, computed } from 'vue';
import { decode } from 'blurhash';

export default defineComponent({
  props: {
    hash: {
      type: String,
      required: true,
    },
    dimensions: {
      type: Object as PropType<{ width: number; height: number }>,
      default: () => ({}),
    },
  },
  setup(props) {
    const canvasRef: Ref<HTMLCanvasElement> = ref(null);
    const defaultHeight = 10;
    const defaultWidth = 10;

    const decodeHash = () => {
      const { width, height } = minimizedDimensions.value;
      const pixels = decode(props.hash, width, height);
      const ctx = canvasRef.value.getContext('2d');
      canvasRef.value.width = width;
      canvasRef.value.height = height;
      const imageData = ctx.createImageData(width, height);
      imageData.data.set(pixels);
      ctx.putImageData(imageData, 0, 0);
    };

    const minimizedDimensions = computed(() => {
      const { height, width } = props.dimensions || {};
      if (!height) {
        return { height: defaultHeight, width: defaultWidth };
      }

      const aspectRatio = width / height;
      const newWidth = Math.max(defaultWidth, Math.round(aspectRatio * 10));
      const newHeight = Math.round(newWidth / aspectRatio);

      return { width: newWidth, height: newHeight };
    });

    watch(
      () => props.hash,
      (oldValue: string, newValue: string) => {
        if (newValue && oldValue !== newValue) {
          decodeHash();
        }
      }
    );

    onMounted(() => {
      if (!props.hash) {
        return;
      }
      decodeHash();
    });

    return {
      canvasRef,
      defaultWidth,
      defaultHeight,
      minimizedDimensions,
      decodeHash,
    };
  },
});
</script>

<style lang="scss" scoped>
canvas {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: $editor-medium-border-radius;
}
</style>
