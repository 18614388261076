<template>
  <div :class="['input-editor-container', { label }]">
    <EditorContent :editor="viewer" spellcheck="false" />
    <FriedSpinner v-if="isUpdating" class="updating-spinner" :size="20" />
  </div>
</template>
<script lang="ts">
import debounce from 'debounce';
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import History from '@tiptap/extension-history';
import Focus from '@tiptap/extension-focus';
import Text from '@tiptap/extension-text';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import {
  ProsemirrorHelper,
  EnterBreakNode,
  InputDocNode,
  MergeValueNode,
  ParagraphNode,
} from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { onBeforeUnmount, onMounted, computed, defineComponent, ref, watch } from 'vue';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { updateMergeValues, setContent, handleInit } from './helpers/prosemirror-viewer.helper';

export default defineComponent({
  name: 'ProsemirrorViewerFormInput',
  components: {
    EditorContent,
  },
  props: {
    content: {
      type: [Object, String] as PropType<TextContentDoc | string>,
      default: '',
    },
    multiLine: { type: Boolean, default: true },
    bold: { type: Boolean, default: true },
    italic: { type: Boolean, default: true },
    underline: { type: Boolean, default: true },
    superscript: { type: Boolean, default: true },
    label: { type: Boolean, default: true },
    link: { type: Boolean, default: true },
    fieldFocused: { type: Boolean, default: false },
    editable: { type: Boolean, default: false },
    placeholder: { type: String, default: '' },
    isUpdating: { type: Boolean, default: false },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const viewer: Ref<TipTapEditor | null> = ref(null);

    const contentPayload = computed(() => ({
      viewer: viewer.value,
      content: props.content,
      fields: props.fields,
      mergeValues: props.mergeValues,
    }));

    const innerTextContent = computed(() => {
      let transaction = viewer.value?.state.tr;

      transaction = ProsemirrorHelper.replaceBrNodes(transaction, viewer.value?.state.schema);

      return ProsemirrorHelper.getStringFromTransaction(transaction, viewer.value?.state.schema);
    });

    const update = () => {
      if (!props.editable) {
        return;
      }

      emit('update', innerTextContent.value);
    };

    watch(
      () => props.editable,
      () => {
        viewer.value.setEditable(props.editable);
      }
    );

    const debounceUpdate: () => void = debounce(update, 300);

    const inputViewer = computed(() => ({
      content: props.content,
      editable: props.editable,
      extensions: [
        InputDocNode,
        Focus,
        ParagraphNode,
        Text,
        MergeValueNode,
        History,
        ...(props.bold ? [Bold] : []),
        ...(props.italic ? [Italic] : []),
        ...(props.underline ? [Underline] : []),
        ...(props.superscript ? [Superscript] : []),
        ...(props.multiLine ? [EnterBreakNode] : []),
        ...(props.link
          ? [
              Link.configure({
                autolink: false,
                openOnClick: true,
                linkOnPaste: false,
              }),
            ]
          : []),
      ],
      onBlur: update,
      onUpdate: debounceUpdate,
      onCreate: () => handleInit(viewer.value, props.fields, props.mergeValues),
      enableInputRules: false,
      enablePasteRules: false,
    }));

    onMounted(() => {
      viewer.value = new TipTapEditor(inputViewer.value);
    });

    onBeforeUnmount(() => {
      viewer.value.destroy();
    });

    watch(
      () => props.fields,
      () => {
        if (!viewer.value.isDestroyed) {
          updateMergeValues(contentPayload.value);
        }
      }
    );

    watch(
      () => props.mergeValues,
      (newValues, oldValues) => {
        if (!viewer.value.isDestroyed || JSON.stringify(newValues) === JSON.stringify(oldValues)) {
          return;
        }

        updateMergeValues(contentPayload.value);
      }
    );

    watch(
      () => props.content,
      () => {
        setContent(contentPayload.value);
      }
    );

    watch(
      () => props.fieldFocused,
      fieldFocused => {
        if (fieldFocused) {
          viewer.value.view.focus();
        }
      }
    );

    return {
      viewer,
      inputViewer,
      innerTextContent,
      update,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/form-input-editor';

.updating-spinner {
  position: absolute;
  right: var(--spacing-50);
  top: calc(#{var(--spacing-50)} + 1px);
  width: 20px;
}
</style>
