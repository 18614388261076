import { Breakpoint } from '@getaccept/lib-shared-new/src/enums/breakpoint';
import { useMediaQuery } from '@vueuse/core';

export default function useBreakpoints() {
  const isXXSmallScreen = useMediaQuery(
    `(max-width: ${Breakpoint.XSmall - 1}px), (max-width: ${
      Breakpoint.XSmall - 1
    }px) and (orientation: landscape)`
  );
  const isXSmallScreen = useMediaQuery(
    `(min-width: ${Breakpoint.XSmall}px) and (max-width: ${Breakpoint.Small - 1}px), (min-width: ${
      Breakpoint.XSmall
    }px) and (max-width: ${Breakpoint.Small - 1}px) and (orientation: landscape)`
  );
  const isSmallScreen = useMediaQuery(
    `(min-width: ${Breakpoint.Small}px) and (max-width: ${
      Breakpoint.Medium - 1
    }px) and (orientation: landscape)`
  );
  const isMediumScreen = useMediaQuery(
    `(min-width: ${Breakpoint.Medium}px) and (max-width: ${Breakpoint.Large - 1}px)`
  );
  const isLargeScreen = useMediaQuery(`(min-width: ${Breakpoint.Large}px)`);

  const isXXSmallScreenAndDown = useMediaQuery(
    `(max-width: ${Breakpoint.XXSmall}px) , (max-width: ${Breakpoint.XXSmall}px)  and (orientation: landscape)`
  );

  const isXSmallScreenAndDown = useMediaQuery(
    `(max-width: ${Breakpoint.XSmall}px) , (max-width: ${Breakpoint.XSmall}px)  and (orientation: landscape)`
  );

  const isSmallScreenAndDown = useMediaQuery(
    `(max-width: ${Breakpoint.Small}px) , (max-width: ${Breakpoint.Small}px)  and (orientation: landscape)`
  );

  const isMediumScreenAndDown = useMediaQuery(`(max-width: ${Breakpoint.Medium}px)`);

  const isXSmallScreenAndUp = useMediaQuery(
    `(min-width: ${Breakpoint.XSmall}px) , (min-width: ${Breakpoint.XSmall}px)  and (orientation: landscape)`
  );

  const isSmallScreenAndUp = useMediaQuery(
    `(min-width: ${Breakpoint.Small}px) , (min-width: ${Breakpoint.Small}px)  and (orientation: landscape)`
  );

  const isMediumScreenAndUp = useMediaQuery(`(min-width: ${Breakpoint.Medium}px)`);

  return {
    isXXSmallScreenAndDown,
    isXXSmallScreen,
    isXSmallScreen,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXSmallScreenAndDown,
    isSmallScreenAndDown,
    isMediumScreenAndDown,
    isXSmallScreenAndUp,
    isSmallScreenAndUp,
    isMediumScreenAndUp,
  };
}
