import type { Ref } from 'vue';
import { readonly, ref } from 'vue';
import type { LDClient, LDOptions, LDFlagSet, LDContextCommon } from 'launchdarkly-js-client-sdk';
import { initialize as init } from 'launchdarkly-js-client-sdk';
import { kebabToCamelCase } from '../helpers';
import type { LaunchDarklyFlags } from './types/launch-darkly-flags';
import type { LaunchDarklyContextKind } from './types/enums/launch-darkly-context-kind';

interface LDSingleKindContext extends LDContextCommon {
  kind: LaunchDarklyContextKind;
}

const client: Ref<LDClient> = ref(null);
const flags: Ref<LaunchDarklyFlags> = ref({});
const ready: Ref<boolean> = ref(false);

const formatLDFlags = (flags: LDFlagSet): LaunchDarklyFlags =>
  Object.fromEntries(Object.keys(flags).map((key: string) => [kebabToCamelCase(key), flags[key]]));

const initialize = (clientSideId: string, context: LDSingleKindContext, options?: LDOptions) => {
  if (client.value) {
    throw new Error('LaunchDarkly client already initialized');
  }

  if (!clientSideId) {
    throw new Error(`Cannot initialize LaunchDarkly without a clientSideID`);
  }

  client.value = init(clientSideId, context, options);

  client.value.on('ready', () => {
    flags.value = formatLDFlags(client.value.allFlags());
    ready.value = true;
  });

  client.value.on('change', (changes: LDFlagSet) => {
    const flattenedFlags: LaunchDarklyFlags = Object.fromEntries(
      Object.keys(changes).map(key => [key, changes[key].current])
    );

    const newFlags = formatLDFlags(flattenedFlags);

    flags.value = { ...flags.value, ...newFlags };
  });

  client.value.on('failed', async () => {
    await shutdownLDClient();
    throw new Error(`LaunchDarkly client could not connect to the server`);
  });

  client.value.on('error', async (error: Error) => {
    await shutdownLDClient();
    throw new Error(`LaunchDarkly error: ${error.message}`);
  });
};

const shutdownLDClient = async () => {
  if (client.value) {
    await client.value.close();
    client.value = null;
  }
  ready.value = false;
  flags.value = {};
};

export function useLaunchDarkly() {
  return {
    ready: readonly(ready),
    flags: readonly(flags),
    initialize,
  };
}
