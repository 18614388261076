import type { Recipient, SearchRecipient } from '../../types/Recipient';
import { capitalizeFirstLetter } from '../../helpers';
import { RecipientRole } from '../enums/recipient-role';
import { t } from '../../helpers/translation.helper';

export class RecipientsHelper {
  static getTranslatedRole(role: RecipientRole | string) {
    switch (role) {
      case RecipientRole.CC:
        return t('cc');
      case RecipientRole.Signer:
        return t('signer');
      case RecipientRole.ExternalApprover:
        return t('external_approver');
      case RecipientRole.Approver:
      case RecipientRole.InternalApproverAPIFallback:
        return t('internal-approver');
      default:
        return '';
    }
  }

  static getRoleTooltip(role: RecipientRole) {
    switch (role) {
      case RecipientRole.Signer:
        return [
          t('signer-role-description-bullet-one'),
          t('recipient-role-description-visible-bullet'),
          t('recipient-role-description-certificate-bullet'),
        ];
      case RecipientRole.Approver:
      case RecipientRole.InternalApproverAPIFallback:
        return [
          t('internal-approver-role-description-bullet-one'),
          t('recipient-role-description-not-visible-bullet'),
          t('recipient-role-description-not-certificate-bullet'),
        ];
      case RecipientRole.ExternalApprover:
        return [
          t('external-approver-role-description-bullet-one'),
          t('recipient-role-description-visible-bullet'),
          t('recipient-role-description-certificate-bullet'),
        ];
      case RecipientRole.CC:
        return [
          t('viewer-role-description-bullet-one'),
          t('recipient-role-description-visible-bullet'),
          t('recipient-role-description-certificate-bullet'),
        ];
    }
  }

  static isInternalApprover(role: RecipientRole | string) {
    return [RecipientRole.Approver, RecipientRole.InternalApproverAPIFallback].includes(
      role as RecipientRole
    );
  }

  static isApprover(role: RecipientRole | string) {
    return RecipientsHelper.isInternalApprover(role) || role === RecipientRole.ExternalApprover;
  }

  static getRecipientFullName(recipient: Partial<Recipient>): string {
    if (!recipient) {
      return '';
    }
    return recipient.fullName || `${recipient.firstName || ''} ${recipient.lastName || ''}`.trim();
  }

  static isOnlyNumbers(input: string): boolean {
    return /^\d+$/.test(input);
  }

  static formatSearchString(search = ''): string {
    let formattedSearch = search.trim();
    if (this.isOnlyNumbers(formattedSearch)) {
      formattedSearch =
        formattedSearch.charAt(0) === '0' ? formattedSearch.substring(1) : formattedSearch;
    }
    return formattedSearch;
  }

  static getRecipientDataFromEmail(email: string): SearchRecipient {
    if (email.indexOf('@') === -1) {
      return null;
    }
    const names: string[] = email
      .split('@')[0]
      .split('.')
      .map(name => capitalizeFirstLetter(name));

    if (!names[0]) {
      return null;
    }
    if (names[0].length === 1) {
      return { email: email };
    }
    const lastNames: string[] = names.slice(1, names.length);
    return {
      firstName: names[0],
      lastName: lastNames.join(' ').trim() || '',
      fullName: `${names.join(' ')}`.trim(),
      email: email,
    };
  }
}
