import type { TableRow } from '../types/table-content';
import { ProsemirrorType, NodeAlign } from '../types/enums';

const tableRow: TableRow = {
  type: ProsemirrorType.TableRow,
  content: [
    {
      type: ProsemirrorType.TableCell,
      attrs: {
        colwidth: [310],
      },
      content: [
        {
          type: ProsemirrorType.Paragraph,
          attrs: {
            align: NodeAlign.Left,
          },
        },
      ],
    },
    {
      type: ProsemirrorType.TableCell,
      attrs: {
        colwidth: [310],
      },
      content: [
        {
          type: ProsemirrorType.Paragraph,
          attrs: {
            align: NodeAlign.Left,
          },
        },
      ],
    },
  ],
};

export const fallbackTableContent: string = JSON.stringify({
  type: ProsemirrorType.Doc,
  content: [
    {
      type: ProsemirrorType.Table,
      content: [tableRow, tableRow],
    },
  ],
});
