<template>
  <div
    class="video-player-container"
    data-external="video-player-container-element"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <video
      v-if="isHTML5Video"
      ref="playerRef"
      preload="metadata"
      :data-poster="thumbUrl"
      :src="videoPlayerType === VideoPlayerType.Url ? url : videoStream"
      playsinline
      @error="$emit('error')"
    />
    <div
      v-else-if="isYouTubeOrVimeo"
      ref="playerRef"
      :data-plyr-provider="playerProviderId"
      :data-plyr-embed-id="playerEmbedId"
    />
  </div>
</template>

<script lang="ts">
import type { PropType, Ref } from 'vue';
import { defineComponent, ref, watch, computed, onMounted, onBeforeUnmount } from 'vue';
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';
import { until } from '@vueuse/core';
import { VideoLinkHelper } from '../../link/helpers/video-link.helper';
import { UrlHelper } from '../../../helpers/url.helper';
import { throttle } from '../../../helpers';
import { VideoPlayerType } from '../types/enums/video-player-type';

export default defineComponent({
  props: {
    file: {
      type: File as PropType<File>,
      default: null,
    },
    url: {
      type: String,
      default: '',
    },
    controls: {
      type: Boolean,
      default: true,
    },
    clickToPlay: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
    },
    muted: {
      type: Boolean,
    },
    playOnHover: {
      type: Boolean,
    },
    ratio: {
      type: String,
      default: '',
    },
    thumbUrl: {
      type: String,
      default: '',
    },
  },
  emits: ['timeupdate', 'playing', 'pause', 'ended', 'error'],
  setup(props, { emit }) {
    const player = ref(null);
    const playerRef: Ref<HTMLVideoElement> = ref(null);

    watch(
      () => props.thumbUrl,
      () => setPoster(props.thumbUrl)
    );

    const isBlob = (url: string) => url.indexOf('blob') > -1;

    const isYouTubeOrVimeo = computed(() =>
      [VideoPlayerType.Youtube, VideoPlayerType.Vimeo].includes(videoPlayerType.value)
    );
    const isHTML5Video = computed(() =>
      [VideoPlayerType.File, VideoPlayerType.Url].includes(videoPlayerType.value)
    );

    const videoStream = computed(() => {
      if (!props.file) {
        return null;
      }
      const videoBlob: Blob = props.file as Blob;
      return window.URL.createObjectURL(videoBlob);
    });

    const videoPlayerType = computed(() => {
      if (props.file) {
        return VideoPlayerType.File;
      }
      if (VideoLinkHelper.getYoutubeURLValidation(props.url).valid) {
        return VideoPlayerType.Youtube;
      }
      if (VideoLinkHelper.getVimeoURLValidation(props.url).valid) {
        return VideoPlayerType.Vimeo;
      }
      if (UrlHelper.isValidUrl(props.url) || isBlob(props.url)) {
        return VideoPlayerType.Url;
      }
      return VideoPlayerType.Unknown;
    });

    const playerProviderId = computed(() => {
      switch (videoPlayerType.value) {
        case VideoPlayerType.Youtube:
          return 'youtube';
        case VideoPlayerType.Vimeo:
          return 'vimeo';
        default:
          return '';
      }
    });

    const playerEmbedId = computed(() => {
      switch (videoPlayerType.value) {
        case VideoPlayerType.Youtube:
          return VideoLinkHelper.getYoutubeURLValidation(props.url).id;
        case VideoPlayerType.Vimeo:
          return VideoLinkHelper.getVimeoURLValidation(props.url).id;
        default:
          return '';
      }
    });

    const setPoster = (value: string) => {
      if (!playerRef.value) {
        return;
      }

      playerRef.value.poster = value;
    };

    const handleTimeupdate = () => {
      emit('timeupdate', Math.floor(player.value.currentTime * 1000));
    };

    const handlePlaying = () => {
      emit('playing');
    };

    const handlePause = () => {
      setPoster(props.thumbUrl);
      emit('pause');
    };

    const handleEnded = () => {
      setPoster(props.thumbUrl);
      emit('ended');
    };

    const pause = () => {
      if (!playerRef.value) {
        return;
      }

      player.value.pause();
    };

    const play = () => {
      if (!playerRef.value) {
        return;
      }

      player.value.play();
    };

    const handleMouseEnter = () => {
      if (props.playOnHover) {
        play();
      }
    };

    const handleMouseLeave = () => {
      if (props.playOnHover) {
        pause();
      }
    };

    const throttledHandleTimeUpdate = throttle(handleTimeupdate, 1000);

    const createPlayer = () => {
      player.value = new Plyr(playerRef.value, {
        settings: [],
        storage: { enabled: false },
        autoplay: props.autoplay,
        muted: props.muted,
        clickToPlay: props.clickToPlay,
        ratio: props.ratio,
        fullscreen: {
          enabled: true,
          fallback: true,
          iosNative: true,
          container: null,
        },
        controls: props.controls
          ? [
              'play-large',
              'play',
              'progress',
              'current-time',
              'mute',
              'volume',
              'airplay',
              'fullscreen',
            ]
          : [],
        resetOnEnd: true,
        invertTime: false,
      });
      player.value.on('timeupdate', throttledHandleTimeUpdate);
      player.value.on('playing', handlePlaying);
      player.value.on('pause', handlePause);
      player.value.on('ended', handleEnded);
    };

    const setPlayerSource = () => {
      player.value.source = {
        type: 'video',
        sources: [
          {
            src: playerEmbedId.value,
            provider: playerProviderId.value,
          },
        ],
      };
    };

    watch([playerEmbedId, playerProviderId, playerRef], () => {
      if (player.value && isYouTubeOrVimeo.value) {
        setPlayerSource();
      }
    });

    const destroyPlayer = async () => {
      if (!player.value) {
        return;
      }

      player.value.destroy();
      player.value = null;
    };

    onMounted(async () => {
      await until(playerRef).toBeTruthy();
      createPlayer();
    });

    onBeforeUnmount(destroyPlayer);

    return {
      player,
      videoStream,
      VideoPlayerType,
      videoPlayerType,
      handleTimeupdate,
      handlePlaying,
      handlePause,
      handleEnded,
      pause,
      play,
      handleMouseEnter,
      handleMouseLeave,
      throttledHandleTimeUpdate,
      isBlob,
      setPoster,
      playerRef,
      playerProviderId,
      playerEmbedId,
      isYouTubeOrVimeo,
      isHTML5Video,
    };
  },
});
</script>

<style lang="scss" scoped>
/* stylelint-disable selector-class-pattern */
.video-player-container {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: $xs) {
    border-radius: 0;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  :deep(.plyr__control--overlaid) {
    background: var(--brand-orange);
  }

  :deep(.plyr--full-ui input[type='range']) {
    color: var(--brand-orange);
  }

  :deep(.plyr) {
    min-width: auto;
  }

  :deep(.plyr--video .plyr__control.plyr__tab-focus),
  :deep(.plyr--video .plyr__control:hover),
  :deep(.plyr--video .plyr__control[aria-expanded='true']) {
    background: var(--brand-orange);
  }
}
</style>
