import type { ExtractPropTypes, PropType, Ref } from 'vue';
import { onMounted, watch, onBeforeUnmount, computed, ref } from 'vue';
import { Breakpoint } from '@getaccept/lib-shared-new/src/enums/breakpoint';
import { useRouter, useRoute } from 'vue-router';
import type { Cell } from '../../types';
import { SlideShowHelper } from '../../helpers/slide-show.helper';

export const slideShowProps = {
  cells: { type: Array as PropType<Cell[]>, required: true },
  rowId: { type: String, required: true },
};

export function useSlideShow(props: ExtractPropTypes<typeof slideShowProps>) {
  const route = useRoute();
  const router = useRouter();

  const current: Ref<number> = ref(0);
  const currentGridSlide: Ref<number> = ref(0);
  const direction: Ref<number> = ref(1);
  const transitionName: Ref<string> = ref('fade');
  const isGridView: Ref<boolean> = ref(false);
  const dragPos: Ref<number> = ref(0);
  const isSticky: Ref<boolean> = ref(false);
  const isLightboxView = ref(false);
  const lightboxContainerRef: Ref<HTMLElement> = ref(null);

  const slides = computed(() => props.cells[0].nodes.length);
  const gridSlides = computed(() => Math.ceil(slides.value / 9));

  const handleToggleLightBoxView = (value: boolean) => {
    if (value) {
      document.body.appendChild(lightboxContainerRef.value);
    }
    isLightboxView.value = value;
  };

  const handleObserver = (isVisible: boolean) => {
    isSticky.value = !isVisible;
  };

  const observerOptions: Ref<Record<string, any>> = ref({
    callback: handleObserver,
    throttle: 100,
    intersection: {
      threshold: 1,
    },
    throttleOptions: {
      leading: false,
    },
  });

  const slide = (dir: number) => {
    direction.value = dir;
    dir === 1 ? (transitionName.value = 'slide-next') : (transitionName.value = 'slide-prev');
    if (isGridView.value) {
      currentGridSlide.value = SlideShowHelper.updateCurrentSlide(
        currentGridSlide.value,
        dir,
        gridSlides.value
      );
    } else {
      current.value = SlideShowHelper.updateCurrentSlide(current.value, dir, slides.value);
    }
  };

  const handleToggleGridView = (value: boolean) => {
    transitionName.value = '';
    isGridView.value = value;
  };

  const handleClick = (index: number) => {
    transitionName.value = '';
    isGridView.value = false;
    current.value = index;
  };

  const currentSlide = computed(() => {
    if (isGridView.value) {
      return currentGridSlide.value;
    }
    return current.value;
  });

  const handleDragStart = (e: MouseEvent | TouchEvent) => {
    if (e instanceof MouseEvent) {
      e.preventDefault();
    }
    dragPos.value = (e as MouseEvent).clientX || (e as TouchEvent).touches[0].clientX;
  };

  const handleDragEnd = (e: MouseEvent | TouchEvent) => {
    if (isResizing.value) {
      dragPos.value = 0;
      return;
    }

    transitionName.value = '';
    const dragThreshold = e instanceof MouseEvent ? 0 : 80;
    const posX = (e as MouseEvent).clientX || (e as TouchEvent).changedTouches[0].clientX;
    if (dragPos.value - dragThreshold > posX) {
      slide(1);
    } else if (dragPos.value + dragThreshold < posX) {
      slide(-1);
    } else if (e instanceof TouchEvent && isTap(posX)) {
      isLightboxView.value ? handleShowToolbar() : openLightbox();
    }
    dragPos.value = 0;
  };

  const isTap = (posX: number) => {
    const tapThreshold = 2;
    return dragPos.value - posX < tapThreshold && dragPos.value - posX > -tapThreshold;
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      removeLightBoxQuery();
    }
    if (e.key === 'ArrowLeft' || e.code === 'KeyA') {
      slide(-1);
    } else if (e.key === 'ArrowRight' || e.code === 'KeyD') {
      slide(1);
    }
  };

  const isSmallScreen = ref(false);

  const updateScreenSize = () => {
    isSmallScreen.value = document.body.offsetWidth < Breakpoint.Small;
  };

  const resizeTimer = ref(null);
  const isResizing = ref(false);

  const handleResize = () => {
    updateScreenSize();
    if (resizeTimer.value) {
      clearTimeout(resizeTimer.value);
      resizeTimer.value = null;
    }
    isResizing.value = true;
    resizeTimer.value = setTimeout(() => {
      isResizing.value = false;
    }, 100);
  };

  const showToolbarTimer = ref(null);
  const showToolbar = ref(false);

  const handleShowToolbar = () => {
    if (showToolbarTimer.value) {
      clearTimeout(showToolbarTimer.value);
      showToolbarTimer.value = null;
    }
    showToolbar.value = true;
    showToolbarTimer.value = setTimeout(() => {
      showToolbar.value = false;
    }, 1000);
  };

  const removeLightBoxQuery = () => {
    if (!route.query.lightbox) {
      return;
    }
    const query = Object.assign({}, route.query);
    delete query.lightbox;
    router.replace({ query });
  };

  const lightboxRoute = computed(() => {
    if (isLightboxView.value) {
      const query = Object.assign({}, route.query);
      delete query.lightbox;
      return { ...route, query };
    } else {
      return { ...route, query: { ...route.query, lightbox: props.rowId } };
    }
  });

  const openLightbox = () => {
    router.replace(lightboxRoute.value);
  };

  watch(
    () => route.query?.lightbox,
    lightboxId => {
      lightboxId == props.rowId ? handleToggleLightBoxView(true) : handleToggleLightBoxView(false);
    }
  );

  watch(
    slides,
    () => {
      if (currentGridSlide.value === gridSlides.value) {
        currentGridSlide.value -= 1;
      }
      if (current.value === slides.value) {
        current.value -= 1;
      }
    },
    { immediate: true }
  );

  onMounted(() => {
    updateScreenSize();
  });

  onBeforeUnmount(() => {
    if (isLightboxView.value) {
      document.body.removeChild(lightboxContainerRef.value);
    }
  });

  return {
    resizeTimer,
    showToolbarTimer,
    showToolbar,
    handleShowToolbar,
    current,
    currentGridSlide,
    direction,
    handleKeyDown,
    transitionName,
    isGridView,
    dragPos,
    isSticky,
    slides,
    isLightboxView,
    gridSlides,
    lightboxContainerRef,
    handleObserver,
    observerOptions,
    route,
    slide,
    handleToggleLightBoxView,
    handleToggleGridView,
    removeLightBoxQuery,
    lightboxRoute,
    handleClick,
    currentSlide,
    handleDragEnd,
    handleDragStart,
    handleResize,
    isResizing,
    isSmallScreen,
  };
}
