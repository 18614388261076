export class UrlHelper {
  static getHttpsUrl(url: string): string {
    if (url?.startsWith('http://') || url?.startsWith('https://')) {
      return url;
    }
    return `https://${url}`;
  }

  // Instead of only checking the technical validity of the URL, tries to accept strings that are likely to resolve to websites
  static isValidUrl(url: string): boolean {
    try {
      const domains = new URL(UrlHelper.getHttpsUrl(url)).hostname.split('.');
      return domains.length > 1 && domains.every(domain => domain.length);
    } catch {
      return false;
    }
  }
}
