export class SignatureConstants {
  static writeSignatureWidth = 775;
  static writeSignatureHeight = 258;
  static writeSignatureColor = '#333';
  static writeSignatureBackgroundColor = '#F8FAFC';
  static writeSignatureAlign = 'center';
  static writeSignatureFont = 'Pinyon Script';
  static signatureCharacterList = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWX';
  static drawSignatureHeight = '100%';
  static drawSignatureWidth = '100%';
  static initialsMaxLength = 7;
  static initialsValidationRegex = /^[\p{L}|.]{1,7}$/u;
  static saveType = 'image/svg+xml';
  static saveWriteType = 'data:image/svg+xml;base64';
}
