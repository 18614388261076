<template>
  <div
    :class="[
      'cell',
      {
        'is-columns-row': rowType === RowType.Columns,
        'is-cell-type-image-only': cellType === CellType.ImageOnly,
      },
    ]"
    :style="widthStyle"
  >
    <DetachedCommentAnchor
      v-for="comment in commentsWithoutNode"
      :id="comment.id"
      :key="comment.id"
    />
    <template v-for="(node, index) of filteredNodes" :key="node.id">
      <EditorNodeDivider
        v-if="node.type === NodeType.Divider"
        :key="node.id"
        :row-type="rowType"
        :block-width-class="blockWidthClass"
        :node="node as any"
        :current-user="currentUser"
        :linked-contract="getLinkedContract(node)"
      />
      <template v-else-if="node.type === NodeType.Image">
        <div
          v-if="rowType === RowType.SlideShow"
          :key="node.id"
          :class="{
            'visible-grid-slide': isGridView && getVisibleSlides(index, currentSlide, isGridView),
          }"
        >
          <transition :name="transitionName">
            <EditorNodeImage
              v-show="getVisibleSlides(index, currentSlide, isGridView)"
              :key="node.id"
              class="image-node view"
              :class="{ 'grid-transition': isGridView }"
              :node="node as any"
              :is-grid-view="isGridView"
              :block-width-class="blockWidthClass"
              :is-pdf-viewer="isPdfViewer"
              :row-type="rowType"
              :parent-first-child="parentFirstChild"
              :parent-last-child="parentLastChild"
              :is-block-toggled="isBlockToggled"
              @click="isGridView && $emit('click', index)"
              @fetch-new-image="$emit('fetch-new-image', $event)"
              @observe-node-visibility="$emit('observe-node-visibility', $event)"
            />
          </transition>
        </div>
        <EditorNodeImage
          v-else
          :row-type="rowType"
          :parent-first-child="parentFirstChild"
          :parent-last-child="parentLastChild"
          :block-width-class="blockWidthClass"
          :is-pdf-viewer="isPdfViewer"
          :node="node as any"
          :page-width="pageWidth"
          :is-block-toggled="isBlockToggled"
          @fetch-new-image="$emit('fetch-new-image', $event)"
          @observe-node-visibility="$emit('observe-node-visibility', $event)"
        />
      </template>
      <EditorNodeTable
        v-else-if="node.type === NodeType.Table"
        :row-type="rowType"
        :node="node as any"
        :merge-values="mergeValues"
        :is-pdf-viewer="isPdfViewer"
        :block-width-class="blockWidthClass"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodeText
        v-else-if="node.type === NodeType.Text"
        :row-type="rowType"
        :node="node as any"
        :merge-values="mergeValues"
        :has-link-node="hasLinkNode(nodes)"
        :parent-first-child="parentFirstChild"
        :parent-last-child="parentLastChild"
        :block-width-class="blockWidthClass"
        :comments="filterCommentsByNode(comments, node.id)"
        :selected-comment-id="selectedCommentId"
        :show-contextual-comments="showContextualComments"
        :create-contextual-comment-button-state="createContextualCommentButtonState"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
        @create-comment="$emit('create-comment', { ...$event, nodeId: node.id })"
        @select-comment="$emit('select-comment', $event)"
      />
      <EditorNodeLink
        v-else-if="node.type === NodeType.Link"
        :row-type="rowType"
        :node="node as any"
        :theme="theme"
        :parent-first-child="parentFirstChild"
        :parent-last-child="parentLastChild"
        :block-width-class="blockWidthClass"
        @node-click="$emit('node-click', $event)"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodeVideo
        v-else-if="node.type === NodeType.Video"
        :row-type="rowType"
        :node="node as any"
        :block-width-class="blockWidthClass"
        @track-video="$emit('track-video', $event)"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodeForm
        v-else-if="node.type === NodeType.Form"
        :context="context"
        :node="node as any"
        :focused-required-id="focusedRequiredId"
        :merge-values="mergeValues"
        :is-mobile="isSmallScreen"
        :block-width-class="blockWidthClass"
        :user-id="currentUserId"
        :sender="sender"
        :recipient="recipient"
        :recipient-input="recipientInput"
        :is-pdf-viewer="isPdfViewer"
        :updating-inputs="updatingInputs"
        :failed-input-updates="failedInputUpdates"
        @update-input-value="$emit('update-input-value', $event)"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodePricingTable
        v-else-if="node.type === NodeType.PricingTable && usedPricingTableSections(node).length > 0"
        :node="node as any"
        :context="context"
        :sections="usedPricingTableSections(node)"
        :recipient-input="recipientInput"
        :recipient="recipient"
        :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
        :merge-values="mergeValues"
        :block-width-class="blockWidthClass"
        @update-input-value="$emit('update-input-value', $event)"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodeSignature
        v-else-if="node.type === NodeType.Signature"
        :context="context"
        :node="node as any"
        :merge-values="mergeValues"
        :block-width-class="blockWidthClass"
        :sender="sender"
        :recipient="recipient"
        :focused-required-id="focusedRequiredId"
        :partially-signed-fields="partiallySignedFields"
        :recipient-input="recipientInput"
        :is-pdf-viewer="isPdfViewer"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
        @partially-sign="$emit('partially-sign', $event)"
      />
      <EditorNodeContractLink
        v-else-if="node.type === NodeType.ContractLink"
        :row-type="rowType"
        :node="node as any"
        :recipient="recipient"
        :merge-values="mergeValues"
        :parent-first-child="parentFirstChild"
        :parent-last-child="parentLastChild"
        :pusher-client="pusherClient"
        :block-width-class="blockWidthClass"
        :current-user="currentUser"
        :linked-contract="getLinkedContract(node)"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
      <EditorNodeContentPlaceholder
        v-else-if="node.type === NodeType.ContentPlaceholder"
        :node="node as any"
        :row-type="rowType"
        :parent-first-child="parentFirstChild"
        :parent-last-child="parentLastChild"
        :block-width-class="blockWidthClass"
        :placeholder="getContentPlaceholder(node.id)"
      />
      <EditorNodePricingSummary
        v-else-if="node.type === NodeType.PricingSummary"
        :key="`${node.id}-pricing-summary`"
        :context="context"
        :node="node as any"
        :recipient-input="recipientInput"
        @observe-node-visibility="$emit('observe-node-visibility', $event)"
      />
    </template>
  </div>
</template>
<script lang="ts">
import {
  CellType,
  EBlockWidth,
  NodeType,
  RowType,
  LinkBlockHelper,
  SlideShowHelper,
  Context,
  hasUsedPricingRows,
} from '@getaccept/editor-lib-new';
import type {
  Node,
  MergeValues,
  PricingTable,
  ContractListItem,
  ContractLink,
} from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { PusherClient } from '@getaccept/pusher';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import EditorNodeVideo from './EditorNodeVideo.vue';
import EditorNodePricingTable from './pricing-table/EditorNodePricingTable.vue';
import EditorNodeText from './EditorNodeText.vue';
import EditorNodeTable from './EditorNodeTable.vue';
import EditorNodeImage from './EditorNodeImage.vue';
import EditorNodeForm from './EditorNodeForm.vue';
import EditorNodeDivider from './EditorNodeDivider.vue';
import EditorNodeLink from './EditorNodeLink.vue';
import EditorNodeSignature from './EditorNodeSignature.vue';
import EditorNodeContractLink from './contract-link/EditorNodeContractLink.vue';
import EditorNodeContentPlaceholder from './content-placeholder/EditorNodeContentPlaceholder.vue';
import EditorNodePricingSummary from './EditorNodePricingSummary.vue';

export default defineComponent({
  name: 'EditorCell',
  components: {
    DetachedCommentAnchor,
    EditorNodeDivider,
    EditorNodeImage,
    EditorNodeTable,
    EditorNodeText,
    EditorNodeLink,
    EditorNodeVideo,
    EditorNodeForm,
    EditorNodePricingTable,
    EditorNodeSignature,
    EditorNodeContractLink,
    EditorNodeContentPlaceholder,
    EditorNodePricingSummary,
  },
  props: {
    context: { type: String as PropType<Context>, default: () => Context.Document },
    nodes: { type: Array as PropType<Node[]>, default: () => [] },
    mergeValues: {
      type: Object as PropType<MergeValues>,
      default: () => ({ document: {}, sender: {}, recipients: [], entity: {} }),
    },
    isPdfViewer: { type: Boolean },
    rowType: { type: String as PropType<RowType>, default: RowType.Standard },
    parentFirstChild: { type: Boolean },
    parentLastChild: { type: Boolean },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    focusedRequiredId: { type: String, default: '' },
    cellWidth: { type: Number, default: 100 },
    pageWidth: { type: Number, default: 0 },
    isBlockToggled: { type: Boolean },
    cellType: {
      type: String as PropType<CellType>,
      default: null,
    },
    sender: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    recipient: {
      type: Object as PropType<Recipient>,
      default: () => ({}),
    },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    theme: {
      type: Object as PropType<SigningTheme>,
      default: () => ({}),
    },
    currentSlide: { type: Number, default: 0 },
    isGridView: { type: Boolean },
    transitionName: { type: String, default: '' },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    pusherClient: { type: Object as PropType<PusherClient>, default: undefined },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean, default: false },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
    currentUser: { type: Object as PropType<User>, default: undefined },
    linkedContracts: { type: Array as PropType<ContractListItem[]>, default: () => [] },
    mappedPlaceholders: {
      type: Array as PropType<MappedContentPlaceholder[]>,
      default: () => [],
    },
    allowedToEditAfterPartiallySigned: { type: Boolean },
  },
  emits: [
    'fetch-new-image',
    'observe-node-visibility',
    'click',
    'node-click',
    'track-video',
    'update-input-value',
    'select-comment',
    'create-comment',
    'partially-sign',
  ],
  setup(props) {
    const filterNodes = ({ type }: Node) => {
      switch (props.cellType) {
        case CellType.ImageAndText:
          return [NodeType.Image, NodeType.Text].includes(type);
        case CellType.ImageOnly:
          return NodeType.Image === type;
        case CellType.TextOnly:
          return NodeType.Text === type;
        default:
          return false;
      }
    };

    const currentUserId = computed(() => props.currentUser?.id);

    const filteredNodes = computed(() =>
      props.cellType ? props.nodes.filter(filterNodes) : props.nodes
    );

    const filteredNodeIds = computed(() => filteredNodes.value.map(node => node.id));

    const commentsWithoutNode = computed(() =>
      props.comments.filter(
        comment => !filteredNodeIds.value.includes(comment.editorSelection?.nodeId)
      )
    );

    const isSmallScreen = computed(
      () =>
        props.blockWidthClass === EBlockWidth.XXSmall ||
        props.blockWidthClass === EBlockWidth.XSmall
    );

    const widthStyle = computed(() => {
      if (isSmallScreen.value) {
        return 'width: 100%;';
      } else if (props.rowType === RowType.Columns) {
        return `width:calc(${props.cellWidth}% - 1rem)`;
      }
      return `width:${props.cellWidth}%`;
    });

    const usedPricingTableSections = (node: Node) =>
      (<PricingTable>node).pricingTableSections.filter(hasUsedPricingRows);

    const getLinkedContract = (node: ContractLink) =>
      props.linkedContracts.find(({ id }) => id === node.contractId);

    const getContentPlaceholder = (nodeId: string) =>
      props.mappedPlaceholders.find(({ id }) => id === nodeId);

    return {
      commentsWithoutNode,
      filterCommentsByNode: CommentsHelper.filterCommentsByNode,
      filteredNodes,
      filterNodes,
      getVisibleSlides: SlideShowHelper.getVisibleSlides,
      hasLinkNode: LinkBlockHelper.hasLinkNode,
      isSmallScreen,
      NodeType,
      RowType,
      widthStyle,
      usedPricingTableSections,
      getLinkedContract,
      CellType,
      getContentPlaceholder,
      currentUserId,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-cell';
</style>
