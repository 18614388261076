import type { CommentTextSelectionUpdate } from '@getaccept/editor-lib-new';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import type { UpdateCommentDto } from '@getaccept/lib-shared-new/src/contextual-commenting/types/update-comment-dto';
import type { Ref } from 'vue';
import { ref } from 'vue';
import { defineStore } from 'pinia';

export type CommentType = Comment | DrComment;

export const useBaseCommentsStore = defineStore('base-comments', () => {
  const comments: Ref<CommentType[]> = ref([]);

  const setComments = (newComments: CommentType[]) => {
    comments.value = newComments;
  };

  const updateComment = (payload: Partial<UpdateCommentDto>) => {
    comments.value = comments.value.map(existingComment =>
      existingComment.id === payload.commentId
        ? { ...existingComment, ...payload }
        : existingComment
    );
  };

  const hoveredCommentId = ref('');

  const setHoveredCommentId = (commentId: string) => {
    hoveredCommentId.value = commentId;
  };

  const previouslySelectedCommentId = ref('');

  const setPreviouslySelectedCommentId = (commentId: string) => {
    previouslySelectedCommentId.value = commentId;
  };

  const updatedTextSelections: Ref<{ [nodeId: string]: CommentTextSelectionUpdate[] }> = ref({});

  const updateTextSelections = (nodeId: string, payload: CommentTextSelectionUpdate[]) => {
    updatedTextSelections.value[nodeId] = payload;
  };

  const editorHasRendered = ref(false);

  const setEditorHasRendered = () => (editorHasRendered.value = true);

  const reset = () => {
    comments.value = [];
    hoveredCommentId.value = '';
  };

  return {
    setComments,
    updateComment,
    updateTextSelections,
    setEditorHasRendered,
    setHoveredCommentId,
    setPreviouslySelectedCommentId,
    reset,
    hoveredCommentId,
    comments,
    updatedTextSelections,
    editorHasRendered,
    previouslySelectedCommentId,
  };
});
