import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';
import { useTextSelection, useDebounceFn, useWindowSize } from '@vueuse/core';

export function useCreateContextualCommentButton(
  showContextualComments: Ref<boolean>,
  editorRef: Ref<HTMLElement>,
  getCoordsAtSelectionEnd: () => { top: number; left: number }
) {
  // Native eventlistener needed as Prosemirror doesn't fire a
  // selection change event when the selection is cleared
  const selection = useTextSelection();

  const buttonPosition: Ref<{ top: number; left: number }> = ref(null);
  const selectionLength = computed(() => selection.text?.value?.length);
  const isSelecting = ref(false);

  watch(selectionLength, length => {
    isSelecting.value = length > 0;
    debouncedResetIsSelecting();

    if (!selectionLength.value) {
      hideButton();
    }
  });

  const debouncedResetIsSelecting = useDebounceFn(() => (isSelecting.value = false), 300);

  const calculatePosition = () => {
    if (!selectionLength.value) {
      hideButton();
      return;
    }

    const coords = getCoordsAtSelectionEnd();

    if (!coords) {
      hideButton();
      return;
    }

    const editorOffset = editorRef.value.getBoundingClientRect();
    const editorContainerStyle = window.getComputedStyle(editorRef.value);
    const editorTopOffset = editorOffset.top + parseInt(editorContainerStyle.paddingTop, 10);

    buttonPosition.value = {
      top: coords.top - editorTopOffset,
      left: coords.left - editorOffset.left,
    };
  };

  const { width, height } = useWindowSize();
  watch([width, height], calculatePosition);

  const hideButton = () => (buttonPosition.value = null);

  const showCreateCommentButton = computed(
    () => showContextualComments.value && buttonPosition.value && !isSelecting.value
  );

  return {
    isSelecting,
    calculatePosition,
    hideButton,
    showCreateCommentButton,
    buttonPosition,
  };
}
