export const allowedNumericKeys: string[] = [
  'Enter',
  'Esc',
  'Backspace',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const allowedDecimalKeys: string[] = [...allowedNumericKeys, ',', '.'];
