import { RecipientStatus } from '../../recipients/enums/recipient-status';

export class SigningHelper {
  static hasRecipientSigned(status: RecipientStatus): boolean {
    return status === RecipientStatus.Signed || status === RecipientStatus.Approved;
  }

  static isIOS(): boolean {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }

  static isSafari(): boolean {
    const { userAgent } = navigator;
    return userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1;
  }
}
