<template>
  <EditorNodeWrapper
    class="pricing-summary-container"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <div
      v-for="([, { tables, totalPrice }], index) in pricingTablesGroupedByCurrency"
      :key="index"
      class="summary-group"
    >
      <div class="header-row">
        <div class="name-column">{{ node.labels.nameColumn || t('name') }}</div>
        <div class="price-column">{{ node.labels.priceColumn || t('price') }}</div>
      </div>
      <div v-for="table in tables" :key="table.id" class="row">
        <div class="name-column">{{ table.displayName || table.name || t('untitled') }}</div>
        <div class="price-column">
          {{ currencyFormat(table.value, table.currencySettings) }}
        </div>
      </div>
      <div class="summary-container">
        <div class="value-label">{{ node.labels.totalPrice || t('total-price') }}</div>
        <div class="value">{{ currencyFormat(totalPrice, tables[0].currencySettings) }}</div>
      </div>
    </div>
    <div v-if="pricingTablesGroupedByCurrency.size === 0" class="summary-group">
      <div class="header-row">
        <div class="name-column">{{ node.labels.nameColumn || t('name') }}</div>
        <div class="price-column">{{ node.labels.priceColumn || t('price') }}</div>
      </div>
      <div class="summary-container">
        <div class="value-label">{{ node.labels.totalPrice || t('total-price') }}</div>
        <div class="value">{{ 0 }}</div>
      </div>
    </div>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { PricingSummary, Context, PricingTable } from '@getaccept/editor-lib-new';
import {
  PricingSummaryHelper,
  PricingTableHelper,
  hasUsedPricingRows,
} from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import { storeToRefs } from 'pinia';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { useEditorViewContentStore } from '../store/editor-view-content.store';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  components: {
    EditorNodeWrapper,
  },
  props: {
    node: {
      type: Object as PropType<PricingSummary>,
      required: true,
    },
    context: { type: String as PropType<Context>, required: true },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
  },
  emits: ['observe-node-visibility'],
  setup(props) {
    const editorViewContentStore = useEditorViewContentStore();
    const { allPricingTables } = storeToRefs(editorViewContentStore);

    const usedPricingTableSections = (node: PricingTable) =>
      node.pricingTableSections.filter(hasUsedPricingRows).length > 0;

    const pricingTablesGroupedByCurrency = computed(() =>
      PricingSummaryHelper.pricingTablesGroupedByCurrency(
        allPricingTables.value.filter(usedPricingTableSections),
        props.recipientInput,
        props.node.tables
      )
    );

    return {
      currencyFormat: PricingTableHelper.currencyFormat,
      pricingTablesGroupedByCurrency,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-pricing-summary';
</style>
