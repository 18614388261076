import type { HeadingOptions as BaseOptions } from '@tiptap/extension-heading';
import { Heading } from '@tiptap/extension-heading';
import { mergeAttributes } from '@tiptap/core';
import { HeadingLevel } from '../../types/enums';

type Level = 1 | 2 | 3 | 7;

interface HeadingOptions extends BaseOptions {
  defaultLevel: HeadingLevel;
  customLevels: Level[];
}

export const HeadingNode = Heading.extend<HeadingOptions>({
  addKeyboardShortcuts() {
    return {};
  },

  addOptions() {
    return {
      ...this.parent?.(),
      customLevels: [1, 2, 3, 7],
      defaultLevel: HeadingLevel.Heading1,
    };
  },

  addCommands() {
    return {};
  },

  addAttributes() {
    return {
      level: {
        default: this.options.defaultLevel || HeadingLevel.Heading1,
        rendered: false,
      },
    };
  },

  parseHTML() {
    return this.options.customLevels.map((level: any) => ({
      tag: level === 7 ? 'small' : `h${level}`,
      attrs: { level },
    }));
  },

  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.customLevels.includes(node.attrs.level);
    const level: Level = hasLevel ? node.attrs.level : this.options.defaultLevel;

    return [
      level === 7 ? 'small' : `h${level}`,
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
