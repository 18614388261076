import type { Ref } from 'vue';
import { computed } from 'vue';
import type { PricingTableSection } from '../types/pricing-table';

export function usePricingTableRows(section: Ref<PricingTableSection>) {
  const rows = computed(() => section.value?.rows || []);
  const sortedRows = computed(() =>
    rows.value.map(row => ({
      ...row,
      values: section.value.columns.flatMap(({ id }) =>
        row.values.find(({ columnId }) => columnId === id)
      ),
    }))
  );

  return {
    rows,
    sortedRows,
  };
}
