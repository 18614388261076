import type {
  Field,
  PricingTable,
  PricingTableSection,
  PricingTableSummaryValues,
  Text,
} from '../../types';
import { PricingTableHelper } from '../pricing-table.helper';

export const getSectionsWithRowsFromInputValues = (
  node: PricingTable,
  recipientInputFields?: Field[]
): PricingTableSection[] => {
  const nodeFields = (node as Text)?.fields || [];
  const updatedFields = recipientInputFields ? recipientInputFields : nodeFields;
  const sectionsWithEnabledRows = PricingTableHelper.sectionsWithEnabledRows(
    node.pricingTableSections,
    updatedFields
  );
  return PricingTableHelper.sectionsWithRowsFromInputValues(sectionsWithEnabledRows, updatedFields);
};

export const getSummaryValues = (
  node: PricingTable,
  fields?: Field[]
): PricingTableSummaryValues => {
  if (node.preCalculated) {
    return node?.pricingTableSummaryValues;
  }
  return PricingTableHelper.calculateSummaryValues(
    getSectionsWithRowsFromInputValues(node, fields),
    node?.pricingTableSummaryValues
  );
};

export const getSummaryValuesPriceEnabled = (node: PricingTable): boolean =>
  getSummaryValues(node).price?.enabled;
