<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      rowTypeClass,
      blockWidthClass,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
      },
    ]"
  >
    <ResourcePlaceholder v-if="isResourcePlaceholder" :node="node" :placeholder="placeholder" />
    <ElementPlaceholder v-else :node="node" />
  </EditorNodeWrapper>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { EBlockWidth } from '@getaccept/editor-lib-new';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import type { ContentPlaceholder } from '@getaccept/editor-lib-new/src/types/models';
import { PlaceholderType } from '@getaccept/editor-lib-new/src/types/enums';
import EditorNodeWrapper from '../EditorNodeWrapper.vue';
import ResourcePlaceholder from './ResourcePlaceholder.vue';
import ElementPlaceholder from './ElementPlaceholder.vue';

export default defineComponent({
  components: {
    EditorNodeWrapper,
    ResourcePlaceholder,
    ElementPlaceholder,
  },
  props: {
    node: {
      type: Object as PropType<ContentPlaceholder>,
      required: true,
    },
    parentFirstChild: { type: Boolean },
    parentLastChild: { type: Boolean },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    rowTypeClass: { type: String, default: null },
    placeholder: {
      type: Object as PropType<MappedContentPlaceholder>,
      default: () => ({}),
    },
  },
  setup(props) {
    const isResourcePlaceholder = computed(
      () => props.node?.placeholder?.type === PlaceholderType.Resource
    );

    return {
      isResourcePlaceholder,
    };
  },
});
</script>
