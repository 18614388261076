<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'link-container',
      blockWidthClass,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
      },
    ]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <ThemedButton
      :class="['open-link-btn', node.align.toLowerCase()]"
      data-external="view-link-button"
      :theme="theme"
      @click="handleClick"
    >
      <ProsemirrorViewerInput class="open-link-btn-text" :content="node.content" />
    </ThemedButton>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { MergeValues, EBlockWidth, Link } from '@getaccept/editor-lib-new';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import ThemedButton from '@getaccept/lib-shared-new/src/components/ThemedButton.vue';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import ProsemirrorViewerInput from './ProsemirrorViewerInput.vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeLink',
  components: {
    ProsemirrorViewerInput,
    ThemedButton,
    EditorNodeWrapper,
  },
  props: {
    node: {
      type: Object as PropType<Link>,
      default: () => ({}),
    },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    parentFirstChild: { type: Boolean, default: false },
    parentLastChild: { type: Boolean, default: false },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    theme: { type: Object as PropType<SigningTheme>, default: () => ({}) },
  },
  emits: ['observe-node-visibility', 'node-click'],
  setup(props, { emit }) {
    const handleClick = () => {
      const url = UrlHelper.getHttpsUrl(props.node.linkUrl);
      window.open(url, '_blank');
      emit('node-click', { nodeId: props.node.id });
    };

    return {
      props,
      handleClick,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-link';

.open-link-btn-text {
  cursor: pointer;
}
</style>
