import { EPricingTableColumn, defaultSummaryValues } from '@getaccept/editor-lib-new';
import type {
  Field,
  PricingTableCurrencySettings,
  PricingTableSection,
  PricingTableSummaryValues,
  PricingTableColumn,
} from '@getaccept/editor-lib-new';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { PricingTableHelper } from '../pricing-table.helper';

export const getSubtotalDiscount = (
  sectionSummary: PricingTableSummaryValues,
  currencySettings: PricingTableCurrencySettings
): string => {
  if (getSubtotalDiscountIsEmpty(sectionSummary)) {
    return t('add-discount').toString();
  }
  return `-${PricingTableHelper.currencyFormat(sectionSummary.discount.value, currencySettings)}`;
};

export const getTotalPriceValue = (summaryValues: PricingTableSummaryValues): string =>
  summaryValues?.price?.value;

export const getTotalPriceAfterDiscount = (
  summaryValues: PricingTableSummaryValues,
  sections: PricingTableSection[],
  fields: Field[]
): string => {
  const { discount } = summaryValues;
  const totalDiscount = discount || defaultSummaryValues.discount;
  const discountInCurrency = String(
    (Number(getTotalPriceBeforeDiscountAndTax(sections, fields)) * Number(totalDiscount.value)) /
      100
  );
  return String(
    Number(getTotalPriceBeforeDiscountAndTax(sections, fields)) - Number(discountInCurrency)
  );
};

export const getTotalPriceBeforeDiscountAndTax = (
  sections: PricingTableSection[],
  fields: Field[]
): string => {
  if (!sections) {
    return;
  }
  const sectionTotal = sections
    .map(section => {
      const enabledRows = PricingTableHelper.getEnabledRows(section.rows, fields);
      const rowsFromInputFieldValues = PricingTableHelper.getRowsFromInputFieldValues(
        enabledRows,
        section.columns,
        fields
      );

      return Number(
        PricingTableHelper.calculateSectionSummary(
          rowsFromInputFieldValues,
          section.columns,
          section.sectionSummary
        ).price.value
      );
    })
    .reduce((a, b) => a + b, 0);
  return String(sectionTotal);
};

export const getSubtotalValue = (sectionSummary: PricingTableSummaryValues): string =>
  sectionSummary?.price?.value;

export const getSubtotalEnabled = (sectionSummary: PricingTableSummaryValues): boolean =>
  sectionSummary?.price?.enabled;

export const getSubtotalDiscountValue = (sectionSummary: PricingTableSummaryValues): string =>
  sectionSummary?.discount?.value;

export const getSubtotalDiscountIsEmpty = (sectionSummary: PricingTableSummaryValues): boolean =>
  getSubtotalDiscountValue(sectionSummary) === '0' ||
  getSubtotalDiscountValue(sectionSummary) === '';

export const getShowSubtotalDiscount = (
  columns: PricingTableColumn[],
  sectionSummary: PricingTableSummaryValues,
  isPreCalculated: boolean
): boolean => {
  if (
    !PricingTableHelper.getIsColumnEnabled(columns, EPricingTableColumn.Discount) ||
    !PricingTableHelper.getIsColumnEnabled(columns, EPricingTableColumn.TotalPrice)
  ) {
    return false;
  }
  if (isPreCalculated) {
    return true;
  }
  return !getSubtotalDiscountIsEmpty(sectionSummary);
};

export const getSubtotalTax = (sectionSummary: PricingTableSummaryValues): string =>
  getSubtotalTaxValue(sectionSummary) === '' || getSubtotalTaxValue(sectionSummary) === '0'
    ? null
    : getSubtotalTaxValue(sectionSummary);

export const getSubtotalTaxValue = (sectionSummary: PricingTableSummaryValues): string =>
  sectionSummary?.tax?.value;

export const getSubtotalTaxIsEmpty = (sectionSummary: PricingTableSummaryValues): boolean =>
  getSubtotalTaxValue(sectionSummary) === '0' || getSubtotalTaxValue(sectionSummary) === '';

export const getShowSubtotalTax = (
  columns: PricingTableColumn[],
  sectionSummary: PricingTableSummaryValues,
  isPreCalculated: boolean
): boolean => {
  if (!PricingTableHelper.getIsColumnEnabled(columns, EPricingTableColumn.Tax)) {
    return false;
  }
  if (isPreCalculated) {
    return true;
  }
  return !getSubtotalTaxIsEmpty(sectionSummary);
};

export const getTotalDiscountIsEmpty = (summaryValues: PricingTableSummaryValues): boolean => {
  const totalDiscountValue = summaryValues?.discount?.value;
  return totalDiscountValue === '0' || totalDiscountValue === '';
};

export const getTotalTaxIsEmpty = (summaryValues: PricingTableSummaryValues): boolean => {
  const totalTaxValue = summaryValues?.tax?.value;
  return totalTaxValue === '0' || totalTaxValue === '';
};

export const getTotalDiscount = (
  summaryValues: PricingTableSummaryValues,
  currencySettings: PricingTableCurrencySettings,
  isPreCalculated: boolean,
  sections: PricingTableSection[],
  fields: Field[]
): string => {
  const { discount } = summaryValues;
  const totalDiscount = discount || defaultSummaryValues.discount;
  const discountInCurrency = String(
    (Number(getTotalPriceBeforeDiscountAndTax(sections, fields)) * Number(totalDiscount.value)) /
      100
  );

  if (totalDiscount.flatFee) {
    return `-${PricingTableHelper.currencyFormat(totalDiscount.value, currencySettings)}`;
  } else {
    if (isPreCalculated) {
      return `${totalDiscount.value}%`;
    }
    return `-${PricingTableHelper.currencyFormat(discountInCurrency, currencySettings)} (${
      totalDiscount.value
    }%)`;
  }
};

export const getTotalTax = (
  summaryValues: PricingTableSummaryValues,
  currencySettings: PricingTableCurrencySettings,
  isPreCalculated: boolean,
  sections: PricingTableSection[],
  fields: Field[]
): string => {
  const { tax: totalTax, discount: totalDiscount } = summaryValues;
  const enabledTotalDiscount = totalDiscount.enabled ? Number(totalDiscount.value) / 100 : 0;
  const enabledTotalDiscountFlatFee = totalDiscount.enabled ? totalDiscount.value : 0;

  const totalDiscountSum = totalDiscount.flatFee
    ? enabledTotalDiscountFlatFee
    : Number(getTotalPriceBeforeDiscountAndTax(sections, fields)) * enabledTotalDiscount;
  const subtotalAfterTotalDiscount =
    Number(getTotalPriceBeforeDiscountAndTax(sections, fields)) - Number(totalDiscountSum);
  const taxInCurrency = String((subtotalAfterTotalDiscount * Number(totalTax.value)) / 100);

  if (totalTax.flatFee) {
    return PricingTableHelper.currencyFormat(totalTax.value, currencySettings);
  } else {
    return isPreCalculated
      ? `${totalTax.value}%`
      : `${PricingTableHelper.currencyFormat(taxInCurrency, currencySettings)} (${
          totalTax.value
        }%)`;
  }
};
