import type { ComposerTranslation } from 'vue-i18n';

export const t: ComposerTranslation = (...attrs) => (window as any).i18n.t(...attrs);

export function translateEnum(value: string, key: string, referenceEnum: any) {
  const translationKey: string = getEnumTranslationKey(value, key, referenceEnum);
  return t(translationKey);
}

export function getEnumTranslationKey(value: string, key: string, referenceEnum: any): string {
  const enumKeys: string[] = Object.keys(referenceEnum);
  const enumValues: string[] = Object.values(referenceEnum);
  const index: number = enumValues.indexOf(value);

  if (index < 0) {
    if (process.env.NODE_ENV === 'development') {
      console.error('Not valid value in the provided Enum', value);
    }
    return undefined;
  }
  const enumKey: string = enumKeys[index].toLowerCase();

  return `${key}-${enumKey}`;
}

export function getLocaleFromCountryCode(
  countryCode: 'en' | 'da' | 'sv' | 'no' | 'fr' | 'es' | string
) {
  if (countryCode === 'en') {
    return 'en-US';
  }
  if (countryCode === 'da') {
    return 'da-DK';
  }
  if (countryCode === 'sv') {
    return 'sv-SE';
  }
  if (countryCode === 'no') {
    return 'no-NO';
  }
  if (countryCode === 'fr') {
    return 'fr-FR';
  }
  if (countryCode === 'es') {
    return 'es-ES';
  }

  return 'en-US';
}
