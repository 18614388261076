import { Extension } from '@tiptap/core';
import { NodeAlign } from '../../types/enums';

export interface TextAlignOptions {
  types: string[];
  alignments: string[];
  defaultAlignment: string;
}

const getElementAligntment = (element: HTMLElement): string | undefined => {
  const classes = element.classList.values();

  for (const value of classes) {
    if (
      [NodeAlign.Left, NodeAlign.Center, NodeAlign.Right, NodeAlign.Justify].includes(
        value as NodeAlign
      )
    ) {
      return value;
    }
  }

  return undefined;
};

export const TextAlign = Extension.create<TextAlignOptions>({
  name: 'text-align',

  addOptions() {
    return {
      types: [],
      alignments: [NodeAlign.Left, NodeAlign.Center, NodeAlign.Right, NodeAlign.Justify],
      defaultAlignment: NodeAlign.Left,
    };
  },

  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          align: {
            default: this.options.defaultAlignment,
            parseHTML: element => getElementAligntment(element) || this.options.defaultAlignment,
            renderHTML: attributes => ({ class: attributes.align }),
          },
        },
      },
    ];
  },
});
