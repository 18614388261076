<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'text-container',
      rowTypeClass,
      blockWidthClass,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
      },
      { 'text-link-node': hasLinkNode },
    ]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <DetachedCommentAnchor
      v-for="comment in commentsWithoutTextSelection"
      :id="comment.id"
      :key="comment.id"
    />
    <ProsemirrorViewerText
      :content="jsonContent"
      :merge-values="mergeValues"
      :fields="fields"
      :default-align="defaultAlign"
      :default-heading="defaultHeading"
      :comments="commentsWithTextSelection"
      :selected-comment-id="selectedCommentId"
      :show-contextual-comments="showContextualComments"
      :create-contextual-comment-button-state="createContextualCommentButtonState"
      @create-comment="$emit('create-comment', $event)"
      @select-comment="$emit('select-comment', $event)"
    />
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { Text, MergeValues, EBlockWidth, Field } from '@getaccept/editor-lib-new';
import { HeadingLevel, NodeAlign, RowType, fallbackTextContent } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import ProsemirrorViewerText from './ProsemirrorViewerText.vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeText',
  components: {
    DetachedCommentAnchor,
    ProsemirrorViewerText,
    EditorNodeWrapper,
  },
  props: {
    node: {
      type: Object as PropType<Text>,
      default: () => ({}),
    },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    hasLinkNode: { type: Boolean, default: false },
    rowType: { type: String as PropType<RowType>, default: RowType.Standard },
    parentFirstChild: { type: Boolean, default: false },
    parentLastChild: { type: Boolean, default: false },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean, default: false },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
  },
  emits: ['observe-node-visibility', 'create-comment', 'select-comment'],
  setup(props) {
    const fields = computed(() => props.node?.fields || []);
    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);

    const commentsWithTextSelection = computed(() =>
      props.comments.filter(comment => comment.editorSelection?.textSelection)
    );

    const commentsWithoutTextSelection = computed(() =>
      props.comments.filter(comment => !comment.editorSelection?.textSelection)
    );
    const isLayoutRow = computed(() =>
      [RowType.Columns, RowType.SideBySide].includes(props.rowType)
    );
    const defaultHeading = computed(() =>
      isLayoutRow.value ? HeadingLevel.Heading2 : HeadingLevel.Heading1
    );
    const defaultAlign = computed(() =>
      RowType.Columns === props.rowType ? NodeAlign.Center : NodeAlign.Left
    );
    const jsonContent = computed(() => {
      const contentToParse = isMarkdownField.value
        ? markdownField.value.value
        : props.node.content || fallbackTextContent;
      return JSON.parse(contentToParse);
    });
    const isMarkdownField = computed(() => Boolean(props.node.markdownFieldId));

    const markdownField = computed(
      () =>
        fields.value.find(field => field.id === props.node.markdownFieldId) ||
        ({ value: null } as Field)
    );

    return {
      commentsWithTextSelection,
      commentsWithoutTextSelection,
      fields,
      rowTypeClass,
      isLayoutRow,
      defaultHeading,
      defaultAlign,
      jsonContent,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node';
@import '@getaccept/editor-lib-new/src/scss/editor-node-text';
</style>
