<template>
  <div
    class="input-editor-container"
    data-external="editor-view-input-contract-link-description-container"
  >
    <EditorContent :editor="viewer" spellcheck="false" />
  </div>
</template>
<script lang="ts">
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Text from '@tiptap/extension-text';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import {
  NoHeadingDocNode,
  NoHeadingListItemNode,
  NodeAlign,
  ProsemirrorType,
  TextAlign,
  BulletListNode,
  OrderedListNode,
  ParagraphNode,
  MergeValueNode,
  HardBreakNode,
} from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { onBeforeUnmount, onMounted, computed, defineComponent, ref, watch } from 'vue';
import { updateMergeValues, setContent, handleInit } from './helpers/prosemirror-viewer.helper';

export default defineComponent({
  components: {
    EditorContent,
  },
  props: {
    defaultAlign: { type: String as PropType<NodeAlign>, default: () => NodeAlign.Left },
    content: {
      type: [Object, String] as PropType<TextContentDoc | string>,
      default: '',
    },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  setup(props) {
    const viewer: Ref<TipTapEditor | null> = ref(null);

    const contentPayload = computed(() => ({
      viewer: viewer.value,
      content: props.content,
      fields: props.fields,
      mergeValues: props.mergeValues,
    }));

    const textViewer = computed(() => ({
      content: props.content,
      editable: false,
      enableInputRules: false,
      enablePasteRules: false,
      extensions: [
        NoHeadingDocNode,
        ParagraphNode,
        Text,
        Bold,
        Italic,
        Strike,
        Superscript,
        Underline,
        OrderedListNode,
        BulletListNode,
        MergeValueNode,
        NoHeadingListItemNode,
        Link.configure({
          autolink: false,
          openOnClick: true,
          linkOnPaste: false,
        }),
        HardBreakNode,
        TextAlign.configure({
          defaultAlignment: props.defaultAlign,
          types: [
            ProsemirrorType.Paragraph,
            ProsemirrorType.BulletList,
            ProsemirrorType.OrderedList,
          ],
        }),
      ],
      onCreate: () => handleInit(viewer.value, props.fields, props.mergeValues),
    }));

    onMounted(() => {
      viewer.value = new TipTapEditor(textViewer.value);
    });

    onBeforeUnmount(() => {
      viewer.value.destroy();
    });

    watch(
      () => [props.fields, props.mergeValues],
      () => {
        updateMergeValues(contentPayload.value);
      }
    );

    watch(
      () => props.content,
      () => {
        setContent(contentPayload.value);
      }
    );

    return {
      viewer,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/prosemirror-editor-text';
@import '@getaccept/editor-lib-new/src/scss/prosemirror-editor-text-styles';

.input-editor-container {
  margin-bottom: var(--spacing-100);
}
</style>
