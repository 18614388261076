<template>
  <EditorNodeWrapper
    :class="['node-container', 'table-container', rowTypeClass, blockWidthClass]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <ProsemirrorViewerTable
      :content="jsonContent as any"
      :border-color="borderColor"
      :alternate-row-color="alternateRowColor"
      :header-row-background-color="headerRowBackgroundColor"
      :merge-values="mergeValues"
      :fields="fields"
      :is-pdf-viewer="isPdfViewer"
    />
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { Table, TableContentDoc, MergeValues, EBlockWidth } from '@getaccept/editor-lib-new';
import { fallbackTableContent, RowType } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';
import ProsemirrorViewerTable from './ProsemirrorViewerTable.vue';

export default defineComponent({
  name: 'EditorNodeTable',
  components: {
    ProsemirrorViewerTable,
    EditorNodeWrapper,
  },
  props: {
    node: { type: Object as PropType<Table>, default: () => ({}) },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    isPdfViewer: { type: Boolean, default: false },
    rowType: { type: String as PropType<RowType>, default: () => RowType.Standard },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
  },
  emits: ['observe-node-visibility'],
  setup(props) {
    const fields = computed(() => props.node.fields || []);

    const jsonContent = computed((): TableContentDoc => {
      const tableContent = props.node.content || fallbackTableContent;
      return JSON.parse(tableContent);
    });

    const borderColor = computed(() => props.node.tableStyle?.borderColor);
    const alternateRowColor = computed(() => props.node.tableStyle?.alternateRowColor);
    const headerRowBackgroundColor = computed(
      () => props.node.tableStyle?.headerRowBackgroundColor
    );
    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);

    return {
      fields,
      jsonContent,
      borderColor,
      alternateRowColor,
      headerRowBackgroundColor,
      rowTypeClass,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node';
</style>
