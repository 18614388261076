<template>
  <div :class="['contract-link-header', blockWidthClass]">
    <div class="contract-link-header-title">
      <FriedIcon icon="contracts" />
      <slot name="title" />
    </div>
    <div class="status-content">
      <slot name="button" />
      <FriedStatusBadge
        v-if="showStatus"
        :text="statusBadgeContent.text"
        :status-type="statusBadgeContent.statusType"
        :border="statusBadgeContent.border"
      />
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { StatusHelper } from '@getaccept/lib-shared-new/src/helpers/status.helper';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ContractLinkHeader',
  props: {
    status: { type: String as PropType<DocumentStatus>, default: null },
    expired: { type: Boolean },
    blockWidthClass: { type: String, default: null },
  },
  setup(props) {
    const { t } = useI18n();
    const showStatus = computed(
      () =>
        props.status === DocumentStatus.Signed ||
        props.status === DocumentStatus.Recalled ||
        props.status === DocumentStatus.Expired ||
        props.expired
    );

    const statusBadgeContent = computed(() => ({
      text: getStatusText.value,
      statusType: getStatusType.value,
      border: StatusHelper.getDocumentHasBadgeBorder(props.status),
    }));

    const getStatusText = computed(() =>
      props.status === DocumentStatus.Recalled || props.expired
        ? t('document-status-expired')
        : StatusHelper.getDocumentStatusTranslation(props.status)
    );

    const getStatusType = computed(() =>
      props.expired
        ? StatusHelper.getDocumentStatusType(DocumentStatus.Expired)
        : StatusHelper.getDocumentStatusType(props.status)
    );

    return {
      showStatus,
      statusBadgeContent,
      getStatusText,
      getStatusType,
    };
  },
});
</script>

<style lang="scss" scoped>
.contract-link-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-92);
  padding: var(--spacing-100) var(--spacing-200);
  margin: auto calc(var(--spacing-100) * -1);

  &.xsmall,
  &.xxsmall {
    padding: var(--spacing-100);
    margin: auto calc(var(--spacing-50) * -1);
  }

  .contract-link-header-title {
    display: flex;
    align-items: center;

    .svg-icon {
      margin-right: var(--spacing-100);
      flex-shrink: 0;
    }
  }

  .status-content {
    display: flex;
    align-items: center;
    gap: var(--spacing-50);

    :deep(.heading-5) {
      font-family: var(--editor-headings-font-family);
    }
  }
}
</style>
