<template>
  <FriedCard class="signer-container">
    <div :class="['names', blockWidthClass]">
      <div v-if="isSigningOrder" class="counter">
        {{ orderNum }}
      </div>
      <FriedStatusBadge
        v-if="isBlockWidthSmall(blockWidthClass)"
        :text="statusBadgeContent.text"
        :status-type="statusBadgeContent.statusType"
        :border="statusBadgeContent.border"
      />
      <div class="details">
        <FriedParagraph class="text-gray" :size="ParagraphSize.Small">
          {{ role }}
        </FriedParagraph>
        <div :class="['name-title', blockWidthClass]">
          <FriedH4>{{ fullName }}</FriedH4>
          <FriedParagraph
            v-if="(contractDisplaySettings as ContractDisplaySettings).title"
            class="text-gray"
            >{{ formattedTitle }}</FriedParagraph
          >
        </div>
        <FriedParagraph
          v-if="(contractDisplaySettings as ContractDisplaySettings).company && companyName"
          >{{ companyName }}</FriedParagraph
        >
        <FriedParagraph
          v-if="(contractDisplaySettings as ContractDisplaySettings).email && email"
          :size="ParagraphSize.Small"
          class="email"
          >{{ email }}</FriedParagraph
        >
      </div>
      <div class="recipient-status">
        <FriedStatusBadge
          v-if="!isBlockWidthSmall(blockWidthClass)"
          :text="statusBadgeContent.text"
          :status-type="statusBadgeContent.statusType"
          :border="statusBadgeContent.border"
        />
        <div
          v-if="signingPageUrl"
          v-tooltip="{
            ...toolTipOptions,
            placement: TooltipPlacement.Top,
            content: getTooltip,
          }"
        >
          <FriedButton
            target="_blank"
            data-external="editor-view-contract-link-signing-page-url"
            :disabled="isSigningPageUrlDisabled"
            @click="handleClick"
          >
            <template #leftIcon>
              <FriedIcon icon="share" />
            </template>
            {{ $t('review_contract') }}
          </FriedButton>
        </div>
      </div>
    </div>
  </FriedCard>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { ParagraphSize, Placement as TooltipPlacement } from '@getaccept/fried-tofu';
import { StatusHelper } from '@getaccept/lib-shared-new/src/helpers/status.helper';
import type { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import type { ContractDisplaySettings } from '@getaccept/editor-lib-new/src/types/models';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import { EBlockWidth, EditorHelper, toolTipOptions } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';

export default defineComponent({
  name: 'SignerDetails',
  props: {
    contractDisplaySettings: {
      type: Object as PropType<ContractDisplaySettings>,
      default: () => ({}),
    },
    isSigningOrder: { type: Number, default: 0 },
    status: { type: String as PropType<RecipientStatus>, default: null },
    role: { type: String, default: '' },
    fullName: { type: String, default: '' },
    email: { type: String, default: '' },
    companyName: { type: String, default: '' },
    title: { type: String, default: '' },
    orderNum: { type: Number, default: null },
    signingPageUrl: { type: String, default: null },
    contractStatus: { type: String as PropType<DocumentStatus>, default: null },
    prevSigner: { type: Object as PropType<Recipient>, default: null },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    contractExpired: { type: Boolean, default: null },
  },
  setup(props) {
    const statusBadgeContent = computed(() => ({
      text: StatusHelper.getRecipientStatusTranslation(props.status as RecipientStatus, true),
      statusType: StatusHelper.getRecipientStatusType(props.status as RecipientStatus),
      border: StatusHelper.getRecipientHasBadgeBorder(props.status as RecipientStatus),
    }));

    const formattedTitle = computed(() => {
      if (props.title) {
        return props.blockWidthClass === EBlockWidth.XXSmall ? props.title : ` | ${props.title}`;
      }
      return null;
    });

    const isSigningPageUrlDisabled = computed(
      () => Boolean(isSigningOrderAndNotUserTurn.value) || props.contractExpired
    );

    const isSigningOrderAndNotUserTurn = computed(
      () =>
        props.isSigningOrder &&
        prevSignerStatus.value !== RecipientStatus.Signed &&
        prevSignerStatus.value !== RecipientStatus.Approved &&
        props.status === RecipientStatus.Added &&
        props.orderNum !== 1
    );

    const prevSignerStatus = computed(() => props.prevSigner?.status);

    const getTooltip = computed(() => {
      if (props.contractExpired) {
        return t('contract_expired_cant_access_document');
      }
      if (isSigningOrderAndNotUserTurn.value) {
        return t('cant_access_document_until_prev_signed');
      }
      return null;
    });

    const handleClick = () => {
      window.open(props.signingPageUrl, '_blank');
    };

    return {
      toolTipOptions,
      prevSignerStatus,
      TooltipPlacement,
      isSigningPageUrlDisabled,
      isSigningOrderAndNotUserTurn,
      getTooltip,
      formattedTitle,
      EBlockWidth,
      handleClick,
      statusBadgeContent,
      ParagraphSize,
      isBlockWidthSmall: EditorHelper.isBlockWidthSmall,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/contract-link-signer-details';
</style>
