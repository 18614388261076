export enum ProsemirrorType {
  MergeTag = 'merge_tag',
  Paragraph = 'paragraph',
  Heading = 'heading',
  Table = 'table',
  TableRow = 'table_row',
  TableCell = 'table_cell',
  TableHeader = 'table_header',
  OrderedList = 'ordered_list',
  BulletList = 'bullet_list',
  ListItem = 'list_item',
  Text = 'text',
  HardBreak = 'hard_break',
  Doc = 'doc',
  Link = 'link',
  Mention = 'mention',
}
