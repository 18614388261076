import Color from 'color';
import type { SigningTheme } from '../types/theme';
import {
  colorLightnessThreshold,
  colorHoverPercentage,
  colorDisabledLightness,
  colorDisabledSaturation,
  colorBoostSecondaryLightNormal,
  colorBoostSecondaryLightHover,
  colorBoostSecondaryLightDisabled,
  colorFadePrimaryLightDisabled,
} from '../constants/branding';

const gray = '#3B3B3B';
const lightGray = '#969696';

export class BrandingHelper {
  static getTextColor(accentColor: string, disabled: boolean, secondary: boolean) {
    const light: boolean = this.colorLight(accentColor);
    if (light) {
      if (!disabled && !secondary) {
        return gray;
      } else if (disabled && !secondary) {
        return lightGray;
      } else if (!disabled && secondary) {
        return new Color(accentColor).darken(colorBoostSecondaryLightNormal).hex();
      } else {
        return new Color(accentColor).darken(colorBoostSecondaryLightDisabled).hex();
      }
    }

    if (!secondary) {
      return '#ffffff';
    } else if (!disabled) {
      return accentColor;
    } else {
      return this.getDisabledColor(accentColor);
    }
  }
  static getBackgroundColor(accentColor: string, disabled: boolean, secondary: boolean) {
    const light: boolean = this.colorLight(accentColor);
    if (light && disabled && !secondary) {
      return new Color(accentColor).fade(colorFadePrimaryLightDisabled);
    }

    if (!disabled && !secondary) {
      return accentColor;
    } else if (disabled && !secondary) {
      return this.getDisabledColor(accentColor);
    } else {
      return '#ffffff';
    }
  }
  static getBackgroundColorHover(accentColor: string, disabled: boolean, secondary: boolean) {
    const light: boolean = this.colorLight(accentColor);
    if (light) {
      if (!disabled && !secondary) {
        return new Color(accentColor).darken(colorHoverPercentage).hex();
      } else if (!disabled && secondary) {
        return new Color(accentColor).fade(0.5);
      }
    }

    if (!disabled && !secondary) {
      return new Color(accentColor).lighten(colorHoverPercentage).hex();
    } else if (!disabled && secondary) {
      return new Color(accentColor).fade(0.9);
    } else {
      return this.getBackgroundColor(accentColor, disabled, secondary);
    }
  }
  static getBorderColor(accentColor: string, disabled: boolean, secondary: boolean) {
    const light: boolean = this.colorLight(accentColor);
    if (light) {
      if (!disabled && secondary) {
        return new Color(accentColor).darken(colorBoostSecondaryLightNormal).hex();
      } else if (disabled && secondary) {
        return new Color(accentColor).darken(colorBoostSecondaryLightDisabled).hex();
      }
    }

    if (!secondary) {
      return 'none';
    } else if (!disabled) {
      return accentColor;
    } else {
      return this.getDisabledColor(accentColor);
    }
  }

  static getBorderColorHover(accentColor: string, disabled: boolean, secondary: boolean) {
    const light: boolean = this.colorLight(accentColor);
    if (light && secondary && !disabled) {
      return new Color(accentColor).darken(colorBoostSecondaryLightHover).hex();
    }
    return this.getBorderColor(accentColor, disabled, secondary);
  }

  static colorLight = (color: string): boolean =>
    new Color(color).lightness() > colorLightnessThreshold;

  static getDisabledColor = (accentColor: string): string => {
    const color: Color = new Color(accentColor).hsl();
    color.color[1] = colorDisabledSaturation;
    color.color[2] = colorDisabledLightness;
    return color.hex();
  };

  static getBackground(theme: SigningTheme, isIE: boolean, isDsr?: boolean): string {
    if (!theme) {
      return '';
    }
    if (theme.backgroundImage) {
      return `background-image: url(${theme.backgroundImage});`;
    }
    let path = '/assets/img/background/signing-page-bg.svg';
    if (isIE) {
      path = '/assets/img/background/signing-page-bg.png';
    }
    if (isDsr) {
      path = require('../../assets/gradient.png');
    }
    return `background-image: url(${path})`;
  }

  static getAccentColor(theme: SigningTheme): string {
    if (!theme || !theme.accentColor) {
      return '';
    }
    return `background-color: ${theme.accentColor}; border-color: ${theme.accentColor};`;
  }

  static getLogoUrl(theme: SigningTheme): string {
    const gaWhiteLogo = 'https://media.getaccept.com/logo/x7gpxjpr.png?1528788212';

    if (!theme || theme.logoUrl === gaWhiteLogo) {
      return '';
    }
    return theme.logoUrl;
  }
}
