import { EditorHelper } from '@getaccept/editor-lib-new';
import type { EditorContent } from '@getaccept/editor-lib-new';
import type { Ref } from 'vue';
import { computed, ref } from 'vue';
import { defineStore } from 'pinia';

export const useEditorViewContentStore = defineStore('editor-view-content', () => {
  const content: Ref<EditorContent[]> = ref(null);

  const setContent = (newContent: EditorContent[]) => {
    content.value = newContent;
  };

  const allPricingTables = computed(() =>
    EditorHelper.getAllPricingTablesFromContent(content.value || [])
  );

  return {
    setContent,
    allPricingTables,
  };
});
