<template>
  <div class="column-headers-container">
    <div
      v-for="(column, index) in enabledColumns"
      :key="column.id"
      :class="{ ...getColumnHeaderClasses(column, index) }"
    >
      <div class="header-text" :data-external="`pricing-table-header-text-name:${column.name}`">
        {{ getColumnDisplayName(column.id, columns) }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { PricingTableColumn } from '@getaccept/editor-lib-new';
import { PricingTableHelper } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PricingTableColumnHeaders',
  props: {
    columns: { type: Array as PropType<PricingTableColumn[]>, default: () => [] },
    enabledColumns: { type: Array as PropType<PricingTableColumn[]>, default: () => [] },
  },
  setup() {
    const getColumnHeaderClasses = (column: PricingTableColumn, index: number) => {
      const leftAlign = index === 0 || column.isCustom;
      return {
        'column-header': true,
        'left-align': leftAlign,
        'right-align': !leftAlign,
      };
    };

    return {
      getColumnDisplayName: PricingTableHelper.getColumnDisplayName,
      getColumnHeaderClasses,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-column-headers';
</style>
