<template>
  <div
    :class="[
      'row-container',
      'side-by-side',
      blockWidthClass,
      isTextNodeFirst ? 'column-reverse' : 'column',
    ]"
  >
    <div :class="['flex-container', blockWidthClass]">
      <DetachedCommentAnchor
        v-for="comment in commentsWithoutCell"
        :id="comment.id"
        :key="comment.id"
      />
      <EditorCell
        v-for="(cell, index) in cells"
        :key="cell.id"
        :cell-id="cell.id"
        :cell-width="cell.width"
        :nodes="cell.nodes"
        :theme="theme"
        :merge-values="mergeValues"
        :row-type="RowType.SideBySide"
        :parent-first-child="isParentFirstChild(index)"
        :parent-last-child="isParentLastChild(index)"
        :block-width-class="blockWidthClass"
        :page-width="pageWidth"
        :is-pdf-viewer="isPdfViewer"
        :is-block-toggled="isBlockToggled"
        :comments="filterCommentsByCell(comments, cell.id)"
        :selected-comment-id="selectedCommentId"
        :show-contextual-comments="showContextualComments"
        :create-contextual-comment-button-state="createContextualCommentButtonState"
        @create-comment="$emit('create-comment', { ...$event, cellId: cell.id })"
        @select-comment="$emit('select-comment', $event)"
        @node-click="$emit('node-click', { ...$event, cellId: cell.id })"
        @observe-node-visibility="$emit('observe-node-visibility', { ...$event, cellId: cell.id })"
        @fetch-new-image="$emit('fetch-new-image', { ...$event, targetCellId: cell.id })"
      />
    </div>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { MergeValues, Cell, EBlockWidth } from '@getaccept/editor-lib-new';
import { NodeType, RowType } from '@getaccept/editor-lib-new';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import EditorCell from './EditorCell.vue';

export default defineComponent({
  name: 'EditorSideBySideRow',
  components: {
    DetachedCommentAnchor,
    EditorCell,
  },
  props: {
    cells: { type: Array as PropType<Cell[]>, default: () => [] },
    isPdfViewer: { type: Boolean },
    mergeValues: {
      type: Object as PropType<MergeValues>,
      default: () => ({ document: {}, sender: {}, recipients: [], entity: {} }),
    },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    pageWidth: { type: Number, default: 0 },
    isBlockToggled: { type: Boolean },
    theme: {
      type: Object as PropType<SigningTheme>,
      default: () => ({}),
    },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean, default: false },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
  },
  emits: [
    'create-comment',
    'select-comment',
    'node-click',
    'observe-node-visibility',
    'fetch-new-image',
  ],
  setup(props) {
    const isTextNodeFirst = computed(() => props.cells[0]?.nodes?.[0]?.type === NodeType.Text);

    const isParentFirstChild = (index: number) => index === 0;

    const isParentLastChild = (index: number) => index === props.cells.length - 1;

    const cellIds = computed(() => props.cells.map(({ id }) => id));

    const commentsWithoutCell = computed(() =>
      props.comments.filter(comment => !cellIds.value.includes(comment.editorSelection?.cellId))
    );

    return {
      commentsWithoutCell,
      RowType,
      isTextNodeFirst,
      isParentFirstChild,
      isParentLastChild,
      filterCommentsByCell: CommentsHelper.filterCommentsByCell,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-row';
</style>
