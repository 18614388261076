import ListItem from '@tiptap/extension-list-item';

export const ListItemNode = ListItem.extend({
  name: 'list_item',

  content: '(paragraph | heading) block*',

  addKeyboardShortcuts() {
    return {
      // known issue that when tabbing to create a sub-list the new ol/ul get default alignment
      // we should check current selections alignment and apply align after creating sublist
      Tab: () => this.editor.commands.sinkListItem(this.name),
      'Shift-Tab': () => this.editor.commands.liftListItem(this.name),
    };
  },
});
