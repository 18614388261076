<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'image-container',
      rowTypeClass,
      blockWidthClass,
      imageSize,
      imageAlign,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
      },
    ]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <div
      ref="imageContent"
      :class="[
        'image-content',
        {
          'no-src-url': !node.srcUrl,
        },
      ]"
    >
      <div :style="imageLoaderContainerStyle">
        <transition-group name="fade" tag="div">
          <template v-if="!isPdfViewer && !isLoaded">
            <FriedSkeleton
              v-if="!node.blurhash"
              :key="`${node.id}-loader`"
              class="loading-container"
              :style="imageLoaderContainerStyle"
            />
            <BlurhashCanvas
              v-else
              :key="`${node.id}-blurhash`"
              class="blurhash-container"
              :hash="node.blurhash"
              :dimensions="node.dimensions"
              :style="imageLoaderContainerStyle"
            />
          </template>
          <div
            v-show="isPdfViewer || isLoaded"
            :key="`${node.id}-loader`"
            class="image-element-container"
          >
            <img v-if="node.externalId" class="unsplash-beacon" :src="unsplashBeaconSrcUrl" />
            <template v-if="node.linkUrl">
              <a :href="node.linkUrl" target="_blank">
                <ImageWithTransforms
                  v-if="rowType === RowType.Columns"
                  class="image"
                  :image="node"
                  :src-url="srcUrl"
                  :block-width-class="blockWidthClass"
                  @image-loaded="handleImageLoaded"
                  @image-load-error="handleLoadError"
                />
                <img
                  v-else
                  class="image"
                  :src="srcUrl"
                  alt=""
                  @load="handleImageLoaded"
                  @error="handleLoadError"
                />
                <div v-if="isHeadingSet" class="fade-overlay"></div>
              </a>
            </template>
            <template v-else>
              <ImageWithTransforms
                v-if="rowType === RowType.Columns"
                class="image"
                :image="node"
                :src-url="srcUrl"
                :block-width-class="blockWidthClass"
                @image-loaded="handleImageLoaded"
                @image-load-error="handleLoadError"
              />
              <img
                v-else
                class="image"
                :src="srcUrl"
                alt=""
                @load="handleImageLoaded"
                @error="handleLoadError"
              />
              <div v-if="isHeadingSet" class="fade-overlay"></div>
            </template>
            <ProsemirrorViewerInput
              v-if="isHeadingSet"
              ref="headingInput"
              :style="fontSize"
              class="image-heading"
              :content="node.heading"
              @loaded="handleTextLoaded"
            />
          </div>
        </transition-group>
      </div>
      <ProsemirrorViewerInput v-if="isCaptionSet" class="image-caption" :content="node.caption" />
    </div>
    <div v-if="isGridView && slideNumber" class="slide-number">
      {{ slideNumber }}
    </div>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { ImageNode, EBlockWidth } from '@getaccept/editor-lib-new';
import { ImageHelper, getUnsplashAppId, RowType, SlideShowHelper } from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { nextTick, defineComponent, computed, ref, watch } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';
import BlurhashCanvas from '@getaccept/editor-lib-new/src/components/BlurhashCanvas.vue';
import ProsemirrorViewerInput from './ProsemirrorViewerInput.vue';
import ImageWithTransforms from './ImageWithTransforms.vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeImage',
  components: {
    ImageWithTransforms,
    ProsemirrorViewerInput,
    EditorNodeWrapper,
    BlurhashCanvas,
  },
  props: {
    node: { type: Object as PropType<ImageNode>, default: () => ({}) },
    rowType: { type: String as PropType<RowType>, default: () => RowType.Standard },
    parentFirstChild: { type: Boolean },
    parentLastChild: { type: Boolean },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    pageWidth: { type: Number, default: 0 },
    isBlockToggled: { type: Boolean },
    isGridView: { type: Boolean },
    isPdfViewer: { type: Boolean },
  },
  emits: ['observe-node-visibility', 'fetch-new-image'],
  setup(props, { emit }) {
    const imageContent: Ref<HTMLElement> = ref(null);
    const headingInput: Ref<typeof ProsemirrorViewerInput> = ref(null);
    const isTextLoaded = ref(false);
    const minTextSize = 12;
    const maxTextSize = 64;
    const textFont = ref({ size: maxTextSize });
    const isLoaded = ref(false);
    const isHeadingSet = computed(() => props.node.heading !== '<p></p>' && !!props.node.heading);
    const isCaptionSet = computed(() => props.node.caption !== '<p></p>' && !!props.node.caption);
    const fontSize = computed(() => `--font-size: ${textFont.value?.size}px;`);
    const imageSize = computed(() => props.node.imageSize?.toLowerCase());
    const imageAlign = computed(() => props.node.imageAlign?.toLowerCase());
    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);
    const unsplashBeaconSrcUrl = computed(
      () =>
        `https://views.unsplash.com/v?app_id=${getUnsplashAppId()}&photo_id=${
          props.node.externalId
        }`
    );

    const imageLoaderContainerStyle = computed(() =>
      !props.isPdfViewer && !isLoaded.value
        ? ImageHelper.getLoaderContainerStyle(props.node, props.rowType)
        : null
    );

    const slideNumber = computed(() => {
      const displayName = props.node?.displayName;
      return SlideShowHelper.extractSlideNrFromName(displayName);
    });

    const setTextSize = () => {
      if (!isHeadingSet.value) {
        return;
      }

      const textNode = imageContent.value?.querySelector('.image-heading p');

      if (!textNode) {
        return;
      }

      isTextLoaded.value = true;
      const parentContainerWidth = headingInput.value.$el.clientWidth;
      const currentTextWidth = textNode.scrollWidth;
      const currentFontSize = maxTextSize;

      const newValue = Math.floor(
        Math.min(
          Math.max(minTextSize, (parentContainerWidth / currentTextWidth) * currentFontSize),
          maxTextSize
        )
      );

      textFont.value = {
        ...textFont.value,
        size: newValue,
      };
    };

    const resetTextSize = async () => {
      textFont.value.size = maxTextSize;
      await nextTick();
      setTextSize();
    };

    const handleTextLoaded = () => {
      if (isTextLoaded.value) {
        return;
      }
      resetTextSize();
    };

    const handleImageLoaded = () => {
      isLoaded.value = true;
      resetTextSize();
    };

    const handleLoadError = () => {
      const { imageId, id } = props.node;
      if (!imageId) {
        return;
      }

      emit('fetch-new-image', {
        targetNodeId: id,
        imageId,
      });
    };

    const imageContainer = computed(() =>
      ImageHelper.getImageContainer(imageContent.value, props.rowType)
    );

    const lazySrcUrl: Ref<string> = ref(null);

    const srcUrl = computed(() => (props.isPdfViewer ? props.node.srcUrl : lazySrcUrl.value));

    useIntersectionObserver(imageContainer, ([{ isIntersecting }]) => {
      if (!isIntersecting || lazySrcUrl.value === props.node.srcUrl || !props.node.srcUrl) {
        return;
      }
      lazySrcUrl.value = props.node.srcUrl;
    });

    watch(
      () => props.node.srcUrl,
      () => {
        if (!props.node.srcUrl || lazySrcUrl.value === props.node.srcUrl) {
          return;
        }
        const element = imageContainer.value?.getBoundingClientRect();
        if (!ImageHelper.isElementInView(element)) {
          return;
        }
        isLoaded.value = false;
        lazySrcUrl.value = props.node.srcUrl;
      }
    );

    watch(
      () => props.isBlockToggled,
      () => {
        if (!props.isBlockToggled) {
          return;
        }

        resetTextSize();
      }
    );

    watch(
      () => props.pageWidth,
      () => {
        resetTextSize();
      }
    );

    return {
      RowType,
      imageContent,
      headingInput,
      isHeadingSet,
      isCaptionSet,
      fontSize,
      imageSize,
      imageAlign,
      rowTypeClass,
      unsplashBeaconSrcUrl,
      srcUrl,
      lazySrcUrl,
      handleTextLoaded,
      resetTextSize,
      setTextSize,
      handleLoadError,
      handleImageLoaded,
      imageLoaderContainerStyle,
      isLoaded,
      textFont,
      isTextLoaded,
      slideNumber,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node';
@import '@getaccept/editor-lib-new/src/scss/editor-node-image';

.loading {
  background-color: rgb(255 255 255 / 80%);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-container {
  &.half,
  &.full,
  &.original,
  &.cover {
    .image-content {
      a {
        .image {
          width: 100%;
        }
      }

      &.no-src-url {
        min-height: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &.row-type-columns {
    .image-content.no-src-url {
      min-height: auto;
    }
  }
}
</style>
