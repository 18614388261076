import { DateTime } from 'luxon';
import { t } from './translation.helper';

export class DateHelper {
  static isToday(date: DateTime): boolean {
    return date.hasSame(DateTime.utc(), 'day');
  }

  static isYesterday(date: DateTime): boolean {
    return date.hasSame(DateTime.utc().minus({ days: 1 }), 'day');
  }

  static isTomorrow(date: DateTime): boolean {
    return date.hasSame(DateTime.utc().plus({ days: 1 }), 'day');
  }

  static isSameHour(date: DateTime, compareDate: DateTime = DateTime.utc()): boolean {
    return date.hasSame(compareDate, 'hour');
  }

  static isSameYear(date: DateTime, compareDate: DateTime = DateTime.utc()): boolean {
    return date.hasSame(compareDate, 'year');
  }

  static isSameDay(date: DateTime, compareDate: DateTime): boolean {
    return date.hasSame(compareDate, 'day');
  }
  /**
   *  Returns string 'Today', 'Yesterday', 'Tomorrow' or null
   */
  static todayPlusMinusOne(date: DateTime): string {
    if (DateHelper.isToday(date)) {
      return t('Today');
    }
    if (DateHelper.isYesterday(date)) {
      return t('Yesterday');
    }

    if (DateHelper.isTomorrow(date)) {
      return t('tomorrow');
    }
    return null;
  }
  /**
   *  Returns 'Today', 'Yesterday', 'Tomorrow', 'x days ago' or 'x days left'
   */
  static relativeTime(date: DateTime): string {
    const todayPlusMinusOne = DateHelper.todayPlusMinusOne(date);
    if (todayPlusMinusOne) {
      return todayPlusMinusOne;
    }
    const daysLeft: number = DateHelper.differenceInDays(date);
    return daysLeft < 0
      ? t('days-ago', { daysAgo: Math.abs(daysLeft) })
      : t('days-left', { daysLeft });
  }
  /**
   *  Returns string 'Today', 'Yesterday', 'Tomorrow', or format like 'Oct 14, 1983'
   */
  static formattedDate(date: DateTime): string {
    const todayPlusMinusOne = DateHelper.todayPlusMinusOne(date);
    if (todayPlusMinusOne) {
      return todayPlusMinusOne;
    }
    return DateHelper.mediumDate(date);
  }

  /**
   *  Returns format like 'Oct 14, 1983'
   */
  static mediumDate(date: DateTime) {
    if (DateHelper.isSameYear(date)) {
      return date.toLocaleString({
        day: 'numeric',
        month: 'short',
      });
    }
    return date.toLocaleString({ ...DateTime.DATE_MED });
  }

  /**
   *  Returns format like 'Oct 14, 1983 at 12:00 PM'
   */
  static mediumDateTime(date: DateTime) {
    return date.toLocaleString({ ...DateTime.DATETIME_MED });
  }

  /**
   *  Returns string 'Today', 'Yesterday', 'Tomorrow', or format like 'October 14, 1983'
   */
  static formattedDateFull(date: DateTime): string {
    const todayPlusMinusOne = DateHelper.todayPlusMinusOne(date);
    if (todayPlusMinusOne) {
      return todayPlusMinusOne;
    }
    return date.toLocaleString({ ...DateTime.DATE_FULL });
  }

  static differenceInDays(date: DateTime): number {
    return Math.ceil(date.diff(DateTime.now(), 'days').toObject().days);
  }

  static years(offset = 0): number[] {
    const startYear = 1970;
    const numberOfYears: number = DateTime.utc().plus({ years: offset }).year - startYear;
    return [...Array(numberOfYears).keys()].map((year: number) => year + startYear).reverse();
  }

  static disableDateTo(date: DateTime): { to?: Date } {
    return {
      to: date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toJSDate(),
    };
  }

  static parseLegacyDate(date: string): number {
    return date ? DateTime.fromISO(date.replace(' ', 'T')).toMillis() : null;
  }

  static fromMillisToISO(millis: number): string {
    return millis ? DateTime.fromMillis(millis).toISO() : '';
  }

  static fromSecondsToMediumDate(seconds: number): string {
    return seconds
      ? DateTime.fromSeconds(seconds).toLocaleString({ ...DateTime.DATE_FULL }) // should be DATE_MED?
      : '';
  }

  static mediumDateLocale(date: DateTime, locale?: string): string {
    return date ? date.setLocale(locale || 'en-US').toLocaleString({ ...DateTime.DATE_FULL }) : '';
  }

  // This method is used to convert a date from a date picker to a string in the format 'YYYY-MM-DD'
  // The date picker returns a timestamp with an offset based on your timezone
  // To make sure we get the correct date, we need to subtract the timezone offset from the date
  static fromJSDateToISODate(date: Date): string {
    if (!date) {
      return '';
    }
    return DateTime.fromJSDate(date).toFormat('yyyy-LL-dd');
  }

  static fromISODateToLocaleString(date: string): string {
    if (!date) {
      return '';
    }
    return DateTime.fromISO(date).toLocaleString({ ...DateTime.DATE_FULL });
  }
}
