import gql from 'graphql-tag';

export const AuthQuery = gql`
  query Auth(
    $documentId: String!
    $recipientId: String!
    $authId: String!
    $authSteps: [AuthStepInput!]
    $setRecipientViewedStatus: Boolean
  ) {
    auth(
      documentId: $documentId
      recipientId: $recipientId
      authId: $authId
      authSteps: $authSteps
      setRecipientViewedStatus: $setRecipientViewedStatus
    ) {
      jwt
      exp
      authSteps {
        type
        data {
          error
          errorType
          universalLinkUrls
          text
          textData {
            entity {
              name
            }
            document {
              name
            }
          }
          question
          mobile
        }
      }
      metaData {
        redirectUrl
        isFirstVisit
      }
      status
    }
  }
`;
