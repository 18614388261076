import { Node } from '@tiptap/core';

export const MergeValueNode = Node.create({
  name: 'merge_tag',

  selectable: true,

  group: 'inline',

  inline: true,

  addAttributes() {
    return {
      tagId: {
        default: '',
        parseHTML: element => element.getAttribute('tagId'),
      },
      category: {
        default: '',
        parseHTML: element => element.getAttribute('category'),
      },
      mergeKey: {
        default: '',
        parseHTML: element => element.getAttribute('mergeKey'),
      },
      customName: {
        default: '',
        parseHTML: element => element.getAttribute('customName'),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span.merge-tag',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes, class: 'merge-tag' }];
  },
});
