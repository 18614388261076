/* eslint-disable prettier/prettier */
<template>
  <div class="write-signature-container">
    <FriedInput
      v-model="inputText"
      class="name"
      data-external="write-signature-modal-text-input"
      :label="t('write-your-name')"
      :maxlength="maxLength"
      @update:model-value="handleChange"
    />
    <CharacterCounter class="character-counter" :text="inputText" :max-length="maxLength" />
    <div class="write-signature">
      <div id="svg-signature" />
      <FriedSpinner v-if="!showWriteSignature" class="signature-loader" />
    </div>
  </div>
</template>

<script lang="ts">
import { SVG } from '@svgdotjs/svg.js';
import { SignatureConstants } from '@getaccept/lib-shared-new/src/signature/constants/signature.constants';
import CharacterCounter from '@getaccept/lib-shared-new/src/components/CharacterCounter.vue';
import PinyonScript from '@getaccept/lib-shared-new/src/signature/constants/pinyon-script';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import { SignatureHelper } from '@getaccept/lib-shared-new/src/helpers/signature.helper';
import '@svgdotjs/svg.js/dist/polyfillsIE.js';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import { useI18n } from 'vue-i18n';
import type { PropType } from 'vue';
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  components: {
    CharacterCounter,
  },
  props: {
    assignee: {
      type: Object as PropType<SigningPageRecipient | User>,
      default: () => ({}) as SigningPageRecipient | User,
    },
  },
  emits: ['signature'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const inputText = ref('');
    const canvas = ref(null);
    const ctx = ref(null);
    const showWriteSignature = ref(false);
    const loaderTimeout = ref(500);
    const fontFamily = ref(SignatureConstants.writeSignatureFont);
    const draw = ref(null);
    const maxLength = ref(40);

    const setCanvasData = () => {
      canvas.value = document.createElement('canvas');
      canvas.value.width = SignatureConstants.writeSignatureWidth;
      canvas.value.height = SignatureConstants.writeSignatureHeight;
      ctx.value = canvas.value.getContext('2d');
    };

    const setStaticData = () => {
      ctx.value.fillStyle = SignatureConstants.writeSignatureColor;
      ctx.value.textAlign = SignatureConstants.writeSignatureAlign;
    };

    const setDynamicData = () => {
      const fontSize: number = canvas.value.height * 0.6 - (inputText.value.length - 12) * 5;
      ctx.value.font = `${fontSize}px ${fontFamily.value}`;
      ctx.value.font = ctx.value.fillText(
        inputText.value,
        canvas.value.width / 2,
        canvas.value.height * 0.7,
        canvas.value.width * 0.7
      );
      const signatureNative = SignatureHelper.convertSignature(ctx.value, canvas.value);
      const signatureBase30 = SignatureHelper.nativeToBase30(signatureNative);

      if (draw.value) {
        const base64 = SignatureHelper.encodeUnicodeToBase64(draw.value.svg());
        const signatureBase64 = `${SignatureConstants.saveWriteType},${base64}`;

        emit('signature', {
          signatureBase30,
          signatureBase64,
        });
      }
    };

    const setSvgData = () => {
      if (draw.value) {
        draw.value.remove();
      }

      draw.value = SVG().addTo('#svg-signature').size('100%', '100%');
      draw.value.style(PinyonScript);
      const text = draw.value
        .text(inputText.value)
        .font({ family: fontFamily.value, size: 12, fill: '#3b3b3b' });
      const boundingRect = text.node.getBoundingClientRect();
      const boundingRectParent = draw.value.node.getBoundingClientRect();
      const bbox = {
        width: boundingRect.width,
        height: boundingRect.height,
        y: boundingRect.top - boundingRectParent.top,
        x: boundingRect.left - boundingRectParent.left,
      };
      draw.value.viewbox(
        -10,
        -10,
        bbox.x + bbox.width + bbox.x + 20,
        bbox.y + bbox.height + bbox.y + 20
      );
    };

    const handleChange = () => {
      setSvgData();
      ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
      setDynamicData();
    };

    onMounted(() => {
      setCanvasData();
      setStaticData();
      setDynamicData();
      setTimeout(() => {
        inputText.value = props.assignee.fullName;
        showWriteSignature.value = true;
        handleChange();
      }, loaderTimeout.value);
    });

    return {
      t,
      maxLength,
      inputText,
      handleChange,
      showWriteSignature,
      setSvgData,
      setDynamicData,
      setStaticData,
      setCanvasData,
      loaderTimeout,
      draw,
      ctx,
      canvas,
      fontFamily,
    };
  },
});
</script>
<style lang="scss" scoped>
@import 'https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap';

.write-signature-container {
  .character-counter {
    margin-bottom: var(--spacing-100);
    margin-left: var(--spacing-25);
  }

  .write-signature-input {
    width: 100%;
  }

  .name {
    width: 350px;

    @media screen and (max-width: $xs) {
      width: 100%;
    }
  }

  .write-signature {
    margin-bottom: var(--spacing-50);
    height: 14rem;
    position: relative;
    background-color: var(--gray-98);
    border: 1px solid var(--gray-89);
    border-radius: var(--radius-medium);

    .signature-loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    @media screen and (max-width: $sm) {
      height: 10rem;
    }

    @media screen and (max-width: $xs) {
      height: 8rem;
    }

    #svg-signature {
      height: 14rem;

      @media screen and (max-width: $sm) {
        height: 10rem;
      }

      @media screen and (max-width: $xs) {
        height: 8rem;
      }
    }

    .write-signature-box {
      height: 14rem;
      width: 100%;

      @media screen and (max-width: $sm) {
        height: 7rem;
      }
    }
  }
}
</style>
