<template>
  <div class="input-label-content">
    <div v-if="input.inputSettings.type === FieldInputType.Checkbox" :class="['input-label']"></div>
    <ProsemirrorViewerFormInput
      v-else
      :class="['input-label', { 'is-required': input.inputSettings.required && editable }]"
      data-external="form-input-text-label-view"
      :content="getSafeText(input.inputSettings.richTextLabel)"
      :merge-values="mergeValues"
      :fields="fields"
      :multi-line="false"
      :label="false"
      :link="false"
      placeholder=""
    />
  </div>
</template>
<script lang="ts">
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import { FieldInputType, ProsemirrorHelper } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';
import ProsemirrorViewerFormInput from './ProsemirrorViewerFormInput.vue';

export default defineComponent({
  name: 'InputSetLabel',
  components: {
    ProsemirrorViewerFormInput,
  },
  props: {
    input: { type: Object as PropType<Field>, default: () => ({}) },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    editable: { type: Boolean, default: false },
  },
  setup() {
    return {
      getSafeText: ProsemirrorHelper.getSafeText,
      FieldInputType,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-set';
</style>
