<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'signature-container view',
      rowTypeClass,
      blockWidthClass,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
        'empty-signature-container': getVisibleSignatures.length === 0,
      },
    ]"
    data-external="editor-view-signature-element-container"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <SignatureItem
      v-for="(signature, index) in getVisibleSignatures"
      :id="signature.id"
      :key="signature.id"
      :signature="signature"
      :node="node"
      :sender="sender"
      :is-loading-signature="isLoadingSignature"
      :recipient="recipient"
      :focused-required-id="focusedRequiredId"
      :partially-signed-fields="partiallySignedFields"
      :context="context"
      :is-pdf-viewer="isPdfViewer"
      :data-external="`edit-signature-${index}`"
      :class="[blockWidthClass, 'signature-content']"
      :merge-values="mergeValues"
      @open-signature-modal="handleShowSignatureModal"
      @update-loading-signature="isLoadingSignature = false"
    />
    <FriedModal
      v-if="showSignatureModal"
      :closable="true"
      :size="ModalSize.Large"
      @close="showSignatureModal = false"
    >
      <VerifySignature
        :recipient="recipient as any"
        :entity="mergeValues.entity as any"
        :document-name="mergeValues.document.name"
        :in-editor="true"
        @sign="handleVerifySignature"
        @cancel="showSignatureModal = false"
      />
    </FriedModal>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { MergeValues, EBlockWidth, Signature, Context } from '@getaccept/editor-lib-new';
import { RowType } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { ref, computed, defineComponent } from 'vue';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import VerifySignature from '@getaccept/lib-shared-new/src/signing/components/VerifySignature.vue';
import { ModalSize } from '@getaccept/fried-tofu';
import { SignatureHelper } from '@getaccept/editor-lib-new/src/helpers/signature.helper';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import EditorNodeWrapper from './EditorNodeWrapper.vue';
import SignatureItem from './SignatureItem.vue';

export default defineComponent({
  name: 'EditorNodeSignature',
  components: {
    EditorNodeWrapper,
    SignatureItem,
    VerifySignature,
  },
  props: {
    node: { type: Object as PropType<Signature>, default: () => ({}) },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    rowType: { type: String as PropType<RowType>, default: RowType.Standard },
    parentFirstChild: { type: Boolean, default: false },
    parentLastChild: { type: Boolean, default: false },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    recipient: { type: Object as PropType<Recipient>, default: () => ({}) },
    sender: { type: Object as PropType<User>, default: () => ({}) },
    focusedRequiredId: { type: String, default: null },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    context: { type: String as PropType<Context>, default: null },
    isPdfViewer: { type: Boolean, default: false },
  },
  emits: ['observe-node-visibility', 'partially-sign'],
  setup(props, { emit }) {
    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);
    const showSignatureModal = ref(false);
    const isLoadingSignature = ref(false);

    const getVisibleSignatures = computed(() =>
      SignatureHelper.getVisibleFields(props.node.fields)
    );

    const handleVerifySignature = async ({ signatureBase64, signatureBase30 }) => {
      showSignatureModal.value = false;
      isLoadingSignature.value = true;
      emit('partially-sign', { signatureBase64, signatureBase30, nodeId: props.node.id });
    };

    const handleShowSignatureModal = () => {
      if (!(props.recipient as Recipient).signature) {
        showSignatureModal.value = true;
        return;
      }
      handleVerifySignature({
        signatureBase64: (props.recipient as Recipient).signature,
        signatureBase30: {},
      });
    };

    return {
      rowTypeClass,
      getVisibleSignatures,
      handleShowSignatureModal,
      showSignatureModal,
      handleVerifySignature,
      ModalSize,
      isLoadingSignature,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-signature';
</style>
