<template>
  <div class="pricing-summary-container" tabindex="0">
    <div class="summary-container">
      <div class="header display-name-input-styling viewer">{{ summaryValues.displayName }}</div>
      <div
        v-show="summaryDiscountEnabled"
        class="discount-container"
        data-external="discount-container-2"
      >
        <div class="discount display-name-input-styling viewer">
          {{ summaryValuesDiscount.displayName }}
        </div>
        <div
          class="discount-value pointer"
          :class="{ empty: getTotalDiscountIsEmpty(summaryValues) }"
        >
          {{ getTotalDiscountValue }}
        </div>
      </div>
      <div v-show="summaryTaxEnabled" class="tax-container" data-external="tax-container-2">
        <div class="tax display-name-input-styling viewer">{{ summaryValues.tax.displayName }}</div>
        <div class="tax-value pointer" :class="{ empty: getTotalDiscountIsEmpty(summaryValues) }">
          {{ getTotalTaxValue }}
        </div>
      </div>
      <div class="total-container" data-external="total-container-2">
        <div class="total display-name-input-styling viewer">
          {{ summaryPriceDisplayName }}
        </div>
        <div class="total-value">
          {{ currencyFormat(getTotalPriceValue(summaryValues), currencySettings) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type {
  Field,
  PricingTableCurrencySettings,
  PricingTableSection,
  PricingTableSummaryValues,
} from '@getaccept/editor-lib-new';
import {
  PricingTableHelper,
  getTotalDiscount,
  getTotalDiscountIsEmpty,
  getTotalTax,
  getTotalTaxIsEmpty,
  getTotalPriceValue,
  defaultSummaryValues,
} from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'PricingTablePriceSummary',
  props: {
    summaryValues: {
      type: Object as PropType<PricingTableSummaryValues>,
      default: () => ({ ...defaultSummaryValues }),
    },
    currencySettings: {
      type: Object as PropType<PricingTableCurrencySettings>,
      default: () => ({}),
    },
    isPreCalculated: { type: Boolean },
    sections: { type: Array as PropType<PricingTableSection[]>, default: () => [] },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  setup(props) {
    const summaryDiscountEnabled = computed(() => props.summaryValues.discount?.enabled);
    const summaryTaxEnabled = computed(() => props.summaryValues.tax?.enabled);
    const summaryPriceDisplayName = computed(() => props.summaryValues.price?.displayName || '');
    const summaryValuesDiscount = computed(
      () => props.summaryValues.discount || defaultSummaryValues.discount
    );

    const getTotalTaxValue = computed(() => {
      if (getTotalTaxIsEmpty(props.summaryValues)) {
        return '';
      }
      return getTotalTax(
        props.summaryValues,
        props.currencySettings,
        props.isPreCalculated,
        props.sections,
        props.fields
      );
    });

    const getTotalDiscountValue = computed(() => {
      if (getTotalDiscountIsEmpty(props.summaryValues)) {
        return '';
      }
      return getTotalDiscount(
        props.summaryValues,
        props.currencySettings,
        props.isPreCalculated,
        props.sections,
        props.fields
      );
    });

    return {
      getTotalTaxValue,
      getTotalDiscountValue,
      summaryDiscountEnabled,
      summaryTaxEnabled,
      summaryPriceDisplayName,
      summaryValuesDiscount,

      currencyFormat: PricingTableHelper.currencyFormat,
      getTotalTaxIsEmpty,
      getTotalDiscountIsEmpty,
      getTotalPriceValue,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-summary';
</style>
