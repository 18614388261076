import type { ImageNode } from '../types';
import { RowType } from '../types';

export class ImageHelper {
  static getLoaderContainerStyle(node: ImageNode, rowType: RowType): Record<string, string> {
    if (rowType === RowType.Columns) {
      return {
        'aspect-ratio': '1.79 / 1',
      };
    }

    const { height, width } = node?.dimensions || {};

    if (!height) {
      return {
        'aspect-ratio': '16 / 9',
      };
    }

    return {
      'aspect-ratio': `${width} / ${height}`,
      width: `${width}px`,
      margin: 'auto',
      'max-width': '100%',
    };
  }

  static isElementInView = ({ top, height }: { top: number; height: number }) =>
    top >= -height && top < window.innerHeight;

  static getImageContainer = (imageContainer: HTMLElement, rowType: RowType): HTMLElement => {
    if (rowType === RowType.SlideShow) {
      return imageContainer?.closest('.row-container');
    }
    return imageContainer;
  };
}
