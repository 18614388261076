<template>
  <div class="pricing-table-checkbox-container">
    <FriedCheckbox
      :id="inputId"
      :disabled="disabled"
      data-external="pricing-table-checkbox-input-edit"
      :model-value="inputValue === '1'"
      @update:model-value="handleUpdateChecked"
    />
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import { ProsemirrorHelper } from '../../helpers/prosemirror.helper';
import type { Field } from '../../types';

export default defineComponent({
  name: 'PricingTableCheckbox',
  props: {
    disabled: { type: Boolean, default: false },
    input: { type: Object as PropType<Field>, required: true },
  },
  emits: ['update-checked'],
  setup(props, { emit }) {
    const inputId = computed(() => props.input?.id);
    const inputValue = computed(() => props.input?.value);

    const handleUpdateChecked = (checked: boolean) => {
      emit(
        'update-checked',
        { ...props.input, value: checked ? '1' : '0' },
        'optionalProductFieldId'
      );
    };

    return { handleUpdateChecked, ProsemirrorHelper, inputId, inputValue };
  },
});
</script>
<style lang="scss" scoped>
@import '../../scss/input-checkbox';

.pricing-table-checkbox-container {
  .checkbox-container {
    margin-top: 0;
    padding: 0;
  }
}
</style>
