<template>
  <div class="input-editor-container">
    <EditorContent class="prosemirror-wrapper" :editor="viewer" spellcheck="false" />
  </div>
</template>
<script lang="ts">
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Text from '@tiptap/extension-text';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { InputDocNode, EnterBreakNode, ParagraphNode } from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { defineComponent, watch, ref, onMounted, onBeforeUnmount, computed, nextTick } from 'vue';

export default defineComponent({
  name: 'ProsemirrorViewerInput',
  components: {
    EditorContent,
  },
  props: {
    content: {
      type: [Object, String] as PropType<TextContentDoc | string>,
      default: () => ({}),
    },
  },
  emits: ['loaded'],
  setup(props, { emit }) {
    const viewer: Ref<TipTapEditor | null> = ref(null);

    watch(
      () => props.content,
      () => {
        viewer.value?.commands.setContent(props.content);
      }
    );

    const inputViewer = computed(() => ({
      content: props.content,
      editable: false,
      extensions: [InputDocNode, ParagraphNode, Text, EnterBreakNode],
      onCreate: handleLoaded,
      enableInputRules: false,
      enablePasteRules: false,
    }));

    const handleLoaded = async () => {
      await nextTick();
      emit('loaded');
    };

    onMounted(() => {
      viewer.value = new TipTapEditor(inputViewer.value);
    });

    onBeforeUnmount(() => {
      viewer.value?.destroy();
    });

    return {
      viewer,
      handleLoaded,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/prosemirror-editor-input';
</style>
