import { Extension } from '@tiptap/core';
import { splitListItem } from '@tiptap/pm/schema-list';
import { toggleMark, setBlockType } from '@tiptap/pm/commands';
import type { Mark, Node as ProsemirrorNode } from '@tiptap/pm/model';
import type { EditorState, Transaction, Selection } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import { ProsemirrorHelper } from '../../helpers/prosemirror.helper';
import { ProsemirrorType } from '../../types/enums';

const isEmptyDocWithTwoNodesLineSelected = (
  line: number,
  doc: ProsemirrorNode,
  selection: Selection
): boolean =>
  !doc.textContent.length &&
  doc.childCount === 2 &&
  doc.content.child(line).content.size === 0 &&
  selection.$anchor.parent === doc.child(line);

const isSecondNodeEmpty = (doc: ProsemirrorNode): boolean => doc.child(1)?.textContent.length === 0;

const isDocNonEmptyAndFirstLineSelectedWithNoContent = (
  view: EditorView,
  doc: ProsemirrorNode,
  selection: Selection
): boolean => {
  const isSelectionInFirstNode = !doc.resolve(selection.$anchor.before(1)).nodeBefore;
  return (
    doc.textContent.length &&
    doc.childCount === 2 &&
    isSelectionInFirstNode &&
    isSecondNodeEmpty(doc) &&
    view.endOfTextblock('right')
  );
};

const moveSelectionToFirstLine = (
  doc: ProsemirrorNode,
  selection: Selection,
  dispatch,
  tr: Transaction
) => {
  const firstNodePosition: number = selection.anchor - doc.child(0).nodeSize;

  const transaction: Transaction = ProsemirrorHelper.selectTextNode(tr, firstNodePosition);
  dispatch(transaction);
};

const moveSelectionToSecondLine = (
  doc: ProsemirrorNode,
  selection: Selection,
  dispatch,
  tr: Transaction
) => {
  const secondNodePosition: number = selection.anchor + doc.child(1).nodeSize;

  const transaction: Transaction = ProsemirrorHelper.selectTextNode(tr, secondNodePosition);
  dispatch(transaction);
};

const handleSplitListItem = (state: EditorState, dispatch, view: EditorView): boolean => {
  const { nodeBefore, parent } = state.selection.$head;

  if (!nodeBefore) {
    return false;
  }

  const isValidListSplit: boolean = splitListItem(state.schema.nodes.list_item)(state);

  if (isValidListSplit) {
    splitListItem(state.schema.nodes.list_item)(state, dispatch);
    setBlockType(parent.type, parent.attrs)(view.state, view.dispatch);

    const marks: Mark[] = nodeBefore.marks.filter(
      (mark: Mark) => mark.type.name !== ProsemirrorType.Link
    );

    marks.forEach(({ type, attrs }: Mark) => {
      toggleMark(type, attrs)(view.state, view.dispatch);
    });

    return true;
  }

  return false;
};

const setSelectionToSecondLine = (
  { doc, selection, tr }: EditorState,
  dispatch,
  view: EditorView
): boolean => {
  if (
    isEmptyDocWithTwoNodesLineSelected(0, doc, selection) ||
    isDocNonEmptyAndFirstLineSelectedWithNoContent(view, doc, selection)
  ) {
    moveSelectionToSecondLine(doc, selection, dispatch, tr);
    return true;
  }

  return false;
};

export const TextPlaceholderKeymap = Extension.create({
  name: 'textPlaceholderKeymap',

  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor: { state, view } }) => {
        if (isEmptyDocWithTwoNodesLineSelected(1, state.doc, state.selection)) {
          moveSelectionToFirstLine(state.doc, state.selection, view.dispatch, state.tr);
          return true;
        }

        return isEmptyDocWithTwoNodesLineSelected(0, state.doc, state.selection);
      },
      Enter: ({ editor: { state, view } }) => {
        const splitList: boolean = handleSplitListItem(state, view.dispatch, view);

        if (splitList) {
          return true;
        }

        const changedSelection: boolean = setSelectionToSecondLine(state, view.dispatch, view);

        return changedSelection;
      },
    };
  },
});
