export enum FieldType {
  Merge = 'Merge',
  Input = 'Input',
  Custom = 'Custom',
  Markdown = 'Markdown',
}

export enum FieldInputType {
  Checkbox = 'Checkbox',
  Number = 'Number',
  Dropdown = 'Dropdown',
  Text = 'Text',
  Signature = 'Signature',
  Email = 'Email',
  Date = 'Date',
}

export enum MergeCategory {
  None = 'None',
  Document = 'Document',
  Sender = 'Sender',
  Entity = 'Entity',
  Recipient = 'Recipient',
}

export enum MergeKey {
  name = 'name',
  value = 'value',
  sendDate = 'sendDate',
  uniqueId = 'uniqueId',
  firstName = 'firstName',
  lastName = 'lastName',
  title = 'title',
  email = 'email',
  mobile = 'mobile',
  phone = 'phone',
  companyName = 'companyName',
  companyNumber = 'companyNumber',
  address = 'address',
  zip = 'zip',
  state = 'state',
  city = 'city',
  country = 'country',
  registeredNumber = 'registeredNumber',
  website = 'website',
  expirationDate = 'expirationDate',
  signDate = 'signDate',
  fullName = 'fullName',
}

export enum MergeLabel {
  Name = 'name',
  Value = 'value',
  SendDate = 'send-date',
  UniqueId = 'unique-id',
  FirstName = 'first-name',
  LastName = 'last-name',
  Title = 'title',
  Email = 'email',
  Mobile = 'mobile',
  CompanyName = 'company-name',
  CustomerName = 'customer-name',
  OrganisationNumber = 'org-number',
  EntityName = 'entity-name',
  Address = 'street-address',
  Zip = 'zip-code',
  State = 'state',
  City = 'city',
  Country = 'country',
  RegisteredNumber = 'business-registration-number',
  Website = 'website',
  ExpirationDate = 'expiration-date',
  SignDate = 'sign-date',
  FullName = 'fullname',
}
