<template>
  <FriedButton
    :class="[
      'themed-button',
      {
        icon,
        dense,
        disabled,
        'is-loading': isLoading,
        rounded,
        theme,
        secondary,
        'disable-hover': disableHover,
        'active-custom-hover': activeCustomHover,
      },
    ]"
    :style="buttonStyle"
    :loading="loadingRecipientInput"
    @click="handleClick"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <slot></slot>
    <span :class="['button-loader', { visible: isLoading }]">
      <FriedSpinner v-if="isLoading" :size="30" :current-color="true" />
    </span>
  </FriedButton>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import { BrandingHelper } from '../signing-theme/helpers/branding.helper';
import type { SigningTheme } from '../signing-theme/types/theme';
import type { EntityTheme } from '../types/theme';

export default defineComponent({
  props: {
    dense: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    rounded: {
      type: Boolean,
    },
    secondary: {
      type: Boolean,
    },
    disableHover: {
      type: Boolean,
    },
    customHover: {
      type: Boolean,
    },
    isHovered: {
      type: Boolean,
    },
    loadingRecipientInput: {
      type: Boolean,
    },
    theme: {
      type: Object as PropType<SigningTheme | EntityTheme>,
      default: null,
    },
  },
  emits: ['focus', 'blur', 'click'],
  setup(props, { emit }) {
    const handleClick = event => {
      emit('click', event);
    };

    const buttonStyle = computed(() => {
      if (props.theme && props.theme.accentColor) {
        return {
          '--button-background-color': BrandingHelper.getBackgroundColor(
            props.theme.accentColor,
            props.disabled,
            props.secondary
          ),
          '--button-background-color--hover': BrandingHelper.getBackgroundColorHover(
            props.theme.accentColor,
            props.disabled,
            props.secondary
          ),
          '--button-text-color': BrandingHelper.getTextColor(
            props.theme.accentColor,
            props.disabled,
            props.secondary
          ),
          '--button-border-color': BrandingHelper.getBorderColor(
            props.theme.accentColor,
            props.disabled,
            props.secondary
          ),
          '--button-border-color--hover': BrandingHelper.getBorderColorHover(
            props.theme.accentColor,
            props.disabled,
            props.secondary
          ),
        };
      }
      return {};
    });

    const activeCustomHover = computed(() => props.customHover && props.isHovered);

    return { handleClick, buttonStyle, activeCustomHover };
  },
});
</script>

<style lang="scss" scoped>
@mixin add-button-color-style(
  $color,
  $background-color,
  $background-color-hover,
  $border-color,
  $border-color-hover,
  $disable-hover
) {
  color: $color;
  background: $background-color;
  border: 1px solid $border-color;

  @if $disable-hover == false {
    &:hover,
    &:focus,
    &.active-custom-hover {
      transition: all 0s;
      background: $background-color-hover;
      border: 1px solid $border-color-hover;
    }
  }
}

.themed-button {
  &.rounded {
    padding: var(--spacing-50);
    border-radius: 50%;
    box-sizing: content-box;
    align-items: center;

    > * {
      display: flex;
    }
  }

  &.is-loading,
  &.disabled {
    pointer-events: none;
    opacity: 0.55;
    cursor: default;
    border-color: none;
  }

  .button-loader {
    width: 0;
    transition: all 0.2s;
    font-size: 0.8em;
    display: none;

    &.visible {
      display: initial;
      margin-left: 0.6em;
      width: 1.5em;
    }
  }

  &:not(.secondary, .disable-hover) {
    @include add-button-color-style(
      var(--white),
      var(--signature-gradient),
      var(--signature-gradient-hover),
      none,
      none,
      $disable-hover: false
    );
  }

  &:not(.secondary).disable-hover {
    @include add-button-color-style(
      var(--white),
      var(--signature-gradient),
      var(--signature-gradient-hover),
      none,
      none,
      $disable-hover: true
    );
  }

  &.secondary:not(.disable-hover) {
    @include add-button-color-style(
      var(--brand-orange),
      var(--white),
      var(--brand-orange-light),
      var(--brand-orange),
      var(--brand-orange),
      $disable-hover: false
    );
  }

  &.secondary.disable-hover {
    @include add-button-color-style(
      var(--brand-orange),
      var(--white),
      var(--brand-orange-light),
      var(--brand-orange),
      var(--brand-orange),
      $disable-hover: true
    );
  }

  // Theme variables not used in IE11
  @supports not (-ms-high-contrast: none) {
    &.theme:not(.disable-hover) {
      @include add-button-color-style(
        var(--button-text-color),
        var(--button-background-color),
        var(--button-background-color--hover),
        var(--button-border-color),
        var(--button-border-color--hover),
        $disable-hover: false
      );
    }

    &.theme.disable-hover {
      @include add-button-color-style(
        var(--button-text-color),
        var(--button-background-color),
        var(--button-background-color--hover),
        var(--button-border-color),
        var(--button-border-color--hover),
        $disable-hover: true
      );
    }
  }
}
</style>
