<template>
  <NodeViewWrapper
    as="span"
    :class="['mention', { removed: !participant || participant.deletedAt }]"
    :data-mention-participant-id="dataMentionParticipantId"
    :data-mention-id="dataMentionId"
    >{{ mappedValue }}</NodeViewWrapper
  >
</template>
<script lang="ts">
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import type { ComputedRef } from 'vue';
import { computed, defineComponent } from 'vue';
import { MentionsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/mentions/helpers/mentions.helper';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';

export default defineComponent({
  name: 'MentionView',
  components: {
    NodeViewWrapper,
  },
  props: {
    extension: nodeViewProps.extension,
    node: nodeViewProps.node,
  },
  setup(props) {
    const dataMentionParticipantId = computed(
      () => props.node.attrs['data-mention-participant-id']
    );
    const dataMentionId = computed(() => props.node.attrs['data-mention-id']);

    const mentionParticipants = computed(() => props.extension.options.mentionParticipants || []);

    const participant = computed(
      () =>
        dataMentionId.value &&
        MentionsHelper.findParticipant(dataMentionParticipantId.value, mentionParticipants.value)
    );

    const mappedValue: ComputedRef<string> = computed(() => {
      if (!dataMentionId.value) {
        return '';
      }

      return `@${
        participant.value ? participant.value.fullName : t('dr_chat_removed_participant')
      }`;
    });

    return { mappedValue, dataMentionId, dataMentionParticipantId, participant };
  },
});
</script>
<style lang="scss" scoped>
.mention {
  color: var(--link-blue);

  &.removed {
    color: var(--gray-70);
  }
}
</style>
