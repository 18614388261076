import type { Command } from '@tiptap/core';
import { Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import type { EditorView } from '@tiptap/pm/view';
import type { Node as ProsemirrorNode } from '@tiptap/pm/model';
import { DecorationSet, Decoration } from '@tiptap/pm/view';
import type { Transaction } from '@tiptap/pm/state';
import { CommentStatus } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment-status';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import type { CommentType } from '../../../store/base-comments.store';
import type {
  Spec,
  Attributes,
  CursorIntersectionCallback,
  CommentHighlightsState,
  CommentTextSelectionUpdate,
  CommentHighlightsOptions,
  CommentHighlightsAction,
  ShowCommentHighlightsAction,
  HideCommentHighlightsAction,
  SelectCommentHighlightAction,
  HoverCommentHighlightAction,
  UpdateCommentHighlightsAction,
} from './types/comment-highlights-types';
import { CommentHighlightsHelper } from './helpers/comment-highlights.helper';

export const commentHighlightsPluginKey = new PluginKey('comment-highlights');

declare module '@tiptap/core' {
  interface Commands {
    commentHighlight: {
      showCommentHighlights: (comments: Array<CommentType>) => Command;
      hideCommentHighlights: () => Command;
      selectCommentHighlight: (id: string) => Command;
      hoverCommentHighlight: (id: string) => Command;
      updateCommentHighlights: (comments: Array<CommentType>) => Command;
    };
  }
}

const createDecorationFromComment = (
  { editorSelection, id, status }: CommentType,
  isSelected: boolean,
  isHovered: boolean,
  isVisible: boolean
): Decoration => {
  const [from, to] = [
    editorSelection.textSelection.anchor,
    editorSelection.textSelection.head,
  ].sort((a, b) => a - b);

  const spec: Spec = { id, status, isSelected, isHovered, isVisible };
  const attrs: Attributes = {
    nodeName: 'span',
    'data-comment-id': id,
    'data-external': `highlighted-text-id:${id}-text`,
    class: CommentHighlightsHelper.getDecorationClass(status, isSelected, isHovered, isVisible),
  };
  return Decoration.inline(from, to, attrs, spec);
};

const onKeyup =
  (callback: CursorIntersectionCallback) =>
  ({ state }: EditorView) => {
    const {
      selection: { from },
    } = state;

    const { decorations }: CommentHighlightsState = commentHighlightsPluginKey.getState(state);
    const intersectedCommentHighlightSpecs = decorations.find(from, from).map(({ spec }) => spec);

    const spec = intersectedCommentHighlightSpecs[0];
    if (!spec?.id || !spec?.isVisible) {
      return false;
    }
    callback({ commentId: spec.id, from });
    return false;
  };

const onClick =
  (callback: CursorIntersectionCallback) =>
  ({ state }: EditorView, position: number) => {
    const { decorations }: CommentHighlightsState = commentHighlightsPluginKey.getState(state);
    const intersectedCommentHighlightSpecs = decorations
      .find(position, position)
      .map(({ spec }) => spec);

    const spec = intersectedCommentHighlightSpecs[0];
    if (!spec?.id || !spec?.isVisible) {
      return false;
    }
    callback({ commentId: spec.id, from: position });
    return false;
  };

const deselect = (decorations: DecorationSet, doc: ProsemirrorNode) => {
  const selectedDecoration = decorations.find().find(({ spec }) => spec.isSelected);

  if (!selectedDecoration) {
    return decorations;
  }

  const deselectedDecoration = Decoration.inline(
    selectedDecoration.from,
    selectedDecoration.to,
    {
      nodeName: 'span',
      'data-comment-id': selectedDecoration.spec.id,
      'data-external': `highlighted-text-id:${selectedDecoration.spec.id}-text`,
      class: CommentHighlightsHelper.getDecorationClass(
        selectedDecoration.spec.status,
        false,
        selectedDecoration.spec.isHovered,
        selectedDecoration.spec.isVisible
      ),
    },
    {
      id: selectedDecoration.spec.id,
      status: selectedDecoration.spec.status,
      isSelected: false,
      isHovered: selectedDecoration.spec.isHovered,
      isVisible: selectedDecoration.spec.isVisible,
    }
  );

  return decorations.remove([selectedDecoration]).add(doc, [deselectedDecoration]);
};

const unhover = (decorations: DecorationSet, doc: ProsemirrorNode) => {
  const hoveredDecoration = decorations.find().find(({ spec }) => spec.isHovered);

  if (!hoveredDecoration) {
    return decorations;
  }

  const dehoveredDecoration = Decoration.inline(
    hoveredDecoration.from,
    hoveredDecoration.to,
    {
      nodeName: 'span',
      'data-comment-id': hoveredDecoration.spec.id,
      'data-external': `highlighted-text-id:${hoveredDecoration.spec.id}-text`,
      class: CommentHighlightsHelper.getDecorationClass(
        hoveredDecoration.spec.status,
        hoveredDecoration.spec.isSelected,
        false,
        hoveredDecoration.spec.isVisible
      ),
    },
    {
      id: hoveredDecoration.spec.id,
      status: hoveredDecoration.spec.status,
      isSelected: hoveredDecoration.spec.isSelected,
      isHovered: false,
      isVisible: hoveredDecoration.spec.isVisible,
    }
  );

  return decorations.remove([hoveredDecoration]).add(doc, [dehoveredDecoration]);
};

const getSelections = (declarations: DecorationSet): CommentTextSelectionUpdate[] =>
  declarations.find().map(({ from, to, spec }) => ({
    id: spec.id,
    from,
    to,
  }));

const commentHighlightsPlugin = (options: CommentHighlightsOptions) =>
  new Plugin({
    key: commentHighlightsPluginKey,
    state: {
      init(): CommentHighlightsState {
        return { decorations: DecorationSet.empty };
      },
      apply(
        transaction: Transaction,
        { decorations }: CommentHighlightsState
      ): CommentHighlightsState {
        const action: CommentHighlightsAction = transaction.getMeta(commentHighlightsPluginKey);

        if (transaction['meta']['preventUpdate']) {
          return { decorations };
        }

        switch (action?.type) {
          case 'update':
            //TODO: early return if no changes
            decorations.find().forEach(decoration => {
              const comment = action.comments.find(comment => comment.id === decoration.spec.id);

              if (comment) {
                //TODO: also check if editorselection s3 version differs
                const isUpdated = comment.status !== decoration.spec.status;

                if (isUpdated) {
                  const isVisible = CommentsHelper.isCommentVisible(
                    comment,
                    decoration.spec.isVisible
                  );

                  const newDecoration = Decoration.inline(
                    decoration.from,
                    decoration.to,
                    {
                      nodeName: 'span',
                      'data-comment-id': decoration.spec.id,
                      'data-external': `highlighted-text-id:${decoration.spec.id}-text`,
                      class: CommentHighlightsHelper.getDecorationClass(
                        comment.status,
                        decoration.spec.isSelected,
                        decoration.spec.isHovered,
                        isVisible
                      ),
                    },
                    {
                      id: decoration.spec.id,
                      status: comment.status,
                      isSelected: decoration.spec.isSelected,
                      isHovered: decoration.spec.isHovered,
                      isVisible,
                    } as Spec
                  );

                  decorations = decorations
                    .remove([decoration])
                    .add(transaction.doc, [newDecoration]);
                }
              } else {
                decorations = decorations.remove([decoration]);
              }
            });
            action.comments.forEach(comment => {
              const decoration = decorations.find().find(d => d.spec.id === comment.id);

              if (!decoration) {
                decorations = decorations.add(transaction.doc, [
                  createDecorationFromComment(
                    comment,
                    false,
                    false,
                    comment.status !== CommentStatus.Resolved
                  ),
                ]);
              }
            });
            break;
          case 'hide':
            //TODO: early return if no changes
            decorations.find().forEach(decoration => {
              const newDecoration = Decoration.inline(
                decoration.from,
                decoration.to,
                {
                  nodeName: 'span',
                  'data-comment-id': decoration.spec.id,
                  'data-external': `highlighted-text-id:${decoration.spec.id}-text`,
                  class: CommentHighlightsHelper.getDecorationClass(
                    decoration.spec.status,
                    decoration.spec.isSelected,
                    decoration.spec.isHovered,
                    false
                  ),
                },
                {
                  id: decoration.spec.id,
                  status: decoration.spec.status,
                  isSelected: decoration.spec.isSelected,
                  isHovered: decoration.spec.isHovered,
                  isVisible: false,
                }
              );
              decorations = decorations.remove([decoration]).add(transaction.doc, [newDecoration]);
            });
            break;
          case 'show':
            //TODO: early return if no changes
            decorations.find().forEach(decoration => {
              const comment = action.comments.find(
                (comment: CommentType) => comment.id === decoration.spec.id
              );

              const isVisible = CommentsHelper.isCommentVisible(comment, decoration.spec.isVisible);

              const newDecoration = Decoration.inline(
                decoration.from,
                decoration.to,
                {
                  nodeName: 'span',
                  'data-comment-id': decoration.spec.id,
                  'data-external': `highlighted-text-id:${decoration.spec.id}-text`,
                  class: CommentHighlightsHelper.getDecorationClass(
                    decoration.spec.status,
                    decoration.spec.isSelected,
                    decoration.spec.isHovered,
                    isVisible
                  ),
                },
                {
                  id: decoration.spec.id,
                  status: decoration.spec.status,
                  isSelected: decoration.spec.isSelected,
                  isHovered: decoration.spec.isHovered,
                  isVisible,
                } as Spec
              );
              decorations = decorations.remove([decoration]).add(transaction.doc, [newDecoration]);
            });
            break;
          case 'select':
            decorations = deselect(decorations, transaction.doc);

            // eslint-disable-next-line no-case-declarations
            const decoration = decorations
              .find()
              .find(decoration => decoration.spec.id === action.id);

            if (decoration) {
              const newDecoration = Decoration.inline(
                decoration.from,
                decoration.to,
                {
                  nodeName: 'span',
                  'data-comment-id': decoration.spec.id,
                  'data-external': `highlighted-text-id:${decoration.spec.id}-text`,
                  class: CommentHighlightsHelper.getDecorationClass(
                    decoration.spec.status,
                    true,
                    decoration.spec.isHovered,
                    decoration.spec.isVisible
                  ),
                },
                {
                  id: decoration.spec.id,
                  status: decoration.spec.status,
                  isSelected: true,
                  isHovered: decoration.spec.isHovered,
                  isVisible: decoration.spec.isVisible,
                }
              );
              decorations = decorations.remove([decoration]).add(transaction.doc, [newDecoration]);
            }

            break;
          case 'hover':
            decorations = unhover(decorations, transaction.doc);

            // eslint-disable-next-line no-case-declarations
            const targetDecoration = decorations
              .find()
              .find(targetDecoration => targetDecoration.spec.id === action.id);

            if (targetDecoration) {
              const newDecoration = Decoration.inline(
                targetDecoration.from,
                targetDecoration.to,
                {
                  nodeName: 'span',
                  'data-comment-id': targetDecoration.spec.id,
                  'data-external': `highlighted-text-id:${targetDecoration.spec.id}-text`,
                  class: CommentHighlightsHelper.getDecorationClass(
                    targetDecoration.spec.status,
                    targetDecoration.spec.isSelected,
                    true,
                    targetDecoration.spec.isVisible
                  ),
                },
                {
                  id: targetDecoration.spec.id,
                  status: targetDecoration.spec.status,
                  isSelected: targetDecoration.spec.isSelected,
                  isHovered: true,
                  isVisible: targetDecoration.spec.isVisible,
                }
              );
              decorations = decorations
                .remove([targetDecoration])
                .add(transaction.doc, [newDecoration]);
            }

            break;
          default:
        }

        const mappedDecorations = decorations.map(transaction.mapping, transaction.doc);
        if (transaction.docChanged) {
          options.onHighlightsChanged(getSelections(mappedDecorations));
        }

        return { decorations: mappedDecorations };
      },
    },

    props: {
      handleClick: onClick(options.onCursorIntersection),
      handleDOMEvents: {
        keyup: onKeyup(options.onCursorIntersection),
      },
      decorations(state): DecorationSet {
        //TODO: Handle cursor update and selection update here. Also handle case where
        // cursor is intersecting multiple highlights.
        const { decorations }: CommentHighlightsState = commentHighlightsPluginKey.getState(state);
        return decorations;
      },
    },
  });

export const CommentHighlights = Extension.create<CommentHighlightsOptions>({
  name: 'commentHighlight',
  addOptions() {
    return {
      onCursorIntersection: ({ commentId, from }) => ({
        commentId,
        from,
      }),
      onHighlightsChanged: (textSelections: CommentTextSelectionUpdate[]) => textSelections,
    };
  },
  addCommands() {
    return {
      showCommentHighlights:
        (comments: CommentType[]) =>
        ({ dispatch, state }) => {
          if (!dispatch) {
            return;
          }

          const metaData: ShowCommentHighlightsAction = {
            type: 'show',
            comments,
            addToHistory: false,
          };

          state.tr.setMeta(commentHighlightsPluginKey, metaData);

          return true;
        },
      hideCommentHighlights:
        () =>
        ({ dispatch, state }) => {
          if (!dispatch) {
            return;
          }

          const metaData: HideCommentHighlightsAction = {
            type: 'hide',
            addToHistory: false,
          };

          state.tr.setMeta(commentHighlightsPluginKey, metaData);

          return true;
        },
      selectCommentHighlight:
        (id: string) =>
        ({ dispatch, state }) => {
          if (!dispatch) {
            return;
          }

          const metaData: SelectCommentHighlightAction = {
            type: 'select',
            id,
            addToHistory: false,
          };

          state.tr.setMeta(commentHighlightsPluginKey, metaData);

          return true;
        },
      hoverCommentHighlight:
        (id: string) =>
        ({ dispatch, state }) => {
          if (!dispatch) {
            return;
          }
          const metaData: HoverCommentHighlightAction = {
            type: 'hover',
            id,
            addToHistory: false,
          };

          state.tr.setMeta(commentHighlightsPluginKey, metaData);

          return true;
        },
      updateCommentHighlights:
        (comments: CommentType[]) =>
        ({ dispatch, state }) => {
          if (!dispatch) {
            return;
          }

          const metaData: UpdateCommentHighlightsAction = {
            type: 'update',
            comments,
            addToHistory: false,
          };

          state.tr.setMeta(commentHighlightsPluginKey, metaData);

          return true;
        },
    };
  },
  addProseMirrorPlugins() {
    return [commentHighlightsPlugin(this.options)];
  },
});
