import type { ExtractPropTypes, PropType, Ref, ComputedRef } from 'vue';
import { onBeforeUnmount, ref } from 'vue';
import type { ContractLink } from '@getaccept/editor-lib-new/src/types/models';
import type { Channel, PusherClient } from '@getaccept/pusher';
import { ChannelType, DocumentEvent } from '@getaccept/pusher';
import type { RecipientRole } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-role';

export const contractPusherProps = {
  node: { type: Object as PropType<ContractLink>, default: () => ({}) },
};

export function useContractPusher(
  props: ExtractPropTypes<typeof contractPusherProps>,
  pusherClient: ComputedRef<PusherClient>
) {
  const contractPusherChannel: Ref<Channel> = ref(null);
  const recipientStatus: Ref<{ status: string; recipientId: string }> = ref(null);
  const recipientRole: Ref<{ role: RecipientRole; recipientId: string }> = ref(null);
  const documentStatus: Ref<{ status: string; documentId: string }> = ref(null);
  const newExpirationDate: Ref<number> = ref(null);

  const pusherSubscribe = async () => {
    const { contractId } = props.node;
    if (!contractId) {
      return;
    }
    contractPusherChannel.value = await pusherClient.value.subscribe({
      type: ChannelType.Document,
      documentId: contractId,
    });
    addRecipientListeners();
    addDocumentListeners();
  };

  const addRecipientListeners = () => {
    [
      DocumentEvent.Signed,
      DocumentEvent.Approved,
      DocumentEvent.Viewed,
      DocumentEvent.Reviewed,
      DocumentEvent.Rejected,
      DocumentEvent.Sent,
      DocumentEvent.Hardbounced,
    ].forEach(status => {
      pusherClient.value.listen(contractPusherChannel.value, status, payload => {
        if (!payload.recipientId && status === DocumentEvent.Signed) {
          documentStatus.value = { status, documentId: payload.documentId };
          return;
        }
        recipientStatus.value = {
          status,
          recipientId: payload.recipientId,
        };
      });
    });
  };

  const addDocumentListeners = () => {
    [DocumentEvent.Recalled, DocumentEvent.Expired].forEach(status => {
      pusherClient.value.listen(contractPusherChannel.value, status, payload => {
        documentStatus.value = { status, documentId: payload.documentId };
      });
    });

    pusherClient.value.listen(
      contractPusherChannel.value,
      DocumentEvent.ExpirationUpdate,
      payload => {
        newExpirationDate.value = payload.newExpirationDate;
      }
    );

    pusherClient.value.listen(contractPusherChannel.value, DocumentEvent.RoleChanged, payload => {
      recipientRole.value = { role: payload.recipientRole, recipientId: payload.recipientId };
    });
  };

  onBeforeUnmount(() => {
    if (contractPusherChannel.value) {
      pusherClient.value.unsubscribe(contractPusherChannel.value);
    }
  });

  return {
    documentStatus,
    recipientStatus,
    recipientRole,
    contractPusherChannel,
    newExpirationDate,
    pusherSubscribe,
    addRecipientListeners,
    addDocumentListeners,
  };
}
