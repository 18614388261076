<template>
  <div :class="['slider-bottom-bar-container', blockWidthClass, { 'is-sticky': isSticky }]">
    <div class="slider-bottom-bar">
      <div class="pagination">
        <FriedIconButton
          class="btn-prev"
          data-external="editor-button-slideshow-prev"
          aria-label="Previous slide"
          @click="$emit('slide', -1)"
        >
          <FriedIcon icon="chevron-left" />
        </FriedIconButton>
        <div class="page-number">
          {{ currentSlide + 1 + '/' + numOfSlides }}
        </div>
        <FriedIconButton
          class="btn-next"
          data-external="editor-button-slideshow-next"
          aria-label="Next slide"
          @click="$emit('slide', 1)"
        >
          <FriedIcon icon="chevron-right" />
        </FriedIconButton>
      </div>
      <div v-if="!isSmallScreen" class="view-mode-button-container">
        <FriedIconButton
          :class="['grid-view-button', { active: isGridView }]"
          data-external="editor-button-slideshow-card-view"
          @click="$emit('toggle-grid-view', !isGridView)"
        >
          <FriedIcon icon="card" />
        </FriedIconButton>
        <router-link :to="route">
          <FriedIconButton data-external="editor-button-slideshow-lightbox-view">
            <FriedIcon :icon="isLightboxView ? 'arrow-collapse' : 'arrow-expand'" />
          </FriedIconButton>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  name: 'SlideShowBottomBar',
  props: {
    isGridView: { type: Boolean },
    isLightboxView: { type: Boolean },
    currentSlide: { type: Number, default: 0 },
    slides: { type: Number, default: 1 },
    gridSlides: { type: Number, default: 1 },
    isSticky: { type: Boolean },
    blockWidthClass: { type: String, default: '' },
    route: { type: Object, default: () => ({}) },
    isSmallScreen: { type: Boolean },
  },
  emits: ['slide', 'toggle-grid-view'],
  setup(props) {
    const numOfSlides = computed(() => {
      if (props.isGridView) {
        return props.gridSlides;
      }
      return props.slides;
    });

    return {
      numOfSlides,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-row-slideshow';
</style>
