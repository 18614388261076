<template>
  <div
    v-tooltip="{
      content: tooltipText,
      distance: 16,
      delay: { show: 300, hide: 100 },
    }"
    :style="{
      position: 'absolute',
      transform: `translateY(${top - buttonHeight}px)`,
      left: left + 'px',
    }"
  >
    <button
      ref="createCommentButton"
      v-wave
      class="create-comment-button"
      data-external="create-comment-button"
      :disabled="disabled"
      @click="createComment"
    >
      <FriedH4 class="text hidden-xs-and-up">{{ t('cc_tooltip_add_comment') }}</FriedH4>
      <FriedIcon icon="create-contextual-comment" />
    </button>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import useBreakpoints from '@getaccept/lib-shared-new/src/composables/breakpoints.composable';

export default defineComponent({
  props: {
    position: {
      type: Object as PropType<{ top: number; left: number }>,
      required: true,
    },
    state: {
      type: String as PropType<CreateContextualCommentButtonState>,
      required: true,
    },
  },
  emits: ['click'],
  setup(props, { emit }) {
    const disabled = computed(() => props.state !== CreateContextualCommentButtonState.Enabled);
    const createCommentButton = ref(null);

    const { isXXSmallScreen } = useBreakpoints();

    const mobileDeviceTopOffset = 70;
    const top = computed(() => {
      if (isXXSmallScreen.value) {
        return props.position.top + mobileDeviceTopOffset;
      }
      return props.position.top;
    });

    const mobileDeviceLeftOffset = 75;
    const left = computed(() => {
      if (isXXSmallScreen.value) {
        return props.position.left - mobileDeviceLeftOffset;
      }
      return props.position.left;
    });

    const tooltipText = computed(() => {
      switch (props.state) {
        case CreateContextualCommentButtonState.YouAreNotTheSender:
          return t('cc_tooltip_colleague_not_doc_owner');
        case CreateContextualCommentButtonState.DocumentIsNotEligible:
          return t('cc_tooltip_document_not_eligible');
        case CreateContextualCommentButtonState.ContentUpdatePending:
          return t('cc_tooltip_content_updating');
        case CreateContextualCommentButtonState.SenderHasMadeChanges:
          return t('cc_tooltip_new_comments_disabled');
        case CreateContextualCommentButtonState.DocumentHasUnpublishedChanges:
          return t('cc_tooltip_unpublished_changes');
        case CreateContextualCommentButtonState.Enabled:
        default:
          return t('cc_tooltip_add_comment');
      }
    });

    const createComment = () => {
      emit('click');
    };

    const buttonHeight = computed(() => createCommentButton.value?.offsetHeight);

    onMounted(() => {
      createCommentButton.value.addEventListener('touchstart', createComment);
    });

    onBeforeUnmount(() => {
      createCommentButton.value.removeEventListener('touchstart', createComment);
    });

    return {
      t,
      createComment,
      tooltipText,
      disabled,
      top,
      left,
      createCommentButton,
      buttonHeight,
    };
  },
});
</script>
<style lang="scss" scoped>
$icon-size: 1.25rem;

.create-comment-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-75);
  min-width: 2.5rem;
  width: fit-content;
  height: 2.5rem;
  color: var(--white);
  background-color: var(--blue-45);
  box-shadow: $box-shadow-3;
  border-radius: var(--radius-medium);
  padding: var(--spacing-50) var(--spacing-100);
  margin: 0;
  border: none;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;

  @media screen and (min-width: $xs) {
    padding: 0;
    border-bottom-left-radius: 0;
  }

  .text {
    color: white;
    white-space: nowrap;
  }

  &:disabled {
    color: var(--white);
    background-color: #b4cef1;
    cursor: initial;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: var(--blue-60);
  }
}
</style>
