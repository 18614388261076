import type { Font } from './types/font';
import type { UnicodeRange } from './types/unicode-range';

export class FontService {
  private static readonly FONTS_STYLE_SHEET_TITLE = 'dynamically-created-font-faces';
  private static readonly UNICODE_RANGES = {
    cyrillicExt: 'U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F',
    cyrillic: 'U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116',
    greekExt: 'U+1F00-1FFF',
    greek: 'U+0370-03FF',
    latinExt:
      'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
    latin:
      'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
    vietnamese:
      'U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB',
    hebrew: 'U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F',
    devanagari:
      'U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB',
  };

  private static getFontsStyleSheet(): CSSStyleSheet {
    for (const sheet of document.styleSheets) {
      if (sheet.title === FontService.FONTS_STYLE_SHEET_TITLE) {
        return sheet;
      }
    }

    const newSheetElement = document.createElement('style');
    newSheetElement.title = FontService.FONTS_STYLE_SHEET_TITLE;
    document.body.appendChild(newSheetElement);

    return newSheetElement.sheet;
  }

  private static hasFontFaceRule(sheet: CSSStyleSheet, fontFamily: string): boolean {
    return [...sheet.cssRules].some((rule: CSSStyleRule) => rule.style.fontFamily === fontFamily);
  }

  private static getUnicodeRange(unicodeRange: UnicodeRange): string {
    return FontService.UNICODE_RANGES[unicodeRange] || 'U+0-10FFFF';
  }

  private static getUrl(url: string) {
    try {
      new URL(url);
      return url;
    } catch {
      return `${process.env.VUE_APP_BASEPATH}/assets/fonts/${url}`;
    }
  }

  private static insertFontFaceRules(sheet: CSSStyleSheet, font: Font): void {
    font.sources.forEach(source => {
      const fontFaceRule = `
      @font-face {
        font-display: swap;
        font-family: ${font.fontFamily};
        font-style: ${source.fontStyle || 'normal'};
        font-weight: ${source.fontWeight || 'normal'};
        unicode-range: ${FontService.getUnicodeRange(source.unicodeRange)};
        src: url("${FontService.getUrl(source.src)}") format("${source.format || 'woff2'}");
      }
      `;

      sheet.insertRule(fontFaceRule);
    });
  }

  static loadFont(font: Font): void {
    const sheet = FontService.getFontsStyleSheet();

    if (FontService.hasFontFaceRule(sheet, font.fontFamily)) {
      return;
    }

    FontService.insertFontFaceRules(sheet, font);
  }
}
