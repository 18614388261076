import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { NodeType, RowType } from '../types/enums/attributes';
import type {
  ContentPlaceholder,
  ElementPlaceholderPayload,
  Row,
  Section,
  Node,
} from '../types/models';
import { PlaceholderType } from '../types/enums/placeholder-type';
import { PlaceholderElementType } from '../types/enums/placeholder-element-type';
import { Context } from '../types/enums/context';

export class PlaceholderHelper {
  static filterSectionForPlaceholders(section: Section) {
    return !section.rows.every(row =>
      row.cells.every(cell => cell.nodes.every(node => node.type === NodeType.ContentPlaceholder))
    );
  }

  static filterRowForPlaceholders(row: Row) {
    return !(
      row.cells.length === 1 &&
      (!row.cells[0].nodes.length || row.cells[0].nodes[0].type === NodeType.ContentPlaceholder)
    );
  }

  static getPlaceholderType = (node: Node): PlaceholderType => {
    if (!node) return null;

    if (node.type === NodeType.ContentPlaceholder && 'placeholder' in node) {
      return (node as ContentPlaceholder).placeholder.type;
    }
    return null;
  };

  static getElementType = (node: Node): PlaceholderElementType => {
    if (!node) return null;

    if (node.type === NodeType.ContentPlaceholder && 'placeholder' in node) {
      const { placeholder } = node as ContentPlaceholder;
      if (placeholder.type === PlaceholderType.Element && 'elementType' in placeholder) {
        return (placeholder as ElementPlaceholderPayload).elementType;
      }
    }
    return null;
  };

  static getElementIcon = (node: Node) => {
    const elementType = PlaceholderHelper.getElementType(node);
    switch (elementType) {
      case PlaceholderElementType.Video:
        return 'camera';
      case PlaceholderElementType.SlideShow:
        return 'presentation';
      default:
        return null;
    }
  };

  static getElementHeading = (node: Node, context: Context) => {
    const elementType = PlaceholderHelper.getElementType(node);
    const isTemplateOrResource = [Context.DsrTemplate, Context.DsrResource].includes(context);

    switch (elementType) {
      case PlaceholderElementType.Video:
        return isTemplateOrResource
          ? t('content_video_element_placeholder')
          : t('content_video_element_placeholder_room_context');
      case PlaceholderElementType.SlideShow:
        return isTemplateOrResource
          ? t('content_slideshow_element_placeholder')
          : t('content_slideshow_element_placeholder_room_context');
      default:
        return null;
    }
  };

  static getElementDescription = (node: Node, context: Context) => {
    const elementType = PlaceholderHelper.getElementType(node);
    const isTemplateOrResource = [Context.DsrTemplate, Context.DsrResource].includes(context);

    if (!isTemplateOrResource) return null;

    switch (elementType) {
      case PlaceholderElementType.Video:
        return t('content_video_element_placeholder_description');
      case PlaceholderElementType.SlideShow:
        return t('content_slideshow_element_placeholder_description');
      default:
        return null;
    }
  };

  static getDeletePlaceholderTitle = (node: Node) => {
    const placeholderType = PlaceholderHelper.getPlaceholderType(node);
    const elementType = PlaceholderHelper.getElementType(node);

    if (placeholderType === PlaceholderType.Resource) {
      return t('delete_resource_placeholder_question');
    }

    if (placeholderType === PlaceholderType.Element) {
      switch (elementType) {
        case PlaceholderElementType.Video:
          return t('delete_video_placeholder_question');
        case PlaceholderElementType.SlideShow:
          return t('delete_slideshow_placeholder_question');
        default:
          return null;
      }
    }

    return null;
  };

  static getCreatePayloadType = (elementType: PlaceholderElementType) => {
    switch (elementType) {
      case PlaceholderElementType.Video:
        return NodeType.Video;
      case PlaceholderElementType.SlideShow:
        return RowType.SlideShow;
      default:
        return null;
    }
  };
}
