import type { EditorBlock, ImageNode } from '../types';
import { NodeType } from '../types';

export const getLoadImageURLPayloads = (block: EditorBlock) =>
  block.content.sections.flatMap(({ id: targetSectionId, rows }) =>
    rows.flatMap(({ id: targetRowId, cells }) =>
      cells.flatMap(({ id: targetCellId, nodes }) =>
        nodes
          .filter(
            ({ type, srcUrl, imageId }: ImageNode) => type === NodeType.Image && !srcUrl && imageId
          )
          .flatMap(({ id, imageId }: ImageNode) => ({
            contentId: block.contentId,
            targetSectionId,
            targetRowId,
            targetCellId,
            targetNodeId: id,
            imageId,
          }))
      )
    )
  );
