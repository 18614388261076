<template>
  <div class="signature-content input-flex-container">
    <div
      :class="[
        'input-wrapper signature-input-wrapper',
        { 'hidden-signature': signature.inputSettings.hide },
      ]"
    >
      <div
        v-tooltip="getTooltip(signature)"
        :class="[
          'input-default-text input-default-signature signature-field',
          {
            'current-recipient-field': getIsAssignedToCurrentUser(signature) && !getSignatureSvg,
            'focused-field': fieldHasFocus,
          },
          getBorderStyle,
        ]"
        :data-external="`editor-view-signature-field-${node.borderStyle}`"
        @click="handleClickSignField(signature)"
      >
        <img
          v-if="getSignatureSvg"
          class="signature-image view"
          alt="signature-image"
          :src="getSignatureSvg"
        />
        <p v-else-if="!isPdfViewer" class="signature-text view">
          {{ getSignatureFieldText(signature) }}
        </p>
        <FriedSpinner
          v-if="isLoadingSignature && getIsAssignedToCurrentUser(signature)"
          class="updating-spinner"
          :size="20"
        />
      </div>
      <div
        v-if="showMergeTagContainer"
        :class="['merge-tag-container view', getAlign]"
        data-external="editor-view-signature-merge-tag-container"
      >
        <div :data-external="`editor-view-signature-merge-tags-container-alignment-${node.align}`">
          <template
            v-for="(tag, _index) in getVisibleMergeTags(node.mergeTagsSettings)"
            :key="_index"
          >
            <div
              :class="tag.key.toLowerCase()"
              :data-external="`editor-view-signature-merge-tag-${tag.key}`"
            >
              {{ tag.value }}
            </div>
          </template>
          <div
            v-if="signDateSettings.show"
            class="sign-date"
            data-external="editor-view-signature-merge-tag-signing-date"
          >
            {{ getSignDate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import type { MergeValues, Field, Signature } from '@getaccept/editor-lib-new';
import { Context, MergeKey } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { ref, watch, computed, defineComponent } from 'vue';
import type { MergeTagsSettings } from '@getaccept/editor-lib-new/src/types/models';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { SignatureHelper } from '@getaccept/editor-lib-new/src/helpers/signature.helper';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import { useInputFieldView } from '../composables/input-field-view.composable';

export default defineComponent({
  name: 'SignatureItem',
  props: {
    signature: {
      type: Object as PropType<Field>,
      default: () => ({}),
    },
    node: {
      type: Object as PropType<Signature>,
      default: () => ({}),
    },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    recipient: { type: Object as PropType<Recipient>, required: true },
    isLoadingSignature: { type: Boolean, default: false },
    sender: { type: Object as PropType<User>, default: () => ({}) },
    focusedRequiredId: { type: String, default: null },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    context: { type: String as PropType<Context>, default: null },
    isPdfViewer: { type: Boolean, default: null },
  },
  emits: ['update-loading-signature', 'open-signature-modal'],
  setup(props, { emit }) {
    const { getTooltip, getAssignedRecipient, getIsAssignedToCurrentUser } =
      useInputFieldView(props);
    const fieldHasFocus = ref(false);

    const fields = computed(() => props.node?.fields || []);
    const signDateSettings = computed(() => props.node?.mergeTagsSettings.signDate);

    const showMergeTagContainer = computed(
      () => getVisibleMergeTags(props.node.mergeTagsSettings).length || signDateSettings.value.show
    );

    const getBorderStyle = computed(() => props.node.borderStyle?.toLowerCase());
    const getAlign = computed(() => props.node.align?.toLowerCase());

    const getSignDate = computed(() => {
      if (!signedNode.value) {
        return props.isPdfViewer ? null : t('sign_date').toString();
      }
      return SignatureHelper.formatSignDate(
        signDateSettings.value.format,
        signedNode.value.tsaTime,
        props.mergeValues
      );
    });

    const signedNode = computed(() =>
      SignatureHelper.hasPartiallySignedField(
        props.partiallySignedFields,
        props.signature.recipientId,
        props.signature.userId,
        props.node.id
      )
    );

    watch(
      () => props.focusedRequiredId,
      () => {
        if (
          getIsAssignedToCurrentUser(props.signature) &&
          props.signature.id === props.focusedRequiredId
        ) {
          fieldHasFocus.value = true;
        } else {
          fieldHasFocus.value = false;
        }
      }
    );

    const getSignatureSvg = computed(() => {
      if (props.signature.userId) {
        return props.context === Context.Document || props.isPdfViewer
          ? (props.mergeValues.sender as User)?.signature
          : (props.sender as User).signature;
      }
      if (signedNode.value) {
        emit('update-loading-signature');
        return SignatureHelper.getRecipient(
          props.mergeValues.recipients,
          props.signature.recipientId
        )?.signature;
      }
      return null;
    });

    const getSignatureText = (name: string) => t('signature_display_here', { name }).toString();

    const getSignatureFieldText = (signature: Field) => {
      if (getIsAssignedToCurrentUser(signature)) {
        return t('add_your_signature_here').toString();
      }
      return getSignatureText(getAssignedRecipient(signature)?.fullName);
    };

    const handleClickSignField = (signature: Field) => {
      if (props.isLoadingSignature || getSignatureSvg.value) {
        return;
      }
      if (getIsAssignedToCurrentUser(signature)) {
        emit('open-signature-modal');
      }
    };

    const isMergeTagEnabled = (mergeTagsSettings: MergeTagsSettings, mergeKey: string) => {
      if (mergeKey === MergeKey.signDate) {
        return false;
      }
      return mergeTagsSettings[mergeKey] === true;
    };

    const getVisibleMergeTags = (settings: MergeTagsSettings) =>
      Object.keys(settings)
        .filter(key => isMergeTagEnabled(props.node.mergeTagsSettings, key as MergeKey))
        .map(key => {
          const mergeTag = props.signature.userId
            ? SignatureHelper.getMergeValueForSender(props.mergeValues, key)
            : SignatureHelper.getMergeValueForRecipient(
                props.mergeValues,
                key,
                props.signature.recipientId
              );
          return { key, value: mergeTag };
        });

    return {
      fields,
      getVisibleMergeTags,
      getTooltip,
      getIsAssignedToCurrentUser,
      getAssignedRecipient,
      getSignatureText,
      getSignatureFieldText,
      handleClickSignField,
      getSignatureSvg,
      getSignDate,
      fieldHasFocus,
      isMergeTagEnabled,
      signDateSettings,
      showMergeTagContainer,
      getBorderStyle,
      getAlign,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-set';
@import '@getaccept/editor-lib-new/src/scss/signature-item';
</style>
