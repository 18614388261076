<template>
  <EditorNodeWrapper
    :class="['pricing-table-container', blockWidthClass]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <div class="pricing-table-content">
      <PricingTableSection
        v-for="section in sections"
        :key="section.id"
        :node-id="node.id"
        :section-id="section.id"
        :sections="sections"
        :currency-settings="node.currencySettings"
        :is-pre-calculated="node.preCalculated"
        :is-block-width-small="isBlockWidthSmall"
        :merge-values="mergeValues"
        :fields="fieldsWithInput || []"
        :context="context"
        :recipient-input="recipientInput"
        :recipient="recipient"
        :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
        :summary-values="getSummaryValues(node)"
        @update-input-value="$emit('update-input-value', $event)"
      />
      <PricingTablePriceSummary
        v-show="getSummaryValuesPriceEnabled(node)"
        :summary-values="getSummaryValues(node, fieldsWithInput)"
        :currency-settings="node.currencySettings"
        :is-pre-calculated="node.preCalculated"
        :fields="fieldsWithInput || []"
        :sections="getSectionsWithRowsFromInputValues(node)"
      />
    </div>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import {
  getSectionsWithRowsFromInputValues,
  getSummaryValues,
  getSummaryValuesPriceEnabled,
} from '@getaccept/editor-lib-new/src/helpers/pricing-table/editor-node-pricing-table.helper';
import type {
  PricingTable,
  MergeValues,
  PricingTableSection as IPricingTableSection,
} from '@getaccept/editor-lib-new';
import { PricingTableHelper, EBlockWidth, Context } from '@getaccept/editor-lib-new';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import EditorNodeWrapper from '../EditorNodeWrapper.vue';
import PricingTableSection from './PricingTableSection.vue';
import PricingTablePriceSummary from './PricingTablePriceSummary.vue';

export default defineComponent({
  name: 'EditorNodePricingTable',
  components: {
    PricingTablePriceSummary,
    PricingTableSection,
    EditorNodeWrapper,
  },
  props: {
    node: { type: Object as PropType<PricingTable>, default: () => ({}) },
    sections: { type: Array as PropType<IPricingTableSection[]>, default: () => [] },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    recipient: {
      type: Object as PropType<Recipient>,
      default: () => ({}),
    },
    context: { type: String as PropType<Context>, default: () => Context.Document },
    allowedToEditAfterPartiallySigned: { type: Boolean },
  },
  emits: ['update-input-value', 'observe-node-visibility'],
  setup(props) {
    const fieldsWithInput = computed(() =>
      PricingTableHelper.fieldsWithRecipientInput(props.node.fields, props.recipientInput)
    );

    const isBlockWidthSmall = computed(
      () =>
        props.blockWidthClass === EBlockWidth.XXSmall ||
        props.blockWidthClass === EBlockWidth.XSmall
    );

    return {
      getSectionsWithRowsFromInputValues,
      getSummaryValues,
      getSummaryValuesPriceEnabled,
      fieldsWithInput,
      isBlockWidthSmall,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-pricing-table';
</style>
