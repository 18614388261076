import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import { Context, EditorFieldHelper, EditorHelper } from '@getaccept/editor-lib-new';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { ExtractPropTypes, PropType } from 'vue';
import { computed } from 'vue';
import type { TooltipOptions } from '@getaccept/fried-tofu';
import { Placement } from '@getaccept/fried-tofu';

import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { Participant } from '@getaccept/dsr-shared-new';

export const inputFieldProps = {
  recipient: { type: Object as PropType<Recipient>, required: true },
  sender: { type: Object as PropType<Partial<User>>, default: () => ({}) as User },
  mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
  context: { type: String as PropType<Context>, required: true },
  userId: { type: String, default: '' },
};

export function useInputFieldView(props: Partial<ExtractPropTypes<typeof inputFieldProps>>) {
  const currentCollaborator = computed(() =>
    props.mergeValues?.collaborators?.find(
      collaborator => collaborator.userId === props.userId || collaborator.id === props.userId
    )
  );

  const getTooltip = (input: Field): TooltipOptions => ({
    content: getIsAssignedToCurrentUser(input)
      ? t('field-assigned-to-you').toString()
      : `${t('field-assigned-to').toString()} ${getAssigneeFullName(input)}`,
    distance: 10,
    placement: Placement.Top,
  });

  const getIsAssignedToCurrentUser = (input: Field) =>
    getIsSigningSiteAndAssignedToRecipient(input) || getIsAppSiteAndAssignedToUser(input);

  const getIsSigningSiteAndAssignedToRecipient = (input: Field) =>
    EditorHelper.isPublicContext(props.context) &&
    EditorFieldHelper.isAssignedToRecipient(props.recipient, input);

  const getIsAppSiteAndAssignedToUser = (input: Field) => {
    if (EditorHelper.isPublicContext(props.context)) {
      return false;
    }
    if (
      props.context !== Context.DsrEdit ||
      props.userId === (props.sender as Participant).userId ||
      props.userId === props.sender.id
    ) {
      return EditorFieldHelper.isAssignedToSender(props.sender, input);
    }

    return EditorFieldHelper.isAssignedToSender(currentCollaborator?.value, input);
  };

  const getAssigneeFullName = (input: Field) => {
    const user = getAssignedSender(input);
    if (user) {
      return user.fullName || '';
    }

    const recipient = getAssignedRecipient(input);
    return recipient?.fullName || recipient?.email || '';
  };

  const getAssignedRecipient = (input: Field) =>
    props.mergeValues?.recipients?.find(recipient =>
      EditorFieldHelper.isAssignedToRecipient(recipient, input)
    );

  const getAssignedSender = (field: Field) => {
    if (EditorFieldHelper.isAssignedToSender(props.sender, field)) {
      return props.mergeValues?.sender;
    }

    return props.mergeValues?.collaborators?.find(collaborator =>
      EditorFieldHelper.isAssignedToSender(collaborator, field)
    );
  };

  return {
    getTooltip,
    getAssignedRecipient,
    getAssigneeFullName,
    getIsAssignedToCurrentUser,
    getIsSigningSiteAndAssignedToRecipient,
    getIsAppSiteAndAssignedToUser,
    getAssignedSender,
  };
}
