import type { RecipientMap } from '@getaccept/editor-lib-new/src/types/models';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import { UrlHelper } from '@getaccept/lib-shared-new/src/helpers/url.helper';
import { PusherClient } from '@getaccept/pusher';
import type { AuthenticationStep } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step';
import type { AuthenticationStepInput } from '@getaccept/lib-shared-new/src/authentication/types/authentication-step-input';
import { AuthenticationStepType } from '@getaccept/lib-shared-new/src/authentication/types/enums/authentication-step-type';
import { getPrefixedPublicApiBaseUrl } from '@getaccept/lib-shared-new/src/helpers/get-prefixed-api-base-url';
import { ContractHelper as ContractShared } from '@getaccept/editor-lib-new/src/helpers/contract.helper';
import { EditorViewService } from '../../api/services/editor-view.service';

export class ContractHelper {
  static async getToken(contractId: string, signingPageUrl: string, recipient: Recipient) {
    const url = signingPageUrl.split('/a/');
    const authId = url[1];
    const recipientId = url[0].slice(url[0].lastIndexOf('/') + 1);
    try {
      const { jwt, authSteps } = await EditorViewService.getToken(contractId, authId, recipientId);

      if (!authSteps) {
        return jwt;
      }

      const authStepsInput = ContractHelper.setAuthSteps(authSteps, recipient);
      const result = await EditorViewService.getToken(
        contractId,
        authId,
        recipientId,
        authStepsInput
      );
      return result.jwt;
    } catch (error) {
      return null;
    }
  }

  static async connectPusher(jwt: string, pusherClient: PusherClient) {
    try {
      await pusherClient.connect(jwt);
    } catch (e) {
      console.error('pusher error', e);
    }
  }

  static async createPusherClient(jwt: string) {
    try {
      const domain = getPrefixedPublicApiBaseUrl(
        process.env.VUE_APP_PUBLIC_API_BASE_URL,
        window.location.hostname
      );
      const pusherClient = new PusherClient(UrlHelper.getHttpsUrl(domain));
      await ContractHelper.connectPusher(jwt, pusherClient);
      return pusherClient;
    } catch (e) {
      return null;
    }
  }

  static async loadContract(jwt: string) {
    try {
      return await EditorViewService.getDocument(jwt);
    } catch (error) {
      return null;
    }
  }

  static async getSigningPageUrl(contractId: string) {
    try {
      return await EditorViewService.getSigningPageUrl(contractId);
    } catch (error) {
      return null;
    }
  }

  static findMappedRecipient(id: string, recipient: Recipient, recipientMaps: RecipientMap[]) {
    return recipientMaps.find(
      recipientMap =>
        recipientMap.contractRecipientId === id &&
        ContractShared.isParticipantMappedToContract(recipientMap, recipient)
    );
  }

  static setAuthSteps = (
    authSteps: AuthenticationStep[],
    recipient: Recipient
  ): AuthenticationStepInput[] => {
    const authStepsInput: AuthenticationStepInput[] = [];
    authSteps.forEach(authStep => {
      switch (authStep.type) {
        case AuthenticationStepType.IdentifyRecipient:
          authStepsInput.push({
            type: AuthenticationStepType.IdentifyRecipient,
            value: recipient.email,
          });
          break;
        case AuthenticationStepType.GdprConsent:
          // Recipient has already given GDPR consent before accessing the room
          authStepsInput.push({
            type: AuthenticationStepType.GdprConsent,
            value: '1',
          });
          break;
        default:
          authStepsInput.push({
            type: authStep.type,
            value: '0',
          });
          break;
      }
    });
    return authStepsInput;
  };
}
