export const colorLightnessThreshold = 80;
export const colorHoverPercentage = 0.1;

export const colorDisabledLightness = 85;
export const colorDisabledSaturation = 85;

export const colorBoostSecondaryLightNormal = 0.2;
export const colorBoostSecondaryLightHover = 0.3;
export const colorBoostSecondaryLightDisabled = 0.05;

export const colorFadePrimaryLightDisabled = 0.2;
