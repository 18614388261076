import gql from 'graphql-tag';

export class FontFragments {
  static fontFragment = gql`
    fragment fontFragment on UpsertFont {
      fontFamily
      displayName
      sources {
        fontStyle
        fontWeight
        unicodeRange
        format
        src
      }
    }
  `;
}
