import { Node } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import type { MentionParticipant } from '@getaccept/lib-shared-new/src/contextual-commenting/mentions/types/mention-participant';
import MentionView from './MentionView.vue';

interface MentionNodeAttributes {
  mentionParticipants: MentionParticipant[];
}

export const MentionNode = Node.create<MentionNodeAttributes>({
  name: 'mention',

  group: 'inline',

  inline: true,

  parseHTML() {
    return [
      {
        tag: 'span',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', { ...HTMLAttributes }, HTMLAttributes['data-mention-id'] || ''];
  },

  addAttributes() {
    return {
      ['data-mention-participant-id']: {
        default: null,
      },
      ['data-mention-id']: {
        default: null,
      },
      ['data-display-name']: {
        default: null,
      },
    };
  },

  addOptions() {
    return {
      mentionParticipants: [],
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(MentionView as any);
  },
});
