<template>
  <div>
    <div v-if="placeholder.loading" class="editor-node-loader">
      <FriedSpinner :size="48" />
      <FriedH3>{{ t('content_placeholder_is_loading_resource') }}</FriedH3>
    </div>
    <EditorNodeEmptyState
      v-else
      icon="editor-block"
      :heading="t('content_resource_placeholder')"
      :description="emptyStateDescription"
    >
      <template #action>
        <div v-if="hasPlaceholder" class="content-resource-placeholder">
          <template v-if="node.content">
            <FriedH5>{{ t('content_element_placeholder_description_room_context') }}</FriedH5>
            <FriedParagraph>{{ node.content }}</FriedParagraph>
          </template>
          <div class="content-resource-placeholder-resources">
            <div
              v-for="resource in placeholder.resources"
              :key="resource.id"
              :class="[
                'content-resource-placeholder-row',
                {
                  deleted: isResourceUnavailable(resource),
                },
              ]"
            >
              <FriedRadioButton disabled :value="resource.id" />
              <FriedH4>{{ resource.title }}</FriedH4>
            </div>
          </div>
        </div>
      </template>
    </EditorNodeEmptyState>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import type { PropType } from 'vue';
import { EditorNodeEmptyState } from '@getaccept/editor-lib-new';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { DsrResource } from '@getaccept/lib-shared-new/src/library/types/dsr-resource';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import type {
  ContentPlaceholder,
  ContentPlaceholderPayload,
} from '@getaccept/editor-lib-new/src/types/models';

export default defineComponent({
  components: {
    EditorNodeEmptyState,
  },
  props: {
    node: {
      type: Object as PropType<ContentPlaceholder>,
      required: true,
    },
    placeholder: {
      type: Object as PropType<MappedContentPlaceholder>,
      default: () => ({}),
    },
  },
  setup(props) {
    const hasPlaceholder = computed(
      () => !!(props.node.placeholder as ContentPlaceholderPayload)?.resourceIds?.length
    );
    const emptyStateDescription = computed(() =>
      hasPlaceholder.value ? null : t('content_placeholder_is_empty_view_only')
    );
    const isResourceUnavailable = (
      resource: DsrResource | Pick<DsrResource, 'id' | 'title' | 'contentId'>
    ) => {
      const isDeleted = 'deletedAt' in resource && resource.deletedAt !== null;
      const isContentIdMissing = !resource.contentId;
      return isDeleted || isContentIdMissing;
    };

    return {
      t,
      hasPlaceholder,
      emptyStateDescription,
      isResourceUnavailable,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-loader';
@import '@getaccept/editor-lib-new/src/scss/editor-node-content-resource-placeholder';
</style>
