import TableCell from '@tiptap/extension-table-cell';
import { tableColumnWidth } from '../../constants';

export const TableCellNode = TableCell.extend({
  name: 'table_cell',

  content: '(paragraph | heading | bullet_list | ordered_list)+',

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: [tableColumnWidth],
        parseHTML: element => {
          const colwidth = element.getAttribute('colwidth');
          return colwidth ? [parseInt(colwidth, 10)] : null;
        },
      },
    };
  },
});
