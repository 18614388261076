<template>
  <div>
    <EditorNodeEmptyState :icon="elementIcon" :heading="heading">
      <template v-if="node.content" #action>
        <div>
          <FriedH5>{{ t('content_element_placeholder_description_room_context') }}</FriedH5>
          <FriedParagraph class="element-placeholder-note">{{ node.content }}</FriedParagraph>
        </div>
      </template>
    </EditorNodeEmptyState>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import type { PropType } from 'vue';
import { EditorNodeEmptyState, PlaceholderHelper, Context } from '@getaccept/editor-lib-new';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { ContentPlaceholder } from '@getaccept/editor-lib-new/src/types/models';

export default defineComponent({
  components: {
    EditorNodeEmptyState,
  },
  props: {
    node: {
      type: Object as PropType<ContentPlaceholder>,
      default: () => ({}),
    },
  },
  setup(props) {
    const elementIcon = computed(() => PlaceholderHelper.getElementIcon(props.node));
    const heading = computed(() =>
      PlaceholderHelper.getElementHeading(props.node, Context.DsrPublished)
    );

    return {
      t,
      elementIcon,
      heading,
    };
  },
});
</script>

<style lang="scss" scoped>
.element-placeholder-note {
  white-space: pre;
  text-align: left;
}
</style>
