import gql from 'graphql-tag';

export const getImageURLQuery = gql`
  query image($id: String!, $height: String) {
    image(id: $id, height: $height) {
      url
      externalId
      blurhash
    }
  }
`;
