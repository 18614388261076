import type { Field, NumberInputSettings } from '../../types';

export const getMinValue = (inputField: Field & { inputSettings: NumberInputSettings }): string => {
  if (isNaN(Number(inputField.inputSettings?.minValue))) {
    return null;
  }
  return inputField.inputSettings?.minValue;
};

export const getMaxValue = (inputField: Field & { inputSettings: NumberInputSettings }): string => {
  if (isNaN(Number(inputField.inputSettings?.maxValue))) {
    return null;
  }
  return inputField.inputSettings?.maxValue;
};
