import { EPricingTableColumn } from '../types/enums';
import type { PricingTableSummaryValues } from '../types/pricing-table';

export const customColumnNamePrefix = 'customColumn';
export const maxCustomColumns = 6;
export const minColumnWidth = 10;
export const totalPriceColumnWidthPercent = 30;

export const nonGridColumns = [
  EPricingTableColumn.Description,
  EPricingTableColumn.Sku,
  EPricingTableColumn.Discount,
  EPricingTableColumn.NetPrice,
];

export const alwaysEnabledColumns = [
  EPricingTableColumn.Name,
  EPricingTableColumn.Description,
  EPricingTableColumn.Sku,
  EPricingTableColumn.NetPrice,
];

export const defaultSummaryValues: PricingTableSummaryValues = {
  displayName: '',
  price: {
    displayName: '',
    value: '',
    enabled: false,
    flatFee: false,
  },
  discount: {
    displayName: '',
    value: '',
    enabled: false,
    flatFee: false,
  },
  tax: {
    displayName: '',
    value: '',
    enabled: false,
    flatFee: false,
  },
};
