export enum PricingTableColumn {
  Name = 'name',
  Description = 'description',
  Sku = 'sku',
  Price = 'price',
  Units = 'units',
  Tax = 'tax',
  TotalPrice = 'totalPrice',
  NetPrice = 'netPrice',
  Discount = 'discount',
}

export enum ValueAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ColumnMenuState {
  Root,
  VisibleColumns,
  CustomColumnSettings,
}

export enum NumberInputType {
  Currency,
  Percentage,
  Number,
  Text,
}

export enum CurrencyUnitDisplay {
  Short = 'short',
  Long = 'long',
  Narrow = 'narrow',
}

export enum CurrencyNotation {
  Standard = 'standard',
  Scientific = 'scientific',
  Engineering = 'engineering',
  Compact = 'compact',
}

export enum PricingTableCurrencyDisplay {
  Symbol = 'symbol',
  Code = 'code',
}

export enum SummaryCategory {
  Price = 'price',
  Discount = 'discount',
  Tax = 'tax',
}

export enum SummaryValue {
  Value = 'value',
  FlatFee = 'flatFee',
  DisplayName = 'displayName',
}

export enum CustomCurrency {
  Ore = 'ORE',
}
