import gql from 'graphql-tag';

export const editorViewDocumentListUserFragment = gql`
  fragment editorViewDocumentListUserFragment on User {
    id
    fullName
    thumbUrl
    title
    email
  }
`;

export const editorViewDocumentListRecipientFragment = gql`
  fragment editorViewDocumentListRecipientFragment on Recipient {
    id
    companyName
    fullName
    thumbUrl
    title
    email
    status
    role
    orderNum
  }
`;

export const editorViewDocumentFragment = gql`
  fragment editorViewDocumentFragment on DocumentDto {
    id
    name
    status
    isSigning
    isSelfsign
    companyName
    isSigningOrder
    expirationDate
    type
    user {
      ...editorViewDocumentListUserFragment
    }
    recipients {
      ...editorViewDocumentListRecipientFragment
    }
  }
  ${editorViewDocumentListUserFragment}
  ${editorViewDocumentListRecipientFragment}
`;
