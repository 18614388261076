import gql from 'graphql-tag';
import { editorViewDocumentFragment } from './editor-view.fragments';

export const getDocumentQuery = gql`
  query Document {
    document {
      ...editorViewDocumentFragment
    }
  }
  ${editorViewDocumentFragment}
`;

export const getSigningPageUrlQuery = gql`
  query SigningPageUrl($documentId: String!) {
    signingPageUrl(documentId: $documentId)
  }
`;
