import { StatusType } from '@getaccept/fried-tofu';
import { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import { DocumentStatus } from '@getaccept/lib-shared-new/src/enums/document-status';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { TeamStatus } from '@getaccept/lib-shared-new/src/enums/team-status';
import { capitalizeFirstLetter } from '@getaccept/lib-shared-new/src/helpers';
import { DsrStatus } from '@getaccept/lib-shared-new/src/enums/dsr-status';
import { DocumentFilter } from '../enums/document-filter';

export class StatusHelper {
  static getDocumentHasBadgeBorder(status: DocumentStatus): boolean {
    return [
      DocumentStatus.Signed,
      DocumentStatus.SignedWithoutVerification,
      DocumentStatus.Lost,
      DocumentStatus.Recalled,
    ].includes(status);
  }

  static getDocumentStatusType(status: DocumentStatus): StatusType {
    switch (status) {
      case DocumentStatus.Sent:
        return StatusType.Info;

      case DocumentStatus.Scheduled:
      case DocumentStatus.Sealed:
        return StatusType.LightBlue;

      case DocumentStatus.Hardbounced:
      case DocumentStatus.Rejected:
      case DocumentStatus.Recalled:
      case DocumentStatus.Lost:
      case DocumentStatus.Expired:
        return StatusType.Error;

      case DocumentStatus.SignedWithoutVerification:
      case DocumentStatus.Signed:
      case DocumentStatus.Approved:
        return StatusType.Success;

      case DocumentStatus.Viewed:
      case DocumentStatus.Reviewed:
        return StatusType.Branded;

      default:
        return StatusType.None;
    }
  }
  static getDocumentStatusTranslation(status: DocumentStatus) {
    return t(`document-status-${status}`);
  }

  static getDocumentFilterHasBadgeBorder(filter: DocumentFilter): boolean {
    return [DocumentFilter.Signed, DocumentFilter.Recalled].includes(filter);
  }
  static getDocumentFilter(filter: DocumentFilter): StatusType {
    switch (filter) {
      case DocumentFilter.Sent:
        return StatusType.Info;

      case DocumentFilter.Reviewed:
        return StatusType.Branded;

      case DocumentFilter.Signed:
        return StatusType.Success;

      case DocumentFilter.Expired:
      case DocumentFilter.Rejected:
      case DocumentFilter.Recalled:
        return StatusType.Error;

      default:
        return StatusType.None;
    }
  }

  static getRecipientStatusType(status: RecipientStatus): StatusType {
    switch (status) {
      case RecipientStatus.Sent:
        return StatusType.Info;
      case RecipientStatus.Rejected:
        return StatusType.Error;
      case RecipientStatus.Signed:
      case RecipientStatus.Approved:
        return StatusType.Success;
      case RecipientStatus.Viewed:
      case RecipientStatus.Reviewed:
        return StatusType.Branded;
      default:
        return StatusType.None;
    }
  }

  static getTeamStatusType(status: TeamStatus): StatusType {
    switch (status) {
      case TeamStatus.Active:
        return StatusType.Info;
      case TeamStatus.Pending:
      case TeamStatus.Inactive:
      default:
        return StatusType.None;
    }
  }

  static getRecipientStatusTranslation(status: RecipientStatus, usePrefix = false) {
    if (
      status === RecipientStatus.Hardbounced ||
      !Object.values(RecipientStatus).includes(status)
    ) {
      return t('recipient_status_not_opened');
    }

    return usePrefix ? t(`recipient-status-${status}`) : t(`${capitalizeFirstLetter(status)}`);
  }

  static getRecipientHasBadgeBorder(status: RecipientStatus) {
    return [RecipientStatus.Signed, RecipientStatus.Approved, RecipientStatus.Rejected].includes(
      status
    );
  }

  static getDsrStatusTranslation(status: DsrStatus): string {
    switch (status) {
      case DsrStatus.Draft:
        return t('draft');
      case DsrStatus.Unpublished:
        return t('unpublished');
      case DsrStatus.Published:
        return t('published');
      default:
        return '';
    }
  }

  static getDsrStatusType(status: DsrStatus): StatusType {
    switch (status) {
      case DsrStatus.Draft:
        return StatusType.Info;
      case DsrStatus.Unpublished:
        return StatusType.Error;
      case DsrStatus.Published:
        return StatusType.Success;
      default:
        return StatusType.None;
    }
  }
}
