<template>
  <div
    :class="[
      'row-container',
      blockWidthClass,
      {
        'contains-cover-image': containsCoverImage,
        'contains-cover-video': containsCoverVideo,
      },
    ]"
  >
    <DetachedCommentAnchor
      v-for="comment in commentsWithoutCell"
      :id="comment.id"
      :key="comment.id"
    />
    <EditorCell
      v-for="cell in cells"
      :key="cell.id"
      :context="context"
      :focused-required-id="focusedRequiredId"
      :nodes="cell.nodes"
      :cell-width="cell.width"
      :merge-values="mergeValues"
      :is-pdf-viewer="isPdfViewer"
      :block-width-class="blockWidthClass"
      :page-width="pageWidth"
      :is-block-toggled="isBlockToggled"
      :sender="sender"
      :pusher-client="pusherClient"
      :recipient="recipient"
      :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
      :recipient-input="recipientInput"
      :updating-inputs="updatingInputs"
      :partially-signed-fields="partiallySignedFields"
      :failed-input-updates="failedInputUpdates"
      :comments="filterCommentsByCell(comments, cell.id)"
      :selected-comment-id="selectedCommentId"
      :show-contextual-comments="showContextualComments"
      :create-contextual-comment-button-state="createContextualCommentButtonState"
      :current-user="currentUser"
      :linked-contracts="linkedContracts"
      @update-input-value="$emit('update-input-value', $event)"
      @track-video="$emit('track-video', $event)"
      @observe-node-visibility="$emit('observe-node-visibility', { ...$event, cellId: cell.id })"
      @partially-sign="$emit('partially-sign', $event)"
      @create-comment="$emit('create-comment', { ...$event, cellId: cell.id })"
      @select-comment="$emit('select-comment', $event)"
      @fetch-new-image="$emit('fetch-new-image', { ...$event, targetCellId: cell.id })"
    />
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import type { Cell, MergeValues, EBlockWidth, ContractListItem } from '@getaccept/editor-lib-new';
import { Context } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import type { PusherClient } from '@getaccept/pusher';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import EditorCell from './EditorCell.vue';

export default defineComponent({
  name: 'EditorRow',
  components: {
    EditorCell,
    DetachedCommentAnchor,
  },
  props: {
    cells: { type: Array as PropType<Cell[]>, default: () => [] },
    context: { type: String as PropType<Context>, default: () => Context.Document },
    mergeValues: {
      type: Object as PropType<MergeValues>,
      default: () => ({ document: {}, sender: {}, recipients: [], entity: {} }),
    },
    isPdfViewer: { type: Boolean },
    focusedRequiredId: { type: String, default: '' },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    containsCoverImage: { type: Boolean },
    containsCoverVideo: { type: Boolean },
    pageWidth: { type: Number, default: 0 },
    isBlockToggled: { type: Boolean },
    sender: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    recipient: {
      type: Object as PropType<Recipient>,
      default: () => ({}),
    },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    pusherClient: { type: Object as PropType<PusherClient>, default: undefined },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean, default: false },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    currentUser: { type: Object as PropType<User>, default: undefined },
    linkedContracts: { type: Array as PropType<ContractListItem[]>, default: () => [] },
    mappedPlaceholders: {
      type: Array as PropType<MappedContentPlaceholder[]>,
      default: () => [],
    },
    allowedToEditAfterPartiallySigned: { type: Boolean },
  },
  emits: [
    'update-input-value',
    'track-video',
    'observe-node-visibility',
    'partially-sign',
    'create-comment',
    'select-comment',
    'fetch-new-image',
  ],
  setup(props) {
    const cellIds = computed(() => props.cells.map(({ id }) => id));

    const commentsWithoutCell = computed(() =>
      props.comments.filter(comment => !cellIds.value.includes(comment.editorSelection?.cellId))
    );
    return { commentsWithoutCell, filterCommentsByCell: CommentsHelper.filterCommentsByCell };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-row';
</style>
