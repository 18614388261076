export enum SelectionType {
  Text = 'Text',
  Node = 'Node',
}

export interface ProseMirrorSelection {
  type: SelectionType;
  anchor: number;
  head?: number;
}

export interface CommentEditorSelection {
  editorBlockId: string;
  editorBlockVersion: string;
  sectionId?: string;
  rowId?: string;
  cellId?: string;
  nodeId?: string;
  textSelection?: ProseMirrorSelection;
  content?: string;
  detachedFromContent?: boolean;
  detachedFromVersion?: boolean;
}
