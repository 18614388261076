<template>
  <NodeViewWrapper
    v-tooltip="{
      ...toolTipOptions,
      content: tooltipContent,
    }"
    :class="[
      'merge-tag',
      `${mergeTagType}-tag`,
      {
        'is-selected': selected,
        'value-empty': isMergeValueEmpty,
        'placeholder-state': mergeValue() === mergeTagPlaceholder,
      },
    ]"
    :merge-key="mergeKeyAttribute"
    :category="categoryAttribute"
    :custom-name="customName"
    :tag-id="tagId"
    :data-external="`editor-merge-tag-${mergeTagType}-${mergeLabel}-id-${tagId}`"
    @dblclick="openMenu"
    @click="selectMergeTag"
  >
    <!--
  -->{{ mergeValue()
    }}<!--
  -->
  </NodeViewWrapper>
</template>
<script lang="ts">
import type { Transaction } from '@tiptap/pm/state';
import type { Node as ProsemirrorNode } from '@tiptap/pm/model';
import type { Editor } from '@tiptap/vue-3';
import { NodeViewWrapper } from '@tiptap/vue-3';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { PropType, Ref } from 'vue';
import { ref, defineComponent, watch, computed, onMounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { Participant } from '@getaccept/dsr-shared-new';
import { storeToRefs } from 'pinia';
import {
  MergeKey,
  FieldType,
  MergeCategory,
  ProsemirrorType,
  FieldInputType,
} from '../../types/enums';
import { ProsemirrorHelper } from '../../helpers/prosemirror.helper';
import { toolTipOptions } from '../../constants';
import type { Field } from '../../types';
import { DateHelper } from '../../../../lib-shared-new/src/helpers/date.helper';
import { EditorHelper } from '../../helpers/editor.helper';
import { useEditorFieldsStore } from '../../store/editor-fields.store';
import type { MergeTagOptions } from './merge-tag-node';

export default defineComponent({
  name: 'MergeTagView',
  components: {
    NodeViewWrapper,
  },
  props: {
    node: { type: Object as PropType<ProsemirrorNode>, required: true },
    selected: { type: Boolean, default: false },
    editor: { type: Object as PropType<Editor>, required: true },
    getPos: { type: Function as PropType<() => number>, required: true },
  },
  setup(props) {
    const { t } = useI18n();
    const mergeTagPlaceholder = '{{';
    const options: Ref<MergeTagOptions> = ref(null);
    const editorFieldsStore = useEditorFieldsStore();
    const { allFields, mergeValues, signatureFields, localFields } = storeToRefs(editorFieldsStore);

    const fields = computed(() => [
      ...localFields.value,
      ...allFields.value,
      ...signatureFields.value,
    ]);

    watch(
      () => props.editor.options.extensions,
      () => {
        const mergeTagExtension = props.editor.options.extensions.find(
          extension => extension.name === ProsemirrorType.MergeTag
        );

        if (!mergeTagExtension) {
          return;
        }

        options.value = mergeTagExtension.options;
      },
      { immediate: true }
    );

    const mergeKeyAttribute = computed(() => props.node.attrs.mergeKey as string);
    const categoryAttribute = computed(() => props.node.attrs.category as string);
    const tagId = computed(() => props.node.attrs.tagId as string);
    const customName = computed(() => props.node.attrs.customName as string);
    const field = computed(() => fields.value?.find(({ id }: Field) => id === tagId.value));
    const inputFieldType = computed(
      () =>
        allFields.value.find(({ inputSettings }: Field) => inputSettings !== null)?.inputSettings
          ?.type
    );
    const documentValues = computed(() => mergeValues.value?.document);
    const sender = computed(() => mergeValues.value?.sender);
    const entity = computed(() => mergeValues.value?.entity);
    const recipient = computed(() =>
      mergeValues.value?.recipients?.find(
        recipient =>
          recipientId.value === recipient.id ||
          participantId.value === recipient.id ||
          (options.value?.isDsr &&
            EditorHelper.isAssignedToParticipant(recipient as Participant, field.value))
      )
    );
    const category = computed(() => field.value?.category);
    const fieldType = computed(() => field.value?.type);
    const mergeKey = computed(() => field.value?.mergeKey);
    const recipientId = computed(() => field.value?.recipientId);
    const participantId = computed(() => field.value?.participantId);
    const mergeLabel = computed(() => String(customName.value || mergeKey.value).toLowerCase());
    const mergeTagType = computed(() =>
      String(fieldType.value === FieldType.Custom ? FieldType.Custom : category.value).toLowerCase()
    );

    const senderMergeKey = computed(() => {
      if (mergeKey.value === MergeKey.mobile) {
        return sender.value?.[mergeKey.value] ? mergeKey.value : MergeKey.phone;
      }
      return mergeKey.value;
    });

    const recipientMergeKey = computed(() => {
      if (mergeKey.value === MergeKey.mobile) {
        return recipient.value?.[mergeKey.value] ? mergeKey.value : MergeKey.phone;
      }
      return mergeKey.value;
    });

    const mergeValue = (): string => {
      if (fieldType.value === FieldType.Custom) {
        return field.value.value || field.value.customName;
      }

      switch (category.value || fieldType.value) {
        case MergeCategory.Recipient:
          return recipientValue.value;

        case MergeCategory.Entity:
          return entity.value?.[mergeKey.value] || `${category.value}.${mergeKey.value}`;

        case MergeCategory.Sender:
          return sender.value?.[senderMergeKey.value] || `${category.value}.${mergeKey.value}`;

        case MergeCategory.Document:
          return (
            documentValue.value ||
            `${options.value?.isDsr ? t('room') : category.value}.${mergeKey.value}`
          );

        default:
          return mergeTagPlaceholder;
      }
    };

    const documentValue = computed(() => {
      const value: string = documentValues.value?.[mergeKey.value];
      const isDateFieldInputType = inputFieldType.value === FieldInputType.Date;

      if (!value) {
        return '';
      }

      if (mergeKey.value === MergeKey.sendDate || mergeKey.value === MergeKey.expirationDate) {
        const date = DateTime.fromISO(value);
        return isDateFieldInputType
          ? DateHelper.mediumDateLocale(date)
          : DateHelper.mediumDateTime(date);
      }

      return value;
    });

    const recipientValue = computed(
      () =>
        recipientNotFoundOrRole.value ||
        recipient.value?.[recipientMergeKey.value] ||
        `${recipient.value.firstName + recipient.value.lastName}.${mergeKey.value}`
    );

    const recipientNotFoundOrRole = computed(() => {
      if (!recipient.value) {
        return options.value?.isDsr
          ? t('missing_participant').toString()
          : t('missing-recipient').toString();
      }

      if (!!recipient.value && !recipient.value.email && !(recipient.value as Recipient)?.mobile) {
        return `${(recipient.value as Recipient).roleName}.${mergeKey.value}`;
      }

      return false;
    });

    const recipientTooltip = computed(
      () =>
        recipientNotFoundOrRole.value ||
        `${recipient.value.firstName + recipient.value.lastName}.${mergeKey.value}`
    );

    const tooltipContent = computed(() => {
      if (fieldType.value === FieldType.Custom && field.value.value) {
        return field.value.customName;
      }

      if (!category.value && fieldType.value !== FieldType.Custom) {
        return mergeKey.value;
      }

      if (category.value === MergeCategory.Recipient && !recipient.value) {
        return options.value?.isDsr
          ? t('you_need_to_connect_a_new_participant').toString()
          : t('you-need-to-connect-a-new-recipient').toString();
      }

      if (isMergeValueEmpty.value) {
        return `${t('value-missing').toString()} ${mergeValue()}`;
      }

      if (category.value === MergeCategory.Recipient) {
        return recipientTooltip.value;
      }

      return `${category.value}.${mergeKey.value}`;
    });

    const isRoleInTemplate = computed(
      () =>
        options.value?.isTemplate &&
        category.value === MergeCategory.Recipient &&
        !!recipient.value &&
        !recipient.value.email &&
        !(recipient.value as User).mobile
    );

    const isMergeValueEmpty = computed(() => {
      if (fieldType.value === FieldType.Custom) {
        return !field.value.value;
      }

      if (mergeKey.value === MergeKey.sendDate || isRoleInTemplate.value) {
        return false;
      }

      if (category.value === MergeCategory.Recipient) {
        return !recipient.value || !recipient.value?.email || !recipient.value?.[mergeKey.value];
      }

      if (options.value?.isDsr && category.value === MergeCategory.Document) {
        return `${t('room')}.${mergeKey.value}`;
      }

      return mergeValue() === `${category.value}.${mergeKey.value}`;
    });

    onMounted(async () => {
      if (field.value) {
        return;
      }

      await nextTick();
      openMenu();
    });

    const selectMergeTag = () => {
      const selection: Transaction = ProsemirrorHelper.selectNode(
        props.editor.state.tr,
        props.getPos()
      );
      props.editor.view.dispatch(selection);
    };

    const openMenu = () => {
      if (!tagId.value) {
        selectMergeTag();
      }
      options.value.onOpenMenu();
    };

    return {
      toolTipOptions,
      tooltipContent,
      mergeKeyAttribute,
      categoryAttribute,
      mergeLabel,
      mergeTagType,
      openMenu,
      selectMergeTag,
      isMergeValueEmpty,
      mergeValue,
      mergeTagPlaceholder,
      tagId,
      customName,
      options,
      field,
      documentValues,
      sender,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/merge-tag-colors';
/* stylelint-disable no-descending-specificity */

.merge-tag {
  display: inline;
  white-space: initial;
  word-break: break-word;
  background-image: linear-gradient(to bottom, $document-91 0%, $document-91 100%);
  background-size: 100% calc(100% - 0.25rem);
  background-position: center;
  background-repeat: no-repeat;
  color: $ga-light-black;

  &.sender-tag,
  &.entity-tag {
    background-image: linear-gradient(to bottom, $sender-92 0%, $sender-92 100%);

    &.is-selected {
      background-image: linear-gradient(to bottom, $sender-82 0%, $sender-82 100%);
    }
  }

  &.recipient-tag {
    background-image: linear-gradient(to bottom, $recipient-90 0%, $recipient-90 100%);

    &.value-empty {
      background-image: linear-gradient(
        to bottom,
        rgba($recipient-90, 0.5) 0%,
        rgba($recipient-90, 0.5) 100%
      );
    }

    &.is-selected {
      background-image: linear-gradient(to bottom, $recipient-80 0%, $recipient-80 100%);
    }
  }

  &.custom-tag {
    background-image: linear-gradient(to bottom, var(--blue-93) 0%, var(--blue-93) 100%);

    &.is-selected {
      background-image: linear-gradient(to bottom, var(--blue-86) 0%, var(--blue-86) 100%);
    }
  }

  &:hover {
    cursor: pointer;
  }

  &.is-selected {
    background-image: linear-gradient(to bottom, $document-81 0%, $document-81 100%);
  }
}

h1 .merge-tag {
  border-radius: $editor-h1-merge-tag-border-radius;
  padding: 0 $editor-h1-merge-tag-padding;
  background-size: 100% calc(100% - 0.325rem);
}

h2 .merge-tag {
  border-radius: $editor-h2-merge-tag-border-radius;
  padding: 0 $editor-h2-merge-tag-padding;
  background-size: 100% calc(100% - 0.1875rem);
}

h3 .merge-tag {
  border-radius: $editor-h3-merge-tag-border-radius;
  padding: 0 $editor-h3-merge-tag-padding;
  background-size: 100% calc(100% - 0.1875rem);
}

small .merge-tag {
  border-radius: $editor-label-merge-tag-border-radius;
  padding: 0 $editor-label-merge-tag-padding;
  background-size: 100% calc(100% - 0.125rem);
}

p .merge-tag {
  border-radius: $editor-normal-merge-tag-border-radius;
  padding: 0 $editor-normal-merge-tag-padding;
  background-size: 100% 100%;
}

h1 .merge-tag,
h2 .merge-tag,
h3 .merge-tag,
small .merge-tag,
p .merge-tag {
  &.placeholder-state {
    background: none;
    padding: 0;
  }
}
</style>
