<template>
  <div :class="['counter', { 'length-warning': warning, visible: visible }]">
    {{ counterText }}
  </div>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  props: {
    text: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: undefined,
    },
    suffix: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const visible = computed(() => !!props.text && !!props.maxLength);
    const warning = computed(() => visible.value && props.text.length > props.maxLength);
    const counterText = computed(() =>
      visible.value ? `${props.text.length} / ${props.maxLength} ${props.suffix || ''}` : `0 / 0`
    );

    return {
      visible,
      warning,
      counterText,
    };
  },
});
</script>
<style lang="scss" scoped>
.counter {
  align-self: center;
  padding-right: var(--spacing-50);
  color: var(--text-gray);
  visibility: hidden;

  &.length-warning {
    color: var(--error-dark);
  }

  &.visible {
    visibility: visible;
  }
}
</style>
