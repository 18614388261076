import { ProsemirrorType } from '../types/enums';

export const fallbackTextContent: string = JSON.stringify({
  type: ProsemirrorType.Doc,
  content: [
    {
      type: ProsemirrorType.Heading,
    },
    {
      type: ProsemirrorType.Paragraph,
    },
  ],
});
