export enum DocumentStatus {
  Archived = 'archived',
  Draft = 'draft',
  Hardbounced = 'hardbounced',
  Importing = 'importing',
  Lost = 'lost',
  Processing = 'processing',
  Recalled = 'recalled',
  Rejected = 'rejected',
  Reviewed = 'reviewed',
  Scheduled = 'scheduled',
  Sealed = 'sealed',
  Sent = 'sent',
  Signed = 'signed',
  SignedWithoutVerification = 'signedwithoutverification',
  Viewed = 'viewed',
  // DSR specific statuses
  Published = 'published',
  // Legacy statuses that do not exist on the BE anymore; check what to replace them with
  Approved = 'approved',
  Expired = 'expired',
  Delivered = 'delivered',
  PartlySigned = 'partly-signed',
  Unpublished = 'unpublished',
}
