import type {
  Comment,
  Reply,
} from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import { CommentStatus } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment-status';
import type { CommentType } from '@getaccept/editor-lib-new/src/store/base-comments.store';
import type { Recipient } from '../../types/Recipient';
import type { User } from '../../users/types/user';
import type { CommentParticipant } from '../types/comment-participant';
import type { CommentParticipantMetaData } from '../types/comment-participant-meta-data';
import type { CommentReadEvent } from '../types/comment-read-event';
import type { CurrentUser } from '../types/current-user';

export class CommentsHelper {
  static addReply(reply: Reply, commentId: string, comments: Comment[]) {
    return comments.map((comment: Comment) => {
      if (comment.id !== commentId) {
        return comment;
      }
      if (comment.replies?.length) {
        return { ...comment, replies: [...comment.replies, reply] };
      }
      return { ...comment, replies: [reply] };
    });
  }

  static addRead(readEvent: CommentReadEvent, commentId: string, comments: Comment[]) {
    return comments.map(comment =>
      comment.id === commentId ? { ...comment, reads: [...comment.reads, readEvent] } : comment
    );
  }

  static addReplyRead(
    readEvent: CommentReadEvent,
    replyId: string,
    commentId: string,
    comments: Comment[]
  ) {
    return comments.map(comment =>
      comment.id === commentId
        ? {
            ...comment,
            replies: comment.replies.map(reply =>
              reply.id === replyId ? { ...reply, reads: [...reply.reads, readEvent] } : reply
            ),
          }
        : comment
    );
  }

  static isGhostComment(comment: Comment) {
    return (
      comment.editorSelection?.detachedFromContent || comment.editorSelection?.detachedFromVersion
    );
  }

  static isReadByUser(reads: CommentReadEvent[], userId: string) {
    return reads.some(read => read.participant.userId === userId);
  }

  static isReadByRecipient(reads: CommentReadEvent[], recipientId: string) {
    return reads.some(read => read.participant.recipientId === recipientId);
  }

  static isViewableComment(
    comment: Comment,
    currentUserId: string,
    validStatuses: CommentStatus[]
  ) {
    const { editorSelection, status, createdBy } = comment;
    const statuses = [...validStatuses];

    if ((createdBy?.userId || createdBy?.recipientId) === currentUserId) {
      statuses.push(CommentStatus.Draft);
    }

    return !!editorSelection && statuses.includes(status);
  }

  static findComment(comments: CommentType[], commentId: string) {
    return comments.find(({ id }) => id === commentId);
  }

  static commentExists(comments: Comment[], commentId: string) {
    return comments.some(({ id }) => id === commentId);
  }

  static findReply(comments: Comment[], commentId: string, replyId: string) {
    const comment = CommentsHelper.findComment(comments, commentId) as Comment;
    return comment ? comment.replies.find(({ id }) => id === replyId) : undefined;
  }

  private static getUnreadCommentsByUser(comments: Comment[], userId: string) {
    return comments.filter(({ reads }) => !CommentsHelper.isReadByUser(reads, userId));
  }

  static getUnreadCommentsByRecipient(comments: Comment[], recipientId: string) {
    return comments.filter(({ reads }) => !CommentsHelper.isReadByRecipient(reads, recipientId));
  }

  static getUnreadRepliesByUser(replies: Reply[], userId: string) {
    return replies.filter(({ reads }) => !CommentsHelper.isReadByUser(reads, userId));
  }

  static getUnreadRepliesByRecipient(replies: Reply[], recipientId: string) {
    return replies.filter(({ reads }) => !CommentsHelper.isReadByRecipient(reads, recipientId));
  }

  static hasUnreadComments(
    comments: Comment[],
    { userId, recipientId }: CommentParticipant
  ): boolean {
    if (userId) {
      const unreadCommentsCount = CommentsHelper.getUnreadCommentsByUser(comments, userId).length;
      const unreadRepliesCount = comments.flatMap(({ replies }) =>
        CommentsHelper.getUnreadRepliesByUser(replies, userId)
      ).length;

      return unreadCommentsCount + unreadRepliesCount > 0;
    }

    if (recipientId) {
      const unreadCommentsCount = CommentsHelper.getUnreadCommentsByRecipient(
        comments,
        recipientId
      ).length;
      const unreadRepliesCount = comments.flatMap(({ replies }) =>
        CommentsHelper.getUnreadRepliesByRecipient(replies, recipientId)
      ).length;

      return unreadCommentsCount + unreadRepliesCount > 0;
    }

    return false;
  }

  static sortByCreatedAt(comments: Comment[], ascending: boolean) {
    return [...comments].sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return ascending ? -1 : 1;
      }
      if (a.createdAt > b.createdAt) {
        return ascending ? 1 : -1;
      }

      return 0;
    });
  }

  static commentAlreadyUpdated(
    comments: CommentType[],
    commentId: string,
    data: Partial<CommentType>
  ): boolean {
    const affectedComment = this.findComment(comments, commentId);
    if (!affectedComment) {
      return false;
    }
    return Object.keys(data).every((key: string) => affectedComment[key] === data[key]);
  }

  static filterCommentsBySection(
    comments: Array<CommentType>,
    sectionId: string
  ): Array<CommentType> {
    return comments.filter(comment => comment.editorSelection?.sectionId === sectionId);
  }

  static filterCommentsByRow(comments: Array<CommentType>, rowId: string) {
    return comments.filter(comment => comment.editorSelection?.rowId === rowId);
  }

  static filterCommentsByCell(comments: Array<CommentType>, cellId: string) {
    return comments.filter(comment => comment.editorSelection?.cellId === cellId);
  }

  static filterCommentsByNode(comments: Array<CommentType>, nodeId: string) {
    return comments.filter(comment => comment.editorSelection?.nodeId === nodeId);
  }

  static isCommentRead(comment: Comment, participant: CommentParticipant | CurrentUser) {
    if (comment.status === CommentStatus.Resolved || comment.status === CommentStatus.Draft) {
      return true;
    }
    return this.isRead(comment.reads, participant);
  }

  static isRead(reads: CommentReadEvent[], participant: CommentParticipant | CurrentUser) {
    if (participant.userId) {
      return CommentsHelper.isReadByUser(reads, participant.userId);
    }
    return CommentsHelper.isReadByRecipient(reads, participant.recipientId);
  }

  static isCommentVisible(comment: CommentType, defaultVisibility = true) {
    if (!comment.status || comment.status === CommentStatus.Resolved) {
      return false;
    }
    return defaultVisibility;
  }

  static isDraft(comment: Comment) {
    return comment.status === CommentStatus.Draft;
  }

  static hasContent(comment: Comment) {
    return !!comment.editorSelection?.content;
  }

  static isCommentTemporary(comments: Comment[], commentId: string): boolean {
    return !!comments.find((comment: Comment) => comment.id === commentId)?.isTemporary;
  }

  static getDecorationClass(
    status: CommentStatus,
    isSelected: boolean,
    isHovered: boolean,
    isVisible: boolean
  ) {
    if (!isVisible || status === CommentStatus.Resolved) {
      return 'decoration-invisible';
    }
    if (isHovered) {
      return 'decoration-hovered';
    }
    if (isSelected) {
      return 'decoration-selected';
    }
    if (status === CommentStatus.Active) {
      return 'decoration-active';
    }
    if (status === CommentStatus.Draft) {
      return 'decoration-draft';
    }
  }

  static hasNoOverlappingComments(comments: Array<CommentType>, from: number) {
    if (!comments?.length) {
      return true;
    }
    return (
      comments.filter(
        comment =>
          comment.editorSelection?.textSelection?.anchor <= from &&
          comment.editorSelection?.textSelection?.head >= from
      ).length < 2
    );
  }

  static getNextCommentIdToBeSelected(
    comments: Array<CommentType>,
    selectedCommentId: string,
    from: number
  ) {
    const intersectingComments = comments
      .filter(
        comment =>
          comment.editorSelection?.textSelection?.anchor <= from &&
          comment.editorSelection?.textSelection?.head >= from
      )
      .sort(
        (a, b) => a.editorSelection.textSelection.anchor - b.editorSelection.textSelection.anchor
      );

    const selectedCommentIdIndex = intersectingComments.findIndex(
      comment => comment.id === selectedCommentId
    );
    if (selectedCommentIdIndex === intersectingComments.length - 1) {
      return intersectingComments[0].id;
    }
    return intersectingComments[selectedCommentIdIndex + 1].id;
  }

  static getUserMetaData(
    participant: CommentParticipant,
    recipients: Recipient[],
    sender: User
  ): CommentParticipantMetaData {
    if (participant?.recipientId) {
      const recipient = recipients.find(({ id }) => id === participant.recipientId);
      return {
        name: recipient?.fullName || recipient?.email || '',
        thumbUrl: recipient?.thumbUrl || '',
      };
    }

    if (participant?.userId) {
      const { fullName, email } = sender as User;
      return {
        name: fullName || email,
        thumbUrl: (sender as User).thumbUrl || '',
      };
    }

    return { name: '', thumbUrl: '' };
  }
}
