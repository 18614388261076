<template>
  <div v-click-outside="close" class="dropdown-items-wrapper">
    <div v-if="header" class="header">
      {{ header }}
    </div>
    <slot></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DropdownItemsWrapper',
  props: {
    header: { type: String, default: '' },
  },
  emits: ['close'],
  setup(_, { emit }) {
    const close = () => {
      emit('close');
    };

    return { close };
  },
});
</script>
<style lang="scss" scoped>
.dropdown-items-wrapper {
  z-index: 7;
  display: flex;
  flex-direction: column;
  border-radius: var(--radius-medium);
  padding: var(--spacing-50);
  background: var(--white);
  box-shadow: var(--shadow-3);
  width: 15rem;
  position: relative;

  .header {
    color: var(--text-gray);
    font-size: var(--label-small-font-size);
    margin: var(--spacing-50);
  }
}
</style>
