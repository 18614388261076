<template>
  <FriedModalSubContainer class="verify-signature-container">
    <template #header>
      <FriedModalHeader v-if="!isSmallHeightMode" :header="t('signing')" />
    </template>
    <div
      v-show="!!entity.isSigningWrite"
      :class="['verify-signature-toggler', { disabled: loading }]"
    >
      <FriedTab
        v-for="tab in tabs"
        :key="tab.key"
        :value="tab.key"
        :model-value="activeTab"
        @update:model-value="toggleSignatureType"
      >
        {{ tab.label }}
      </FriedTab>
    </div>
    <div class="signature-description">
      <FriedParagraph>
        {{ t('please_put_your_signature_in_the_box') }}
      </FriedParagraph>
      <FriedParagraph>
        {{ signingInfoText }}
      </FriedParagraph>
    </div>
    <div
      :class="['signature-container', { disabled: loading, 'space-top': !entity.isSigningWrite }]"
      data-external="signature-draw-container"
    >
      <WriteSignature
        v-if="!drawSignatureIsActive && !!entity.isSigningWrite"
        :assignee="recipient"
        @signature="handleWriteSignature"
      />
      <SignaturePad
        v-else
        :class="['signature-pad', { 'small-height': isSmallHeightMode }]"
        @signature="handleDrawSignature"
        @cancel="handleCancel"
        @sign="handleSign"
        @reset="handleReset"
      />
    </div>
    <template #footer>
      <FriedModalButtonsFooter class="action-buttons">
        <FriedButton
          class="action-button"
          data-external="verify-signing-modal-cancel-doc-btn"
          :button-type="ButtonType.Secondary"
          @click="handleCancel"
          >{{ t('cancel') }}
        </FriedButton>
        <FriedButton
          id="sign"
          data-external="verify-signing-modal-sign-doc-btn"
          class="action-button"
          :loading="loading"
          :button-type="ButtonType.Branding"
          :disabled="!signatureExists"
          @click="handleSign"
        >
          {{ inEditor ? t('sign') : t('next') }}
        </FriedButton>
      </FriedModalButtonsFooter>
    </template>
  </FriedModalSubContainer>
</template>

<script lang="ts">
import { ButtonType } from '@getaccept/fried-tofu';
import type { Entity } from '@getaccept/lib-shared-new/src/entity/signing-site/types/entity';
import { throttle } from '@getaccept/lib-shared-new/src/helpers';
import { useI18n } from 'vue-i18n';
import type { SigningPageRecipient } from '@getaccept/lib-shared-new/src/types/signing-page-recipient';
import type { PropType } from 'vue';
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue';
import SignaturePad from '../draw/components/SignaturePad.vue';
import WriteSignature from './WriteSignature.vue';

export default defineComponent({
  components: {
    SignaturePad,
    WriteSignature,
  },
  props: {
    recipient: {
      type: Object as PropType<SigningPageRecipient>,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
    entity: {
      type: Object as PropType<Entity>,
      default: () => ({}),
    },
    documentName: {
      type: String,
      default: '',
    },
    inEditor: {
      type: Boolean,
    },
  },
  emits: ['cancel', 'sign'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const drawSignatureIsActive = ref(true);

    const writeSignatureData = ref({
      signatureBase30: '',
      signatureBase64: '',
    });

    const drawSignatureData = ref({
      signatureBase30: '',
      signatureBase64: '',
    });

    const isSmallHeightMode = ref(false);

    const tabs = computed(() => [
      {
        label: t('draw'),
        key: 'draw',
        isActive: drawSignatureIsActive.value,
      },
      {
        label: t('type'),
        key: 'type',
        isActive: !drawSignatureIsActive.value,
      },
    ]);

    const activeTab = computed(() => (drawSignatureIsActive.value ? 'draw' : 'type'));

    const signingInfoText = computed(
      () =>
        `${t('signature_by_hand_or_typing_v2', {
          recipientName: props.recipient.fullName,
          documentName: props.documentName,
        })}`
    );

    const signatureExists = computed(() => {
      const typeSignatureExist =
        writeSignatureData.value.signatureBase30 &&
        writeSignatureData.value.signatureBase64 &&
        !drawSignatureIsActive.value;

      const drawSignatureExist =
        drawSignatureData.value.signatureBase30 &&
        drawSignatureData.value.signatureBase64 &&
        drawSignatureIsActive.value;

      return !!(typeSignatureExist || drawSignatureExist);
    });

    const toggleSignatureType = () => {
      drawSignatureIsActive.value = !drawSignatureIsActive.value;
    };

    const handleCancel = () => {
      emit('cancel');
    };

    const handleSign = () => {
      if (drawSignatureIsActive.value) {
        emit('sign', drawSignatureData.value);
      } else {
        emit('sign', writeSignatureData.value);
      }
    };

    const handleReset = () => {
      drawSignatureData.value = {
        signatureBase30: '',
        signatureBase64: '',
      };
    };

    const handleDrawSignature = ({ signatureBase30, signatureBase64 }) => {
      drawSignatureData.value = {
        signatureBase30,
        signatureBase64,
      };
    };

    const handleWriteSignature = (signature: {
      signatureBase30: string;
      signatureBase64: string;
    }) => {
      if (signature.signatureBase30 && signature.signatureBase64) {
        writeSignatureData.value = signature;
      } else {
        writeSignatureData.value = {
          signatureBase30: '',
          signatureBase64: '',
        };
      }
    };

    const setScreenSize = () => {
      isSmallHeight();
    };

    const isSmallHeight = () => {
      isSmallHeightMode.value = window.innerWidth > window.innerHeight && window.innerHeight < 600;
    };

    const created = () => {
      window.addEventListener('resize', throttle(setScreenSize, 300));
      setScreenSize();
    };

    created();

    onBeforeUnmount(() => {
      window.removeEventListener('resize', setScreenSize);
    });

    return {
      t,
      ButtonType,
      tabs,
      drawSignatureIsActive,
      signingInfoText,
      signatureExists,
      handleCancel,
      handleSign,
      handleReset,
      handleDrawSignature,
      handleWriteSignature,
      toggleSignatureType,
      isSmallHeightMode,
      setScreenSize,
      isSmallHeight,
      drawSignatureData,
      writeSignatureData,
      activeTab,
    };
  },
});
</script>
<style lang="scss" scoped>
.verify-signature-toggler {
  margin: var(--spacing-100) 0;
  display: flex;
  width: 100%;

  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  .verify-signature-button {
    border-bottom: 3px solid transparent;
    width: 5rem;
    text-align: center;
    padding: var(--spacing-50);
    margin: 0 var(--spacing-50);
    cursor: pointer;

    &.active {
      border-bottom: 3px solid var(--brand-orange);
    }
  }
}

.verify-signature-container.modal-sub-container {
  @media screen and (min-width: $sm), (max-height: $sm) and (orientation: landscape) {
    height: auto;
  }
}

.verify-signature-tabs {
  display: flex;
  justify-content: center;
}

.signature-pad {
  height: 20rem;

  @media screen and (max-width: $sm) {
    height: 14rem;
  }

  &.small-height {
    @media screen and (max-width: $xs) {
      height: 14rem;
    }
  }
}

.signature-description {
  p {
    margin-bottom: var(--spacing-100);
  }
}

.signature-container {
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  &.space-top {
    margin-top: var(--spacing-150);
  }
}

.rotate-phone-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30%;
}
</style>
