<template>
  <div :class="['image-with-transforms-container', blockWidthClass]">
    <div class="position-anchor">
      <div ref="containerRef" class="image-container">
        <div ref="insideRef" class="image-inside"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ImageTransformHelper } from '@getaccept/editor-lib-new';
import type { ImageNode, EBlockWidth } from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { nextTick, defineComponent, computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';

export default defineComponent({
  name: 'ImageWithTransforms',
  props: {
    image: { type: Object as PropType<ImageNode>, default: () => ({}) },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    srcUrl: { type: String, default: null },
  },
  emits: ['image-load-error', 'image-loaded'],
  setup(props, { emit }) {
    const imageLoadElement: Ref<HTMLImageElement> = ref(null);
    const insideRef: Ref<HTMLDivElement> = ref(null);
    const containerRef: Ref<HTMLDivElement> = ref(null);

    const scale = computed(
      () => props.image.imageTransforms?.scale || ImageTransformHelper.SCALE_MIN_VALUE
    );
    const ratio = computed(() => scale.value / 100);

    const translateX = computed(() => props.image.imageTransforms?.translateX || 0);
    const translateY = computed(() => props.image.imageTransforms?.translateY || 0);

    const resetImage = async () => {
      await nextTick();
      if (!insideRef.value) {
        return;
      }
      insideRef.value.style.backgroundImage = 'url()';
    };

    const setImageSize = async () => {
      await nextTick();
      if (!containerRef.value || !imageLoadElement.value) {
        return;
      }

      const { clientWidth: areaWidth, clientHeight: areaHeight } = containerRef.value;
      const { naturalWidth, naturalHeight } = imageLoadElement.value;

      if (!naturalWidth || !areaWidth) {
        return;
      }

      const [initialWidth, initialHeight] = ImageTransformHelper.getInitialImageSize(
        naturalWidth,
        naturalHeight,
        areaWidth,
        areaHeight
      );

      const transformOrigin = ImageTransformHelper.getTransformOrigin(
        initialHeight * ratio.value,
        initialWidth * ratio.value,
        areaHeight,
        areaWidth
      );

      insideRef.value.style.transformOrigin = transformOrigin;
      insideRef.value.style.width = `${initialWidth}px`;
      insideRef.value.style.height = `${initialHeight}px`;
    };

    const handleImageLoaded = async () => {
      await nextTick();
      if (!insideRef.value) {
        return;
      }

      insideRef.value.style.backgroundImage = `url(${imageLoadElement.value.src})`;
      setImageSize();
      emit('image-loaded');
    };

    const handleImageError = () => {
      emit('image-load-error');
    };

    const setImageData = () => {
      imageLoadElement.value = new Image();
      imageLoadElement.value.onload = handleImageLoaded;
      imageLoadElement.value.onerror = handleImageError;
      imageLoadElement.value.src = props.srcUrl;

      if (!props.srcUrl) {
        resetImage();
      }
    };

    watch(
      () => props.image.imageTransforms,
      async () => {
        await nextTick();
        if (!insideRef.value) {
          return;
        }

        const translateXCSS = `translateX(${translateX.value / ratio.value}%)`;
        const translateYCSS = `translateY(${translateY.value / ratio.value}%)`;

        insideRef.value.style.transform = `scale(${ratio.value}) ${translateXCSS} ${translateYCSS} translateZ(0)`;
      },
      { immediate: true }
    );

    watch(
      () => props.srcUrl,
      () => {
        setImageData();
      }
    );

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (!entry.target.clientWidth) {
          return;
        }
        setImageSize();
      });
    });

    onMounted(() => {
      resizeObserver.observe(containerRef.value);
      if (props.srcUrl) {
        setImageData();
      }
    });

    onBeforeUnmount(() => {
      resizeObserver.disconnect();
    });

    return {
      insideRef,
      containerRef,
      resetImage,
      handleImageLoaded,
      handleImageError,
      setImageData,
      imageLoadElement,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/image-with-transforms';

.image-with-transforms-container,
.image-inside {
  cursor: inherit;
}
</style>
