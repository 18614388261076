import OrderedList from '@tiptap/extension-ordered-list';

export const OrderedListNode = OrderedList.extend({
  name: 'ordered_list',

  content() {
    return 'list_item+';
  },

  addCommands() {
    return {};
  },

  addKeyboardShortcuts() {
    return {};
  },

  addInputRules() {
    return [];
  },
});
