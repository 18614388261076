/* eslint-disable sonarjs/cognitive-complexity */
import type { EditorBlock, EditorContent, LoadImagePayload } from '../types';

export const setImageSrcURLInContent = (
  content: EditorContent,
  payload: LoadImagePayload & { srcUrl: string; externalId: string; blurhash: string }
) => ({
  ...content,
  sections: content.sections.map(section =>
    section.id !== payload.targetSectionId
      ? section
      : {
          ...section,
          rows: section.rows.map(row =>
            row.id !== payload.targetRowId
              ? row
              : {
                  ...row,
                  cells: row.cells.map(cell =>
                    cell.id !== payload.targetCellId
                      ? cell
                      : {
                          ...cell,
                          nodes: cell.nodes.map(node =>
                            node.id !== payload.targetNodeId
                              ? node
                              : {
                                  ...node,
                                  srcUrl: payload.srcUrl,
                                  externalId: payload.externalId,
                                  blurhash: payload.blurhash,
                                }
                          ),
                        }
                  ),
                }
          ),
        }
  ),
});

export const setImageSrcURL = (
  document: {
    pages: {
      editorBlock?: EditorBlock;
    }[];
  },
  payload: LoadImagePayload & { srcUrl: string; externalId: string; blurhash: string }
) => ({
  ...document,
  pages: document.pages.map(page => ({
    ...page,
    editorBlock: !page.editorBlock
      ? undefined
      : {
          ...page.editorBlock,
          content:
            page.editorBlock.contentId !== payload.contentId
              ? page.editorBlock.content
              : setImageSrcURLInContent(page.editorBlock.content, payload),
        },
  })),
});
