<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'contract-link-container',
      rowTypeClass,
      blockWidthClass,
      {
        'parent-first-child': parentFirstChild,
        'parent-last-child': parentLastChild,
      },
    ]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <template v-if="showContractDetails && contractLoading">
      <div class="editor-node-loader">
        <FriedSpinner :size="48" />
        <FriedH3>{{ loadingContractText }}</FriedH3>
      </div>
    </template>
    <ContractLinkHeader
      v-if="node.contractId"
      class="view"
      :status="contractStatus"
      :expired="contractExpired"
      :block-width-class="blockWidthClass"
    >
      <template #title>
        <FriedH3 class="contract-title paragraph-large viewer">{{ node.name }}</FriedH3>
      </template>
    </ContractLinkHeader>
    <template v-if="showContractDetails && node.contractId">
      <div :class="['contract-link-content', blockWidthClass]">
        <DescriptionInput
          v-if="showContractDescription"
          class="description view"
          :content="contractDescription"
          :fields="node.fields"
          :merge-values="mergeValues"
          :default-align="NodeAlign.Left"
          data-external="editor-view-contract-link-contract-description-input"
        />
        <div v-if="hasContractDetails" class="signer-details-container">
          <SignerDetails
            v-if="contract.isSelfsign"
            :key="contractSender.id"
            :contract-display-settings="node.contractDisplaySettings"
            :role="$t('sender')"
            :title="contractSender.title"
            :full-name="contractSender.fullName"
            :company-name="senderCompanyName"
            :email="contractSender.email"
            :status="RecipientStatus.Signed"
            :contract-status="contract.status"
            :block-width-class="blockWidthClass"
          />
          <template v-if="filteredRecipients.length">
            <SignerDetails
              v-for="(signer, index) in filteredRecipients"
              :key="signer.id"
              :is-signing-order="contract.isSigningOrder"
              :signing-page-url="getSigningPageUrlForRecipient(signer.id)"
              :contract-display-settings="node.contractDisplaySettings"
              :role="translatedRole(signer.role)"
              :title="signer.title"
              :prev-signer="getPreviousSigner(index)"
              :full-name="signer.fullName"
              :company-name="signer.companyName"
              :email="signer.email"
              :status="signer.status"
              :order-num="signer.orderNum"
              :block-width-class="blockWidthClass"
              :contract-expired="contractExpired"
            />
          </template>
        </div>
        <ContractLinkNoAccess
          v-else
          :description="t('dr_participant_noaccess_paragraph', { roomOwnerName })"
        />
      </div>
    </template>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { PropType, Ref } from 'vue';
import { watch, onMounted, defineComponent, computed, ref, toRef } from 'vue';
import type { ContractLink } from '@getaccept/editor-lib-new/src/types/models';
import type { ContractListItem, EBlockWidth, MergeValues } from '@getaccept/editor-lib-new';
import { NodeAlign, ValueAlign } from '@getaccept/editor-lib-new';
import type { PusherClient } from '@getaccept/pusher';
import { useContractPusher } from '@getaccept/editor-lib-new/src/components/composables/contract-pusher.composable';
import { useContractLink } from '@getaccept/editor-lib-new/src/components/composables/contract-link.composable';
import { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import ContractLinkHeader from '@getaccept/editor-lib-new/src/components/contract-link/ContractLinkHeader.vue';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { ContractHelper as ContractHelperShared } from '@getaccept/editor-lib-new/src/helpers/contract.helper';
import { RecipientRole } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-role';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import ContractLinkNoAccess from '@getaccept/editor-lib-new/src/components/contract-link/ContractLinkNoAccess.vue';
import EditorNodeWrapper from '../EditorNodeWrapper.vue';
import { ContractHelper } from '../helpers/contract.helper';
import DescriptionInput from '../DescriptionInput.vue';
import SignerDetails from './SignerDetails.vue';

export default defineComponent({
  name: 'EditorNodeContractLink',
  components: {
    EditorNodeWrapper,
    SignerDetails,
    ContractLinkHeader,
    DescriptionInput,
    ContractLinkNoAccess,
  },
  props: {
    node: {
      type: Object as PropType<ContractLink>,
      default: () => ({}),
    },
    dsrTemplateId: { type: String, default: '' },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    parentFirstChild: { type: Boolean },
    parentLastChild: { type: Boolean },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    rowTypeClass: { type: String, default: null },
    recipient: { type: Object as PropType<Recipient>, default: () => ({}) },
    currentUser: { type: Object as PropType<User>, default: undefined },
    pusherClient: { type: Object as PropType<PusherClient>, default: undefined },
    linkedContract: { type: Object as PropType<ContractListItem>, default: () => ({}) },
  },
  emits: ['observe-node-visibility'],
  setup(props) {
    const participantPusherClient: Ref<PusherClient> = ref(null);
    const pusherClient = computed(() =>
      props.currentUser ? props.pusherClient : participantPusherClient.value
    );
    const { pusherSubscribe, recipientStatus, documentStatus, recipientRole, newExpirationDate } =
      useContractPusher(props, pusherClient);
    const contract: Ref<ContractListItem> = ref(null);
    const signingPageUrl: Ref<string> = ref(null);
    const contractSender = computed(() => contract.value?.user);
    const contractLoading = ref(false);
    const senderCompanyName = computed(
      () => props.mergeValues.entity.companyName || props.mergeValues.entity.name
    );

    const getSigningPageUrlForRecipient = (id: string) => {
      const recipientMap = ContractHelper.findMappedRecipient(
        id,
        props.recipient,
        props.node.recipientMaps
      );
      if (!recipientMap) {
        return null;
      }
      return signingPageUrl.value;
    };

    const loadContract = async () => {
      const { contractId } = props.node;
      if (!contractId) {
        return;
      }
      contract.value = await getTokenAndLoadContract(contractId);
    };

    const getTokenAndLoadContract = async (contractId: string) => {
      signingPageUrl.value = await ContractHelper.getSigningPageUrl(contractId);
      if (!signingPageUrl.value) {
        return null;
      }
      const jwt = await ContractHelper.getToken(contractId, signingPageUrl.value, props.recipient);
      participantPusherClient.value = await ContractHelper.createPusherClient(jwt);
      pusherSubscribe();
      return await ContractHelper.loadContract(jwt);
    };

    const updateRecipientStatus = async ({
      status,
      recipientId,
    }: {
      status: string;
      recipientId: string;
    }) => {
      const recipient = contract.value.recipients.find(recipient => recipient.id === recipientId);
      if (!recipient) {
        const { recipients } = await getTokenAndLoadContract(props.node.contractId);
        contract.value = {
          ...contract.value,
          recipients,
        };
        return;
      }
      contract.value = await ContractHelperShared.updateContractRecipientStatus(
        contract.value,
        recipientId,
        status as RecipientStatus
      );
    };

    const loadingContractText = computed(() =>
      t('connecting_contract_to_room', {
        contractName: props.node.name,
      })
    );

    const showContractDescription = computed(() =>
      Boolean(contractDescription.value.content[0]?.content)
    );

    const roomOwnerName = computed(() => props.mergeValues?.sender?.fullName);

    const hasContractDetails = computed(() => contract.value?.id);

    const getPreviousSigner = (index: number) => {
      for (let i = index - 1; i >= 0; i--) {
        const recipient = filteredRecipients.value[i];
        if (recipient?.role !== RecipientRole.CC) {
          return recipient;
        }
      }
    };

    watch(
      () => props.linkedContract,
      () => {
        if (props.linkedContract && props.currentUser) {
          contract.value = { ...props.linkedContract };
          pusherSubscribe();
        }
      },
      { immediate: true }
    );

    onMounted(async () => {
      if (props.currentUser) {
        return;
      }
      contractLoading.value = true;
      await loadContract();
      contractLoading.value = false;
    });

    const {
      updateDocumentStatus,
      updateRecipientRole,
      filteredRecipients,
      translatedRole,
      contractStatus,
      showContractDetails,
      contractExpired,
      contractDescription,
    } = useContractLink(contract, toRef(props, 'node'));

    watch(recipientStatus, () => updateRecipientStatus(recipientStatus.value));

    watch(documentStatus, () => updateDocumentStatus(documentStatus.value));

    watch(recipientRole, () => updateRecipientRole(recipientRole.value));

    watch(newExpirationDate, () => loadContract());

    return {
      contract,
      contractDescription,
      contractExpired,
      contractLoading,
      senderCompanyName,
      contractSender,
      contractStatus,
      filteredRecipients,
      getPreviousSigner,
      getSigningPageUrlForRecipient,
      updateRecipientStatus,
      loadingContractText,
      NodeAlign,
      showContractDescription,
      showContractDetails,
      signingPageUrl,
      translatedRole,
      getTokenAndLoadContract,
      loadContract,
      ValueAlign,
      RecipientStatus,
      t,
      roomOwnerName,
      hasContractDetails,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-contract-link';
@import '@getaccept/editor-lib-new/src/scss/editor-node-loader';
</style>
