export class ImageTransformHelper {
  static SCALE_MIN_VALUE = 100;
  static SCALE_MAX_VALUE = 300;

  static getInitialImageSize(
    naturalImageWidth: number,
    naturalImageHeight: number,
    containerWidth: number,
    containerHeight: number
  ): [number, number] {
    let initialWidth: number;
    let initialHeight: number;

    if (!this.isWideImage(naturalImageWidth, naturalImageHeight, containerWidth, containerHeight)) {
      const imageWidthRatio = containerWidth / naturalImageWidth;
      initialWidth = containerWidth;
      initialHeight = naturalImageHeight * imageWidthRatio;
    } else {
      const imageHeightRatio = containerHeight / naturalImageHeight;
      initialWidth = naturalImageWidth * imageHeightRatio;
      initialHeight = containerHeight;
    }

    return [initialWidth, initialHeight];
  }

  static isWideImage(
    imageWidth: number,
    imageHeight: number,
    areaWidth: number,
    areaHeight: number
  ): boolean {
    const imageAspectRatio = imageWidth / imageHeight;
    const areaAspectRatio = areaWidth / areaHeight;

    return imageAspectRatio >= areaAspectRatio;
  }

  static getTransformOrigin(
    imageHeight: number,
    imageWidth: number,
    areaHeight: number,
    areaWidth: number
  ): string {
    const isWideImage = this.isWideImage(imageWidth, imageHeight, areaWidth, areaHeight);

    if (imageHeight <= areaHeight && isWideImage) {
      return 'center left';
    } else if (imageWidth <= areaWidth && !isWideImage) {
      return 'top center';
    }
    return 'top left';
  }
}
