<template>
  <EditorNodeWrapper
    :class="[
      'node-container',
      'video-container',
      blockWidthClass,
      node.align.toLowerCase(),
      node.imageSize.toLowerCase(),
    ]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <VideoPlayer
      :url="node.url"
      :thumb-url="node.srcUrl"
      ratio="16:9"
      class="video-player viewer"
      @timeupdate="handleTimeUpdate"
      @pause="sendTrackingData"
      @ended="sendTrackingData"
    />
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { EBlockWidth, Video } from '@getaccept/editor-lib-new';
import VideoPlayer from '@getaccept/lib-shared-new/src/video/player/components/VideoPlayer.vue';
import type { PropType, Ref } from 'vue';
import { defineComponent, ref } from 'vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeVideo',
  components: {
    VideoPlayer,
    EditorNodeWrapper,
  },
  props: {
    node: {
      type: Object as PropType<Video>,
      default: () => ({}),
    },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
  },
  emits: ['observe-node-visibility', 'track-video'],
  setup(props, { emit }) {
    const currentVideoPlayTime: Ref<number> = ref(null);

    const handleTimeUpdate = (playtime: number) => {
      currentVideoPlayTime.value = playtime;
    };

    const sendTrackingData = () => {
      if (!currentVideoPlayTime.value) {
        return;
      }
      emit('track-video', { id: props.node.videoId, viewDuration: currentVideoPlayTime.value });
      currentVideoPlayTime.value = null;
    };

    return {
      handleTimeUpdate,
      sendTrackingData,
      currentVideoPlayTime,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-video';
</style>
