<template>
  <div ref="editorContainer" :class="['editor-block', blockWidthClass, { 'is-dsr': isDsr }]">
    <div
      v-if="showCommentsBlockedBanner"
      class="comments-locked"
      :data-external="`block-cc-locked-page-id:${blockId}`"
    >
      <FriedIcon icon="comments-locked" />
      <FriedH5>{{ t('cc_eas_block_is_being_edited_banner') }}</FriedH5>
    </div>
    <div v-if="!isDsr" :class="firstRowContainsCoverImage ? 'block' : 'block-padding'"></div>
    <DetachedCommentAnchor
      v-for="comment in commentsWithoutSectionId"
      :id="comment.id"
      :key="comment.id"
    />
    <EditorSection
      v-for="section in visibleSections"
      :key="section.id"
      :section-id="section.id"
      :is-hidden="section.hidden"
      :context="context"
      :focused-required-id="focusedRequiredId"
      :rows="section.rows"
      :merge-values="mergeValues"
      :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
      :is-dsr="isDsr"
      :is-preview="isPreview"
      :is-pdf-viewer="isPdfViewer"
      :block-width-class="blockWidthClass"
      :page-width="blockOffsetWidth"
      :theme="theme"
      :is-block-toggled="isBlockToggled"
      :sender="sender"
      :recipient="recipient"
      :recipient-input="recipientInput"
      :pusher-client="pusherClient"
      :partially-signed-fields="partiallySignedFields"
      :updating-inputs="updatingInputs as EditorRecipientInput[]"
      :failed-input-updates="failedInputUpdates as EditorRecipientInput[]"
      :comments="filterCommentsBySection(comments, section.id)"
      :selected-comment-id="selectedCommentId"
      :show-contextual-comments="showContextualComments"
      :create-contextual-comment-button-state="createContextualCommentButtonState"
      :current-user="currentUser"
      :linked-contracts="linkedContracts"
      :mapped-placeholders="mappedPlaceholders"
      @node-click="$emit('node-click', { ...$event, sectionId: section.id })"
      @update-input-value="handleUpdateInputValue"
      @track-video="$emit('track-video', $event)"
      @observe-node-visibility="
        $emit('observe-node-visibility', { ...$event, sectionId: section.id })
      "
      @update-element-visibility="
        $emit('observe-section-visibility', { ...$event, sectionId: section.id })
      "
      @partially-sign="$emit('partially-sign', $event)"
      @create-comment="$emit('create-comment', { ...$event, sectionId: section.id })"
      @select-comment="$emit('select-comment', $event)"
      @fetch-new-image="$emit('fetch-new-image', { ...$event, contentId: content.id })"
    />
    <div v-if="!isDsr" :class="lastRowContainsCoverImage ? 'block' : 'block-padding'"></div>
  </div>
</template>
<script lang="ts">
import elementResizeDetectorMaker from 'element-resize-detector';
import debounce from 'debounce';
import type {
  EditorContent,
  MergeValues,
  EBlockWidth,
  ContractListItem,
} from '@getaccept/editor-lib-new';
import { NodeType, EditorHelper, Context, PlaceholderHelper } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { Font, FontSettings } from '@getaccept/lib-shared-new/src/fonts/types/font';
import { openSansV29 } from '@getaccept/lib-shared-new/src/fonts/font-faces';
import { FontService } from '@getaccept/lib-shared-new/src/fonts/font-service';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import type { PropType, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { nextTick, defineComponent, computed, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import type { PusherClient } from '@getaccept/pusher';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { useApolloStore } from '../store/apollo.store';
import { useEditorViewInputStore } from '../store/editor-view-input.store';
import { useEditorViewContentStore } from '../store/editor-view-content.store';
import EditorSection from './EditorSection.vue';

export default defineComponent({
  name: 'EditorBlockView',
  components: {
    DetachedCommentAnchor,
    EditorSection,
  },
  props: {
    isPreview: { type: Boolean },
    content: { type: Object as PropType<EditorContent>, default: undefined },
    allContent: { type: Array as PropType<EditorContent[]>, default: () => [] },
    focusedRequiredId: { type: String, default: undefined },
    mergeValues: { type: Object as PropType<MergeValues>, default: undefined },
    isPdfViewer: { type: Boolean, default: false },
    isBlockToggled: { type: Boolean, default: false },
    blockId: { type: String, default: undefined },
    sender: { type: Object as PropType<User>, default: undefined },
    theme: { type: Object as PropType<SigningTheme>, default: undefined },
    recipient: { type: Object as PropType<Recipient>, default: undefined as Recipient },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    context: { type: String as PropType<Context>, default: () => Context.Document },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    pusherClient: { type: Object as PropType<PusherClient>, default: undefined },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean, default: false },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
    showCommentsLocked: { type: Boolean, default: false },
    currentUser: { type: Object as PropType<User>, default: undefined },
    linkedContracts: { type: Array as PropType<ContractListItem[]>, default: () => [] },
    mappedPlaceholders: { type: Array as PropType<MappedContentPlaceholder[]>, default: () => [] },
    allowedToEditAfterPartiallySigned: {
      type: Boolean,
    },
    apolloClient: {
      type: Object as PropType<ApolloClient<NormalizedCacheObject>>,
      default: () => ({}),
    },
  },
  emits: [
    'node-click',
    'track-video',
    'observe-node-visibility',
    'observe-section-visibility',
    'partially-sign',
    'create-comment',
    'select-comment',
    'fetch-new-image',
    'alert',
    'update-recipient-input',
    'loaded',
  ],
  setup(props, { emit }) {
    const editorContainer: Ref<HTMLElement> = ref(null);
    const blockWidthClass: Ref<EBlockWidth> = ref(null);
    const blockOffsetWidth = ref(0);
    const elementResizeDetector = ref(
      elementResizeDetectorMaker({
        strategy: 'scroll',
      })
    );

    const apolloStore = useApolloStore();
    const editorViewInputStore = useEditorViewInputStore();

    const { updatingInputs, failedInputUpdates, alert } = storeToRefs(editorViewInputStore);

    const sections = computed(() => props.content?.sections);

    const isPublicSite = computed(() => EditorHelper.isPublicContext(props.context));

    const filteredSections = computed(() =>
      (sections.value || []).filter(section =>
        [Context.DsrTemplate, Context.DsrResource, Context.DsrPreview].includes(props.context)
          ? true
          : PlaceholderHelper.filterSectionForPlaceholders(section)
      )
    );

    const visibleSections = computed(() =>
      [Context.DsrTemplate, Context.DsrPreview].includes(props.context)
        ? filteredSections.value
        : filteredSections.value.filter(({ hidden }) => !hidden)
    );

    const firstRowContainsCoverImage = computed(() => {
      if (!sections.value?.[0]) {
        return false;
      }
      return EditorHelper.rowContainsCoverSize(sections.value[0].rows[0], NodeType.Image);
    });

    const lastRowContainsCoverImage = computed(() => {
      if (!sections.value?.[0]) {
        return false;
      }
      const { rows } = sections.value[0];
      return EditorHelper.rowContainsCoverSize(rows[rows.length - 1], NodeType.Image);
    });

    const sectionIds = computed(() => sections.value.map(section => section.id));

    const commentsWithoutSectionId = computed(() =>
      props.comments.filter(
        comment => !sectionIds.value.includes(comment.editorSelection?.sectionId)
      )
    );

    const loadFont = async (font: Font, cssVariable: string) => {
      FontService.loadFont(font);
      await nextTick();
      editorContainer.value?.style?.setProperty(cssVariable, font.fontFamily);
    };

    const editorViewContentStore = useEditorViewContentStore();

    watch(
      () => props.allContent,
      () => {
        editorViewContentStore.setContent(props.allContent);
      },
      { immediate: true }
    );

    watch(alert, () => {
      if (!alert.value) {
        return;
      }

      emit('alert', alert.value);
      editorViewInputStore.setAlert(null);
    });

    watch(
      () => props.content.fontSettings,
      (fonts: FontSettings) => {
        if (
          EditorHelper.shouldLoadFont(
            fonts?.defaultFont,
            isPublicSite.value,
            props.isPreview,
            props.theme?.fontSettings?.defaultFont
          )
        ) {
          loadFont(
            fonts?.defaultFont || props.theme.fontSettings.defaultFont,
            '--editor-default-font-family'
          );
        }
        if (
          EditorHelper.shouldLoadFont(
            fonts?.headingsFont,
            isPublicSite.value,
            props.isPreview,
            props.theme?.fontSettings?.headingsFont
          )
        ) {
          loadFont(
            fonts?.headingsFont || props.theme.fontSettings.headingsFont,
            '--editor-headings-font-family'
          );
        }
      },
      { immediate: true }
    );

    watch(
      () => [props.apolloClient, props.recipient],
      () => {
        if (!props.recipient || !isPublicSite.value) {
          return;
        }
        apolloStore.setApolloClient(props.apolloClient);
      },
      { immediate: true }
    );

    const initFont = () => {
      if (props.content.fontSettings?.defaultFont) {
        return;
      } else if (
        (!isPublicSite.value && !props.theme?.fontSettings?.defaultFont) ||
        !props.content.fontSettings?.defaultFont
      ) {
        loadFont(openSansV29, '--editor-default-font-family');
      }
    };

    const setBlockWidth = (width: number) => {
      if (EditorHelper.isSlideShowLightboxVisible()) {
        return;
      }
      const blockWidth = EditorHelper.getBlockWidth(width);
      blockWidthClass.value = EditorHelper.getBlockWidthClass(blockWidth);
    };

    const updateBlockWidth = () => {
      blockOffsetWidth.value = editorContainer.value?.offsetWidth || 800;
      setBlockWidth(blockOffsetWidth.value);
    };

    const initResizeDetector = () => {
      if (!editorContainer.value) {
        return;
      }
      elementResizeDetector.value.listenTo(editorContainer.value, handleBlockResize);
    };

    const isDsr = computed(() => EditorHelper.isDealroomContext(props.context));

    const debounceUpdateBlockWidth = debounce(updateBlockWidth, 200);

    const handleBlockResize = ({ offsetWidth }: HTMLDivElement) => {
      if (blockOffsetWidth.value === offsetWidth) {
        return;
      }
      debounceUpdateBlockWidth();
    };

    onMounted(async () => {
      await nextTick();
      initResizeDetector();
      updateBlockWidth();
      initFont();
      emit('loaded');
    });

    onBeforeUnmount(() => {
      elementResizeDetector.value?.removeListener(editorContainer.value);
    });

    const handleUpdateInputValue = ({ id: fieldId, value }: { id: string; value: string }) => {
      emit(
        'update-recipient-input',
        props.recipientInput.filter(input => input.fieldId !== fieldId).concat({ fieldId, value })
      );

      editorViewInputStore.updateInputValue(
        {
          value,
          fieldId,
          blockId: props.blockId,
        },
        props.context === Context.DsrPublished
      );
    };

    const showCommentsBlockedBanner = computed(
      () =>
        props.showContextualComments &&
        props.showCommentsLocked &&
        props.createContextualCommentButtonState ===
          CreateContextualCommentButtonState.SenderHasMadeChanges
    );

    return {
      commentsWithoutSectionId,
      editorContainer,
      blockWidthClass,
      loadFont,
      setBlockWidth,
      handleUpdateInputValue,
      updatingInputs,
      failedInputUpdates,
      firstRowContainsCoverImage,
      lastRowContainsCoverImage,
      sections,
      visibleSections,
      blockOffsetWidth,
      initResizeDetector,
      handleBlockResize,
      elementResizeDetector,
      isDsr,
      filterCommentsBySection: CommentsHelper.filterCommentsBySection,
      filteredSections,
      showCommentsBlockedBanner,
      t,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-block';
</style>
<style lang="scss">
@import '@getaccept/editor-lib-new/src/scss/editor-block-fonts';
</style>
