import { NumberInputType, fallbackParagraphContent } from '@getaccept/editor-lib-new';
import { EPricingTableColumn } from '@getaccept/editor-lib-new/src/types/enums';
import type {
  Field,
  PricingTableCurrencySettings,
  PricingTableRow,
  PricingTableRowValue,
  PricingTableColumn,
  PricingTableSection,
} from '@getaccept/editor-lib-new';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { EditorHelper } from '../editor.helper';
import { PricingTableHelper } from '../pricing-table.helper';

export const getRowValues = (row: PricingTableRow) => row?.values;

export const getRowDiscountFlatFee = (row: PricingTableRow) => row?.discountFlatFee;

export const getRowTaxFlatFee = (row: PricingTableRow) => row?.taxFlatFee;

export const getRowVariableQuantityField = (row: PricingTableRow, fields: Field[]) =>
  fields.find(field => field.id === row?.variableQuantityFieldId);

export const hasDiscount = (columns: PricingTableColumn[], rowValues: PricingTableRowValue[]) =>
  getRowDiscountEnabled(columns) && !getRowDiscountValueIsEmpty(columns, rowValues);

export const getRowVariableQuantityEnabled = (row: PricingTableRow, fields: Field[]) =>
  !!row?.variableQuantityFieldId && !!getRowVariableQuantityField(row, fields);

export const getRowOptionalProductField = (row: PricingTableRow, fields: Field[]) =>
  fields?.find(field => field.id === row?.optionalProductFieldId);

export const getRowOptionalProductEnabled = (row: PricingTableRow, fields: Field[]) =>
  !!row?.optionalProductFieldId && !!getRowOptionalProductField(row, fields);

export const hasCustomColumn = (columns: PricingTableColumn[], customColumnId: string) =>
  customColumnId ? columns.find(column => column.id === customColumnId).isCustom : undefined;

export const getRowNameValue = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const columnNameId = PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Name);
  return rowValues.find(rowValue => rowValue.columnId === columnNameId)?.value;
};

export const getRowDescriptionValue = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const columnDescriptionId = PricingTableHelper.getColumnIdFromName(
    columns,
    EPricingTableColumn.Description
  );
  const content = rowValues.find(rowValue => rowValue.columnId === columnDescriptionId)?.value;
  return getDescriptionContent(content);
};

export const getDescriptionContent = (content: string) => {
  if (!content) {
    return JSON.parse(fallbackParagraphContent);
  } else if (EditorHelper.IsJsonString(content)) {
    return JSON.parse(content);
  } else {
    return content;
  }
};

export const getRowDescriptionIsEmpty = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => JSON.stringify(getRowDescriptionValue(columns, rowValues)) === fallbackParagraphContent;

export const getRowSkuValue = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const columnSkuId = PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Sku);
  return rowValues.find(rowValue => rowValue.columnId === columnSkuId)?.value;
};

export const getRowCustomValue = (row: PricingTableRow, customColumnId: string) =>
  getRowValues(row).find(rowValue => rowValue.columnId === customColumnId)?.value;

export const getRowSkuValueIsEmpty = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const skuValue = getRowSkuValue(columns, rowValues);
  return skuValue === '0' || skuValue === '';
};

export const getRowDiscountValue = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const columnDiscountId = PricingTableHelper.getColumnIdFromName(
    columns,
    EPricingTableColumn.Discount
  );
  return rowValues.find(rowValue => rowValue.columnId === columnDiscountId)?.value;
};

export const getRowDiscountEnabled = (columns: PricingTableColumn[]) =>
  columns.find(column => column.name === EPricingTableColumn.Discount)?.enabled;

export const getTotalPriceEnabled = (columns: PricingTableColumn[]) =>
  columns.find(column => column.name === EPricingTableColumn.TotalPrice)?.enabled;

export const getRowDiscountValueIsEmpty = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const discountValue = getRowDiscountValue(columns, rowValues);
  return discountValue === '0' || discountValue === '';
};

export const getRowTaxValue = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const columnTaxId = PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Tax);
  return rowValues.find(rowValue => rowValue.columnId === columnTaxId)?.value;
};

export const getRowTaxPercentagePreview = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const tax = getRowTaxValue(columns, rowValues);
  return tax === '' ? '0%' : `${tax}%`;
};

export const getRowTaxValueIsEmpty = (
  columns: PricingTableColumn[],
  rowValues: PricingTableRowValue[]
) => {
  const taxValue = getRowTaxValue(columns, rowValues);
  return taxValue === '0' || taxValue === '';
};

export const getRowNetPrice = (
  isPreCalculated: boolean,
  row: PricingTableRow,
  columns: PricingTableColumn[],
  fields: Field[]
) => {
  const columnNetPriceId = PricingTableHelper.getColumnIdFromName(
    columns,
    EPricingTableColumn.NetPrice
  );
  if (isPreCalculated) {
    return getRowValues(row).find(rowValue => rowValue.columnId === columnNetPriceId)?.value;
  }
  const rowFromInputFieldValues = PricingTableHelper.getRowFromInputFieldValues(
    row,
    columns,
    fields
  );
  return PricingTableHelper.calculateRowNetPrice(rowFromInputFieldValues, columns).value;
};

export const getRowTotalPrice = (
  isPreCalculated: boolean,
  row: PricingTableRow,
  columns: PricingTableColumn[],
  fields: Field[]
) => {
  const columnTotalPriceId = PricingTableHelper.getColumnIdFromName(
    columns,
    EPricingTableColumn.TotalPrice
  );
  if (isPreCalculated) {
    return getRowValues(row).find(rowValue => rowValue.columnId === columnTotalPriceId)?.value;
  }
  const rowFromInputFieldValues = PricingTableHelper.getRowFromInputFieldValues(
    row,
    columns,
    fields
  );
  return PricingTableHelper.calculateRowTotalPrice(rowFromInputFieldValues, columns).value;
};

export const getRowDiscountPercentagePreview = (
  isPreCalculated: boolean,
  row: PricingTableRow,
  columns: PricingTableColumn[],
  fields: Field[],
  currencySettings: PricingTableCurrencySettings
) => {
  const discount = getRowDiscountValue(columns, getRowValues(row));
  if (isPreCalculated) {
    return discount === '' ? '0%' : `${discount}%`;
  }
  const discountInCurrency = String(
    (Number(getRowTotalPrice(isPreCalculated, row, columns, fields)) * Number(discount)) / 100
  );
  return `-${PricingTableHelper.currencyFormat(
    discountInCurrency,
    currencySettings
  )} (${discount}%)`;
};

export const getRowPriceAfterDiscounts = (columns: PricingTableColumn[], row: PricingTableRow) => {
  const rowValues = getRowValues(row);
  const columnPriceId = PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Price);
  const columnUnitsId = PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Units);
  const price = Number(rowValues.find(rowValue => rowValue.columnId === columnPriceId)?.value);
  const units = Number(rowValues.find(rowValue => rowValue.columnId === columnUnitsId)?.value);
  const totalPrice = price * units;
  const rowDiscount = row.discountFlatFee
    ? Number(getRowDiscountValue(columns, rowValues))
    : totalPrice * (Number(getRowDiscountValue(columns, rowValues)) / 100);
  return String(price * units - rowDiscount);
};

export const getRowFormattedDiscount = (
  isPreCalculated: boolean,
  row: PricingTableRow,
  columns: PricingTableColumn[],
  fields: Field[],
  currencySettings: PricingTableCurrencySettings
) => {
  const rowValues = getRowValues(row);
  if (getRowDiscountValueIsEmpty(columns, rowValues)) {
    return t('add-discount');
  } else if (getRowDiscountFlatFee(row)) {
    return `-${PricingTableHelper.currencyFormat(
      getRowDiscountValue(columns, rowValues),
      currencySettings
    )}`;
  } else {
    return getRowDiscountPercentagePreview(isPreCalculated, row, columns, fields, currencySettings);
  }
};

export const getRowFormattedTax = (
  row: PricingTableRow,
  columns: PricingTableColumn[],
  currencySettings: PricingTableCurrencySettings
) => {
  const rowValues = getRowValues(row);
  if (getRowTaxFlatFee(row)) {
    return `${PricingTableHelper.currencyFormat(
      getRowTaxValue(columns, rowValues),
      currencySettings
    )}`;
  } else {
    return `${getRowTaxPercentagePreview(columns, rowValues)}`;
  }
};

export const getFilteredRowValues = (row: PricingTableRow, columns: PricingTableColumn[]) => {
  const rowValues = getRowValues(row);
  const enabledColumnIds = columns.filter(column => column.enabled).map(column => column.id);
  return rowValues.filter(
    rowValue =>
      ![
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Name),
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Description),
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Sku),
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.TotalPrice),
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.NetPrice),
        PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Discount),
      ].includes(rowValue.columnId) && enabledColumnIds.includes(rowValue.columnId)
  );
};

export const getNumberInputType = (
  columns: PricingTableColumn[],
  columnId: string
): NumberInputType => {
  switch (columnId) {
    case PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Price):
      return NumberInputType.Currency;

    case PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.TotalPrice):
      return NumberInputType.Currency;

    case PricingTableHelper.getColumnIdFromName(columns, EPricingTableColumn.Tax):
      return NumberInputType.Percentage;

    default:
      return NumberInputType.Number;
  }
};

const rowsWithText = [EPricingTableColumn.Sku, EPricingTableColumn.Name];
const rowsWithNumbers = [
  EPricingTableColumn.Price,
  EPricingTableColumn.Discount,
  EPricingTableColumn.Tax,
];

const isRowUsed = (value: string, column: EPricingTableColumn) => {
  if (
    column === EPricingTableColumn.Description &&
    JSON.stringify(getDescriptionContent(value)) !== fallbackParagraphContent
  ) {
    return true;
  }

  if (column === EPricingTableColumn.Units && !['1', '0', ''].includes(value)) {
    return true;
  }

  if (rowsWithText.includes(column) && value !== '') {
    return true;
  }

  return rowsWithNumbers.includes(column) && !['0', ''].includes(value);
};

export const hasUsedPricingRows = (section: PricingTableSection) => {
  const rowsToCheck = [
    ...rowsWithText,
    ...rowsWithNumbers,
    EPricingTableColumn.Description,
    EPricingTableColumn.Units,
  ];

  for (let i = 0; i < section.rows.length; i++) {
    const row = section.rows[i];

    for (let y = 0; y < rowsToCheck.length; y++) {
      const rowToCheck = rowsToCheck[y];

      const columnId = section.columns.find(column => column.name === rowToCheck).id;
      const rowValue = row.values.find(rowValue => rowValue.columnId === columnId);

      if (isRowUsed(rowValue.value, rowToCheck)) {
        return true;
      }
    }
  }

  return false;
};
