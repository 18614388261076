import type { Document } from '@getaccept/lib-shared-new/src/types/Document';
import type { DocumentPage } from '@getaccept/lib-shared-new/src/types/document-page';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import { DocumentPageType } from '@getaccept/lib-shared-new/src/enums/document-page-type';
import type { Font } from '@getaccept/lib-shared-new/src/fonts/types/font';
import type { SigningPageDocument } from '@getaccept/lib-shared-new/src/types/signing-page-document';
import type { Participant } from '@getaccept/dsr-shared-new';
import {
  Align,
  EBlockWidth,
  Breakpoint,
  DividerPadding,
  DividerType,
  FieldType,
  ImageSize,
  NodeType,
  RowType,
  FieldInputType,
  SignDateSettingsFormat,
  BorderStyle,
  PlaceholderType,
  Context,
} from '../types/enums';
import { fallbackParagraphContent, fallbackTableContent, fallbackTextContent } from '../constants';
import type {
  BlockWidth,
  Cell,
  Row,
  ImageNode,
  Field,
  Form,
  Text,
  Table,
  TableStyle,
  Node,
  PricingTable,
  PricingTableSummaryValues,
  PricingTableCurrencySettings,
  NodePayload,
  DropdownInputSettings,
  Link,
  Video,
  Signature,
  ContractLink,
  EditorBlock,
  UpdateNodeRequest,
  MergeValues,
} from '../types';
import type { PartiallySignedEvent } from '../types/signed-field';
import type {
  ContentPlaceholderPayload,
  EditorContent,
  ElementPlaceholderPayload,
  MergeTagsSettings,
  PricingSummary,
} from '../types/models';
import { ProsemirrorHelper } from './prosemirror.helper';
import { SignatureHelper } from './signature.helper';

export class EditorHelper {
  static readonly NANO_ID_LENGTH = 21;
  static readonly mergeTagExpression = `{{.{${EditorHelper.NANO_ID_LENGTH}}}}`;

  static hasHiddenContentRight({ offsetWidth, scrollWidth, scrollLeft }: HTMLElement): boolean {
    if (scrollWidth <= offsetWidth) {
      return false;
    }

    return offsetWidth + scrollLeft !== scrollWidth;
  }

  static isPreviousRowSideBySide(index: number, rows: Row[]): boolean {
    return rows[index - 1]?.type === RowType.SideBySide;
  }

  static isBlockWidth(size: EBlockWidth, width: number): boolean {
    switch (size) {
      case EBlockWidth.XXSmall:
        return width <= Breakpoint.XXSmall;
      case EBlockWidth.XSmall:
        return width <= Breakpoint.XSmall;
      case EBlockWidth.Small:
        return width <= Breakpoint.Small;
      case EBlockWidth.Medium:
        return width <= Breakpoint.Medium;
      case EBlockWidth.Large:
        return width <= Breakpoint.Large;
      case EBlockWidth.XLarge:
        return width > Breakpoint.Large;
    }
  }

  static getBlockWidth(width: number): BlockWidth {
    return {
      xxs: EditorHelper.isBlockWidth(EBlockWidth.XXSmall, width),
      xs: EditorHelper.isBlockWidth(EBlockWidth.XSmall, width),
      sm: EditorHelper.isBlockWidth(EBlockWidth.Small, width),
      md: EditorHelper.isBlockWidth(EBlockWidth.Medium, width),
      lg: EditorHelper.isBlockWidth(EBlockWidth.Large, width),
      xl: EditorHelper.isBlockWidth(EBlockWidth.XLarge, width),
    };
  }

  static getBlockWidthClass(blockWidth: BlockWidth): EBlockWidth {
    if (blockWidth?.xxs) {
      return EBlockWidth.XXSmall;
    } else if (blockWidth?.xs) {
      return EBlockWidth.XSmall;
    } else if (blockWidth?.sm) {
      return EBlockWidth.Small;
    } else if (blockWidth?.md) {
      return EBlockWidth.Medium;
    } else if (blockWidth?.lg) {
      return EBlockWidth.Large;
    } else if (blockWidth?.xl) {
      return EBlockWidth.XLarge;
    } else {
      return EBlockWidth.XXSmall;
    }
  }

  static isBlockWidthSmall = (blockWidthClass: EBlockWidth) =>
    [EBlockWidth.XXSmall, EBlockWidth.XSmall, EBlockWidth.Small].includes(blockWidthClass);

  static rowContainsCoverSize(row: Row, type: NodeType): boolean {
    if (row.type === RowType.SlideShow) {
      return false;
    }
    const imageCoverNode = (node: ImageNode) =>
      node.type === type && node.imageSize === ImageSize.Cover;

    return !!(row.cells || []).find(({ nodes }: Cell) => !!nodes.find(imageCoverNode));
  }

  static rowContainsLockedCells(row: Row): boolean {
    if (!row || !row.cells) {
      return false;
    }
    return !!row.cells.find(({ nodes }: Cell) => !!nodes.find(node => node.locked));
  }

  static getEditorInputFieldsWithPageNumInPage({
    editorBlock,
    pageNum,
  }: DocumentPage): (Field & { pageNum: number })[] {
    if (!editorBlock) {
      return [];
    }
    return editorBlock.content.sections.flatMap(({ rows }) =>
      rows.flatMap(({ cells }) =>
        cells.flatMap(({ nodes }) =>
          nodes
            .filter(node => node.type === NodeType.Form)
            .flatMap(({ inputFieldSets, fields }: Form) =>
              inputFieldSets
                .flatMap(fieldSet => fieldSet.ids)
                .map(id => ({
                  ...fields.find(field => field.id === id),
                  pageNum,
                }))
            )
        )
      )
    );
  }

  static getEditorFieldsWithPageNumInPage(
    { editorBlock, pageNum }: DocumentPage,
    types: NodeType[]
  ): (Field & { pageNum: number; nodeId?: string })[] {
    if (!editorBlock) {
      return [];
    }
    return editorBlock.content.sections.flatMap(({ rows }) =>
      rows.flatMap(({ cells }) =>
        cells.flatMap(({ nodes }) =>
          nodes
            .filter(node => types.includes(node.type))
            .flatMap(({ inputFieldSets, fields, id }: Form) =>
              inputFieldSets
                ? inputFieldSets
                    .flatMap(fieldSet => fieldSet.ids)
                    .map(id => ({
                      ...fields.find(field => field.id === id),
                      pageNum,
                    }))
                : fields.map(field => ({
                    ...field,
                    nodeId: id,
                    pageNum,
                  }))
            )
        )
      )
    );
  }

  static getEditorFormMergeFields(pages: DocumentPage[]): Field[] {
    return pages
      .filter(page => page.pageType === DocumentPageType.Editor)
      .flatMap(page =>
        page.editorBlock.content.sections.flatMap(({ rows }) =>
          rows.flatMap(({ cells }) =>
            cells.flatMap(({ nodes }) =>
              nodes
                .filter(node => node.type === NodeType.Form)
                .flatMap(({ fields }: Form) => fields)
                .filter(field => [FieldType.Custom, FieldType.Merge].includes(field.type))
            )
          )
        )
      );
  }

  static getSignatureFieldIds(pages: DocumentPage[]): string[] {
    return pages
      .filter(page => page.pageType === DocumentPageType.Editor)
      .flatMap(page =>
        page.editorBlock.content.sections.flatMap(({ rows }) =>
          rows.flatMap(({ cells }) =>
            cells
              .flatMap(({ nodes }) => nodes.filter(node => node.type === NodeType.Signature))
              .map(({ id }) => id)
          )
        )
      );
  }

  static getAllRequiredEditorFields(
    document: Document | SigningPageDocument,
    types: NodeType[]
  ): (Field & { pageNum: number; nodeId?: string })[] {
    return EditorHelper.getEditorFieldsWithPageNumInDocument(document, types).filter(
      field =>
        field.inputSettings.required &&
        field.recipientId !== null &&
        field.inputSettings.hide !== true
    );
  }

  static getEditorFieldsWithPageNumInDocument(
    { pages }: Document | SigningPageDocument,
    types: NodeType[]
  ): (Field & { pageNum: number; nodeId?: string })[] {
    return pages
      .filter(page => page.editorBlock)
      .flatMap(editorBlock => EditorHelper.getEditorFieldsWithPageNumInPage(editorBlock, types));
  }

  static recipientFieldContent(
    contentValue: string,
    mergeFields: Field[],
    recipientInput: EditorRecipientInput,
    mergeValues: MergeValues
  ): string {
    if (recipientInput?.value?.trim()) {
      return recipientInput.value.trim();
    }
    if (recipientInput?.value.trim() === '') {
      return '';
    }
    if (!recipientInput?.value) {
      if (!EditorHelper.hasMergeTags(contentValue)) {
        return EditorHelper.getRecipientFieldValue(recipientInput, contentValue).trim();
      } else {
        return EditorHelper.setDefaultMergeValue(contentValue, mergeFields, mergeValues).trim();
      }
    }
  }

  static setDefaultMergeValue(value: string, fields: Field[], mergeValues: MergeValues): string {
    const mergeValue = () =>
      ProsemirrorHelper.getMergeValueFromMergeTag(
        ProsemirrorHelper.getTagIdFromMergeTag(value),
        fields,
        mergeValues
      );

    return fields
      .filter(field => value.includes(`{{${field.id}}}`))
      .map(field =>
        value.replace(`{{${field.id}}}`, field.customName ? field.value || '' : mergeValue())
      )
      .join('');
  }

  static hasMergeTags(value: string): boolean {
    return !!value?.match(new RegExp(EditorHelper.mergeTagExpression));
  }

  static getRecipientFieldValue(fieldInput: EditorRecipientInput, fieldValue: string): string {
    return fieldInput?.value || fieldValue || '';
  }

  static getEmptyFormFieldsAssignedToRecipient(
    mergeFields: Field[],
    fields: (Field & { pageNum: number })[],
    recipientInputs: EditorRecipientInput[],
    currentRecipientId: string,
    mergeValues: MergeValues
  ) {
    return fields.filter(({ id, recipientId, inputSettings, value: contentValue }) => {
      const fieldInput = recipientInputs.find(recipientInput => recipientInput.fieldId === id);
      const assignedToCurrentRecipient = currentRecipientId === recipientId;

      if (!assignedToCurrentRecipient) {
        return false;
      }

      switch (inputSettings.type) {
        case FieldInputType.Text:
        case FieldInputType.Email:
        case FieldInputType.Dropdown:
        case FieldInputType.Date:
          return !EditorHelper.recipientFieldContent(
            contentValue,
            mergeFields,
            fieldInput,
            mergeValues
          );
        case FieldInputType.Checkbox:
          return !(fieldInput?.value === '1' || contentValue === '1');
        default:
          return false;
      }
    });
  }

  static getEmptySignatureFieldsAssignedToRecipient(
    fields: (Field & { pageNum: number; nodeId?: string })[],
    currentRecipientId: string,
    partiallySignedFields: PartiallySignedEvent[]
  ) {
    return fields.filter(({ recipientId, nodeId }) => {
      const assignedToCurrentRecipient = currentRecipientId === recipientId;
      const isSignatureInputWithoutValue = !SignatureHelper.hasPartiallySignedField(
        partiallySignedFields,
        currentRecipientId,
        null,
        nodeId
      );

      return assignedToCurrentRecipient && isSignatureInputWithoutValue;
    });
  }

  static isOnlyNumbers(str: string): boolean {
    return /^\d+$/.test(str);
  }

  static IsJsonString(str: string) {
    if (EditorHelper.isOnlyNumbers(str)) {
      return false;
    }

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  private static getNewTextNode({
    content,
    fields,
    nodeType: type,
    nodeId: id,
    locked,
    markdownFieldId,
  }: NodePayload): Text {
    return {
      id,
      type,
      content: content || fallbackTextContent,
      fields: fields || [],
      locked: locked || false,
      markdownFieldId,
    };
  }

  private static getNewContractLinkNode({
    nodeType: type,
    nodeId: id,
    locked,
  }: NodePayload): ContractLink {
    return {
      id,
      type,
      locked: locked || false,
      contractId: null,
      recipientMaps: [],
      name: null,
      fields: [],
      content: fallbackParagraphContent,
      contractDisplaySettings: {
        name: true,
        description: true,
        title: true,
        company: true,
        email: true,
      },
    };
  }

  private static getNewContentPlaceholderNode({
    nodeType: type,
    nodeId: id,
    placeholder,
    content,
  }: NodePayload): Node {
    let placeholderPayload: ContentPlaceholderPayload | ElementPlaceholderPayload = null;

    if (placeholder.type === PlaceholderType.Resource) {
      placeholderPayload = {
        type: PlaceholderType.Resource,
        resourceIds: [],
      } as ContentPlaceholderPayload;
    }

    if (placeholder.type === PlaceholderType.Element && placeholder?.elementType) {
      placeholderPayload = {
        type: PlaceholderType.Element,
        elementType: placeholder.elementType,
      } as ElementPlaceholderPayload;
    }

    return {
      id,
      type,
      content: content || '',
      locked: false,
      placeholder: placeholderPayload,
    };
  }

  private static getNewImageNode({
    nodeType: type,
    nodeId: id,
    imageId,
    srcUrl,
    linkUrl,
    imageSize,
    caption,
    heading,
    imageAlign,
    imageTransforms,
    attribution,
    locked,
    blurhash,
  }: NodePayload): ImageNode {
    return {
      id,
      type,
      imageId: imageId || '',
      srcUrl: srcUrl || '',
      linkUrl: linkUrl || '',
      imageSize: imageSize || ImageSize.Original,
      caption: caption || '',
      heading: heading || '',
      imageAlign: imageAlign || Align.Center,
      imageTransforms: imageTransforms || null,
      attribution: attribution || null,
      locked: locked || false,
      blurhash: blurhash || '',
    };
  }

  private static getNewLinkNode({
    nodeType: type,
    nodeId: id,
    content,
    linkUrl,
    align,
    locked,
  }: NodePayload): Link {
    return {
      id,
      type,
      content,
      linkUrl: linkUrl || '',
      locked: locked || false,
      align: align || Align.Left,
    };
  }

  private static getNewVideoNode({
    nodeType: type,
    nodeId: id,
    srcUrl,
    url,
    videoId,
    imageSize,
    attribution,
    align,
    locked,
  }: NodePayload): Video {
    return {
      id,
      type,
      srcUrl: srcUrl || '',
      imageSize: imageSize || ImageSize.Original,
      url: url || '',
      videoId: videoId || '',
      locked: locked || false,
      attribution: attribution || null,
      align: align || Align.Center,
    };
  }

  private static getNewSignatureNode({
    nodeType: type,
    nodeId: id,
    fields,
    align,
  }: NodePayload): Signature {
    return {
      id,
      type,
      fields: fields || [],
      mergeTagsSettings: EditorHelper.getDefaultMergeTagsSettings(),
      align: align || Align.Center,
      borderStyle: BorderStyle.Line,
      locked: false,
    };
  }

  private static getNewTableNode({
    nodeType: type,
    nodeId: id,
    content,
    tableStyle,
    fields,
    locked,
  }: NodePayload): Table {
    return {
      id,
      type,
      content: content || fallbackTableContent,
      tableStyle: tableStyle || ({} as TableStyle),
      fields: fields || [],
      locked: locked || false,
    };
  }

  private static getNewDividerNode({
    nodeType: type,
    nodeId: id,
    dividerType,
    dividerPadding,
    locked,
  }: NodePayload): Node {
    return {
      id,
      type,
      dividerType: dividerType || DividerType.Thin,
      dividerPadding: dividerPadding || DividerPadding.None,
      locked: locked || false,
    };
  }

  private static getNewFormNode({
    nodeType: type,
    nodeId: id,
    inputFieldSets,
    fields,
    locked,
  }: NodePayload & {
    fields: Field[];
  }): Form {
    return {
      id,
      type,
      inputFieldSets: inputFieldSets || [],
      fields: fields || [],
      locked: locked || false,
    };
  }

  private static getNewPricingTableNode({
    nodeType: type,
    nodeId: id,
    name,
    pricingTableSections,
    fields,
    content,
    locked,
    pricingTableSummaryValues,
    currencySettings,
  }: NodePayload): PricingTable {
    return {
      id,
      type,
      name: name || '',
      pricingTableSections: pricingTableSections || [],
      preCalculated: false,
      fields: fields || [],
      content: content || '',
      locked: locked || false,
      pricingTableSummaryValues: pricingTableSummaryValues || ({} as PricingTableSummaryValues),
      currencySettings: currencySettings || ({} as PricingTableCurrencySettings),
    };
  }

  private static getNewPricingSummaryNode(payload: NodePayload): PricingSummary {
    return {
      id: payload.nodeId,
      locked: payload.locked || false,
      type: NodeType.PricingSummary,
      pricingSummarySettings: payload.pricingSummarySettings,
      labels: payload.labels,
      tables: payload.tables || [],
    };
  }

  static getNewNode(payload: NodePayload): Node | undefined {
    switch (payload.nodeType) {
      case NodeType.Text:
        return EditorHelper.getNewTextNode(payload);

      case NodeType.Image:
        return EditorHelper.getNewImageNode(payload);

      case NodeType.Link:
        return EditorHelper.getNewLinkNode(payload);

      case NodeType.Video:
        return EditorHelper.getNewVideoNode(payload);

      case NodeType.Signature:
        return EditorHelper.getNewSignatureNode(payload);

      case NodeType.Table:
        return EditorHelper.getNewTableNode(payload);

      case NodeType.Divider:
        return EditorHelper.getNewDividerNode(payload);

      case NodeType.Form:
        return EditorHelper.getNewFormNode(payload as NodePayload & { fields: Field[] });

      case NodeType.PricingTable:
        return EditorHelper.getNewPricingTableNode(payload);

      case NodeType.PricingSummary:
        return EditorHelper.getNewPricingSummaryNode(payload);

      case NodeType.ContractLink:
        return EditorHelper.getNewContractLinkNode(payload);

      case NodeType.ContentPlaceholder:
        return EditorHelper.getNewContentPlaceholderNode(payload);

      default:
        return undefined;
    }
  }

  static getRecipientInputField(id: string, recipientInputs: EditorRecipientInput[]) {
    return recipientInputs?.find(({ fieldId }) => fieldId === id);
  }

  static getTextValue(input: Field, recipientInputs: EditorRecipientInput[]): string {
    if (input.userId) {
      return input.value || '';
    }

    const recipientInputValue = EditorHelper.getRecipientInputField(
      input.id,
      recipientInputs
    )?.value;

    if (recipientInputValue || recipientInputValue === '') {
      return recipientInputValue;
    }

    return input.value || '';
  }

  static getCheckboxValue(input: Field, recipientInputs: EditorRecipientInput[]): boolean {
    if (input.userId) {
      return input.value === '1';
    }

    const recipientInputValue = EditorHelper.getRecipientInputField(
      input.id,
      recipientInputs
    )?.value;

    if (recipientInputValue) {
      return recipientInputValue === '1';
    }

    return input.value === '1';
  }

  static getDropdownValue(input: Field, recipientInputs: EditorRecipientInput[]): string {
    if (input.userId) {
      return input.value || '';
    }

    const recipientInputValue = EditorHelper.getRecipientInputField(
      input.id,
      recipientInputs
    )?.value;
    return recipientInputValue || input.value || '';
  }

  static getDropdownValueContent(
    input: Field & { inputSettings: DropdownInputSettings },
    fields: Field[],
    recipientInput: EditorRecipientInput[]
  ) {
    return (
      ProsemirrorHelper.getInputContent(
        EditorHelper.getDropdownValue(input, recipientInput),
        fields
      ) || ProsemirrorHelper.getInputContent(input.inputSettings.placeholder, fields)
    );
  }

  static shouldLoadFont(font: Font, isSigningSite: boolean, isPreview: boolean, themeFont: Font) {
    return !!(font || (!isSigningSite && themeFont) || (isPreview && themeFont));
  }

  static isSlideShowLightboxVisible() {
    const lightboxes = Array.from(document.getElementsByClassName('lightbox-container'));
    return lightboxes.some(lightbox => lightbox['style'].display !== 'none');
  }

  static updateNodeFieldsToString(updateNodePayload: UpdateNodeRequest): UpdateNodeRequest {
    const { fields } = updateNodePayload.payload;
    if (!fields) {
      return updateNodePayload;
    }
    const updatedFields = fields.map(field => ({ ...field, value: field.value || '' }));
    return {
      ...updateNodePayload,
      payload: {
        ...updateNodePayload.payload,
        fields: updatedFields,
      },
    };
  }

  static getAllNodesFromContent = (content: EditorContent) =>
    content.sections
      .flatMap(({ rows }) => rows)
      .flatMap(({ cells }) => cells)
      .flatMap(({ nodes }) => nodes);

  static getAllNodes = (blocks: EditorBlock[]) =>
    blocks.flatMap(({ content }: EditorBlock) => EditorHelper.getAllNodesFromContent(content));

  static getFields = (blocks: EditorBlock[]) =>
    EditorHelper.getAllNodes(blocks)
      .filter((node: Text | Table) => !!node.fields)
      .flatMap(({ fields }: Text | Table) => fields);

  static getAllPricingTablesFromBlocks = (blocks: EditorBlock[]) =>
    EditorHelper.getAllNodes(blocks).filter(
      node => node.type === NodeType.PricingTable
    ) as PricingTable[];

  static getAllPricingSummariesFromBlocks = (blocks: EditorBlock[]) =>
    EditorHelper.getAllNodes(blocks).filter(
      node => node.type === NodeType.PricingSummary
    ) as PricingSummary[];

  static getAllPricingTablesFromContent = (content: EditorContent[]) =>
    content
      .flatMap(EditorHelper.getAllNodesFromContent)
      .filter(node => node.type === NodeType.PricingTable) as PricingTable[];

  static getDefaultMergeTagsSettings = (): MergeTagsSettings => ({
    fullName: true,
    title: false,
    companyName: true,
    companyNumber: false,
    email: false,
    signDate: {
      show: true,
      format: SignDateSettingsFormat.ISO,
    },
  });

  static isDealroomContext = (context: Context) =>
    [
      Context.DsrEdit,
      Context.DsrTemplate,
      Context.DsrResource,
      Context.DsrPublished,
      Context.DsrPreview,
    ].includes(context);

  static isPublicContext = (context: Context) =>
    [Context.Signing, Context.DsrPublished].includes(context);

  static isAssignedToParticipant = (participant: Participant, field: Field) => {
    if (!participant || !field) {
      return false;
    }

    const matchingLegacyRecipientId =
      !!participant.legacyRecipientId && field.recipientId === participant.legacyRecipientId;

    return (
      EditorHelper.hasMatchingParticipantId(participant, field) ||
      matchingLegacyRecipientId ||
      EditorHelper.hasMatchingUserId(participant, field)
    );
  };

  static hasMatchingParticipantId = (participant: Participant, field: Field) => {
    if (!participant || !field) {
      return false;
    }

    return !!participant.newParticipantId && field.participantId === participant.newParticipantId;
  };

  static hasMatchingUserOrParticipantId = (participant: Participant, field: Field) => {
    if (!participant || !field) {
      return false;
    }
    return (
      EditorHelper.hasMatchingUserId(participant, field) ||
      EditorHelper.hasMatchingParticipantId(participant, field)
    );
  };

  static hasMatchingUserId = (participant: Participant, field: Field) => {
    if (!participant || !field) {
      return false;
    }
    return !!field.userId && field.userId === participant.userId;
  };

  static getParticipantId = (participant: Participant) => {
    if (!participant) {
      return null;
    }
    return participant.newParticipantId || participant.id;
  };

  static hasCollaborators = (mergeValues: MergeValues) =>
    Object.prototype.hasOwnProperty.call(mergeValues, 'collaborators');
}
