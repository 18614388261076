export enum Context {
  Document = 'document',
  DocumentView = 'documentView',
  Resource = 'resource',
  ContractsTemplate = 'contractsTemplate',
  Signing = 'signing',
  DsrResource = 'dsrResource',
  DsrTemplate = 'dsrTemplate',
  DsrEdit = 'dsrEdit',
  DsrPublished = 'dsrPublished',
  DsrPreview = 'dsrPreview',
}
