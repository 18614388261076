import { nodeInputRule, VueNodeViewRenderer } from '@tiptap/vue-3';
import MergeTagView from './MergeTagView.vue';
import { MergeValueNode } from './merge-value-node';

export interface MergeTagOptions {
  onOpenMenu: () => void;
  isTemplate: boolean;
  isDsr: boolean;
}

export const MergeTagNode = MergeValueNode.extend<MergeTagOptions>({
  addOptions() {
    return {
      onOpenMenu: () => undefined,
      isTemplate: false,
      isDsr: false,
    };
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: /{{/,
        type: this.type,
      }),
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(MergeTagView as any);
  },
});
