import type { Editor as TipTapEditor } from '@tiptap/vue-3';
import type { Recipient } from '../../../types/Recipient';
import type { User } from '../../../users/types/user';
import type { CurrentUser } from '../../types/current-user';
import { MentionsConstants } from '../constants/mentions.constants';
import type { MentionParticipant } from '../types/mention-participant';

export class MentionsHelper {
  static filterIneligibleParticipants(
    currentUser: CurrentUser,
    participants: MentionParticipant[]
  ) {
    const ineligibleId = currentUser.recipientId || currentUser.userId;
    return participants.filter(
      ({ commentParticipant }: MentionParticipant) =>
        ![commentParticipant.recipientId, commentParticipant.userId].includes(ineligibleId)
    );
  }

  static getMentionParticipants(recipients: Recipient[], sender: User): MentionParticipant[] {
    const mappedRecipients: MentionParticipant[] = recipients.map(
      ({ id, fullName }: Recipient) => ({
        commentParticipant: { recipientId: id, userId: null },
        fullName,
      })
    );
    return [
      ...mappedRecipients,
      { commentParticipant: { userId: sender.id, recipientId: null }, fullName: sender.fullName },
    ];
  }

  static isTextValid(text: string): { show: boolean; query?: string } {
    if (text[text.length - 1] === ' ') {
      return { show: false };
    }
    const splitText: string[] = text.split(' ');
    const textUntilLastSpace = splitText[splitText.length - 1];
    const textLength = textUntilLastSpace.length;
    if (textUntilLastSpace[0] !== '@') {
      return { show: false };
    }

    if (textUntilLastSpace[textLength - 1] === '@') {
      return { show: true };
    }

    const queryWithAtSign = textUntilLastSpace.match(
      MentionsConstants.charactersAfterAtSignRegex
    )[0];
    const query = queryWithAtSign.slice(1);

    return {
      show: true,
      query,
    };
  }

  static shouldShowMentionParticipants(editor: TipTapEditor): { show: boolean; query?: string } {
    if (!editor) {
      return { show: false };
    }
    const { selection } = editor.view.state;
    if (selection.from !== selection.to) {
      return { show: false };
    }
    const textNodeBefore = selection.$to.nodeBefore?.text;
    if (!textNodeBefore) {
      return { show: false };
    }
    return this.isTextValid(textNodeBefore);
  }

  static findParticipant(id: string, participants: MentionParticipant[]): MentionParticipant {
    return participants.find(({ commentParticipant, participantId }) => {
      if (participantId) {
        return participantId === id;
      }
      return commentParticipant.userId === id || commentParticipant.recipientId === id;
    });
  }
}
