import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
export class SlideShowHelper {
  static getVisibleSlides(index: number, currentSlide: number, isGridView: boolean) {
    if (isGridView) {
      return index >= currentSlide * 9 && index <= currentSlide * 9 + 8;
    }
    return index === currentSlide;
  }

  static updateCurrentSlide(slideIndex: number, direction: number, currentSlides: number) {
    if (
      SlideShowHelper.isLastSlideAndDirNext(direction, slideIndex, currentSlides) ||
      SlideShowHelper.isFirstSlideAndDirPrev(direction, slideIndex)
    ) {
      return slideIndex;
    }
    return (slideIndex + direction + currentSlides) % currentSlides;
  }

  static isFirstSlideAndDirPrev(direction: number, currentSlides: number) {
    return currentSlides === 0 && direction === -1;
  }

  static isLastSlideAndDirNext(direction: number, slides: number, currentSlides: number) {
    return slides + direction === currentSlides;
  }

  static setProgress(loaded: number) {
    // slow down the loading animation the closer it gets to 100%
    return Math.round((Math.atan(loaded) / (Math.PI / 2)) * 100 * 1000) / 1000;
  }

  static extractSlideNrFromName(displayName: string) {
    if (displayName && displayName.includes(',')) {
      const lastCommaIndex = displayName.lastIndexOf(',');
      return displayName
        .slice(lastCommaIndex + 1)
        .trim()
        .replace('Slide ', '');
    } else return undefined;
  }

  static getErrorMessage(error: string) {
    return error === 'PDFEncrypted' ? t('pdf_is_encrypted') : t('failed-to-convert-pdf');
  }
}
