<template>
  <FriedDropdown
    :same-width-as-ref="true"
    :append-to-body="true"
    @key-up="handleKeyUp"
    @key-down="handleKeyDown"
    @key-enter="handleKeyEnter"
    @select="$emit('update-selected', input.inputSettings.options[selectedIndex])"
  >
    <template #trigger="{ open }">
      <FriedFieldSet
        :id="input.id"
        data-external="form-input-dropdown-input-view"
        :disabled="isDisabled"
        :tabindex="0"
        class="dropdown-option-box"
        :error="hasUpdateError"
        @keyup="preventOpenIfDisabled"
        @keydown.up.prevent
        @keydown.down.prevent
        @click="preventOpenIfDisabled"
      >
        <ProsemirrorViewerFormOption
          v-tooltip="tooltip"
          class="select-option-button"
          :content="getDropdownValueContent(input, fields, recipientInput)"
          :merge-values="mergeValues"
          :fields="fields"
          :is-updating="isUpdating"
        />
        <div :class="['select-chevron', { open }]">
          <FriedIcon icon="chevron-down" />
        </div>
      </FriedFieldSet>
    </template>
    <template #content>
      <div class="editor-form-dropdown-container">
        <div
          v-for="(option, index) in input.inputSettings.options"
          :key="'option' + index + option"
          class="items-container"
        >
          <ProsemirrorViewerFormOption
            v-if="showDropdownOption(option)"
            :class="['select-option-item', { selected: selectedIndex === index }]"
            :content="getInputContent(option, fields)"
            :merge-values="mergeValues"
            :fields="fields"
            @mouseover="isScrolling || (selectedIndex = index)"
            @mousedown.stop="$emit('update-selected', option)"
          />
        </div>
      </div>
    </template>
  </FriedDropdown>
</template>

<script lang="ts">
import { ProsemirrorHelper, EditorHelper } from '@getaccept/editor-lib-new';
import type { DropdownInputSettings, Field, MergeValues } from '@getaccept/editor-lib-new';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { PropType } from 'vue';
import { nextTick, watch, computed, defineComponent, ref } from 'vue';
import type { TooltipOptions } from '@getaccept/fried-tofu';
import ProsemirrorViewerFormOption from './ProsemirrorViewerFormOption.vue';

export default defineComponent({
  name: 'InputDropdown',
  components: {
    ProsemirrorViewerFormOption,
  },
  props: {
    checked: { type: Boolean },
    editable: { type: Boolean },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    input: {
      type: Object as PropType<Field & { inputSettings: DropdownInputSettings }>,
      default: () => ({}),
    },
    fieldFocused: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    isPdfViewer: { type: Boolean },
    tooltip: { type: Object as PropType<TooltipOptions>, default: () => ({}) },
    hasUpdateError: { type: Boolean },
    isUpdating: { type: Boolean },
  },
  emits: ['update-selected'],
  setup(props) {
    const isScrolling = ref(false);
    const selectedIndex = ref(0);

    const isDisabled = computed(() => !props.editable && !props.isPdfViewer);

    const preventOpenIfDisabled = (event: MouseEvent) => {
      if (!props.editable) {
        event.stopPropagation();
      }
    };

    const mergeTagValue = (mergeTag: string): string =>
      ProsemirrorHelper.getMergeValueFromMergeTag(
        ProsemirrorHelper.getTagIdFromMergeTag(mergeTag),
        props.fields,
        props.mergeValues
      );

    const showDropdownOption = (option: string): boolean => {
      const optionList = option.trim().split(' ');

      const filteredOption = optionList
        .map(item => (EditorHelper.hasMergeTags(item) ? mergeTagValue(item) : item))
        .filter(item => item !== '');

      return !!filteredOption.length;
    };

    const handleKeyEnter = () => {
      selectedIndex.value = props.input.inputSettings.options.indexOf(props.input.value);
    };

    const handleKeyUp = () => {
      if (selectedIndex.value === 0) {
        return;
      }
      selectedIndex.value--;
    };

    const handleKeyDown = () => {
      if (selectedIndex.value === props.input.inputSettings.options.length - 1) {
        return;
      }
      selectedIndex.value++;
    };

    watch(
      () => props.fieldFocused,
      (fieldFocused: boolean) => {
        if (fieldFocused) {
          const input: HTMLInputElement = document.getElementById(
            props.input.id
          ) as HTMLInputElement;
          input.focus({
            preventScroll: true,
          });
        }
      }
    );

    watch(selectedIndex, async () => {
      await nextTick();
      document.querySelector('.dropdown-content .select-option-item.selected')?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    });

    return {
      isScrolling,
      selectedIndex,
      isDisabled,
      preventOpenIfDisabled,
      handleKeyEnter,
      handleKeyUp,
      handleKeyDown,
      getDropdownValueContent: EditorHelper.getDropdownValueContent,
      getInputContent: ProsemirrorHelper.getInputContent,
      showDropdownOption,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-dropdown';
</style>
<style lang="scss">
.editor-form-dropdown-container {
  max-height: 325px;
  overflow-y: auto;
}
</style>
