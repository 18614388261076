import gql from 'graphql-tag';
import { FontFragments } from '@getaccept/lib-shared-new/src/fonts/font.fragment';

export const editorBlockContentFragment = gql`
  fragment editorBlockContentFragment on EditorContent {
    id
    displayName
    fontSettings {
      defaultFont {
        ...fontFragment
      }
      headingsFont {
        ...fontFragment
      }
    }
    sections {
      id
      displayName
      hidden
      rows {
        id
        type
        columnCount
        cells {
          id
          width
          type
          nodes {
            id
            type
            content
            imageId
            linkUrl
            dimensions {
              width
              height
            }
            displayName
            markdownFieldId
            mergeTagsSettings {
              fullName
              title
              companyName
              companyNumber
              email
              signDate {
                show
                format
              }
            }
            contractId
            contractDisplaySettings {
              name
              description
              title
              company
              email
            }
            recipientMaps {
              dsrRecipientId
              participantId
              contractRecipientId
            }
            borderStyle
            fields {
              id
              type
              category
              mergeKey
              recipientId
              userId
              customName
              value
              markdownFormattingOptions
              participantId
              inputSettings {
                type
                required
                label
                richTextLabel
                helpText
                connectedId
                width
                minValue
                maxValue
                placeholder
                options
                hide
              }
            }
            inputFieldSets {
              ids
            }
            caption
            heading
            imageSize
            imageAlign
            align
            url
            videoId
            imageTransforms {
              translateX
              translateY
              scale
            }
            dividerType
            dividerPadding
            tableStyle {
              borderColor
              headerRowBackgroundColor
              alternateRowColor
            }
            name
            preCalculated
            locked
            pricingTableSections {
              id
              displayName
              columns {
                id
                name
                displayName
                width
                enabled
                isCustom
              }
              rows {
                id
                values {
                  columnId
                  value
                }
                discountFlatFee
                taxFlatFee
                optionalProductFieldId
                variableQuantityFieldId
              }
              sectionSummary {
                discount {
                  value
                  enabled
                  flatFee
                  displayName
                }
                tax {
                  value
                  enabled
                  flatFee
                  displayName
                }
                price {
                  value
                  enabled
                  flatFee
                  displayName
                }
              }
            }
            pricingTableSummaryValues {
              displayName
              discount {
                value
                enabled
                flatFee
                displayName
              }
              tax {
                value
                enabled
                flatFee
                displayName
              }
              price {
                value
                enabled
                flatFee
                displayName
              }
            }
            currencySettings {
              formatOptions {
                currency
                currencyDisplay
              }
              locale
            }
            placeholder {
              ... on ResourcePlaceholder {
                type
                resourceIds
              }
              ... on ElementPlaceholder {
                type
                elementType
              }
            }
            pricingSummarySettings {
              displayPricingGroups
              includeZeroValueTables
            }
            labels {
              nameColumn
              priceColumn
              totalPrice
            }
            tables {
              id
              include
              displayName
            }
          }
        }
      }
    }
  }
  ${FontFragments.fontFragment}
`;
