<template>
  <span :data-comment-id="id" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
span {
  height: 0;
  width: 0;
}
</style>
