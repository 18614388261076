<template>
  <div
    :class="[
      'row-container flex-container',
      blockWidthClass,
      'grid-slides-' + gridSlides,
      { 'is-grid-view': isGridView },
    ]"
    @mouseover="handleMouseOver"
    @mouseout="handleMouseOut"
  >
    <div :class="['slideshow-content', blockWidthClass, { 'is-preview': isPreview }]">
      <div ref="slideshowWrapper" class="slideshow-wrapper">
        <SlideShowTopBar
          v-if="isSmallScreen && !isLightboxView"
          :block-width-class="blockWidthClass"
          :is-grid-view="isGridView"
          :route="lightboxRoute"
          :is-lightbox-view="isLightboxView"
          @toggle-grid-view="handleToggleGridView"
          @toggle-lightbox="handleToggleLightBoxView(true)"
        />
        <EditorCell
          v-for="(cell, index) in cells"
          :key="cell.id"
          class="slide"
          :transition-name="transitionName"
          :block-width-class="blockWidthClass"
          :cell-type="cell.type"
          :nodes="cell.nodes"
          :current-slide="currentSlide"
          :is-grid-view="isGridView"
          :is-pdf-viewer="isPdfViewer"
          :row-type="RowType.SlideShow"
          :parent-first-child="isParentFirstChild(index)"
          :parent-last-child="isParentLastChild(index)"
          :is-block-toggled="isBlockToggled"
          :page-width="pageWidth"
          @click="handleClick"
          @observe-node-visibility="
            $emit('observe-node-visibility', { ...$event, cellId: cell.id })
          "
          @mousedown="handleDragStart"
          @mouseup="handleDragEnd"
          @touchstart="handleDragStart"
          @touchend="handleDragEnd"
          @fetch-new-image="$emit('fetch-new-image', { ...$event, targetCellId: cell.id })"
        />
        <SlideShowBottomBar
          v-if="!isPreview"
          :block-width-class="blockWidthClass"
          :is-grid-view="isGridView"
          :current-slide="currentSlide"
          :is-selected="isMouseOver"
          :slides="slides"
          :is-sticky="isSticky"
          :is-small-screen="isSmallScreen"
          :route="lightboxRoute"
          :is-lightbox-view="isLightboxView"
          :grid-slides="gridSlides"
          @slide="slide"
          @toggle-grid-view="handleToggleGridView"
          @toggle-lightbox="handleToggleLightBoxView(true)"
        />
        <span ref="slideshowBottom"></span>
      </div>
    </div>
    <div
      v-show="isLightboxView"
      ref="lightboxContainerRef"
      :class="[
        'row-container lightbox-container',
        blockWidthClass,
        'grid-slides-' + gridSlides,
        { 'is-grid-view': isGridView },
      ]"
      @mousemove="handleShowToolbar"
    >
      <SlideShowTopBar
        v-if="isSmallScreen"
        :block-width-class="blockWidthClass"
        :is-grid-view="isGridView"
        :is-lightbox-view="isLightboxView"
        :route="lightboxRoute"
        @toggle-grid-view="handleToggleGridView"
        @toggle-lightbox="handleToggleLightBoxView(false)"
      />
      <div :class="['slideshow-content lightbox', blockWidthClass]">
        <div class="slideshow-wrapper">
          <EditorCell
            v-for="(cell, index) in cells"
            :key="cell.id"
            class="slide"
            :transition-name="transitionName"
            :block-width-class="blockWidthClass"
            :cell-type="cell.type"
            :nodes="cell.nodes"
            :current-slide="currentSlide"
            :is-grid-view="isGridView"
            :row-type="RowType.SlideShow"
            :is-pdf-viewer="isPdfViewer"
            :parent-first-child="isParentFirstChild(index)"
            :parent-last-child="isParentLastChild(index)"
            :is-block-toggled="isBlockToggled"
            :page-width="pageWidth"
            @click="handleClick"
            @observe-node-visibility="
              $emit('observe-node-visibility', { ...$event, cellId: cell.id })
            "
            @mousedown="handleDragStart"
            @mouseup="handleDragEnd"
            @touchstart="handleDragStart"
            @touchend="handleDragEnd"
            @fetch-new-image="$emit('fetch-new-image', { ...$event, targetCellId: cell.id })"
          />
        </div>
      </div>
      <SlideShowBottomBar
        v-if="!isPreview"
        :is-grid-view="isGridView"
        :class="{ 'show-bar': showToolbar }"
        :current-slide="currentSlide"
        :is-lightbox-view="isLightboxView"
        :is-small-screen="isSmallScreen"
        :route="lightboxRoute"
        :slides="slides"
        :grid-slides="gridSlides"
        @slide="slide"
        @toggle-grid-view="handleToggleGridView"
        @toggle-lightbox="handleToggleLightBoxView(false)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import { useIntersectionObserver, useThrottleFn } from '@vueuse/core';
import type { PropType } from 'vue';
import { onBeforeUnmount, onMounted, computed, defineComponent, ref } from 'vue';
import type { Cell } from '@getaccept/editor-lib-new';
import {
  SlideShowBottomBar,
  SlideShowTopBar,
  useSlideShow,
  EBlockWidth,
  RowType,
} from '@getaccept/editor-lib-new';
import EditorCell from './EditorCell.vue';

export default defineComponent({
  name: 'EditorRowSlideShow',
  components: {
    EditorCell,
    SlideShowBottomBar,
    SlideShowTopBar,
  },
  props: {
    cells: {
      type: Array as PropType<Cell[]>,
      default: () => [],
    },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    rowType: { type: String as PropType<RowType>, default: null },
    isBlockToggled: { type: Boolean, default: false },
    pageWidth: { type: Number, default: null },
    rowId: { type: String, default: null },
    isPreview: { type: Boolean },
    isPdfViewer: { type: Boolean },
  },
  emits: ['observe-node-visibility', 'fetch-new-image'],
  setup(props) {
    const slideshowWrapper = ref(null);
    const slideshowBottom = ref(null);
    const {
      current,
      transitionName,
      isGridView,
      isSticky,
      slides,
      gridSlides,
      observerOptions,
      slide,
      handleToggleGridView,
      handleClick,
      currentSlide,
      handleKeyDown,
      handleDragStart,
      handleDragEnd,
      isLightboxView,
      handleToggleLightBoxView,
      handleShowToolbar,
      showToolbar,
      lightboxContainerRef,
      handleResize,
      removeLightBoxQuery,
      lightboxRoute,
      isSmallScreen,
    } = useSlideShow(props);

    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);
    const isMouseOver = ref(false);
    const isVisible = ref(false);

    const isParentFirstChild = (index: number) => index === 0;

    const isParentLastChild = (index: number) => index === props.cells.length - 1;

    const handleMouseOver = () => {
      isMouseOver.value = true;
    };

    useIntersectionObserver(
      slideshowWrapper,
      ([{ isIntersecting }]) => {
        handleVisibility(isIntersecting);
      },
      {
        threshold: 0.7,
      }
    );
    const slideshowBottomObserveHandler = useThrottleFn(
      ([{ isIntersecting }]) => {
        observerOptions.value.callback(isIntersecting);
      },
      observerOptions.value.throttle,
      false,
      observerOptions.value.leading
    );
    useIntersectionObserver(slideshowBottom, slideshowBottomObserveHandler, {
      threshold: observerOptions.value.threshold,
    });

    const handleVisibility = (visible: boolean) => {
      isVisible.value = visible;
    };

    const handleKeyBoardNavigation = (event: KeyboardEvent) => {
      if (isLightboxView.value || isVisible.value) {
        handleKeyDown(event);
      }
    };

    const handleMouseOut = () => {
      isMouseOver.value = false;
    };

    onMounted(() => {
      window.visualViewport.addEventListener('resize', handleResize);
      window.addEventListener('keydown', handleKeyBoardNavigation);
      removeLightBoxQuery();
    });

    onBeforeUnmount(() => {
      window.visualViewport.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeyBoardNavigation);
    });

    return {
      rowTypeClass,
      current,
      transitionName,
      slides,
      RowType,
      lightboxRoute,
      currentSlide,
      handleClick,
      isParentFirstChild,
      isParentLastChild,
      handleToggleGridView,
      EBlockWidth,
      slide,
      isGridView,
      handleDragEnd,
      handleDragStart,
      observerOptions,
      isSticky,
      gridSlides,
      handleMouseOver,
      handleMouseOut,
      isMouseOver,
      isLightboxView,
      handleToggleLightBoxView,
      handleShowToolbar,
      showToolbar,
      lightboxContainerRef,
      isSmallScreen,
      handleVisibility,
      isVisible,
      slideshowWrapper,
      slideshowBottom,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-row';
@import '@getaccept/editor-lib-new/src/scss/editor-row-slideshow';
</style>
<style lang="scss">
@import '@getaccept/editor-lib-new/src/scss/editor-row-slideshow-lightbox';
</style>
