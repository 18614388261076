<template>
  <EditorNodeWrapper
    :class="['node-container', 'divider-container', rowTypeClass, blockWidthClass, dividerPadding]"
  >
    <div class="divider-content">
      <hr class="divider" :class="dividerType" />
    </div>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { Divider, EBlockWidth, RowType } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeDivider',
  components: {
    EditorNodeWrapper,
  },
  props: {
    node: {
      type: Object as PropType<Divider>,
      default: () => ({}),
    },
    blockWidthClass: { type: String as PropType<EBlockWidth>, default: null },
    rowType: { type: String as PropType<RowType>, default: null },
  },
  setup(props) {
    const dividerType = computed(() => props.node?.dividerType?.toLowerCase());
    const dividerPadding = computed(() => `padding-${props.node?.dividerPadding?.toLowerCase()}`);
    const rowTypeClass = computed(() => `row-type-${props.rowType?.toLowerCase()}`);

    return {
      dividerType,
      dividerPadding,
      rowTypeClass,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node';
@import '@getaccept/editor-lib-new/src/scss/editor-node-divider';
</style>
