<template>
  <div class="section-container">
    <div class="section-header">
      {{ section.displayName }}
    </div>
    <PricingTableColumnHeaders
      v-if="!isBlockWidthSmall"
      :style="gridStyle"
      :columns="columns"
      :enabled-columns="enabledColumns"
      class="column-headers"
    />
    <PricingTableRow
      v-for="(row, index) in sortedRows"
      :key="row.id"
      class="sub-element"
      :data-external="`pricing-table-row-index:${index}`"
      :merge-values="mergeValues"
      :fields="fields"
      :recipient="recipient as any"
      :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
      :recipient-input="recipientInput"
      :row="row"
      :columns="columns"
      :context="context"
      :grid-style="gridStyle"
      :currency-settings="currencySettings"
      :is-pre-calculated="isPreCalculated"
      :is-block-width-small="isBlockWidthSmall"
      @update-input-value="$emit('update-input-value', $event)"
    />
    <div class="section-footer viewer">
      <PricingTableSectionSummary
        :section-summary="sectionSummary"
        :columns="columns"
        :currency-settings="currencySettings"
        :is-pre-calculated="isPreCalculated"
        class="sub-element viewer"
      />
    </div>
  </div>
</template>
<script lang="ts">
import type {
  PricingTableSection as IPricingTableSection,
  PricingTableCurrencySettings,
  MergeValues,
  Field,
} from '@getaccept/editor-lib-new';
import { Context, PricingTableHelper } from '@getaccept/editor-lib-new';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import { usePricingTableColumns } from '@getaccept/editor-lib-new/src/composables/pricing-table-columns.composable';
import { usePricingTableRows } from '@getaccept/editor-lib-new/src/composables/pricing-table-rows.composable';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import PricingTableColumnHeaders from './PricingTableColumnHeaders.vue';
import PricingTableRow from './PricingTableRow.vue';
import PricingTableSectionSummary from './PricingTableSectionSummary.vue';

export default defineComponent({
  name: 'PricingTableSection',
  components: {
    PricingTableColumnHeaders,
    PricingTableRow,
    PricingTableSectionSummary,
  },
  props: {
    sections: { type: Array as PropType<IPricingTableSection[]>, default: () => [] },
    sectionId: { type: String, default: '' },
    currencySettings: {
      type: Object as PropType<PricingTableCurrencySettings>,
      default: () => ({}),
    },
    isPreCalculated: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    context: { type: String as PropType<Context>, default: () => Context.DocumentView },
    isBlockWidthSmall: { type: Boolean },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    recipient: { type: Object as PropType<Recipient>, default: () => ({}) },
    allowedToEditAfterPartiallySigned: { type: Boolean },
  },
  emits: ['update-input-value'],
  setup(props) {
    const section = computed(() => props.sections.find(({ id }) => id === props.sectionId));

    const sectionSummary = computed(() =>
      PricingTableHelper.getSectionSummary(section.value, props.fields, props.isPreCalculated)
    );

    const { columns, enabledColumns, columnWidths } = usePricingTableColumns(section);
    const { rows, sortedRows } = usePricingTableRows(section);

    const gridStyle = computed(() => PricingTableHelper.getGridStyle(columnWidths.value));

    return {
      section,
      sectionSummary,
      columns,
      enabledColumns,
      rows,
      sortedRows,
      gridStyle,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-section';

.section-container {
  padding: var(--spacing-50);
  position: relative;

  .section-footer {
    justify-content: flex-end;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    border: $editor-focus-outline solid var(--blue-60);
    opacity: 0;
    transition: opacity 0.2s;
    border-radius: $editor-medium-border-radius;
  }

  &.hovered,
  &.selected {
    &::after {
      opacity: 1;
    }
  }
}
</style>
