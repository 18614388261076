import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { Participant } from '@getaccept/dsr-shared-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { Field } from '../types';
import { FieldInputType } from '../types/enums';
import { EditorHelper } from './editor.helper';

export class EditorFieldHelper {
  static anyFieldHasEmptyLabel(fields: Field[]) {
    return fields.some(field => !field.inputSettings.label);
  }

  static anyRequiredSenderFieldHasEmptyValue(fields: Field[]) {
    return fields.some(field => field.userId && field.inputSettings.required && !field.value);
  }

  static anyDropdownHasNoOptions(fields: Field[]) {
    return fields.some(
      field =>
        field.inputSettings.type === FieldInputType.Dropdown && !field.inputSettings.options?.length
    );
  }

  static isAssignedToSender = (user: User, input: Field) => {
    if (!user) {
      return false;
    }
    return (
      user?.id === input.userId ||
      user?.id === input.participantId ||
      EditorHelper.hasMatchingUserOrParticipantId(user as Participant, input)
    );
  };

  static isAssignedToRecipient = (recipient: Recipient, input: Field) => {
    if (!recipient) {
      return false;
    }
    return (
      recipient.id === input.recipientId ||
      recipient.id === input.participantId ||
      EditorHelper.isAssignedToParticipant(recipient as Participant, input)
    );
  };
}
