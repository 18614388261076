import type { Font } from '../types/font';
import { cyrillicExt } from '../types/unicode-range';

export const openSansV29: Font = {
  fontFamily: 'open-sans-v29-Vz5u80AXfyqf5xe917VYY',
  displayName: 'Open Sans',
  sources: [
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'greekExt',
      src: 'open-sans-v29-greek-ext-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'cyrillic',
      src: 'open-sans-v29-cyrillic-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'cyrillic',
      src: 'open-sans-v29-cyrillic-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: cyrillicExt,
      src: 'open-sans-v29-cyrillic-ext-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: cyrillicExt,
      src: 'open-sans-v29-cyrillic-ext-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: cyrillicExt,
      src: 'open-sans-v29-cyrillic-ext-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: cyrillicExt,
      src: 'open-sans-v29-cyrillic-ext-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'cyrillic',
      src: 'open-sans-v29-cyrillic-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'cyrillic',
      src: 'open-sans-v29-cyrillic-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'greek',
      src: 'open-sans-v29-greek-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'greek',
      src: 'open-sans-v29-greek-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'greekExt',
      src: 'open-sans-v29-greek-ext-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'greekExt',
      src: 'open-sans-v29-greek-ext-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'greekExt',
      src: 'open-sans-v29-greek-ext-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'greek',
      src: 'open-sans-v29-greek-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'greek',
      src: 'open-sans-v29-greek-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'hebrew',
      src: 'open-sans-v29-hebrew-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'hebrew',
      src: 'open-sans-v29-hebrew-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'hebrew',
      src: 'open-sans-v29-hebrew-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'hebrew',
      src: 'open-sans-v29-hebrew-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'latin',
      src: 'open-sans-v29-latin-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'latin',
      src: 'open-sans-v29-latin-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'latinExt',
      src: 'open-sans-v29-latin-ext-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'latinExt',
      src: 'open-sans-v29-latin-ext-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'latinExt',
      src: 'open-sans-v29-latin-ext-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'latinExt',
      src: 'open-sans-v29-latin-ext-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'latin',
      src: 'open-sans-v29-latin-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'latin',
      src: 'open-sans-v29-latin-regular.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'bold',
      unicodeRange: 'vietnamese',
      src: 'open-sans-v29-vietnamese-700.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'bold',
      unicodeRange: 'vietnamese',
      src: 'open-sans-v29-vietnamese-700italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'italic',
      fontWeight: 'normal',
      unicodeRange: 'vietnamese',
      src: 'open-sans-v29-vietnamese-italic.woff2',
      format: 'woff2',
    },
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      unicodeRange: 'vietnamese',
      src: 'open-sans-v29-vietnamese-regular.woff2',
      format: 'woff2',
    },
  ],
};
