<template>
  <div class="input-flex-container">
    <div
      v-for="(input, inputIndex) in inputSet"
      :key="input.id + 'label'"
      :style="getInputStyle(input)"
      :class="['input-label-container', 'label' + inputIndex]"
    >
      <InputSetLabel
        :input="input"
        :merge-values="mergeValues"
        :fields="fields"
        :editable="getIsEditable(input)"
      />
    </div>
    <div class="row-break"></div>
    <div
      v-for="(input, inputIndex) in inputSet"
      :key="input.id + 'input'"
      :style="getInputStyle(input)"
      :class="['input' + inputIndex]"
      :data-external="`view-form-input-${inputIndex}`"
      class="input-with-help"
    >
      <InputSetField
        :input="input"
        :merge-values="mergeValues"
        :fields="fields"
        :failed-input-updates="failedInputUpdates"
        :recipient-input="recipientInput"
        :recipient="recipient"
        :sender="sender"
        :context="context"
        :user-id="userId"
        :editable="getIsEditable(input)"
        :has-focus="hasFocus(input)"
        :updating-inputs="updatingInputs"
        :is-pdf-viewer="isPdfViewer"
        @update-value="$emit('update-value', $event)"
      />
    </div>
  </div>
</template>
<script lang="ts">
import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import { Context } from '@getaccept/editor-lib-new';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { PropType } from 'vue';
import { computed, defineComponent } from 'vue';
import { SigningHelper } from '@getaccept/lib-shared-new/src/signature/helpers/signing.helper';
import type { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import { useInputFieldView } from '../composables/input-field-view.composable';
import InputSetLabel from './InputSetLabel.vue';
import InputSetField from './InputSetField.vue';

export default defineComponent({
  name: 'InputSet',
  components: {
    InputSetField,
    InputSetLabel,
  },
  props: {
    context: { type: String as PropType<Context>, default: () => Context.Document },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    focusedRequiredId: { type: String, default: '' },
    inputSet: { type: Array as PropType<Field[]>, default: () => [] },
    isMobile: { type: Boolean },
    isPdfViewer: { type: Boolean },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    recipient: { type: Object as PropType<Recipient>, default: () => ({}) },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    sender: { type: Object as PropType<User>, default: () => ({}) },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    userId: { type: String, default: '' },
  },
  emits: ['update-value'],
  setup(props) {
    const { getIsAssignedToCurrentUser } = useInputFieldView(props);

    const hasRecipientSigned = computed(() =>
      SigningHelper.hasRecipientSigned((props.recipient as Recipient)?.status as RecipientStatus)
    );

    const hasFocus = (input: Field) => {
      if (!getIsAssignedToCurrentUser(input)) {
        return false;
      }
      return input.id === props.focusedRequiredId;
    };

    const getIsEditable = (input: Field) =>
      getIsAssignedToCurrentUser(input) &&
      !hasRecipientSigned.value &&
      ![Context.DocumentView, Context.DsrEdit].includes(props.context) &&
      !props.isPdfViewer;

    const getInputStyle = (input: Field) => {
      const width = props.isMobile ? 100 : input.inputSettings?.width || 100;
      return `width: ${width}%;`;
    };

    return {
      getInputStyle,
      getIsAssignedToCurrentUser,
      getIsEditable,
      hasFocus,
      hasRecipientSigned,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/input-set';
</style>
