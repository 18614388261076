<template>
  <div class="signature-pad-container">
    <div class="signature-container">
      <VueSignaturePad
        id="signature-box"
        ref="signaturePad"
        data-external="signature-container-signature-box"
        :width="width"
        :height="height"
        :options="options"
        :save-type="saveType"
        @mouseover="hideText"
      />
      <div class="text-support-line"></div>
      <span v-if="showInfoTextSection" class="signature-info-text">
        {{ t('draw-your-signature-here') }}
      </span>
    </div>
    <FriedRoundedButton
      data-external="draw-signature-clear"
      class="clear-signature-button"
      hide-text-mobile
      icon-align-right
      @click="undo"
    >
      <template #icon>
        <FriedIcon icon="arrow-undo" />
      </template>
      {{ t('undo') }}
    </FriedRoundedButton>
  </div>
</template>

<script lang="ts">
import { SignatureConstants } from '@getaccept/lib-shared-new/src/signature/constants/signature.constants';
import { SignatureHelper } from '@getaccept/lib-shared-new/src/helpers/signature.helper';
import { useI18n } from 'vue-i18n';
import { defineComponent, computed, ref } from 'vue';

export default defineComponent({
  props: {
    height: {
      type: String,
      default: SignatureConstants.drawSignatureHeight,
    },
    width: {
      type: String,
      default: SignatureConstants.drawSignatureWidth,
    },
    saveType: {
      type: String,
      default: SignatureConstants.saveType,
    },
  },
  emits: ['reset', 'signature'],
  setup(_, { emit }) {
    const { t } = useI18n();
    const showInfoText = ref(true);
    const signaturePad = ref(null);

    const showInfoTextSection = computed(() => showInfoText.value);

    const hideText = () => {
      showInfoText.value = false;
    };

    const reset = () => {
      if (!signaturePad.value) {
        return;
      }
      signaturePad.value.clearSignature();
      emit('reset');
    };

    const undo = () => {
      signaturePad.value.undoSignature();
      setSignature();
    };

    const setSignature = () => {
      const rawData = signaturePad.value.toData() || [];
      const jSignature = SignatureHelper.signPadNativeToJSignature(rawData);
      const signatureBase30 = SignatureHelper.nativeToBase30(jSignature);
      const { isEmpty, data } = signaturePad.value.saveSignature('image/svg+xml');
      if (!!signatureBase30 && !isEmpty) {
        emit('signature', { signatureBase30, signatureBase64: data });
      } else {
        reset();
      }
    };

    const options = {
      penColor: SignatureConstants.writeSignatureColor,
      onEnd: setSignature,
      onBegin: hideText,
      backgroundColor: SignatureConstants.writeSignatureBackgroundColor,
    };

    return {
      t,
      signaturePad,
      options,
      undo,
      setSignature,
      reset,
      hideText,
      showInfoTextSection,
      showInfoText,
    };
  },
});
</script>
<style lang="scss" scoped>
.signature-pad-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .signature-container {
    margin-bottom: var(--spacing-50);
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    border: 1px solid var(--gray-89);
    border-radius: var(--radius-medium);

    #signature-box {
      cursor:
        url('../../../assets/EditIcon.png') 5 30,
        auto;
    }

    .signature-info-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .clear-signature-button {
    position: absolute;
    top: var(--spacing-50);
    right: var(--spacing-50);
  }

  .text-support-line {
    border-bottom: 1px solid var(--gray-80);
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -90%);
    width: 80%;
  }

  .action-buttons {
    display: flex;

    > * {
      width: 100%;
      margin-top: var(--spacing-50);

      &:nth-child(1) {
        margin-right: var(--spacing-50);
      }
    }
  }
}
</style>
