<template>
  <div ref="wrapper">
    <slot />
  </div>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useIntersectionObserver } from '@vueuse/core';

export default defineComponent({
  name: 'EditorNodeWrapper',
  emits: ['observe-node-visibility'],
  setup(_props, { emit }) {
    const wrapper = ref(null);

    useIntersectionObserver(wrapper, ([{ isIntersecting }]) => {
      emit('observe-node-visibility', { visible: isIntersecting });
    });

    return { wrapper };
  },
});
</script>
