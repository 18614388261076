import { SignatureConstants } from '../signature/constants/signature.constants';
import type { SignatureData } from '../signature/types/signature-data';

type CharMap = { [key: string]: string };
type Stroke = { x: number[]; y: number[] };
type NativeBase30 = Stroke[];

const keyExists = (key: string, map: CharMap): boolean => Object.keys(map).includes(key);
export class SignatureHelper {
  static penColor: string = SignatureConstants.writeSignatureColor;
  static characterList: string[] = SignatureConstants.signatureCharacterList.split('');
  static minus = 'Z';
  static plus = 'Y';
  static bitness: number = SignatureHelper.characterList.length / 2;
  static charMap = {};
  static charMap_reverse = {};
  static chunkSeparator = '_';
  static allChars: string[] = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWX'.split(
    ''
  );

  public static signPadNativeToJSignature(signPadNative: any[]): string[] {
    const jsigNative: any[] = [];
    let xPoints: any[] = [],
      yPoints: any[] = [];
    signPadNative.forEach(pointgroup => {
      (xPoints = []), (yPoints = []);
      for (let p = 0; p < pointgroup.points.length; p++) {
        xPoints.push(pointgroup.points[p].x);
        yPoints.push(pointgroup.points[p].y);
      }
      jsigNative.push({ x: xPoints, y: yPoints });
    });
    return jsigNative;
  }

  public static base30ToNative(datastring: string): NativeBase30 {
    const data: NativeBase30 = [];
    const chunks: string[] = datastring.split(this.chunkSeparator);

    for (let i = this.bitness - 1; i > -1; i--) {
      this.charMap[this.allChars[i]] = this.allChars[i + this.bitness];
      this.charMap_reverse[this.allChars[i + this.bitness]] = this.allChars[i];
    }

    for (let i = 0; i < chunks.length / 2; i++) {
      data.push({
        x: this.uncomporessStrokeLeg(chunks[i * 2]),
        y: this.uncomporessStrokeLeg(chunks[i * 2 + 1]),
      });
    }
    return data;
  }

  private static uncomporessStrokeLeg(strokeLeg: string): number[] {
    const answer: number[] = [];
    const chars: string[] = strokeLeg.split('');
    let char = '';
    let polarity = 1;
    let partial: string[] = [];
    let preprewhole = 0;
    let prewhole = 0;
    for (let i = 0; i < chars.length; i++) {
      char = chars[i];
      if (keyExists(char, this.charMap) || char === this.minus || char === this.plus) {
        if (partial.length !== 0) {
          prewhole = parseInt(partial.join(''), this.bitness) * polarity + preprewhole;
          answer.push(prewhole);
          preprewhole = prewhole;
        }
        if (char === this.minus) {
          polarity = -1;
          partial = [];
        } else if (char === this.plus) {
          polarity = 1;
          partial = [];
        } else {
          partial = [char];
        }
      } else {
        partial.push(this.charMap_reverse[char]);
      }
    }
    answer.push(parseInt(partial.join(''), this.bitness) * polarity + preprewhole);
    return answer;
  }

  public static jSignToSigpad(jSigNative: any[]): any[] {
    const signPadNative: any[] = [];
    let points: any[] = [];
    jSigNative.forEach(pointgroup => {
      points = [];
      for (let p = 0; p < pointgroup.x.length; p++) {
        points.push({ x: pointgroup.x[p], y: pointgroup.y[p] });
      }
      signPadNative.push({ color: this.penColor, points: points });
    });
    return signPadNative;
  }

  public static nativeToBase30(data: any): string {
    for (let i: number = this.bitness - 1; i > -1; i--) {
      this.charMap[this.characterList[i]] = this.characterList[i + this.bitness];
      this.charMap_reverse[this.characterList[i + this.bitness]] = this.characterList[i];
    }
    const nativeDataLength: number = data.length,
      answer = [];
    let stroke = null;
    for (let i = 0; i < nativeDataLength; i++) {
      stroke = data[i];
      answer.push(this.compressStrokeleg(stroke.x));
      answer.push(this.compressStrokeleg(stroke.y));
    }
    return answer.join(this.chunkSeparator);
  }

  public static compressStrokeleg(data: number[]): string {
    const answer = [],
      strokeDataLength: number = data.length;

    let lastWhole = 0,
      lastPolarity = 1,
      nWhole: number,
      n: number,
      absN: number;

    for (let i = 0; i < strokeDataLength; i++) {
      nWhole = Math.round(data[i]);
      n = nWhole - lastWhole;
      lastWhole = nWhole;
      if (n < 0 && lastPolarity > 0) {
        lastPolarity = -1;
        answer.push(this.minus);
      } else if (n > 0 && lastPolarity < 0) {
        lastPolarity = 1;
        answer.push(this.plus);
      }
      absN = Math.abs(n);
      if (absN >= this.bitness) {
        answer.push(this.remapTailChars(absN.toString(this.bitness)));
      } else {
        answer.push(absN.toString(this.bitness));
      }
    }
    return answer.join('');
  }

  public static remapTailChars(absN: string): string {
    const chars: string[] = absN.split(''),
      l: number = chars.length;
    for (let i = 1; i < l; i++) {
      chars[i] = this.charMap[chars[i]];
    }
    return chars.join('');
  }

  public static convertSignature(ctx, canvas): any[] {
    const imgData: any = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const offset = 1;
    const signPoint: any[] = [];
    const pix: any[] = imgData.data;
    let firstPoint: number[] = null;
    let lastPoint: number[] = null;
    for (let i = 0, n: number = pix.length; i < n; i += 4) {
      if (pix[i + 3] == 255 && !firstPoint) {
        firstPoint = [(i / 4) % canvas.width, Math.ceil(i / 4 / canvas.width)];
      } else if (pix[i + 3] == 0 && firstPoint && lastPoint) {
        signPoint.push({
          x: [firstPoint[0], lastPoint[0] - offset],
          y: [firstPoint[1], lastPoint[1] - offset],
        });
        firstPoint = null;
        lastPoint = null;
      } else {
        lastPoint = [(i / 4) % canvas.width, Math.ceil(i / 4 / canvas.width)];
      }
    }
    return signPoint;
  }

  public static convertRawStrokes(
    rawStrokes: { color: string; x: number; y: number; time: number }[][]
  ): SignatureData[] {
    return rawStrokes.map((stroke: { color: string; x: number; y: number; time: number }[]) => ({
      color: stroke[0].color,
      points: stroke.map((strokeData: { color: string; x: number; y: number; time: number }) => ({
        time: strokeData.time,
        x: strokeData.x,
        y: strokeData.y,
      })),
    }));
  }

  public static encodeUnicodeToBase64(str: string): string {
    const findCharCodeRegex = /%([0-9A-F]{2})/g;

    const encoded: string = encodeURIComponent(str);

    const replacedCharCodes: string = encoded.replace(findCharCodeRegex, (_match, p1) => {
      const charCode = parseInt(`0x${p1}`);
      return String.fromCharCode(charCode);
    });
    return btoa(replacedCharCodes);
  }
}
