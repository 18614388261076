import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { PricingSummaryTable, PricingTable } from '../types/models';
import { PricingTableHelper, getSummaryValues, getTotalPriceValue } from './';

export class PricingSummaryHelper {
  private static pricingTables = (
    allPricingTables: PricingTable[],
    recipientInput: EditorRecipientInput[],
    tables: PricingSummaryTable[]
  ) =>
    allPricingTables.map(pricingTable => ({
      id: pricingTable.id,
      name: pricingTable.name,
      displayName: tables.find(table => table.id === pricingTable.id)?.displayName,
      currencySettings: pricingTable.currencySettings,
      value: getTotalPriceValue(
        getSummaryValues(
          pricingTable,
          PricingTableHelper.fieldsWithRecipientInput(pricingTable.fields, recipientInput)
        )
      ),
    }));

  static pricingTablesGroupedByCurrency = (
    allPricingTables: PricingTable[],
    recipientInput: EditorRecipientInput[],
    tables: PricingSummaryTable[]
  ) => {
    const grouped = new Map<
      string,
      { tables: ReturnType<typeof PricingSummaryHelper.pricingTables>; totalPrice: string }
    >();

    PricingSummaryHelper.pricingTables(allPricingTables, recipientInput, tables).forEach(
      pricingTable => {
        const pricingSummaryTable = tables.find(table => table.id === pricingTable.id);
        if (!pricingSummaryTable?.include) {
          return;
        }

        const currency = pricingTable.currencySettings?.formatOptions?.currency || '';

        if (!grouped.has(currency)) {
          grouped.set(currency, { tables: [], totalPrice: '0' });
        }

        grouped.get(currency).tables.push(pricingTable);
      }
    );

    grouped.forEach(group => {
      group.totalPrice = String(
        group.tables.reduce((acc, pricingTable) => acc + Number(pricingTable.value), 0)
      );
    });

    return grouped;
  };
}
