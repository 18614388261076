export enum NodeType {
  Table = 'Table',
  Divider = 'Divider',
  Image = 'Image',
  Text = 'Text',
  Form = 'Form',
  PricingTable = 'PricingTable',
  Link = 'Link',
  Video = 'VideoNode',
  Signature = 'Signature',
  ContractLink = 'ContractLink',
  ContentPlaceholder = 'ContentPlaceholder',
  PricingSummary = 'PricingSummary',
}

export enum CellType {
  ImageAndText = 'ImageAndText',
  ImageOnly = 'ImageOnly',
  TextOnly = 'TextOnly',
}

export enum RowType {
  Columns = 'Columns',
  Standard = 'Standard',
  SideBySide = 'SideBySide',
  SlideShow = 'SlideShow',
}

export enum DividerType {
  Dashed = 'Dashed',
  Thin = 'Thin',
  Thick = 'Thick',
  Transparent = 'Transparent',
}

export enum DividerPadding {
  None = 'None',
  Small = 'Small',
  Medium = 'Medium',
  Large = 'Large',
}

export enum ImageSize {
  Full = 'Full',
  Half = 'Half',
  Original = 'Original',
  Cover = 'Cover',
}

export enum Align {
  Left = 'Left',
  Right = 'Right',
  Center = 'Center',
}

export enum BorderStyle {
  Outline = 'Outline',
  Line = 'Line',
  Box = 'Box',
}

export enum SignDateSettingsFormat {
  ISO = 'ISO',
  Short = 'Short',
  Long = 'Long',
}
