<template>
  <div>
    <div
      v-if="!isBlockWidthSmall"
      class="rows-container"
      :class="{ optional: hasOptionalProductDeselected }"
      :style="gridStyle"
    >
      <div class="row-value">
        <div v-if="getRowOptionalProductEnabled(row, fields)" class="optional">
          <PricingTableCheckbox
            :input="rowOptionalProductInputField()"
            :disabled="!isEditable"
            @update-checked="handleUpdateChecked"
          />
        </div>
        <div class="row-value name-column sub-row-value">
          <div class="name viewer">
            {{ getRowNameValue(columns, getRowValues(row)) }}
          </div>
          <div v-show="showDescription" class="description">
            <PricingTableDescriptionInput
              :merge-values="mergeValues"
              :fields="fields"
              :content="getRowDescriptionValue(columns, getRowValues(row))"
            />
          </div>
          <div v-show="showSku" class="sku">
            {{ getRowSkuValue(columns, getRowValues(row)) }}
          </div>
        </div>
      </div>
      <div
        v-for="rowValue in getFilteredRowValues(row, columns)"
        :key="rowValue.columnId"
        :class="[
          'row-value',
          {
            strikethrough: hasOptionalProductDeselected,
            'custom-row': hasCustomColumn(columns, rowValue.columnId),
          },
        ]"
      >
        <template
          v-if="
            rowValue.columnId === getColumnIdFromName(columns, EPricingTableColumn.Units) &&
            getRowVariableQuantityEnabled(row, fields)
          "
        >
          <FriedFloater
            v-if="isVariableQuantityEditable"
            v-click-outside="handleCloseVariableQuantityMenu"
            :show="showVariableQuantityMenu"
            :append-to-body="false"
            :placement="Placement.BottomEnd"
            class="variable-quantity-container"
          >
            <template #reference>
              <div
                class="variable-quantity-value"
                tabindex="0"
                @click="showVariableQuantityMenu = !showVariableQuantityMenu"
                @keydown.enter.space="showVariableQuantityMenu = true"
              >
                <div class="variable-quantity-text">
                  {{ rowVariableQuantityField.value }}
                </div>
                <FriedIcon
                  :class="['chevron', { opened: showVariableQuantityMenu }]"
                  icon="chevron-down"
                />
              </div>
            </template>
            <div class="modifier-menu">
              <PricingTableUnitsModifierMenu
                :input-field="rowVariableQuantityField as any"
                :disabled="isDocumentView"
                :open="showVariableQuantityMenu"
                @update-field="handleUpdateVariableQuantityField"
                @close="showVariableQuantityMenu = false"
              />
            </div>
          </FriedFloater>
          <div v-else class="value">
            {{ rowVariableQuantityField.value }}
          </div>
        </template>
        <div
          v-else-if="rowValue.columnId === getColumnIdFromName(columns, EPricingTableColumn.Tax)"
          class="formatted-tax-text"
          data-external="editor-pricing-table-row-value-tax"
        >
          {{ getRowFormattedTax(row, columns, currencySettings) }}
        </div>
        <div v-else class="value">
          {{ getFormat(rowValue) }}
        </div>
      </div>
      <template v-if="showTotalPrice">
        <div
          v-if="isPreCalculated"
          data-external="total-price-column"
          class="row-value total-price-column"
          :class="{ precalculated: isPreCalculated }"
        >
          <div :class="['total-price', { strikethrough: hasDiscount(columns, getRowValues(row)) }]">
            {{ getFormattedPrice(getRowTotalPrice(isPreCalculated, row, columns, fields)) }}
          </div>
          <div
            v-show="showDiscount"
            tabindex="0"
            class="discount"
            :class="{ empty: getRowDiscountValueIsEmpty(columns, getRowValues(row)) }"
          >
            {{ getRowFormattedDiscount(isPreCalculated, row, columns, fields, currencySettings) }}
          </div>
          <div v-show="showDiscount" :class="['discounted-price']">
            {{ getFormattedPrice(getRowNetPrice(isPreCalculated, row, columns, fields)) }}
          </div>
        </div>
        <div
          v-else
          :class="['row-value total-price-column', { strikethrough: hasOptionalProductDeselected }]"
          data-external="total-price-column"
        >
          <div
            class="total-price"
            :class="{ strikethrough: hasDiscount(columns, getRowValues(row)) }"
          >
            {{
              currencyFormat(
                getRowTotalPrice(isPreCalculated, row, columns, fields),
                currencySettings
              )
            }}
          </div>
          <div
            v-show="showDiscount"
            tabindex="0"
            class="discount"
            :class="{ empty: getRowDiscountValueIsEmpty(columns, getRowValues(row)) }"
          >
            {{ getRowFormattedDiscount(isPreCalculated, row, columns, fields, currencySettings) }}
          </div>
          <div v-show="hasDiscount(columns, getRowValues(row))" class="discounted-price">
            {{
              currencyFormat(
                getRowNetPrice(isPreCalculated, row, columns, fields),
                currencySettings
              )
            }}
          </div>
        </div>
      </template>
    </div>
    <div v-if="isBlockWidthSmall" class="rows-container cards">
      <div class="row-value name-column">
        <div class="header">
          {{
            getColumnDisplayName(getColumnIdFromName(columns, EPricingTableColumn.Name), columns)
          }}
        </div>
        <div :class="['name', { 'optional-product': getRowOptionalProductEnabled(row, fields) }]">
          <div v-if="getRowOptionalProductEnabled(row, fields)" class="optional-view">
            <PricingTableCheckbox
              :input="rowOptionalProductInputField()"
              :disabled="!isEditable"
              @update-checked="handleUpdateChecked"
            />
          </div>
          {{ getRowNameValue(columns, getRowValues(row)) }}
        </div>
        <div v-show="showDescription" class="description">
          <PricingTableDescriptionInput
            :merge-values="mergeValues"
            :fields="fields"
            :content="getRowDescriptionValue(columns, getRowValues(row))"
          />
        </div>
        <div v-show="showSku" class="sku">
          {{ getRowSkuValue(columns, getRowValues(row)) }}
        </div>
      </div>
      <div
        v-for="rowValue in getFilteredRowValues(row, columns)"
        :key="rowValue.columnId"
        :class="[
          'row-value',
          { 'custom-row-column-format': hasCustomColumn(columns, rowValue.columnId) },
        ]"
      >
        <div class="header">
          {{ getColumnDisplayName(rowValue.columnId, columns) }}
        </div>
        <template
          v-if="
            rowValue.columnId === getColumnIdFromName(columns, EPricingTableColumn.Units) &&
            getRowVariableQuantityEnabled(row, fields)
          "
        >
          <FriedFloater
            v-if="isVariableQuantityEditable"
            v-click-outside="handleCloseVariableQuantityMenu"
            :show="showVariableQuantityMenu"
            :append-to-body="false"
            :placement="Placement.BottomEnd"
            class="variable-quantity-container"
          >
            <template #reference>
              <div
                class="variable-quantity-value"
                tabindex="0"
                @click="showVariableQuantityMenu = !showVariableQuantityMenu"
                @keydown.enter.space="showVariableQuantityMenu = true"
              >
                <div class="variable-quantity-text">
                  {{ rowVariableQuantityField.value }}
                </div>
                <FriedIcon
                  :class="['chevron', { opened: showVariableQuantityMenu }]"
                  icon="chevron-down"
                />
              </div>
            </template>
            <div class="modifier-menu">
              <PricingTableUnitsModifierMenu
                :input-field="rowVariableQuantityField as any"
                :disabled="isDocumentView"
                :open="showVariableQuantityMenu"
                @update-field="handleUpdateVariableQuantityField"
                @close="showVariableQuantityMenu = false"
              />
            </div>
          </FriedFloater>
          <div v-else class="value">
            {{ rowVariableQuantityField.value }}
          </div>
        </template>
        <div
          v-else-if="rowValue.columnId === getColumnIdFromName(columns, EPricingTableColumn.Tax)"
          class="formatted-tax-text"
          :class="{ strikethrough: hasOptionalProductDeselected }"
          data-external="editor-pricing-table-row-value-tax"
        >
          {{ getRowFormattedTax(row, columns, currencySettings) }}
        </div>
        <div
          v-else
          :class="[
            'value',
            {
              strikethrough: hasOptionalProductDeselected,
            },
          ]"
        >
          {{ getFormat(rowValue) }}
        </div>
      </div>
      <template v-if="showTotalPrice">
        <div
          v-if="isPreCalculated"
          class="row-value total-price-column"
          :class="{ precalculated: isPreCalculated }"
        >
          <div :class="['total-price', { strikethrough: hasOptionalProductDeselected }]">
            {{ getFormattedPrice(getRowTotalPrice(isPreCalculated, row, columns, fields)) }}
          </div>
          <div
            v-show="showDiscount"
            tabindex="0"
            class="discount"
            :class="{ empty: getRowDiscountValueIsEmpty(columns, getRowValues(row)) }"
          >
            {{ getRowFormattedDiscount(isPreCalculated, row, columns, fields, currencySettings) }}
          </div>
          <div v-show="showDiscount" :class="['discounted-price']">
            {{ getFormattedPrice(getRowNetPrice(isPreCalculated, row, columns, fields)) }}
          </div>
        </div>
        <div v-else class="row-value total-price-column">
          <div class="total-price-container">
            <div class="header">
              {{
                getColumnDisplayName(
                  getColumnIdFromName(columns, EPricingTableColumn.TotalPrice),
                  columns
                )
              }}
            </div>
            <div
              class="total-price"
              :class="{
                strikethrough:
                  hasOptionalProductDeselected || hasDiscount(columns, getRowValues(row)),
              }"
            >
              {{
                currencyFormat(
                  getRowTotalPrice(isPreCalculated, row, columns, fields),
                  currencySettings
                )
              }}
            </div>
          </div>
          <div
            v-show="showDiscount"
            tabindex="0"
            class="discount"
            :class="{
              empty: getRowDiscountValueIsEmpty(columns, getRowValues(row)),
              strikethrough: hasOptionalProductDeselected,
            }"
          >
            {{ getRowFormattedDiscount(isPreCalculated, row, columns, fields, currencySettings) }}
          </div>
          <div
            v-show="hasDiscount(columns, getRowValues(row))"
            class="discounted-price"
            :class="{ strikethrough: hasOptionalProductDeselected }"
          >
            {{
              currencyFormat(
                getRowNetPrice(isPreCalculated, row, columns, fields),
                currencySettings
              )
            }}
          </div>
        </div>
      </template>
      <div class="row-divider"></div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  NumberInputType,
  EPricingTableColumn,
  PricingTableHelper,
  getRowDescriptionValue,
  getRowDiscountFlatFee,
  getRowDiscountValue,
  getRowDiscountValueIsEmpty,
  getRowFormattedDiscount,
  getRowNameValue,
  getRowNetPrice,
  getRowOptionalProductField,
  getRowPriceAfterDiscounts,
  getRowSkuValue,
  getRowTaxFlatFee,
  getRowTaxValue,
  getRowTaxValueIsEmpty,
  getRowTotalPrice,
  getRowValues,
  getRowVariableQuantityEnabled,
  getRowVariableQuantityField,
  getRowOptionalProductEnabled,
  getFilteredRowValues,
  getRowFormattedTax,
  getRowDescriptionIsEmpty,
  getRowSkuValueIsEmpty,
  getRowDiscountEnabled,
  getNumberInputType,
  hasDiscount,
  hasCustomColumn,
  Context,
  getTotalPriceEnabled,
} from '@getaccept/editor-lib-new';
import { PricingTableCheckbox } from '@getaccept/editor-lib-new/src/components/pricing-table';
import debounce from 'debounce';
import { SigningHelper } from '@getaccept/lib-shared-new/src/signature/helpers/signing.helper';
import type {
  PricingTableRow as IPricingTableRow,
  Field,
  PricingTableColumn,
  MergeValues,
  PricingTableCurrencySettings,
  PricingTableRowValue,
} from '@getaccept/editor-lib-new';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { RecipientStatus } from '@getaccept/lib-shared-new/src/recipients/enums/recipient-status';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { PropType, Ref } from 'vue';
import { onMounted, watch, computed, ref, defineComponent } from 'vue';
import { Placement } from '@getaccept/fried-tofu';
import PricingTableUnitsModifierMenu from './PricingTableUnitsModifierMenu.vue';
import PricingTableDescriptionInput from './PricingTableDescriptionInput.vue';

export default defineComponent({
  name: 'PricingTableRow',
  components: {
    PricingTableDescriptionInput,
    PricingTableCheckbox,
    PricingTableUnitsModifierMenu,
  },
  props: {
    row: { type: Object as PropType<IPricingTableRow>, default: () => ({}) },
    columns: { type: Array as PropType<PricingTableColumn[]>, default: () => [] },
    isBlockWidthSmall: { type: Boolean },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, default: () => [] },
    recipient: {
      type: Object as PropType<Recipient & { status: RecipientStatus }>,
      default: () => ({}),
    },
    context: { type: String as PropType<Context>, default: () => Context.Document },
    currencySettings: {
      type: Object as PropType<PricingTableCurrencySettings>,
      default: () => ({}),
    },
    gridStyle: { type: String, default: '' },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    isPreCalculated: { type: Boolean },
    allowedToEditAfterPartiallySigned: { type: Boolean },
  },
  emits: ['update-input-value'],
  setup(props, { emit }) {
    const rowVariableQuantityField: Ref<Partial<Field>> = ref({
      value: null,
    });

    const showVariableQuantityMenu = ref(false);

    const showDescription = computed(
      () => !getRowDescriptionIsEmpty(props.columns, getRowValues(props.row))
    );

    const showSku = computed(() => !getRowSkuValueIsEmpty(props.columns, getRowValues(props.row)));

    const showTotalPrice = computed(() => getTotalPriceEnabled(props.columns));

    const showDiscount = computed(
      () =>
        getRowDiscountEnabled(props.columns) &&
        !getRowDiscountValueIsEmpty(props.columns, getRowValues(props.row))
    );

    const isDocumentView = computed(() => props.context === Context.DocumentView);

    const recipientStatus = computed(() => props.recipient?.status);

    const isEditable = computed(
      () =>
        (props.allowedToEditAfterPartiallySigned &&
          !SigningHelper.hasRecipientSigned(recipientStatus.value) &&
          !isDocumentView.value) ||
        props.context === Context.DsrPublished
    );

    const isVariableQuantityEditable = computed(
      () =>
        (!SigningHelper.hasRecipientSigned(recipientStatus.value) &&
          props.allowedToEditAfterPartiallySigned) ||
        props.context === Context.DsrPublished
    );

    const hasOptionalProductDeselected = computed(
      () => rowOptionalProductInputField()?.value === '0'
    );

    const rowOptionalProductInputField = () => {
      let field = getRowOptionalProductField(props.row, props.fields);
      if (!field) {
        return null;
      }

      field = { ...field, value: getFieldValue(field) };
      return field;
    };

    const rowVariableQuantityInputField = () => {
      let field = getRowVariableQuantityField(props.row, props.fields);
      if (!field) {
        return null;
      }

      field = { ...field, value: getFieldValue(field) };
      return field;
    };

    const getFieldValue = (input: Field) => {
      if (!input) {
        return;
      }
      const fieldValue = props.recipientInput?.find(field => field.fieldId === input.id)?.value;

      if (fieldValue) {
        return fieldValue;
      }

      return input.value;
    };

    const handleDebounceUpdateVariableQuantityField = (payload: { id: string; value: string }) => {
      emit('update-input-value', payload);
    };

    const debounceUpdate = debounce(handleDebounceUpdateVariableQuantityField, 300);

    const handleUpdateVariableQuantityField = (payload: { id: string; value: string }) => {
      rowVariableQuantityField.value.value = payload.value;
      debounceUpdate(payload);
    };

    const handleUpdateChecked = (payload: { id: string; value: string }) => {
      emit('update-input-value', payload);
    };

    const handleCloseVariableQuantityMenu = () => {
      showVariableQuantityMenu.value = false;
    };

    const getFormattedPrice = (price: string) => {
      const value = price === '' ? '0' : price;
      return PricingTableHelper.currencyFormat(value, props.currencySettings);
    };

    const getFormat = (rowValue: PricingTableRowValue) => {
      const isCustom = hasCustomColumn(props.columns, rowValue.columnId);
      if (isCustom) {
        return rowValue.value || '';
      }

      const type = getNumberInputType(props.columns, rowValue.columnId);
      const value = rowValue.value || '0';

      switch (type) {
        case NumberInputType.Percentage:
          return `${value}%`;
        case NumberInputType.Currency:
          return PricingTableHelper.currencyFormat(value, props.currencySettings);
        case NumberInputType.Number:
        case NumberInputType.Text:
        default:
          return value;
      }
    };

    onMounted(() => {
      rowVariableQuantityField.value = rowVariableQuantityInputField();
    });

    watch(
      () => [props.fields, props.recipientInput],
      () => {
        rowVariableQuantityField.value = rowVariableQuantityInputField();
      }
    );

    return {
      rowVariableQuantityField,
      showVariableQuantityMenu,
      hasRecipientSigned: SigningHelper.hasRecipientSigned,
      getColumnDisplayName: PricingTableHelper.getColumnDisplayName,
      getColumnIdFromName: PricingTableHelper.getColumnIdFromName,
      currencyFormat: PricingTableHelper.currencyFormat,
      debounceUpdate,
      showDescription,
      showSku,
      showDiscount,
      isDocumentView,
      recipientStatus,
      isEditable,
      hasOptionalProductDeselected,
      rowOptionalProductInputField,
      rowVariableQuantityInputField,
      getFieldValue,
      handleUpdateVariableQuantityField,
      handleDebounceUpdateVariableQuantityField,
      handleUpdateChecked,
      handleCloseVariableQuantityMenu,
      getFormattedPrice,
      NumberInputType,
      getRowOptionalProductEnabled,
      getRowOptionalProductField,
      getRowDescriptionValue,
      getRowValues,
      EPricingTableColumn,
      getRowSkuValue,
      getFilteredRowValues,
      getRowVariableQuantityEnabled,
      isVariableQuantityEditable,
      getRowTaxValue,
      getRowTaxValueIsEmpty,
      getRowTaxFlatFee,
      getRowDiscountValueIsEmpty,
      getRowPriceAfterDiscounts,
      getRowTotalPrice,
      getRowFormattedDiscount,
      getRowFormattedTax,
      getRowNetPrice,
      getRowNameValue,
      getNumberInputType,
      getRowDiscountValue,
      getRowDiscountFlatFee,
      getRowVariableQuantityField,
      hasDiscount,
      getFormat,
      hasCustomColumn,
      showTotalPrice,
      Placement,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-row';

.rows-container {
  .row-value {
    &.name-column {
      .name {
        padding-left: var(--spacing-25);
        min-height: 1rem;
      }

      .sku {
        padding-left: var(--spacing-25);
      }
    }

    .value {
      word-break: break-word;
      padding-right: var(--spacing-25);
    }
  }
}

.variable-quantity-container {
  :deep(.floater-container) {
    z-index: 7;
  }
}
</style>
