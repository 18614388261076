export enum RecipientRole {
  None = 'none',
  Signer = 'signer',
  CC = 'cc',
  Approver = 'approver',
  ExternalApprover = 'externalApprover',
  /**
   *  ❗️ Should not be used, fallback to approver being sent incorrectly from API
   */
  InternalApproverAPIFallback = 'internalApprover',
}
