<template>
  <div class="no-access-info-container">
    <div class="icon-container">
      <FriedIcon icon="lock" />
    </div>
    <FriedH3>{{ t('dr_user_noaccess_title') }}</FriedH3>
    <FriedParagraph>{{ description }}</FriedParagraph>
  </div>
</template>
<script lang="ts">
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    description: { type: String, default: '' },
  },
  setup() {
    return { t };
  },
});
</script>
<style lang="scss" scoped>
.no-access-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-75);
  justify-content: center;
  background-color: var(--gray-92);
  border-radius: var(--radius-medium);
  padding: var(--spacing-100);
  height: 300px;

  .icon-container {
    background-color: var(--base-blue);
    border-radius: 50%;
    padding: var(--spacing-100);
    width: 52px;
    height: 52px;

    .svg-icon {
      color: var(--white);
    }
  }
}
</style>
