import type { ContentLinkBlock } from '@getaccept/lib-shared-new/src/types/content-link';
import type { Link, Node, NodeUpdatePayload, Row } from '../types';
import { NodeType, ProsemirrorType } from '../types';

export class LinkBlockHelper {
  static getLinkBlockText(heading: string, description: string): string {
    return JSON.stringify({
      type: ProsemirrorType.Doc,
      content: [this.getHeading(heading), this.getDescription(description)],
    });
  }

  static getHeading(heading: string) {
    const content = heading
      ? {
          content: [
            {
              type: ProsemirrorType.Text,
              text: heading,
            },
          ],
        }
      : {};

    return {
      ...content,
      type: ProsemirrorType.Heading,
    };
  }

  static getDescription(description: string) {
    const content = description
      ? {
          content: [
            {
              type: ProsemirrorType.Text,
              text: description,
            },
          ],
        }
      : {};

    return {
      ...content,
      type: ProsemirrorType.Paragraph,
    };
  }

  static hasLinkNode(nodes: Node[]): boolean {
    return nodes.some(({ type }) => type === NodeType.Link);
  }

  static rowHasLinkNode(row: Row): boolean {
    return row.cells.some(({ nodes }) => LinkBlockHelper.hasLinkNode(nodes));
  }

  static getLinkUrlFromRow(row: Row): string | undefined {
    const linkNode = row.cells
      .flatMap(cell => cell.nodes)
      .find(({ type }) => type === NodeType.Link) as Link;
    return linkNode?.linkUrl;
  }

  static convertPayload(
    preview: Partial<ContentLinkBlock>,
    imagePayload: NodeUpdatePayload
  ): NodeUpdatePayload {
    return {
      linkUrl: preview?.url,
      imageId: imagePayload?.imageId,
      attribution: imagePayload?.attribution,
      srcUrl: imagePayload?.srcUrl,
      description: LinkBlockHelper.getLinkBlockText(preview?.title, preview?.description),
      content: preview?.buttonText || 'Open Link',
    };
  }
}
