import { Paragraph } from '@tiptap/extension-paragraph';

export const ParagraphNode = Paragraph.extend({
  addKeyboardShortcuts() {
    return {};
  },

  addCommands() {
    return {};
  },
});
