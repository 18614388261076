import type { Ref } from 'vue';
import { computed } from 'vue';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import { useLaunchDarkly } from '@getaccept/lib-shared-new/src/launch-darkly/launch-darkly.composable';
import type { PricingTableColumn, PricingTableSection } from '../types/pricing-table';
import { PricingTableHelper } from '../helpers/pricing-table.helper';
import { EPricingTableColumn } from '../types/enums';
import { maxCustomColumns, minColumnWidth, nonGridColumns } from '../constants/pricing-table';
export function usePricingTableColumns(section: Ref<PricingTableSection>) {
  const { flags } = useLaunchDarkly();

  const columns = computed(() => section.value?.columns || []);
  const enabledColumns = computed(() => PricingTableHelper.getEnabledColumns(columns.value));
  const columnWidths = computed(() => enabledColumns.value?.map(({ width }) => width));

  const customColumns = computed(() => columns.value.filter(({ isCustom }) => isCustom));
  const customColumnThresholdReached = computed(() => {
    const maxColumns = flags.value.pricingTableMultipleColumns ? maxCustomColumns : 1;
    return customColumns.value.length >= maxColumns;
  });

  const discountColumn = computed(() => getColumnByName(EPricingTableColumn.Discount));
  const totalPriceColumn = computed(() => getColumnByName(EPricingTableColumn.TotalPrice));

  const maxEnabledColumnsReached = computed(
    () => enabledColumns.value.length >= Math.floor(100 / minColumnWidth)
  );

  const getColumnById = (columnId: string) => columns.value.find(({ id }) => id === columnId);

  const getColumnByName = (columnName: EPricingTableColumn) =>
    columns.value.find(({ name }) => name === columnName);

  const isColumnEnabled = (column: PricingTableColumn) => column?.enabled || false;
  const isGridColumn = (column: PricingTableColumn) =>
    !nonGridColumns.includes(column.name as EPricingTableColumn);

  const getColumnDisplayName = (column: PricingTableColumn) => {
    if (!column) {
      return '';
    }

    const columnName = column.name === EPricingTableColumn.TotalPrice ? 'total-price' : column.name;

    return column.displayName || t(columnName);
  };

  return {
    columns,
    enabledColumns,
    columnWidths,
    customColumns,
    discountColumn,
    totalPriceColumn,
    customColumnThresholdReached,
    maxEnabledColumnsReached,
    getColumnDisplayName,
    getColumnByName,
    getColumnById,
    isColumnEnabled,
    isGridColumn,
  };
}
