import type { Field, MergeValues } from '@getaccept/editor-lib-new';
import type { Editor as TipTapEditor } from '@tiptap/vue-3';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { ProsemirrorHelper } from '@getaccept/editor-lib-new/src/helpers/prosemirror.helper';

interface SetContentPayload {
  viewer: TipTapEditor;
  content: TextContentDoc | string;
  fields: Field[];
  mergeValues: MergeValues;
}

export const setContent = ({ viewer, content, fields, mergeValues }: SetContentPayload) => {
  if (viewer.view.hasFocus() || JSON.stringify(viewer.getJSON()) === JSON.stringify(content)) {
    return;
  }
  updateMergeValues({ viewer, content, fields, mergeValues });
};

export const updateMergeValues = ({ viewer, content, fields, mergeValues }: SetContentPayload) => {
  viewer.commands.setContent(content);
  viewer.view?.dispatch(
    ProsemirrorHelper.replaceMergeTagsWithText(viewer.view.state, fields, mergeValues)
  );
};

export const handleInit = (viewer: TipTapEditor, fields: Field[], mergeValues: MergeValues) => {
  viewer.view?.dispatch(
    ProsemirrorHelper.replaceMergeTagsWithText(viewer.state, fields, mergeValues)
  );
};
