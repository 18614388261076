<template>
  <div
    :id="`view-${sectionId}`"
    ref="sectionContainer"
    :class="[
      'section-container',
      {
        'is-dsr': isDsr,
      },
    ]"
  >
    <div v-if="isDsr" :class="['margin-top', { 'is-preview': isPreview }]"></div>
    <section
      :class="[
        'section',
        {
          'first-row-is-cover-image': rowContainsCoverSize(rows[0], NodeType.Image),
          'last-row-is-cover-image': rowContainsCoverSize(rows[rows.length - 1], NodeType.Image),
          'is-hidden': isHidden,
        },
      ]"
    >
      <DetachedCommentAnchor
        v-for="comment in commentsWithoutRowId"
        :id="comment.id"
        :key="comment.id"
      />
      <template v-for="(row, index) in filteredRows">
        <EditorSideBySideRow
          v-if="row.type === RowType.SideBySide"
          :key="row.id"
          :cells="row.cells"
          :theme="theme"
          :merge-values="mergeValues"
          :is-pdf-viewer="isPdfViewer"
          :block-width-class="blockWidthClass"
          :class="{ 'side-by-side-sibling': isPreviousRowSideBySide(index, rows) }"
          :page-width="pageWidth"
          :is-block-toggled="isBlockToggled"
          :comments="filterCommentsByRow(comments, row.id)"
          :selected-comment-id="selectedCommentId"
          :show-contextual-comments="showContextualComments"
          :create-contextual-comment-button-state="createContextualCommentButtonState"
          @create-comment="$emit('create-comment', { ...$event, rowId: row.id })"
          @select-comment="$emit('select-comment', $event)"
          @node-click="$emit('node-click', { ...$event, rowId: row.id })"
          @observe-node-visibility="$emit('observe-node-visibility', { ...$event, rowId: row.id })"
          @fetch-new-image="
            $emit('fetch-new-image', { ...$event, targetSectionId: sectionId, targetRowId: row.id })
          "
        />
        <EditorRowColumns
          v-else-if="row.type === RowType.Columns"
          :key="row.id"
          :block-width-class="blockWidthClass"
          :cells="row.cells"
          :column-count="row.columnCount"
          :is-pdf-viewer="isPdfViewer"
          :merge-values="mergeValues"
          :page-width="pageWidth"
          :is-block-toggled="isBlockToggled"
          :comments="filterCommentsByRow(comments, row.id)"
          :selected-comment-id="selectedCommentId"
          :show-contextual-comments="showContextualComments"
          :create-contextual-comment-button-state="createContextualCommentButtonState"
          @create-comment="$emit('create-comment', { ...$event, rowId: row.id })"
          @select-comment="$emit('select-comment', $event)"
          @observe-node-visibility="$emit('observe-node-visibility', { ...$event, rowId: row.id })"
          @fetch-new-image="
            $emit('fetch-new-image', { ...$event, targetSectionId: sectionId, targetRowId: row.id })
          "
        />
        <EditorRowSlideShow
          v-else-if="row.type === RowType.SlideShow"
          :key="row.id"
          :block-width-class="blockWidthClass"
          :cells="row.cells"
          :row-id="row.id"
          :column-count="row.columnCount"
          :is-pdf-viewer="isPdfViewer"
          :is-preview="isPreview"
          :page-width="pageWidth"
          :is-block-toggled="isBlockToggled"
          @observe-node-visibility="$emit('observe-node-visibility', { ...$event, rowId: row.id })"
          @fetch-new-image="
            $emit('fetch-new-image', { ...$event, targetSectionId: sectionId, targetRowId: row.id })
          "
        />
        <EditorRow
          v-else
          :key="row.id"
          :class="[{ 'first-row': index === 0 }, { 'last-row': row === rows[rows.length - 1] }]"
          :cells="row.cells"
          :context="context"
          :merge-values="mergeValues"
          :focused-required-id="focusedRequiredId"
          :is-pdf-viewer="isPdfViewer"
          :block-width-class="blockWidthClass"
          :contains-cover-image="rowContainsCoverSize(row, NodeType.Image)"
          :contains-cover-video="rowContainsCoverSize(row, NodeType.Video)"
          :page-width="pageWidth"
          :is-block-toggled="isBlockToggled"
          :partially-signed-fields="partiallySignedFields"
          :sender="sender"
          :pusher-client="pusherClient"
          :recipient="recipient"
          :allowed-to-edit-after-partially-signed="allowedToEditAfterPartiallySigned"
          :recipient-input="recipientInput"
          :updating-inputs="updatingInputs"
          :failed-input-updates="failedInputUpdates"
          :comments="filterCommentsByRow(comments, row.id)"
          :selected-comment-id="selectedCommentId"
          :show-contextual-comments="showContextualComments"
          :create-contextual-comment-button-state="createContextualCommentButtonState"
          :current-user="currentUser"
          :linked-contracts="linkedContracts"
          :mapped-placeholders="mappedPlaceholders"
          @update-input-value="$emit('update-input-value', $event)"
          @track-video="$emit('track-video', $event)"
          @observe-node-visibility="$emit('observe-node-visibility', { ...$event, rowId: row.id })"
          @partially-sign="$emit('partially-sign', $event)"
          @create-comment="$emit('create-comment', { ...$event, rowId: row.id })"
          @select-comment="$emit('select-comment', $event)"
          @fetch-new-image="
            $emit('fetch-new-image', { ...$event, targetSectionId: sectionId, targetRowId: row.id })
          "
        />
      </template>
      <div v-if="isHidden" class="hidden-overlay">
        <FriedIcon icon="view-hide" />
      </div>
    </section>
    <div v-if="isDsr" class="bottom-padding"></div>
  </div>
</template>
<script lang="ts">
import type { PropType, Ref } from 'vue';
import { nextTick, onBeforeUnmount, onMounted, ref, computed, defineComponent } from 'vue';
import type { EBlockWidth, Row, MergeValues, ContractListItem } from '@getaccept/editor-lib-new';
import {
  useObserveElement,
  NodeType,
  RowType,
  EditorHelper,
  Context,
  PlaceholderHelper,
} from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { SigningTheme } from '@getaccept/lib-shared-new/src/signing-theme/types/theme';
import type { PartiallySignedEvent } from '@getaccept/editor-lib-new/src/types/signed-field';
import type { PusherClient } from '@getaccept/pusher';
import type { Comment } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment';
import { CommentsHelper } from '@getaccept/lib-shared-new/src/contextual-commenting/helpers/comments.helper';
import { CreateContextualCommentButtonState } from '@getaccept/lib-shared-new/src/contextual-commenting/types/create-contextual-comment-button-state';
import DetachedCommentAnchor from '@getaccept/lib-shared-new/src/contextual-commenting/components/DetachedCommentAnchor.vue';
import type { Comment as DrComment } from '@getaccept/dsr-shared-new/src/contextual-commenting/types/comment';
import type { MappedContentPlaceholder } from '@getaccept/editor-lib-new/src/types/mapped-content-placeholder';
import EditorSideBySideRow from './EditorSideBySideRow.vue';
import EditorRow from './EditorRow.vue';
import EditorRowColumns from './EditorRowColumns.vue';
import EditorRowSlideShow from './EditorRowSlideShow.vue';

export default defineComponent({
  name: 'EditorSection',
  components: {
    DetachedCommentAnchor,
    EditorRow,
    EditorRowColumns,
    EditorSideBySideRow,
    EditorRowSlideShow,
  },
  props: {
    context: { type: String as PropType<Context>, default: () => Context.Document },
    focusedRequiredId: { type: String, default: '' },
    sectionId: { type: String, required: true },
    rows: { type: Array as PropType<Row[]>, default: () => [] },
    mergeValues: {
      type: Object as PropType<MergeValues>,
      default: () => ({ document: {}, sender: {}, recipients: [], entity: {} }),
    },
    isPdfViewer: { type: Boolean },
    isDsr: { type: Boolean },
    isPreview: { type: Boolean },
    isHidden: { type: Boolean },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    pageWidth: { type: Number, default: 0 },
    isBlockToggled: { type: Boolean },
    sender: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    theme: {
      type: Object as PropType<SigningTheme>,
      default: () => ({}),
    },
    recipient: {
      type: Object as PropType<Recipient>,
      default: () => ({}),
    },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    partiallySignedFields: { type: Array as PropType<PartiallySignedEvent[]>, default: () => [] },
    pusherClient: { type: Object as PropType<PusherClient>, default: undefined },
    comments: { type: Array as PropType<Array<Comment | DrComment>>, default: () => [] },
    selectedCommentId: { type: String, default: '' },
    showContextualComments: { type: Boolean },
    createContextualCommentButtonState: {
      type: String as PropType<CreateContextualCommentButtonState>,
      default: CreateContextualCommentButtonState.Enabled,
    },
    currentUser: { type: Object as PropType<User>, default: undefined },
    linkedContracts: { type: Array as PropType<ContractListItem[]>, default: () => [] },
    mappedPlaceholders: { type: Array as PropType<MappedContentPlaceholder[]>, default: () => [] },
    allowedToEditAfterPartiallySigned: {
      type: Boolean,
    },
  },
  emits: [
    'create-comment',
    'select-comment',
    'node-click',
    'observe-node-visibility',
    'fetch-new-image',
    'partially-sign',
    'track-video',
    'update-input-value',
    'update-element-visibility',
  ],
  setup(props, { emit }) {
    const filteredRows = computed(() =>
      props.rows.filter(row =>
        [Context.DsrTemplate, Context.DsrResource, Context.DsrPreview].includes(props.context)
          ? true
          : PlaceholderHelper.filterRowForPlaceholders(row)
      )
    );

    const sectionContainer: Ref<HTMLElement> = ref(null);

    const { observe, disconnect } = useObserveElement(emit);

    const initializeIntersectionObserver = async () => {
      await nextTick();
      if (!sectionContainer.value) {
        return;
      }
      observe(sectionContainer.value);
    };

    const rowIds = computed(() => props.rows.map(row => row.id));

    const commentsWithoutRowId = computed(() =>
      props.comments.filter(comment => !rowIds.value.includes(comment.editorSelection?.rowId))
    );

    onMounted(() => {
      if (!props.isDsr) {
        return;
      }
      initializeIntersectionObserver();
    });

    onBeforeUnmount(() => {
      if (!props.isDsr) {
        return;
      }
      disconnect();
    });

    return {
      commentsWithoutRowId,
      filterCommentsByRow: CommentsHelper.filterCommentsByRow,
      filteredRows,
      initializeIntersectionObserver,
      isPreviousRowSideBySide: EditorHelper.isPreviousRowSideBySide,
      NodeType,
      rowContainsCoverSize: EditorHelper.rowContainsCoverSize,
      RowType,
      sectionContainer,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-section';

section {
  &.hidden {
    opacity: 0.7;
  }
}
</style>
