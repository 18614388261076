export const urlMinimumLimit = 17;
export const vimeoPatterns: RegExp[] = [
  /(https?:\/\/vimeo.com\/)([0-9]+)/,
  /(https?:\/\/player.vimeo.com\/video\/)([0-9]*)/,
];
export const youtubePatterns: RegExp[] = [
  /youtu\.be\/([^#&?]{11})/,
  /\?v=([^#&?]{11})/,
  /&v=([^#&?]{11})/,
  /embed\/([^#&?]{11})/,
  /\/v\/([^#&?]{11})/,
];
