<template>
  <div class="pricing-summary-container" tabindex="0">
    <div v-if="getSubtotalEnabled(sectionSummary)" class="summary-container">
      <div v-if="getShowSubtotalDiscount()" class="discount-container">
        <div class="discount display-name-input-styling viewer">
          {{ (sectionSummary as PricingTableSummaryValues).discount.displayName }}
        </div>
        <div class="discount-value" :class="{ empty: getSubtotalDiscountIsEmpty(sectionSummary) }">
          {{ getSubtotalDiscount(sectionSummary, currencySettings) }}
        </div>
      </div>
      <div
        v-if="getShowSubtotalTax(columns, sectionSummary, isPreCalculated)"
        class="tax-container"
      >
        <div class="tax display-name-input-styling viewer">
          {{ (sectionSummary as PricingTableSummaryValues).tax.displayName }}
        </div>
        <div class="tax-value" :class="{ empty: getSubtotalTaxIsEmpty(sectionSummary) }">
          {{ currencyFormat(getSubtotalTax(sectionSummary), currencySettings) }}
        </div>
      </div>
      <div class="total-container">
        <div class="display-name-input-styling viewer">
          {{ (sectionSummary as PricingTableSummaryValues).price.displayName }}
        </div>
        <div class="total-value">
          {{ currencyFormat(getSubtotalValue(sectionSummary), currencySettings) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {
  getSubtotalEnabled,
  getSubtotalDiscountIsEmpty,
  getSubtotalDiscount,
  getShowSubtotalTax,
  getSubtotalTaxIsEmpty,
  getSubtotalValue,
  getSubtotalTax,
} from '@getaccept/editor-lib-new/src/helpers/pricing-table/pricing-table-price-summary.helper';
import type {
  PricingTableCurrencySettings,
  PricingTableSummaryValues,
  PricingTableColumn,
} from '@getaccept/editor-lib-new';
import { PricingTableHelper, EPricingTableColumn } from '@getaccept/editor-lib-new';
import type { PropType } from 'vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PricingTablePriceSummary',
  props: {
    sectionSummary: { type: Object as PropType<PricingTableSummaryValues>, default: () => ({}) },
    currencySettings: {
      type: Object as PropType<PricingTableCurrencySettings>,
      default: () => ({}),
    },
    isPreCalculated: { type: Boolean },
    columns: { type: Array as PropType<PricingTableColumn[]>, default: () => [] },
  },
  setup(props) {
    const getShowSubtotalDiscount = () => {
      const isSubTotalDiscountEmpty = getSubtotalDiscountIsEmpty(props.sectionSummary);
      if (!PricingTableHelper.getIsColumnEnabled(props.columns, EPricingTableColumn.Discount)) {
        return false;
      }
      if (props.isPreCalculated && !isSubTotalDiscountEmpty) {
        return true;
      }
      return !isSubTotalDiscountEmpty;
    };

    return {
      currencyFormat: PricingTableHelper.currencyFormat,
      getShowSubtotalDiscount,
      PricingTableHelper,
      getSubtotalEnabled,
      getSubtotalDiscountIsEmpty,
      getSubtotalDiscount,
      getShowSubtotalTax,
      getSubtotalTaxIsEmpty,
      getSubtotalValue,
      getSubtotalTax,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-summary';

.tax-value {
  &.pointer {
    cursor: pointer;
  }
}
</style>
