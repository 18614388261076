export enum DsrStatus {
  Draft = 'draft',
  Published = 'published',
  Unpublished = 'unpublished',
}

export enum DealroomStatus {
  Draft = 'Draft',
  Published = 'Published',
  Unpublished = 'Unpublished',
  Deleted = 'Deleted',
  Failed = 'Failed',
}
