import { CommentStatus } from '@getaccept/lib-shared-new/src/contextual-commenting/types/comment-status';

export class CommentHighlightsHelper {
  static getDecorationClass(
    status: CommentStatus,
    isSelected: boolean,
    isHovered: boolean,
    isVisible: boolean
  ) {
    if (!isVisible || status === CommentStatus.Resolved) {
      return 'decoration-invisible';
    }
    if (isHovered) {
      return 'decoration-hovered';
    }
    if (isSelected) {
      return 'decoration-selected';
    }
    if (status === CommentStatus.Active) {
      return 'decoration-active';
    }
    if (status === CommentStatus.Draft) {
      return 'decoration-draft';
    }
  }
}
