<template>
  <div class="input-editor-container">
    <EditorContent class="prosemirror-wrapper" :editor="viewer" spellcheck="false" />
  </div>
</template>
<script lang="ts">
import { Editor as TipTapEditor, EditorContent } from '@tiptap/vue-3';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import Text from '@tiptap/extension-text';
import type { MergeValues, Field } from '@getaccept/editor-lib-new';
import {
  ParagraphNode,
  BulletListNode,
  OrderedListNode,
  NoHeadingDocNode,
  NoHeadingListItemNode,
  MergeValueNode,
  HardBreakNode,
} from '@getaccept/editor-lib-new';
import type { PropType, Ref } from 'vue';
import { watch, computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue';
import type { TextContentDoc } from '@getaccept/lib-shared-new/src/types/text-content';
import { updateMergeValues, setContent, handleInit } from '../helpers/prosemirror-viewer.helper';

export default defineComponent({
  name: 'PricingTableDescriptionInput',
  components: {
    EditorContent,
  },
  props: {
    content: { type: [Object, String] as PropType<TextContentDoc | string>, default: '' },
    mergeValues: { type: Object as PropType<MergeValues>, default: () => ({}) },
    fields: { type: Array as PropType<Field[]>, default: () => [] },
  },
  setup(props) {
    const viewer: Ref<TipTapEditor | null> = ref(null);

    const contentPayload = computed(() => ({
      viewer: viewer.value,
      content: props.content,
      fields: props.fields,
      mergeValues: props.mergeValues,
    }));

    const inputViewer = computed(() => ({
      content: props.content,
      enableInputRules: false,
      enablePasteRules: false,
      editable: false,
      extensions: [
        NoHeadingDocNode,
        ParagraphNode,
        Text,
        Bold,
        Italic,
        Strike,
        Superscript,
        Underline,
        OrderedListNode,
        BulletListNode,
        NoHeadingListItemNode,
        Link.configure({
          autolink: false,
          openOnClick: true,
          linkOnPaste: false,
        }),
        MergeValueNode,
        HardBreakNode,
      ],
      onCreate: () => handleInit(viewer.value, props.fields, props.mergeValues),
    }));

    watch(
      () => [props.fields, props.mergeValues],
      () => {
        updateMergeValues(contentPayload.value);
      }
    );

    watch(
      () => props.content,
      () => {
        setContent(contentPayload.value);
      }
    );

    onMounted(() => {
      viewer.value = new TipTapEditor(inputViewer.value);
    });

    onBeforeUnmount(() => {
      viewer.value.destroy();
    });

    return {
      inputViewer,
      viewer,
      contentPayload,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/pricing-table-description';
</style>
