<template>
  <EditorNodeWrapper
    :class="['form-container', blockWidthClass]"
    @observe-node-visibility="$emit('observe-node-visibility', { ...$event, nodeId: node.id })"
  >
    <form @submit.prevent>
      <fieldset>
        <legend></legend>
        <InputSet
          v-for="(inputSet, index) in inputSets"
          :key="index"
          :class="['input-set', blockWidthClass]"
          :data-external="`view-form-input-row-${index}`"
          :input-set="inputSet"
          :context="context"
          :focused-required-id="focusedRequiredId"
          :merge-values="mergeValues"
          :fields="node.fields"
          :sender="sender"
          :user-id="userId"
          :recipient="recipient"
          :recipient-input="recipientInput"
          :is-mobile="isMobile"
          :is-pdf-viewer="isPdfViewer"
          :updating-inputs="updatingInputs"
          :failed-input-updates="failedInputUpdates"
          @update-value="updateInputValue($event.inputId, $event.value)"
          @keydown.stop
        />
      </fieldset>
    </form>
  </EditorNodeWrapper>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import { defineComponent, computed } from 'vue';
import type { MergeValues, Form, EBlockWidth } from '@getaccept/editor-lib-new';
import { Context, FieldInputType } from '@getaccept/editor-lib-new';
import type { Recipient } from '@getaccept/lib-shared-new/src/types/Recipient';
import type { User } from '@getaccept/lib-shared-new/src/users/types/user';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import InputSet from './InputSet.vue';
import EditorNodeWrapper from './EditorNodeWrapper.vue';

export default defineComponent({
  name: 'EditorNodeForm',
  components: {
    InputSet,
    EditorNodeWrapper,
  },
  props: {
    node: { type: Object as PropType<Form>, default: () => ({}) },
    context: { type: String as PropType<Context>, default: () => Context.Document },
    mergeValues: { type: Object as PropType<MergeValues>, required: true },
    isMobile: { type: Boolean },
    blockWidthClass: {
      type: String as PropType<EBlockWidth>,
      default: null,
    },
    focusedRequiredId: { type: String, default: '' },
    sender: {
      type: Object as PropType<User>,
      default: () => ({}),
    },
    recipient: {
      type: Object as PropType<Recipient>,
      default: () => ({}),
    },
    recipientInput: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    isPdfViewer: { type: Boolean, default: false },
    updatingInputs: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    failedInputUpdates: { type: Array as PropType<EditorRecipientInput[]>, required: true },
    userId: { type: String, default: '' },
  },
  emits: ['observe-node-visibility', 'update-input-value'],
  setup(props, { emit }) {
    const updateInputValue = (id: string, value: string) => {
      emit('update-input-value', { id, value });
    };

    const getFieldById = (id: string) => props.node.fields.find(field => field.id === id);

    const inputSets = computed(() =>
      props.node.inputFieldSets.map(inputSet => inputSet.ids.map(getFieldById))
    );

    return {
      FieldInputType,
      updateInputValue,
      inputSets,
      getFieldById,
    };
  },
});
</script>
<style lang="scss" scoped>
@import '@getaccept/editor-lib-new/src/scss/editor-node-form';
</style>
