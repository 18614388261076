// Normally, a load balancer forwards API requests to the correct region. At least one
// customer with custom DNS settings had requests forwarded to the wrong region, making
// our sites very slow for them. To solve their issue, we add the option to prefix the
// url with a region name and connect directly to that specific region.

export const getPrefixedInternalApiBaseUrl = (baseUrl: string, hostname: string) => {
  const regions = ['ap', 'eu', 'us'];
  const prefix = regions.find(region => hostname.startsWith(`${region}.`));

  return prefix ? baseUrl.replace('int', `${prefix}-int`) : baseUrl;
};

export const getPrefixedPublicApiBaseUrl = (baseUrl: string, hostname: string) => {
  const regions = ['ap', 'eu', 'us'];
  const prefix = regions.find(region => hostname.startsWith(`${region}.`));

  return prefix ? baseUrl.replace('pub', `${prefix}-pub`) : baseUrl;
};
