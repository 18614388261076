import { nanoid } from 'nanoid';
import { t } from '@getaccept/lib-shared-new/src/helpers/translation.helper';
import type { Product } from '@getaccept/lib-shared-new/src/product-library/types/product';
import type { EditorRecipientInput } from '@getaccept/lib-shared-new/src/types/editor-recipient-input';
import type { CurrencyFormatOptions } from '../types/pricing-table';
import type {
  PricingTableColumn,
  PricingTableRow,
  PricingTableRowValue,
  PricingTableSection,
  PricingTableSummaryValues,
  PricingTableCurrencySettings,
  Field,
} from '../types';
import { KeysAllowedType, EPricingTableColumn, CustomCurrency } from '../types/enums';
import {
  minColumnWidth,
  totalPriceColumnWidthPercent,
  customColumnNamePrefix,
  nonGridColumns,
} from '../constants';
import { EditorHelper } from './editor.helper';

export class PricingTableHelper {
  static currencyFormat(value: string, currencySettings: PricingTableCurrencySettings): string {
    if (!currencySettings || !currencySettings?.formatOptions?.currency) {
      return '';
    }
    const { locale, formatOptions } = currencySettings;
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      ...formatOptions,
    })
      .formatToParts(Number(value))
      .map(({ type, value }) => {
        if (type === 'currency') {
          return PricingTableHelper.getCustomCurrencyFormat(formatOptions) || value;
        }
        return value;
      })
      .reduce((string, part) => string + part);
  }

  static getCustomCurrencyFormat(formatOptions: CurrencyFormatOptions): string {
    return formatOptions.currency === CustomCurrency.Ore ? 'öre' : null;
  }

  static getNewPricingTableSection(): PricingTableSection {
    const columnNameId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnUnitsId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnDescriptionId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnSkuId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnPriceId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnNetPriceId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnTotalPriceId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnTaxId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const columnDiscountId = nanoid(EditorHelper.NANO_ID_LENGTH);
    return {
      id: nanoid(EditorHelper.NANO_ID_LENGTH),
      rows: [
        {
          id: nanoid(EditorHelper.NANO_ID_LENGTH),
          optionalProductFieldId: '',
          variableQuantityFieldId: '',
          values: [
            {
              columnId: columnUnitsId,
              value: '1',
            },
            {
              columnId: columnNameId,
              value: '',
            },
            {
              columnId: columnDescriptionId,
              value: '',
            },
            {
              columnId: columnSkuId,
              value: '',
            },
            {
              columnId: columnPriceId,
              value: '',
            },
            {
              columnId: columnNetPriceId,
              value: '',
            },
            {
              columnId: columnTotalPriceId,
              value: '',
            },
            {
              columnId: columnTaxId,
              value: '',
            },
            {
              columnId: columnDiscountId,
              value: '',
            },
          ],
        },
      ],
      columns: [
        {
          id: columnNameId,
          name: EPricingTableColumn.Name,
          displayName: t('name'),
          enabled: true,
          width: 40,
        },
        {
          id: columnDescriptionId,
          name: EPricingTableColumn.Description,
          displayName: t('description'),
          enabled: true,
          width: 0,
        },
        {
          id: columnSkuId,
          name: EPricingTableColumn.Sku,
          displayName: t('sku'),
          enabled: true,
          width: 0,
        },
        {
          id: columnPriceId,
          name: EPricingTableColumn.Price,
          displayName: t('price'),
          enabled: true,
          width: 20,
        },
        {
          id: columnUnitsId,
          name: EPricingTableColumn.Units,
          displayName: t('units'),
          enabled: true,
          width: 10,
        },
        {
          id: columnTaxId,
          name: EPricingTableColumn.Tax,
          displayName: t('tax'),
          enabled: false,
          width: 15,
        },
        {
          id: columnDiscountId,
          name: EPricingTableColumn.Discount,
          displayName: t('discount'),
          enabled: false,
          width: 0,
        },
        {
          id: columnNetPriceId,
          name: EPricingTableColumn.NetPrice,
          displayName: t('net-price'),
          enabled: true,
          width: 0,
        },
        {
          id: columnTotalPriceId,
          name: EPricingTableColumn.TotalPrice,
          displayName: t('total-price'),
          enabled: true,
          width: 30,
        },
      ],
      sectionSummary: {
        discount: {
          value: '',
          enabled: true,
          flatFee: false,
          displayName: t('discount'),
        },
        tax: {
          value: '',
          enabled: true,
          flatFee: false,
          displayName: t('tax'),
        },
        price: {
          value: '',
          enabled: true,
          flatFee: false,
          displayName: t('subtotal'),
        },
      },
    };
  }

  static getNewPricingTableRow(
    columns: PricingTableColumn[],
    partialProduct?: Partial<Product>
  ): PricingTableRow {
    const rowId = nanoid(EditorHelper.NANO_ID_LENGTH);
    const values = [
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Units).id,
        value: '1',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Name).id,
        value:
          partialProduct && EPricingTableColumn.Name in partialProduct
            ? partialProduct[EPricingTableColumn.Name]
            : '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Description).id,
        value:
          partialProduct && EPricingTableColumn.Description in partialProduct
            ? partialProduct[EPricingTableColumn.Description]
            : '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Sku).id,
        value:
          partialProduct && EPricingTableColumn.Sku in partialProduct
            ? partialProduct[EPricingTableColumn.Sku]
            : '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Price).id,
        value:
          partialProduct && EPricingTableColumn.Price in partialProduct
            ? partialProduct[EPricingTableColumn.Price]
            : '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.NetPrice).id,
        value: '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.TotalPrice).id,
        value: '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Tax).id,
        value:
          partialProduct &&
          EPricingTableColumn.Tax in partialProduct &&
          partialProduct[EPricingTableColumn.Tax] != null
            ? partialProduct[EPricingTableColumn.Tax]
            : '',
      },
      {
        columnId: columns.find(column => column.name === EPricingTableColumn.Discount).id,
        value: '',
      },
    ];

    const customColumns: PricingTableColumn[] = columns.filter(column => column.isCustom);

    if (customColumns.length > 0) {
      customColumns.forEach(customColumn => {
        values.push({
          columnId: customColumn.id,
          value: '',
        });
      });
    }

    return {
      id: rowId,
      optionalProductFieldId: '',
      variableQuantityFieldId: '',
      values,
    };
  }

  static getEnabledColumns(columns: PricingTableColumn[]): PricingTableColumn[] {
    return columns.filter(
      column => !nonGridColumns.includes(column.name as EPricingTableColumn) && column.enabled
    );
  }

  static getRowPrice(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const columnPriceId = columns.find(column => column.name === EPricingTableColumn.Price).id;
    return Number(row.values.find(rowValue => rowValue.columnId === columnPriceId).value);
  }

  static getRowUnits(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const columnUnitsId = columns.find(column => column.name === EPricingTableColumn.Units).id;
    return Number(row.values.find(rowValue => rowValue.columnId === columnUnitsId).value);
  }

  static getRowDiscount(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const columnDiscountId = columns.find(
      column => column.name === EPricingTableColumn.Discount
    ).id;
    return Number(row.values.find(rowValue => rowValue.columnId === columnDiscountId).value);
  }

  static getColumnIdFromName(columns: PricingTableColumn[], columnName: string) {
    return columns.find(column => column.name === columnName).id;
  }

  static getColumnNameId(columns: PricingTableColumn[]): string {
    return columns?.find(column => column.name === EPricingTableColumn.Name).id;
  }

  static getColumnUnitsId(columns: PricingTableColumn[]): string {
    return columns?.find(column => column.name === EPricingTableColumn.Units).id;
  }

  static getColumnTaxId(columns: PricingTableColumn[]): string {
    return columns.find(column => column.name === EPricingTableColumn.Tax).id;
  }

  static getColumnTotalPriceId(columns: PricingTableColumn[]): string {
    return columns.find(column => column.name === EPricingTableColumn.TotalPrice).id;
  }

  static getColumnDiscountId(columns: PricingTableColumn[]): string {
    return columns.find(column => column.name === EPricingTableColumn.Discount).id;
  }

  static getColumnNetPriceId(columns: PricingTableColumn[]): string {
    return columns.find(column => column.name === EPricingTableColumn.NetPrice).id;
  }

  static getIsColumnEnabled(
    columns: PricingTableColumn[],
    columnName: EPricingTableColumn
  ): boolean {
    return columns?.find(column => column.name === columnName)?.enabled;
  }

  static calculateRowTaxSum(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const taxEnabled = this.getIsColumnEnabled(columns, EPricingTableColumn.Tax);
    if (!taxEnabled) {
      return 0;
    }
    const { taxFlatFee, values, discountFlatFee } = row;
    const price = this.getRowPrice(row, columns);
    const units = this.getIsColumnEnabled(columns, EPricingTableColumn.Units)
      ? this.getRowUnits(row, columns)
      : 1;
    const totalPrice = price * units;
    const discount = this.getIsColumnEnabled(columns, EPricingTableColumn.Discount)
      ? this.getRowDiscount(row, columns)
      : 0;
    const priceAfterDiscount = discountFlatFee
      ? totalPrice - discount
      : totalPrice - totalPrice * (discount / 100);

    const columnTaxId = this.getColumnTaxId(columns);
    const rowTax = Number(values.find(rowValue => rowValue.columnId === columnTaxId)?.value);

    const taxSum = priceAfterDiscount * (rowTax / 100);

    if (taxFlatFee) {
      return rowTax;
    } else {
      return taxSum;
    }
  }

  static calculateRowDiscountSum(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const discountEnabled = this.getIsColumnEnabled(columns, EPricingTableColumn.Discount);
    if (!discountEnabled) {
      return 0;
    }
    const { discountFlatFee, values } = row;
    const rowTotalPrice = Number(this.calculateRowTotalPrice(row, columns).value);
    const columnDiscountId = this.getColumnDiscountId(columns);
    const rowDiscount = Number(
      values.find(rowValue => rowValue.columnId === columnDiscountId)?.value
    );

    if (discountFlatFee) {
      return rowDiscount;
    } else {
      return (rowTotalPrice * rowDiscount) / 100;
    }
  }

  static calculateRowTotalPrice(
    row: PricingTableRow,
    columns: PricingTableColumn[]
  ): PricingTableRowValue {
    const columnTotalPriceId = this.getColumnTotalPriceId(columns);
    const price = this.getRowPrice(row, columns);

    const units = this.getIsColumnEnabled(columns, EPricingTableColumn.Units)
      ? this.getRowUnits(row, columns)
      : 1;

    const value = String(price * units);

    return {
      columnId: columnTotalPriceId,
      value,
    };
  }

  static calculateRowNetPrice(
    row: PricingTableRow,
    columns: PricingTableColumn[]
  ): PricingTableRowValue {
    const columnTotalPriceId = this.getColumnTotalPriceId(columns);
    const columnNetPriceId = this.getColumnNetPriceId(columns);
    const rowTotalPrice = this.calculateRowTotalPrice(row, columns);
    const rowWithTotalPriceCalculated: PricingTableRow = {
      ...row,
      values: [
        ...row.values.filter(rowValue => rowValue.columnId !== columnTotalPriceId),
        rowTotalPrice,
      ],
    };
    const discount = this.getIsColumnEnabled(columns, EPricingTableColumn.Discount)
      ? this.calculateRowDiscountSum(rowWithTotalPriceCalculated, columns)
      : 0;

    const value = String(Number(rowTotalPrice.value) - discount);

    return {
      columnId: columnNetPriceId,
      value,
    };
  }

  static getPriceAfterDiscount(
    discountFlatFee: boolean,
    discountValue: string,
    subtotal: string
  ): number {
    if (discountFlatFee) {
      return Number(subtotal) - Number(discountValue);
    }
    return Number(subtotal) - (Number(subtotal) * Number(discountValue)) / 100;
  }

  static getPriceAfterTax(
    taxFlatFee: boolean,
    priceAfterDiscount: number,
    taxValue: string
  ): number {
    if (taxFlatFee) {
      return priceAfterDiscount + Number(taxValue);
    }
    return priceAfterDiscount + (priceAfterDiscount * Number(taxValue)) / 100;
  }

  static getRowDiscountValue(row: PricingTableRow, columns: PricingTableColumn[]): number {
    const discountEnabled = this.getIsColumnEnabled(columns, EPricingTableColumn.Discount);
    if (!discountEnabled) {
      return 0;
    }
    const { values } = row;
    const columnTaxId = this.getColumnTaxId(columns);
    const columnDiscountId = this.getColumnDiscountId(columns);
    const price = this.getRowPrice(row, columns);
    const units = this.getIsColumnEnabled(columns, EPricingTableColumn.Units)
      ? this.getRowUnits(row, columns)
      : 1;
    const rowTax = Number(values.find(rowValue => rowValue.columnId === columnTaxId)?.value);
    const discount = Number(values.find(rowValue => rowValue.columnId === columnDiscountId)?.value);
    const totalPrice = price * units + (price * units * rowTax) / 100;

    return (totalPrice * discount) / 100;
  }

  static calculateTotalPrice(summaryValues: PricingTableSummaryValues, subtotal: string): string {
    const {
      value: discountValue,
      flatFee: discountFlatFee,
      enabled: discountEnabled,
    } = summaryValues.discount;
    const { value: taxValue, flatFee: taxFlatFee, enabled: taxEnabled } = summaryValues.tax;

    const priceAfterDiscount = discountEnabled
      ? this.getPriceAfterDiscount(discountFlatFee, discountValue, subtotal)
      : Number(subtotal);

    const priceAfterDiscountAndTax = taxEnabled
      ? this.getPriceAfterTax(taxFlatFee, priceAfterDiscount, taxValue)
      : Number(priceAfterDiscount);

    return String(priceAfterDiscountAndTax);
  }

  static calculateRowsTotalValues(
    rows: PricingTableRow[],
    columns: PricingTableColumn[]
  ): PricingTableRow[] {
    const columnTotalPriceId = this.getColumnTotalPriceId(columns);
    const columnNetPriceId = this.getColumnNetPriceId(columns);
    return rows.map(row => {
      const rowTotalPrice: PricingTableRowValue = PricingTableHelper.calculateRowTotalPrice(
        row,
        columns
      );

      const rowNetPrice: PricingTableRowValue = PricingTableHelper.calculateRowNetPrice(
        row,
        columns
      );
      return {
        ...row,
        values: row.values.map(rowValue => {
          if (rowValue.columnId === columnTotalPriceId) {
            return rowTotalPrice;
          } else if (rowValue.columnId === columnNetPriceId) {
            return rowNetPrice;
          } else {
            return rowValue;
          }
        }),
      };
    });
  }

  static calculateSectionSummary(
    rows: PricingTableRow[],
    columns: PricingTableColumn[],
    sectionSummary: PricingTableSummaryValues
  ): PricingTableSummaryValues {
    const subtotalDiscount = String(
      rows
        .map(row => PricingTableHelper.calculateRowDiscountSum(row, columns))
        .reduce((a, b) => a + b, 0)
    );

    const subtotalTax = String(
      rows
        .map(row => PricingTableHelper.calculateRowTaxSum(row, columns))
        .reduce((a, b) => a + b, 0)
    );
    const rowsTotal = rows
      .map(row => Number(PricingTableHelper.calculateRowTotalPrice(row, columns).value))
      .reduce((a, b) => a + b, 0);
    const subtotal = String(rowsTotal - Number(subtotalDiscount) + Number(subtotalTax));

    return {
      price: {
        ...sectionSummary.price,
        value: subtotal,
      },
      discount: {
        ...sectionSummary.discount,
        value: subtotalDiscount,
      },
      tax: {
        ...sectionSummary.tax,
        value: subtotalTax,
      },
    };
  }

  static calculateSummaryValues(
    sections: PricingTableSection[],
    summaryValues: PricingTableSummaryValues
  ): PricingTableSummaryValues {
    const sectionsSubTotal = String(
      sections
        .map(section =>
          Number(
            this.calculateSectionSummary(section.rows, section.columns, section.sectionSummary)
              .price.value
          )
        )
        .reduce((a, b) => a + b, 0)
    );

    const totalPrice = PricingTableHelper.calculateTotalPrice(summaryValues, sectionsSubTotal);

    return {
      displayName: summaryValues.displayName,
      discount: {
        ...summaryValues.discount,
      },
      tax: {
        ...summaryValues.tax,
      },
      price: {
        ...summaryValues.price,
        value: totalPrice,
      },
    };
  }

  static toggleOptionalProductRow(
    rows: PricingTableRow[],
    rowId: string,
    optionalProductFieldId: string
  ): PricingTableRow[] {
    return rows.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          optionalProductFieldId,
        };
      } else {
        return {
          ...row,
        };
      }
    });
  }

  static toggleVariableQuantityRow(
    rows: PricingTableRow[],
    rowId: string,
    variableQuantityFieldId: string
  ): PricingTableRow[] {
    return rows.map(row => {
      if (row.id === rowId) {
        return {
          ...row,
          variableQuantityFieldId,
        };
      } else {
        return {
          ...row,
        };
      }
    });
  }

  static setDisabledColumnWidth(column: PricingTableColumn): number {
    return column.name === EPricingTableColumn.TotalPrice
      ? totalPriceColumnWidthPercent
      : minColumnWidth;
  }

  static disableColumn(columnId: string, section: PricingTableSection): PricingTableSection {
    const { columns } = section;
    const columnsAfterDisabling = this.getEnabledColumns(columns).filter(
      column => column.id !== columnId
    );
    const columnWidths = this.getAdjustedColumnWidths(columnsAfterDisabling);
    const updatedColumns: PricingTableColumn[] = columns.map(column => {
      if (column.id === columnId) {
        return {
          ...column,
          enabled: false,
          ...(column.id !== this.getColumnDiscountId(columns) && {
            width: this.setDisabledColumnWidth(column),
          }),
        };
      } else {
        return {
          ...column,
          ...(column.id !== this.getColumnDiscountId(columns) && {
            width: columnWidths[column.id] || column.width,
          }),
        };
      }
    });
    return {
      ...section,
      columns: updatedColumns,
    };
  }

  static disableColumnAndReCalculate(
    columnId: string,
    section: PricingTableSection
  ): PricingTableSection {
    const { rows, sectionSummary } = section;
    const { columns: columnsAfterDisabling } = this.disableColumn(columnId, section);

    const updatedRows = PricingTableHelper.calculateRowsTotalValues(rows, columnsAfterDisabling);
    const updatedSectionSummary = PricingTableHelper.calculateSectionSummary(
      updatedRows,
      columnsAfterDisabling,
      sectionSummary
    );
    return {
      ...section,
      rows: updatedRows,
      columns: columnsAfterDisabling,
      sectionSummary: updatedSectionSummary,
    };
  }

  static getAdjustedColumnWidths(enabledColumns: PricingTableColumn[]): { [key: string]: number } {
    if (enabledColumns.length === 1) {
      return { [enabledColumns[0].id]: 100 };
    }
    let totalDiff = enabledColumns.reduce((acc, curr) => acc + curr.width, 0) - 100;
    return enabledColumns.reduce((acc, curr) => {
      if (totalDiff === 0) {
        acc[curr.id] = curr.width;
        return acc;
      }
      const res = curr.width - totalDiff;
      if (res < minColumnWidth) {
        acc[curr.id] = minColumnWidth;
        const diff = curr.width - minColumnWidth;
        totalDiff -= diff;
        return acc;
      }
      acc[curr.id] = res;
      totalDiff = 0;
      return acc;
    }, {});
  }

  static enableColumn(columnId: string, section: PricingTableSection): PricingTableSection {
    const { columns } = section;
    const enabledColumn = columns.find(column => column.id === columnId);
    const columnsAfterEnabling = [...this.getEnabledColumns(columns), enabledColumn];
    const columnWidths = this.getAdjustedColumnWidths(columnsAfterEnabling);
    const updatedColumns: PricingTableColumn[] = columns.map(column => ({
      ...column,
      ...(column.id === columnId && { enabled: true }),
      ...(column.id !== this.getColumnDiscountId(columns) && {
        width: columnWidths[column.id] || column.width,
      }),
    }));
    return {
      ...section,
      columns: updatedColumns,
    };
  }

  static enableColumnAndReCalculate(
    columnId: string,
    section: PricingTableSection
  ): PricingTableSection {
    const { rows, sectionSummary } = section;
    const { columns: columnsAfterEnabling } = this.enableColumn(columnId, section);

    const updatedRows = PricingTableHelper.calculateRowsTotalValues(rows, columnsAfterEnabling);
    const updatedSectionSummary = PricingTableHelper.calculateSectionSummary(
      updatedRows,
      columnsAfterEnabling,
      sectionSummary
    );
    return {
      ...section,
      rows: updatedRows,
      columns: columnsAfterEnabling,
      sectionSummary: updatedSectionSummary,
    };
  }

  static createCustomColumn(section: PricingTableSection, index?: number): PricingTableSection {
    const noOfExistingCustomColumns = section.columns.filter(({ isCustom }) => isCustom).length;
    const id = nanoid(EditorHelper.NANO_ID_LENGTH);
    const name = `${customColumnNamePrefix}${noOfExistingCustomColumns + 1}`;

    const newCustomColumn: PricingTableColumn = {
      id,
      name,
      displayName: t('cpq_custom_column'),
      enabled: true,
      width: minColumnWidth,
      isCustom: true,
    };

    const enabledColumns = this.getEnabledColumns(section.columns);
    const columnWidths = this.getAdjustedColumnWidths([...enabledColumns, newCustomColumn]);
    const updatedColumns: PricingTableColumn[] = section.columns.map(column => ({
      ...column,
      width: columnWidths[column.id] || column.width,
    }));

    const enabledColumnsInsertionIndex = index ?? enabledColumns.length - 1;
    const columnIdAtIndex = enabledColumns[enabledColumnsInsertionIndex].id;

    const updatedColumnsInsertionIndex = updatedColumns.findIndex(
      ({ id }) => id === columnIdAtIndex
    );
    updatedColumns.splice(updatedColumnsInsertionIndex, 0, newCustomColumn);

    const updatedRows = section.rows.map(row => ({
      ...row,
      values: [...row.values, { columnId: id, value: '' }],
    }));

    return {
      ...section,
      rows: updatedRows,
      columns: updatedColumns,
    };
  }

  static getColumnDisplayName(columnId: string, columns: PricingTableColumn[]): string {
    const column = columns.find(({ id }) => id === columnId);
    if (!column) {
      return '';
    }
    const columnName = column.name === EPricingTableColumn.TotalPrice ? 'total-price' : column.name;

    return column.displayName || t(columnName);
  }

  static isKeyAllowed(evt: KeyboardEvent, type?: KeysAllowedType): void {
    const keysAllowed: string[] = [
      'Enter',
      'Esc',
      '0',
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
    ];
    if (type === KeysAllowedType.Decimals) {
      keysAllowed.push('.', ',');
    }
    const keyPressed: string = evt.key;

    if (!keysAllowed.includes(keyPressed)) {
      evt.preventDefault();
    }
  }

  static moveArrayItemUp(array: any[], item: any) {
    const index = array.indexOf(item);
    if (index === -1 || index === 0) {
      return array;
    }
    const newArray = [...array];
    newArray.splice(index - 1, 2, array[index], array[index - 1]);
    return newArray;
  }

  static moveArrayItemDown(array: any[], item: any) {
    const index = array.indexOf(item);
    if (index === -1 || index === array.length - 1) {
      return array;
    }
    const newArray = [...array];
    newArray.splice(index, 1);
    newArray.splice(index + 1, 0, item);
    return newArray;
  }

  static getEnabledRows(rows: PricingTableRow[], fields: Field[]): PricingTableRow[] {
    return rows.filter(
      row => !fields.some(field => field.id === row.optionalProductFieldId && field.value === '0')
    );
  }

  static getRowsFromInputFieldValues(
    rows: PricingTableRow[],
    columns: PricingTableColumn[],
    fields: Field[]
  ): PricingTableRow[] {
    return rows.map(row => PricingTableHelper.getRowFromInputFieldValues(row, columns, fields));
  }

  static getRowFromInputFieldValues(
    row: PricingTableRow,
    columns: PricingTableColumn[],
    fields: Field[]
  ): PricingTableRow {
    const field = fields.find(field => field.id === row.variableQuantityFieldId);
    const unitsEnabled = this.getIsColumnEnabled(columns, EPricingTableColumn.Units);
    if (field) {
      return {
        ...row,
        values: row.values.map(rowValue => {
          if (unitsEnabled && rowValue.columnId === PricingTableHelper.getColumnUnitsId(columns)) {
            return {
              ...rowValue,
              value: field.value,
            };
          } else {
            return rowValue;
          }
        }),
      };
    } else {
      return row;
    }
  }

  static sectionsWithEnabledRows(
    sections: PricingTableSection[],
    fields: Field[]
  ): PricingTableSection[] {
    return sections.map(section => ({
      ...section,
      rows: PricingTableHelper.getEnabledRows(section.rows, fields),
    }));
  }

  static sectionsWithRowsFromInputValues(
    sections: PricingTableSection[],
    fields: Field[]
  ): PricingTableSection[] {
    return sections.map(section => ({
      ...section,
      rows: PricingTableHelper.getRowsFromInputFieldValues(section.rows, section.columns, fields),
    }));
  }

  static tableContainsOptProdOrVarQty(sections: PricingTableSection[]): boolean {
    return sections?.some(section =>
      section.rows.some(row => !!row.optionalProductFieldId || !!row.variableQuantityFieldId)
    );
  }

  static fieldsWithRecipientInput = (fields: Field[], recipientInput: EditorRecipientInput[]) =>
    fields?.map(field => ({
      ...field,
      value: recipientInput?.find(input => input.fieldId === field.id)?.value || field.value,
    })) || [];

  static getGridStyle = (columnWidths: number[]) => {
    if (!columnWidths || columnWidths.length === 0) {
      return '';
    }

    return `grid-template-columns: ${columnWidths?.join('% ')}%;`;
  };

  static getSectionSummary = (
    section: PricingTableSection,
    fields: Field[],
    isPreCalculated: boolean
  ) => {
    if (isPreCalculated) {
      return section.sectionSummary;
    }

    const rowsFromInputFieldValues = PricingTableHelper.getRowsFromInputFieldValues(
      PricingTableHelper.getEnabledRows(section.rows, fields),
      PricingTableHelper.getEnabledColumns(section.columns),
      fields
    );

    return PricingTableHelper.calculateSectionSummary(
      rowsFromInputFieldValues,
      section.columns,
      section.sectionSummary
    );
  };
}
