import gql from 'graphql-tag';

export const updateEditorInputFieldMutation = gql`
  mutation updateEditorInputField($value: String!, $fieldId: String!, $blockId: String!) {
    updateEditorInputField(value: $value, fieldId: $fieldId, blockId: $blockId)
  }
`;

export const updateDealroomEditorInputField = gql`
  mutation updateDealroomInputField($value: String!, $fieldId: String!, $blockId: String!) {
    updateDealroomInputField(value: $value, fieldId: $fieldId, blockId: $blockId)
  }
`;

export const partiallySignDocumentMutation = gql`
  mutation partiallySignDocument($signature: String!, $nodeId: String!) {
    partiallySignDocument(signature: $signature, nodeId: $nodeId)
  }
`;
