import type { SetupContext } from 'vue';

export function useObserveElement(emit: SetupContext['emit']) {
  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        emit('update-element-visibility', {
          visible: entry.isIntersecting,
        });
      });
    },
    {
      threshold: [0, 1],
      rootMargin: '-125px 0px -125px 0px',
    }
  );

  const observe = (element: HTMLElement) => {
    observer.observe(element);
  };

  const disconnect = () => {
    observer.disconnect();
  };

  return { observe, disconnect };
}
