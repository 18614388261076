import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { Ref } from 'vue';
import type { Field, MergeValues } from '../types';

export const useEditorFieldsStore = defineStore('editor-fields', () => {
  const allFields: Ref<Field[]> = ref([]);
  const mergeValues = ref<MergeValues>(null);
  const signatureFields: Ref<Field[]> = ref([]);
  const localFields = ref<Field[]>([]);

  const setFields = (fields: Field[]) => {
    allFields.value = fields;
  };

  const setLocalFields = (fields: Field[]) => {
    localFields.value = fields;
  };

  const setMergeValues = (values: MergeValues) => {
    mergeValues.value = values;
  };

  const setSignatureFields = (fields: Field[]) => {
    const allSignatureFields = [...signatureFields.value, ...fields];
    const uniqueSignatureFields = Array.from(new Set(allSignatureFields));
    signatureFields.value = uniqueSignatureFields;
  };

  return {
    setFields,
    allFields,
    setMergeValues,
    mergeValues,
    setSignatureFields,
    signatureFields,
    setLocalFields,
    localFields,
  };
});
